import { ExportUsageModel } from 'shared-puredio';
import { BaseApi, IStoreScope } from './base.api';
import { firestore } from './firebase-init';

export class ExportUsageApi extends BaseApi<ExportUsageModel> {
	constructor() {
		super();
		this.collection = 'exportusage';
		this.needsUserId = true;
	}

	createNewInstance(data?: any): ExportUsageModel {
		return new ExportUsageModel(data!);
	}
}

export class AdminExportUsageApi extends BaseApi<ExportUsageModel> {
	constructor() {
		super();
		this.collection = 'exportusage';
		this.needsUserId = true;
	}

	createNewInstance(data?: any): ExportUsageModel {
		return new ExportUsageModel(data!);
	}

	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		let ref = firestore.collection(this.collection);
		ref.where('userId', '==', scope.userId);

		if (scope.projectId) {
			ref.where('projectId', '==', scope.projectId);
		}

		if (scope.taskGroupId) {
			ref.where('taskGroupId', '==', scope.taskGroupId);
		}
		if (scope.taskType) {
			ref.where('type', '==', scope.taskType);
		}

		return ref;
	}
}
