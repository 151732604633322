import React, { useEffect, useState } from 'react';
import { IProps, useStore } from './../context';
import { observer } from 'mobx-react';
import ProjectList from '../components/Project/ProjectList';
import BaseContainer from '../containers/BaseContainer';
import SideMenu from '../components/SideMenu';
import { GroupModel } from 'shared-puredio';
import ProjectListTitle from 'app/components/Project/ProjectListTitle';
import t from 'app/common/translate';

const ProjectsContainer: React.FC<IProps> = observer((props: IProps) => {
	const store = useStore();

	const [group, setGroup] = useState<GroupModel | undefined>(undefined);
	const [notFound, setNotFound] = useState<boolean>(false);
	const groupId = props.match.params.groupId;

	useEffect(() => {
		const getGroup = async () => {
			const group = await store.groupStore.getById(groupId).catch((e) => e);
			if (group) {
				setGroup(group);
			} else {
				setNotFound(true);
			}
		};

		store.projectStore.resetEntity();
		getGroup();
	}, [groupId, store]);

	const showButton = () => {
		if (!group) return false;

		const data = store.projectStore.projectApi.list.filter((p) => p.groupId === group.id);

		return data.length === 0;
	};

	return (
		<BaseContainer menu={<SideMenu />} navbar={group ? <ProjectListTitle group={group} hideActions={showButton()} /> : <></>}>
			{group && <ProjectList group={group} />}
			{notFound && <div>{t('general:group.not.found')}</div>}
		</BaseContainer>
	);
});

export default ProjectsContainer;
