import React from 'react';
import { UserModel } from 'shared-puredio';
import * as Yup from 'yup';
import t from 'app/common/translate';
import { Formik, Field, Form } from 'formik';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Button } from 'app/components/common/Button';
import FormField from 'app/components/Form/FormField';

const userEditSchema = Yup.object()
	.shape({
		email: Yup.string().email(t('validation:invalid.email')).required(t('validation:required.field')).default(''),
		firstName: Yup.string().required(t('validation:required.field')).default(''),
		lastName: Yup.string().required(t('validation:required.field')).default(''),
	})
	.required();
type UserEdit = Yup.InferType<typeof userEditSchema>;

interface IUserEditProps {
	user: UserModel;
	onDone: (g: UserModel) => void;
}

export const AdminUserDetailEditForm: React.FC<IUserEditProps> = observer((props: IUserEditProps) => {
	const { adminUserStore } = useStore();
	const user = props.user;

	async function handleSubmit(values: UserEdit) {
		user.firstName = values.firstName;
		user.lastName = values.lastName;
		user.email = values.email;
		const m = await adminUserStore.userApi.save(user.fakeId, user);
		props.onDone(m);
	}

	const initialValues = {
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
	};

	const onCancel = () => {
		props.onDone(user);
	};

	return (
		<>
			<div className="form-group">
				<Formik validationSchema={userEditSchema} initialValues={initialValues} onSubmit={handleSubmit}>
					{({ errors, touched }) => {
						return (
							<Form className="group-edit-form">
								<FormField className="" label={'firstName'} error={errors.firstName} touched={touched.firstName}>
									<Field type="text" name="firstName" />
								</FormField>
								<br />
								<FormField className="" label={'lastName'} error={errors.lastName} touched={touched.lastName}>
									<Field type="text" name="lastName" />
								</FormField>
								<FormField className="" label={'email'} error={errors.email} touched={touched.email}>
									<Field type="text" name="email" />
								</FormField>

								<div className="actions">
									<Button type="submit" className="button is-primary">
										{t('common:save')}
									</Button>
									<Button type="button" className="button is-secondary" onClick={onCancel}>
										Abbrechen
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</>
	);
});
