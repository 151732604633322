import gsap from 'gsap';
import { LayerAnimatedTextModel } from '../../models/layer.animted.text.model';
import { LayerType } from '../..';
import TextArea from './TextArea';

/*
subitle restrictions: 
- max 43 chars per line 
- max two lines per subtitle
*/

export default class AnimatedText extends TextArea {
    public layerType: LayerType = LayerType.animatedText;

    animationEnabled: boolean = true;
    animatedTextLayer = new LayerAnimatedTextModel();

    public tick(currentframe) {
        if (!this.phrases) {
            return true;
        }
        this.drawText(currentframe);
        return true;
    }

    public getTextForFrame = (currentframe: number) => {
        // this.subtitleLayer.setSubs(zwesprecherDummy);
        let unix = 0;
        if (currentframe) {
            unix = currentframe / 30;
        }

        this.animatedTextLayer.setPhrasesFromOpts(this.phrases);
        const p = this.animatedTextLayer.getPharseForTime(unix);

        return p ? p.text : undefined;
    };

    public updateTextContent = () => {
        this.text.content = this.getTextForFrame(Math.floor((gsap.globalTimeline.time() - this.anim.audioLayer.start) * 30));
        this.drawBackgroundRect();
        this.controlGroup.bind(this);

        // this.optsUpdate({
        //     x: { v: this.controlGroup.calcFromPixel('w', this.group.position.x), d: 'w' },
        //     y: { v: this.controlGroup.calcFromPixel('h', this.group.position.y), d: 'h' },
        // });
    };
}
