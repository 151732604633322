import { observer } from 'mobx-react';
import React from 'react';
import { PaymentPlan, PlanDefinitions, PlanType, UserModel } from 'shared-puredio';
import { PerkThing } from './Perks';
import PlanRemainingTime from './PlanRemainingTime';
import t from 'app/common/translate';
import { formatCurrency, formatDate } from 'app/utils';
import moment from 'moment';
import { PaddleInfo } from './PaddleInfo';
import { Button } from '../common/Button';
import { useStore } from 'app/context';
import { PaymentCancelSubscriptionModal } from './PaymentCancelSubscriptionModal';
import { PaymentChangeSubscriptionModal } from './PaymentChangeSubscriptionModal';
import ContactButtonWithModal from '../common/ContactButtonWithModal';
import { Trans } from 'react-i18next';

interface IPlansList {
	user: UserModel;
}

export const PlansList = observer((props: IPlansList) => {
	const user = props.user;
	const activePlan = PlanDefinitions.find((p) => p.planType === user.activePlan);
	const otherPlans = PlanDefinitions.filter((p) => p.planType !== user.activePlan && p.planType !== PlanType.custom);
	const customPlan = PlanDefinitions.find((p) => p.planType !== user.activePlan && p.planType === PlanType.custom);
	otherPlans.sort((a, b) => (a.price > b.price ? 1 : -1));

	return (
		<>
			<section className="section pad-top-1rem">
				<div className="container payment has-subscription">
					<PlanRemainingTime hideButton={true} />

					<div className="payment-options-list">
						{activePlan && <PlanTile plan={activePlan} isCurrent={true} user={user} />}
						{otherPlans.map((p) => (
							<PlanTile plan={p} key={p.planType} user={user} />
						))}
						{customPlan && <CustomPlanTile />}
					</div>
				</div>
			</section>
		</>
	);
});

interface IPlanTile {
	plan: PaymentPlan;
	user: UserModel;
	isCurrent?: boolean;
}
export const PlanTile = observer((props: IPlanTile) => {
	const { uiStore, paddleStore } = useStore();
	const { plan, isCurrent, user } = props;
	const type = plan.planType;
	const modalId = uiStore.modalIds.changeSubscription + type;
	const isDowngrade = plan.rank < user.activeRank;

	const onCancelSub = () => {
		uiStore.showModal(uiStore.modalIds.cancelSubscription);
	};
	const onCancelDone = () => {
		uiStore.hideModal(uiStore.modalIds.cancelSubscription);
	};

	const onChangeDone = () => {
		uiStore.hideModal(modalId);
	};

	const openCheckout = () => {
		if (user.hasActiveSubscription) {
			uiStore.showModal(modalId);
		} else {
			paddleStore.openCheckout(plan.monthlyPlanId);
		}
	};

	return (
		<>
			<div className={`payment-option ${isCurrent ? 'is-current' : ''}`}>
				<div className="title is-5">
					{t(plan.title)}
					{isCurrent && <span className="current">{t('payment:plan.current')}</span>}
				</div>

				<div className="price-box">
					<strong>{formatCurrency(plan.price)}</strong>
					{isCurrent && (
						<>
							{user.freeUntil && (
								<span className="tag is-small is-round is-primary">
									{t('payment:plan.freeuntil')} {formatDate(moment(user.freeUntil).toDate(), 'DD.MM.YYYY')}
								</span>
							)}
							{!user.freeUntil && user.activeSubscriptionIsEnding && (
								<span className="tag is-small is-round is-red">
									{t('payment:plan.active.until')} {formatDate(moment(user.activeSubscriptionEndDate).toDate(), 'DD.MM.YYYY')}
								</span>
							)}
						</>
					)}
				</div>

				<div className="plan-message">{t(plan.message)}</div>

				<div className="perks-list">
					{plan.perks.map((perk) => (
						<PerkThing key={perk.perk} perk={perk} />
					))}
				</div>

				<div className="actions">
					<>
						<PaddleInfo />

						{user.hasCancelableSubscription && user.activePlan === type && type !== PlanType.starter && (
							<>
								<Button className={`button is-primary is-inverted`} onClick={onCancelSub}>
									{t('payment:plan.cancel')}
								</Button>
							</>
						)}
						{user.canUpgrade && user.activePlan !== type && type !== PlanType.starter && type !== PlanType.custom && (
							<>
								<Button className={`button is-primary is-inverted`} onClick={openCheckout}>
									{t('payment:plan.upgrade')}
								</Button>
							</>
						)}
						{user.canUpgrade && user.activePlan !== type && type === PlanType.custom && (
							<>
								<ContactButtonWithModal art={'EnterpriseRequest'} className="button is-primary">
									{t('general:support.type.enterpriserequest')}
								</ContactButtonWithModal>
							</>
						)}
					</>
				</div>

				<div className="debug">
					monthlyId: {plan.monthlyPlanId} <br />
					yearlyId: {plan.yearlyPlanId}
				</div>
			</div>
			<PaymentCancelSubscriptionModal onDone={onCancelDone} />
			<PaymentChangeSubscriptionModal onDone={onChangeDone} modalId={modalId} newPlan={plan} isDowngrade={isDowngrade} />
		</>
	);
});

export const CustomPlanTile = () => {
	return (
		<>
			<div className={`payment-option is-custom-plan`}>
				<h2 className="title is-3">{t('plans:enterprise.title')}</h2>
				<div className="plan-message">
					<Trans i18nKey="plans:enterprise.message">bla</Trans>
				</div>
				<div>
					<a className="button is-primary" href={t('plans:enterprise.calendly')} target="_blank" rel="noopener noreferrer">
						{t('common:makeAppointment')}
					</a>
				</div>
			</div>
		</>
	);
};
