import React from 'react';
import { notify } from 'app/common/notify';
import { Button } from './Button';
import t from 'app/common/translate';
import { useStore } from 'app/context';
import { toJS } from 'mobx';
import { RenderItemVM } from 'app/stores/viewmodels/render.item.vm';

interface IRenderAbort {
	renderItem: RenderItemVM;
}

export const RenderAbort = (props: IRenderAbort) => {
	const model = props.renderItem;
	const store = useStore();
	const abortRender = async () => {
		const res = await store!.projectStore.abortRender(model);
		if (res && res.result && res.result.errorCode) {
			let msg = t('api:' + res.result.errorCode);
			console.log(toJS(res.result));
			notify(msg, 'error');
		}
	};

	return (
		<>
			<Button className="button is-secondary" onClick={abortRender}>
				{t('general:render.abort')}
			</Button>
		</>
	);
};
