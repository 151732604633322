import { MailModel } from 'shared-puredio';
import { BaseApi, IStoreScope } from './base.api';
import { firestore } from './firebase-init';

export class AdminMailApi extends BaseApi<MailModel> {
	constructor() {
		super();
		this.collection = 'mail';
		this.needsUserId = false;
	}

	getCollection() {
		return firestore.collection(this.collection);
	}

	createNewInstance(data?: any): MailModel {
		const m = new MailModel(data);
		Object.assign(m, data);
		m.fixDate('created');
		m.fixDate('verifyDate');
		return m;
	}

	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		const ref = firestore.collection(this.collection);
		return ref;
		// return ref.where('userId', '==', scope.userId);
	}
}
