import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { ProjectRender } from './ProjectRender';
import { Modal } from '../../common/Modal';
import { useStore } from 'app/context';

interface IExportsManageModalProps {
	project: ProjectVM;
	modalId: string;
}

export const ProjectRenderModal = observer((props: IExportsManageModalProps) => {
	const project = props.project;
	const { uiStore } = useStore();
	return (
		<>
			<Modal title={''} modalId={props.modalId} size="large">
				<ProjectRender projectVM={project} onDone={() => uiStore.hideModal(props.modalId)} />
			</Modal>
		</>
	);
});
