import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import t from 'app/common/translate';
import { ProjectModel } from 'shared-puredio';

interface IProjectDeleteModalProps {
	project: ProjectModel;
	onDone: () => void;
}

export const ProjectDeleteForm: React.FC<IProjectDeleteModalProps> = observer((props: IProjectDeleteModalProps) => {
	const { projectStore } = useStore();
	const project = props.project;

	const onDelete = async () => {
		await projectStore.deleteById(project.id);

		props.onDone();
	};
	return (
		<>
			<div className="project-delete align-center">
				<div className="title is-3 align-center">{t('general:project.delete')}</div>
				<div className="content pad-1rem">
					<p>{t('general:group.delete.are.you.sure')}</p>
				</div>
				<div className="content pad-1rem">
					<strong>{t('general:project.delete.are.you.really.sure')}</strong>
				</div>

				<div className="pad-1rem">
					<div className="align-center">
						<button className="button" onClick={props.onDone}>
							{t('common:cancel')}
						</button>
						<button className="button is-primary" onClick={onDelete}>
							{t('common:delete')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
});
