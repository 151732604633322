import { Table } from 'app/components/Table/table';
import { TableModel, TableRow } from 'app/components/Table/table.model';
import { useStore } from 'app/context';
import { AdminProjectVm } from 'app/stores/admin.project.store';
import { formatDate } from 'app/utils';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminTemplatesList: React.FC = observer(() => {
	const history = useHistory();
	const { adminProjectStore } = useStore();

	const tm = useMemo(() => {
		const viewDoc = (doc: AdminProjectVm) => {
			runInAction(() => {
				history.push(routes.ADMINTEMPLATES.getPath(doc.project.id));
			});
		};

		const tm = new TableModel<AdminProjectVm, string>();
		let cols = [
			{
				label: 'Id',
				path: 'project._projectTemplate.templateId',
			},
			{
				label: 'Fmt',
				path: 'project.fmtId',
			},
			{
				label: 'Premimum',
				path: 'project._projectTemplate.isPremium',
				render: (row: AdminProjectVm) => {
					return <>{row && row.project._projectTemplate.isPremium ? <>YES</> : <></>}</>;
				},
			},
			{
				label: 'Title',
				path: 'project._projectTemplate.templateTitle',
			},
			{
				label: 'Published',
				path: 'project._projectTemplate.published',
				render: (row: AdminProjectVm) => {
					return <>{row && row.project._projectTemplate.published ? <>YES</> : <>No</>}</>;
				},
			},
			{
				label: 'Created',
				path: 'user.created',
				render: (row: AdminProjectVm) => {
					return <>{row && row.project.created && <>{formatDate(row.project.created).toString()}</>}</>;
				},
			},
			{
				label: 'Modified',
				path: 'user.modified',
				render: (row: AdminProjectVm) => {
					return <>{row && row.project.modified && <>{formatDate(row.project.modified).toString()}</>}</>;
				},
			},
			{
				label: 'History',
				path: 'project.history.length',
			},
		];

		tm.onRowClick = (row: TableRow<AdminProjectVm>) => {
			viewDoc(row.data);
		};
		tm.addTextFilter('Fmt', 'project.fmtId');
		tm.addTextFilter('Title', 'project._projectTemplate.templateTitle');

		tm.setCols(cols as any);
		tm.sortBy = 'created';
		tm.sortAsc = false;
		tm.idProperty = 'fakeId';
		tm.idType = 'string';

		return tm;
	}, [history]);

	autorun(() => {
		tm.setData(adminProjectStore.templatesOnly);
	});

	useEffect(() => {
		adminProjectStore.load();
	}, [adminProjectStore]);

	return (
		<>
			<div className="section">
				<div className="section-title is-size-4">Templates ({adminProjectStore.templatesOnly.length})</div>
			</div>

			<div className="section">
				<Table tm={tm}></Table>
			</div>
		</>
	);
});
