import * as React from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { AnimatedPhrase, LayerModel } from 'shared-puredio';
import { useHistory } from 'react-router';
import routes from 'routes';
import { useEffect, useState } from 'react';
import t from 'app/common/translate';
import { runInAction } from 'mobx';
import { ProjectLayerActions } from './ProjectLayerActions';

export interface ILayerItem {
	layer: LayerModel;
	project: ProjectVM;
}

const LayerTimeLineItem = observer((props: ILayerItem) => {
	const history = useHistory();
	const { animatorStore } = useStore();
	const layer = props.layer;
	const project = props.project;
	const layerKey = layer.key;

	const selectLayer = (e: React.MouseEvent<HTMLDivElement>) => {
		const offset = e.currentTarget.getBoundingClientRect();
		const x = Math.floor(((e.pageX - offset.left) / offset.width) * 10000) / 100;

		const audioLayer = project.model.audioLayer;
		const s = audioLayer.start + (audioLayer.duration * x) / 100;

		animatorStore.seek(s);

		const p = routes.EDITPROJECT.getPath(props.project.id, layerKey);
		history.push({
			pathname: p,
			search: '?panel=' + layerKey,
		});
	};

	const isDisabled = layer.isDisabled;
	const [isActive, setIsActive] = useState(false);
	useEffect(() => {
		if (layer.key === animatorStore.selectedLayerKey) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [animatorStore.selectedLayerKey, animatorStore.paperVm, layer.key]);

	return (
		<div className={`timeline-item is-${layer.layerType.toLowerCase()} ${isDisabled ? 'is-disabled' : ''} ${isActive ? 'is-active' : ''}`} onClick={selectLayer}>
			{!layer.isAnimatedLayer && <span>{t('components:layertype.' + layer.layerType)}</span>}
			{layer.isAnimatedLayer && <AnimatedLayerTimeLineItemsPhrase {...props} />}
		</div>
	);
});

interface IAnimatedLayerTimeLineItemsPhrase extends ILayerItem {
	layer: LayerModel;
	// animText: LayerAnimatedTextModel;
}

const AnimatedLayerTimeLineItemsPhrase = observer((props: IAnimatedLayerTimeLineItemsPhrase) => {
	const layer = props.layer;
	return (
		<>
			{layer.opts.phrases.map((phrase) => (
				<AnimatedLayerTimeLineItem key={phrase.id} {...props} phrase={phrase} />
			))}
		</>
	);
});

interface IAnimatedLayerTimeLineItem extends ILayerItem {
	phrase: AnimatedPhrase;
	// animText: LayerAnimatedTextModel;
}
const round = (num: number) => {
	return Math.round(num * 10000) / 100;
};

const AnimatedLayerTimeLineItem = observer((props: IAnimatedLayerTimeLineItem) => {
	const phrase = props.phrase;
	const audioLayer = props.project.model.audioLayer;
	const [x, setX] = useState(0);
	const [w, setW] = useState(0);

	const { animatorStore } = useStore();

	useEffect(() => {
		const _x = phrase.start / audioLayer.duration;
		const phraseLength = phrase.end - phrase.start;
		const _w = phraseLength / audioLayer.duration;
		setX(round(_x));
		setW(round(_w));
	}, [audioLayer.duration, phrase.end, phrase.start]);

	runInAction(() => {
		phrase.isActive = animatorStore.audioStore.currentTime > phrase.start && animatorStore.audioStore.currentTime < phrase.end;
	});

	return (
		<div className={`animated-text-item ${phrase.isPause ? 'is-pause' : ''} ${phrase.isActive ? 'is-active' : ''}`} style={{ left: `${x}%`, width: `${w}%` }}>
			<span>{phrase.text}</span>
		</div>
	);
});

export const ProjectLayerItem = observer((props: ILayerItem) => {
	const { animatorStore } = useStore();
	const layer = props.layer;
	const isDisabled = layer.isDisabled;
	const [isActive, setIsActive] = useState(false);
	useEffect(() => {
		if (layer.key === animatorStore.selectedLayerKey) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [animatorStore.selectedLayerKey, layer.key, layer.layerType, layer.isDisabled]);

	return (
		<div className={`project-layer-row is-${layer.layerType.toLocaleLowerCase()} ${isDisabled ? 'is-disabled' : ''} ${isActive ? 'is-active' : ''}`}>
			<ProjectLayerActions {...props} />
			<div className="timeline">
				<LayerTimeLineItem {...props} />
			</div>
		</div>
	);
});
