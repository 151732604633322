import { Table } from 'app/components/Table/table';
import { AggregrationType, TableModel, TableRow } from 'app/components/Table/table.model';
import { useStore } from 'app/context';
import { AdminUsageVm } from 'app/stores/admin.exportusage.store';
import { formatDate, formatTime } from 'app/utils';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminUsageList: React.FC = observer(() => {
	const history = useHistory();
	const { adminUsageStore } = useStore();

	const tm = useMemo(() => {
		const viewDoc = (doc: AdminUsageVm) => {
			runInAction(() => {
				history.push(routes.ADMINUSAGE.getPath(doc.usage.id));
			});
		};

		let aggIf = (val: any) => {
			return val > 0;
		};

		const tm = new TableModel<AdminUsageVm, string>();
		let cols = [
			{
				label: 'User',
				path: 'user.fullName',
				sortBy: 'user.lastName',
			},
			{
				label: 'ActivePlan',
				path: 'user.activePlan',
				sortBy: 'user.activePlan',
			},
			{
				label: 'Created',
				path: 'usage.created',
				sortBy: 'usage.created',
				render: (row: AdminUsageVm) => {
					return <>{row && row.usage.created && <>{formatDate(row.usage.created, 'YYYY-MM-DD hh:mm').toString()}</>}</>;
				},
			},
			{
				label: 'Project',
				path: 'project.title',
				sortBy: 'project.title',
				render: (row: AdminUsageVm) => {
					return <>{row && row.project && row.project.title && <>{row.project.title}</>}</>;
				},
			},
			{
				label: 'Duration',
				path: 'usage.duration',
				sortBy: 'usage.duration',
				render: (row: AdminUsageVm) => {
					return <>{row && <>{formatTime(row.usage.duration)}</>}</>;
				},
				agg: [
					{
						path: 'usage.duration',
						format: formatTime,
						aggType: AggregrationType.sum,
						aggIf,
					},
					{
						path: 'usage.duration',
						format: formatTime,
						aggType: AggregrationType.avg,
						aggIf,
					},
					{
						path: 'usage.duration',
						// format: formatTime,
						aggType: AggregrationType.count,
						aggIf,
					},
				],
			},
		];

		tm.onRowClick = (row: TableRow<AdminUsageVm>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols as any);
		tm.sortBy = 'usage.created';
		tm.sortAsc = false;
		tm.idProperty = 'fakeId';
		tm.idType = 'string';

		tm.addTextFilter('Episode', 'project.title');
		tm.addTextFilter('Username', 'user.fullName');
		tm.addDateBeforeFilter('CretedBefore', 'usage.created');
		tm.addDateAfterFilter('After', 'usage.created');

		// tm.setSortBy('project.created');

		return tm;
	}, [history]);

	autorun(() => {
		tm.setData(adminUsageStore.items);
	});

	useEffect(() => {
		adminUsageStore.load();
	}, [adminUsageStore]);

	return (
		<>
			<div className="section">
				<div className="section-title is-size-4">Export Usage items ({adminUsageStore.items.length})</div>
			</div>
			<div className="section">
				<Table tm={tm}></Table>
			</div>
		</>
	);
});
