import { CheckboxListControl } from 'app/components/common/CheckboxListControl';
import InputControl from 'app/components/common/InputControl';
import { Table } from 'app/components/Table/table';
import { TableModel, TableRow } from 'app/components/Table/table.model';
import { useStore } from 'app/context';
import { AdminTaskVM } from 'app/stores/admin.tasks.store';
import { formatDate, formatTime } from 'app/utils';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminTasksList: React.FC = observer(() => {
	const history = useHistory();
	const { adminTasksStore } = useStore();

	const [userId, setUserId] = useState<string>('o7VHD8gSUNUYIL4Fv6aqjOPKVbO2');
	const [projectId, setProjectId] = useState<string>('');
	const [taskGroupId, setTaskGroupId] = useState<string>('');
	const _taskTypes = ['export', 'encodeAudio', 'splitAudio'];
	const [taskTypes, setTaskTypes] = useState<string[]>(_taskTypes);

	const tm = useMemo(() => {
		const viewDoc = (doc: AdminTaskVM) => {
			runInAction(() => {
				history.push(routes.ADMINTASKS.getPath(doc.task.id));
			});
		};

		const tm = new TableModel<AdminTaskVM, string>();
		let cols = [
			{
				label: 'User',
				path: 'user.fullName',
				sortBy: 'user.lastName',
			},
			{
				label: 'Created',
				path: 'task.created',
				sortBy: 'task.created',
				render: (row: AdminTaskVM) => {
					return <>{row && row.task.created && <>{formatDate(row.task.created, 'YYYY-MM-DD hh:mm').toString()}</>}</>;
				},
			},
			{
				label: 'Rendertime',
				path: 'taskDuration',
				sortBy: 'taskDuration',
				render: (row: AdminTaskVM) => {
					return <>{row && row.taskDuration}</>;
				},
			},
			{
				label: 'Project',
				path: 'project.title',
				sortBy: 'project.title',
				render: (row: AdminTaskVM) => {
					return (
						<>
							{row && row.project && row.project.title && (
								<>
									{row.project.title} ({row.project.id})
								</>
							)}
						</>
					);
				},
			},

			// {
			// 	label: 'Duration',
			// 	path: 'usage.duration',
			// 	sortBy: 'usage.duration',
			// },
			{
				label: 'Type',
				path: 'task.type',
			},
			{
				label: 'Video length',
				path: 'project.audioLayer.duration',
				render: (row: AdminTaskVM) => {
					return <>{row && row.project && <>{formatTime(row.project.audioLayer.duration)}</>}</>;
				},
			},
			{
				label: 'Audio length',
				path: 'audioFile.audioDuration',
				render: (row: AdminTaskVM) => {
					return <>{row && row.audioFile && <>{formatTime(row.audioFile.audioDuration)}</>}</>;
				},
			},
			{
				label: 'key',
				path: 'task.key',
			},
			{
				label: 'parts',
				path: 'task.opts.renderAnimationParts',
			},
			{
				label: 'fmt',
				path: 'project.fmtId',
				sortBy: 'project.fmtId',
			},
		];

		tm.onRowClick = (row: TableRow<AdminTaskVM>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols as any);
		tm.sortBy = 'usage.created';
		tm.sortAsc = false;
		tm.idProperty = 'fakeId';
		tm.idType = 'string';

		tm.addTextFilter('Episode', 'project.title');
		tm.addTextFilter('Username', 'user.fullName');

		// tm.setSortBy('project.created');

		return tm;
	}, [history]);

	autorun(() => {
		tm.setData(adminTasksStore.items);
	});

	useEffect(() => {
		const scope = {
			userId,
			taskGroupId,
			taskType: taskTypes,
			projectId,
		};
		adminTasksStore.load(scope);
	}, [adminTasksStore, userId, taskTypes, taskTypes.length, taskGroupId, projectId]);

	const taskTypesItems = _taskTypes.map((t) => {
		return { label: t, value: t };
	});

	const onTaskTypeChange = (vals: string[]) => {
		setTaskTypes(vals);
		const scope = {
			userId,
			taskGroupId,
			taskType: vals,
			projectId,
		};
		adminTasksStore.load(scope);
	};

	return (
		<>
			<div className="section">
				<div className="section-title is-size-4">Tasks ({adminTasksStore.items.length})</div>
			</div>
			<div className="section">
				<div>
					UserId:
					<InputControl name={'userId'} onChange={(n, v) => setUserId(v)} value={userId} />
				</div>
				<div>
					ProjectId:
					<InputControl name={'ProjectId'} onChange={(n, v) => setProjectId(v)} value={projectId} />
				</div>
				<div>
					TaskGroupId:
					<InputControl name={'TaskGroupId'} onChange={(n, v) => setTaskGroupId(v)} value={taskGroupId} />
				</div>
				<div>
					TaskType:
					<CheckboxListControl items={taskTypesItems} values={taskTypes} onSelect={onTaskTypeChange} />
					{/* <InputControl name={'TaskType'} onChange={(n, v) => setTaskTypes(v)} value={taskTypes} /> */}
					{/* <strong>audioProcessing</strong>: encodeAudio, splitAudio <strong>render</strong>: export, renderAnimationPart, mergeSplits */}
				</div>
			</div>

			<div className="section">
				<Table tm={tm}></Table>
			</div>
		</>
	);
});
