import ComputeApi from 'app/api/compute.api';
import Config from 'config';
import _ from 'lodash';
import { action, observable } from 'mobx';
import { BillingInterval, PaddlePayment, PaddlePlan, PaymentPlan, PlanDefinitions, PlanType } from 'shared-puredio';
import { AuthStore } from './auth.store';

export class PaddleStore {
	authStore: AuthStore;
	computeApi: ComputeApi;

	constructor(authStore: AuthStore, computeApi: ComputeApi) {
		this.authStore = authStore;
		this.computeApi = computeApi;
		this.paddle = (window as any).Paddle;

		if (Config.ENV !== 'prod') {
			this.paddle.Environment.set('sandbox');
		}
		this.paddle.Setup({
			vendor: Config.PADDLE_VENDOR_ID,
			eventCallback: this.eventCallback.bind(this),
		});
		if (Config.FEATURE_PAYMENT) {
			this.loadPlans();
		}
	}
	paddle: any;

	paddlePlans: PaddlePlan[] = [];

	@observable
	paddlePayments: PaddlePayment[] = [];

	async loadPlans() {
		if (this.paddlePlans.length === 0) {
			const res = await this.computeApi.getPlans();
			this.paddlePlans = res.map((p: any) => new PaddlePlan(p));
		}
		PlanDefinitions.forEach((pd) => {
			if (pd.planType === PlanType.custom) {
				return;
			}

			const ppMonth = this.paddlePlans.find((pp) => pp.planType === pd.planType && pp.interval === BillingInterval.monthly);
			const ppYear = this.paddlePlans.find((pp) => pp.planType === pd.planType && pp.interval === BillingInterval.yearly);
			if (ppMonth) {
				pd.setMonthlyPlanId(ppMonth.id);
			}
			if (ppYear) {
				pd.setYearlyPlanId(ppYear.id);
			}
		});
		this.plans = PlanDefinitions;
	}

	async loadPaymentHistory() {
		const res = await this.computeApi.listPaymentHistory();
		const payments = res.data.map((p: any) => new PaddlePayment(p));
		this.paddlePayments = _.sortBy(payments, 'payout_date').reverse();
	}

	@observable
	plans: PaymentPlan[] = [];

	@action
	async changeUserSubscription(newPlanId: number, isDowngrade: boolean) {
		const res = await this.computeApi.changeUserSubscription({ newPlanId, isDowngrade });
		if (res.error) {
			alert('Es ist eine Fehler aufgertreten' + res.error);
			return res;
		}
		await this.refreshSubscription();
		return res;
	}

	@action
	async cancelUserSubscription() {
		const res = await this.computeApi.cancelUserSubscription();
		if (res.error) {
			alert('Es ist eine Fehler aufgertreten' + res.error);
			return res;
		}
		await this.refreshSubscription();
		return res;
	}

	async refreshSubscription() {
		await this.computeApi.ensureSubscriptionUpToDate();
		await this.authStore.loadCurrUser();
	}

	async setPaddleUserIdFromEventData(evt: any) {
		if (evt && evt.user && evt.user.id) {
			const paddleUserId = evt.user.id;
			const pid = parseInt(paddleUserId);
			if (this.authStore.current && this.authStore.current.paddleUserId !== pid) {
				await this.computeApi.setPaddleUserId(paddleUserId);
				this.authStore.current!.paddleUserId = parseInt(paddleUserId);
			}
		} else {
			console.warn('no paddle user id returned from event', evt);
		}
	}

	async eventCallback(data: any) {
		// see https://developer.paddle.com/reference/paddle-js/checkout-events#paddlejs-event-callback
		console.log('paddle event', data.event, data);
		const evtName = data.event.toLowerCase();
		await this.setPaddleUserIdFromEventData(data.eventData);
		if (evtName.indexOf('complete') || evtName === 'checkout.complete' || evtName === 'checkout.paymentcomplete') {
			await this.refreshSubscription();
		} else if (evtName === 'checkout.close') {
			await this.refreshSubscription();
		}
	}
	//68674

	openCheckout(productId: number) {
		if (!this.authStore.current!.email) {
			alert('Keine Email hinterlegt');
			return;
		}
		this.paddle.Checkout.open({
			product: productId,
			email: this.authStore.current!.email,
			locale: 'de',
			passthrough: this.authStore.current!.id!,
		});
	}
}
