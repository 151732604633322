import { makeObservable, observable } from 'mobx';

export class ConvertResult {
    constructor() {
        makeObservable(this);
    }
    @observable outputKey?: string;
    @observable errorCode: string = undefined;
    @observable errorObj: any;
    @observable success?: boolean;
    serialize() {
        return JSON.parse(JSON.stringify(this));
    }
}
