export class BaseModel {
    fixDate(prop: string) {
        if (this[prop] && this[prop].toDate) {
            this[prop] = this[prop].toDate();
        }
    }
}





