import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/common/Modal';
import FormField from 'app/components/Form/FormField';
import { useStore } from 'app/context';
import { Formik, Form, Field } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import t from '../../common/translate';
import * as Yup from 'yup';
import { notify } from 'app/common/notify';
import routes from 'routes';
import { useHistory } from 'react-router';

interface IPasswordUpdateModalProps {
	modalId: string;
}
export const UpdateEmailModal: React.FC<IPasswordUpdateModalProps> = observer((props: IPasswordUpdateModalProps) => {
	const updateEmailFormSchema = Yup.object()
		.shape({
			email: Yup.string().email(t('validation:required.email')).required(t('validation:required.field')).default(''),
		})
		.required();

	type UpdateEmail = Yup.InferType<typeof updateEmailFormSchema>;

	const { authStore, uiStore } = useStore();
	const history = useHistory();

	const handleSubmit = async (values: UpdateEmail) => {
		const email = values.email;
		const res = await authStore.updateEmail(email);

		if (res && res.status === 201) {
			notify(t('auth:email.update.success'), 'success');
			notify(t('auth:please.login.again'));
			uiStore.hideModal(props.modalId);
			await authStore.signOut();
			history.push(routes.AUTH_LOGIN.getPath());

			return;
		} else {
			notify(t(res.message), 'error');
		}
	};

	const currentEmail = authStore.current!.email;
	return (
		<>
			<Modal title={t('auth:changeemail.submit')} size="medium" modalId={props.modalId}>
				<Formik validationSchema={updateEmailFormSchema} initialValues={{ email: currentEmail }} onSubmit={handleSubmit}>
					{({ errors, touched, values }) => (
						<Form>
							<FormField label={t('auth:password')} error={errors.email} touched={touched.email}>
								<Field type="text" name="email" />
							</FormField>
							<FormField>
								<Button type="submit" className="button is-primary" disabled={currentEmail === values.email}>
									{t('auth:changeemail.submit')}
								</Button>
							</FormField>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
});
