import { AdminFileApi } from 'app/api/file.api';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { FileModel, UserModel } from 'shared-puredio';
import { AdminUserStore, AdminUserVM } from './admin.user.store';

export class AdminFileVm {
	constructor(p: FileModel, u?: UserModel) {
		makeObservable(this);
		this.file = p;
		this.user = u;
	}
	@observable
	file: FileModel;
	user?: UserModel;
}

export class AdminFilesStore {
	adminFileApi: AdminFileApi;
	adminUserStore: AdminUserStore;

	constructor(adminFileApi: AdminFileApi, adminUserStore: AdminUserStore) {
		makeObservable(this);
		this.adminFileApi = adminFileApi;
		this.adminUserStore = adminUserStore;
	}

	@observable
	items: AdminFileVm[] = observable([]);

	@observable
	current?: AdminFileVm = undefined;

	@action
	loadById(fileId: string) {
		this.current = this.items.find((i) => i.file.id === fileId);
	}

	async load() {
		if (this.items.length > 0) {
			return this.items;
		}
		const scope = {
			userId: 'userId',
		};
		const p: any = [];
		p.push(this.adminFileApi.listAll(scope));
		p.push(this.adminUserStore.load());

		const res = await Promise.all(p);

		const files = res[0] as FileModel[];
		const users = res[1] as AdminUserVM[];

		const items = files.map((f) => {
			const uVM = users.find((u) => u.user.fakeId === f.userId);
			const u = uVM ? uVM.user : undefined;
			return new AdminFileVm(f, u);
		});

		runInAction(() => {
			this.items = items;
		});
		return this.items;
	}
}
