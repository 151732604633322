import React, { useEffect, useState } from 'react';
import { IProps, useStore } from './../context';
import { observer } from 'mobx-react';
import BaseContainer from '../containers/BaseContainer';
import SideMenu from '../components/SideMenu';
import ProjectListTitle from '../components/Project/ProjectListTitle';

import { GroupModel } from 'shared-puredio';
import t from 'app/common/translate';
import { useParams } from 'react-router';
import { ProjectNewSelectTemplate } from 'app/components/Project/New/ProjectNewSelectTemplate';
import { ProjectNewSelectAudio } from 'app/components/Project/New/ProjectNewSelectAudio';

export interface INewProjectParms {
	groupId: string;
	fmtId: string;
	templateId?: string;
}

const ProjectNewContainer: React.FC<IProps> = observer((props: IProps) => {
	const { groupId, templateId } = useParams<INewProjectParms>();
	const store = useStore();

	const [group, setGroup] = useState<GroupModel | undefined>(undefined);
	const [notFound, setNotFound] = useState<boolean>(false);

	useEffect(() => {
		const getGroup = async () => {
			const group = await store.groupStore.getById(groupId).catch((e) => e);
			if (group) {
				setGroup(group);
			} else {
				setNotFound(true);
			}
		};

		store.projectStore.resetEntity();
		getGroup();
	}, [groupId, store]);

	return (
		<>
			<BaseContainer menu={<SideMenu />} navbar={group ? <ProjectListTitle group={group} hideActions={true} /> : <></>}>
				{notFound && <div>{t('general:group.not.found')}</div>}
				{group && (
					<>
						{!templateId && (
							<>
								<section className="section">
									<h2 className="section-title is-size-4">{t('general:project.selectTemplate')}</h2>
								</section>
								<section className="section">
									<ProjectNewSelectTemplate />
								</section>
							</>
						)}
						{templateId && (
							<>
								<ProjectNewSelectAudio />
							</>
						)}
					</>
				)}
			</BaseContainer>
		</>
	);
});

export default ProjectNewContainer;
