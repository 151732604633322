import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'app/components/common/Button';
import Icon from 'app/components/common/Icon';
import routes from 'routes';
import { GroupModel } from 'shared-puredio';
import t from 'app/common/translate';
import Breadcrumb, { IBreadcrumbItem } from 'app/components/Breadcrumb';
import { useHistory } from 'react-router';

interface IProjectTitleProps {
	group: GroupModel;
	hideActions?: boolean;
}

const ProjectListTitle: React.FC<IProjectTitleProps> = observer((props: IProjectTitleProps) => {
	const history = useHistory();
	const group = props.group;

	const crumbs: IBreadcrumbItem[] = [
		{
			title: t('general:groups.title'),
			url: routes.LISTGROUPS.getPath(),
		},
		{
			title: group.title,
		},
	];

	const showAddProjectModal = () => {
		history.push(routes.NEWPROJECT.getPath(group.id));
	};

	return (
		<>
			<div className="app-navbar">
				<Breadcrumb crumbs={crumbs} />

				{!props.hideActions && (
					<div className="actions">
						<Button className="button is-primary" onClick={showAddProjectModal}>
							<Icon iconName="plus" faModifier="fal" />
							{t('general:projects.add')}
						</Button>
					</div>
				)}
			</div>
		</>
	);
});

export default ProjectListTitle;
