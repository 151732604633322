import { computed, observable } from 'mobx';
import * as path from 'path';
import { FileModel } from './file.model';

export interface IAudioData {
    frames: number;
    duration: number;
    sampleRate: number;
    channels: number;
    //peaksKey: string;
    // md5Hash?: string;
    // originalFileName: string;
    // userId: string;
}

export class AudioDataModel implements IAudioData {
    @observable
    frames: number = 0;

    @observable
    duration: number = 0;

    @observable
    samplesPerFrame: number = 0;

    @observable
    sampleRate: number = 0;

    @observable
    channels: number = 0;

    // @observable
    // peaksKey: string = '';

    // @observable
    // md5Hash: string;

    // @observable
    // userId: string;

    serialize() {
        return {
            // originalFileName: this.originalFileName,
            // md5Hash: this.md5Hash,
            // userId: this.userId,
            frames: this.frames,
            duration: this.duration,
            sampleRate: this.sampleRate,
            channels: this.channels,
            // peaksKey: this.peaksKey,
        };
    }

    // @computed
    // get normalizedKey() {
    //     const base = this.baseKey;
    //     let ext = '';
    //     switch (this.fileType) {
    //         case FileType.audio:
    //             ext = '.m4a';
    //             break;
    //         case FileType.image:
    //             ext = '.png';
    //             break;
    //         default:
    //             throw new Error('no normalize key for:' + this.fileType);
    //     }
    //     return path.join(base, 'norm' + ext);
    // }

    // @computed
    // get baseKey() {
    //     const userId = this.userId;
    //     return path.join('users', userId, 'prep', this.md5Hash);
    // }

    // @computed
    // get wavedataKey() {
    //     const base = this.baseKey;
    //     return path.join(base, 'wavedata.dat');
    // }

    // @computed
    // get audiosplitPath() {
    //     const base = this.baseKey;
    //     return path.join(base, 'split');
    // }

    // normalizedKey: string = '';
}
