import React from 'react';
import { notify } from 'app/common/notify';

interface IErrorBoundary {
	hasError: boolean;
	msg: string;
}

export class ErrorBoundary extends React.Component<any, IErrorBoundary> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false, msg: '' };
	}

	componentDidCatch(error: any, info: any) {
		debugger;
		this.setState({ hasError: true, msg: error });
		notify('Es ist ein Fehler aufgetreten', 'error');
		console.error(error);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div>
					<h1>Ein Fehler ist aufgetreten:</h1>
					<p>{this.state.msg}</p>
				</div>
			);
		}
		return this.props.children;
	}
}
