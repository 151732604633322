import React, { useEffect, useState } from 'react';
import { useStore } from 'app/context';
import { formatTime, humanFileSize } from 'app/utils';
import { observer } from 'mobx-react';
import { FileModel } from 'shared-puredio';
import DetailPanel from '../common/DetailPanel';
import { useHistory } from 'react-router';
import routes from 'routes';
import { AudioFileProgress } from './AudioFileProgress';
import { AudioFileInUse } from './AudioFileInUse';
import { AudioFileWaveDataInfo } from './AudioFileWaveDataInfo';
import { Button } from '../common/Button';

export const AudioFileDetail = observer(() => {
	const { fileStore } = useStore();
	const history = useHistory();
	const [file, setFile] = useState<FileModel | undefined>();

	useEffect(() => {
		setFile(fileStore.selected);
	}, [fileStore.selected]);
	const onClose = () => {
		history.push(routes.AUDIOFILES.getPath());
	};

	const reprocess = () => {
		fileStore.parseAudioByKey(file!.originalKey, true);
	};

	return (
		<>
			{file && (
				<DetailPanel title={'audio'} subtitle={file ? file.id : ''} onClose={onClose}>
					<>
						md5: {file.md5Hash}
						<br />
						{file.originalFileName} {humanFileSize(file.metadata.size)} {file.audioData ? formatTime(file.audioData.duration) : ''}
						<Button type="button" onClick={reprocess}>
							Re-Process
						</Button>
						<AudioFileInUse file={file} />
						<AudioFileWaveDataInfo file={file} />
						<AudioFileProgress file={file} />
					</>
				</DetailPanel>
			)}
		</>
	);
});
