import React from 'react';
import { Spinner } from './Spinner';

const BusyScreen: React.FC = () => {
	return (
		<>
			<div className="busy-screen">
				<Spinner />
			</div>
		</>
	);
};

export default BusyScreen;
