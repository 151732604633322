import React, { useEffect, useState } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { FileModel, FileStatus } from 'shared-puredio';
import ProgressRing from '../common/ProgressRing';

interface IAudioFileProgress {
	file: FileModel;
}

export const AudioFileProgress = observer((props: IAudioFileProgress) => {
	const { audioPrepStore } = useStore();

	const [progress, setProgress] = useState(100);

	useEffect(() => {
		console.log('start liesten');
		audioPrepStore.setCurrentMdf(props.file.md5Hash!);
		if (props.file.fileStatus === FileStatus.completed) {
			setProgress(100);
		} else {
			setProgress(audioPrepStore.currentProgress);
		}
		return () => {
			audioPrepStore.setCurrentMdf();
		};
	}, [props.file.md5Hash, audioPrepStore.currentProgress, props.file.fileStatus, audioPrepStore]);

	return (
		<>
			<ProgressRing percentage={progress} modifier="is-static" />
		</>
	);
});
