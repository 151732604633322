import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import t from '../../common/translate';
import { useStore } from 'app/context';
import ContactButtonWithModal from '../common/ContactButtonWithModal';
import { PaymentCancelSubscriptioFeedbackModal } from './PaymentCancelSubscriptioFeedbackModal';
import { notify } from 'app/common/notify';
import { Trans } from 'react-i18next';

interface ICancelSubscriptionModal {
	onDone: () => void;
}

export const PaymentCancelSubscriptionModal: React.FC<ICancelSubscriptionModal> = observer((props: ICancelSubscriptionModal) => {
	const { paddleStore, uiStore } = useStore();
	const modalId = uiStore.modalIds.cancelSubscription;

	const user = paddleStore.authStore.current!;
	const activeSubscription = user.activeSubscription;
	if (!activeSubscription) {
		return <></>;
	}

	const onApprove = async () => {
		const res = await paddleStore.cancelUserSubscription();
		if (res.error) {
			notify(t('general:error.general'), 'error');
			return;
		}
		uiStore.showModal(uiStore.modalIds.cancelSubscriptionFeedback);
	};

	return (
		<Modal title={''} size="large" modalId={modalId} onClose={props.onDone}>
			<div className="welcome-modal content ">
				<h2 className="title is-3 align-center">
					{t('payment:plan.cancel')} {t('payment:plan.emoji')}
				</h2>

				<div className="message has-text-centered pad-top-3rem">
					<>
						<p>{t('payment:message.cancel.areyousure')}</p>
						<p>{t('payment:message.cancel.wedowngradeyou')}</p>
						<p>
							<Trans i18nKey="payment:message.cancel.letschat">
								&nbsp;
								<ContactButtonWithModal art="other" className="button is-text">
									Kontakt
								</ContactButtonWithModal>
							</Trans>
						</p>
						<p></p>
					</>
				</div>

				<div className="message has-text-centered pad-top-3rem">
					<button onClick={onApprove} className="button is-primary is-inverted">
						{t('payment:plan.cancel')}
					</button>
					<button onClick={props.onDone} className="button is-primary">
						{t('common:cancel')}
					</button>
				</div>
			</div>
			<PaymentCancelSubscriptioFeedbackModal onDone={props.onDone} />
		</Modal>
	);
});
