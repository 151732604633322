import React, { useCallback, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../Form/FormField';
import t from '../../common/translate';
import { Button } from './Button';
import { SupoortMailModel } from 'shared-puredio';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';

const supportFormSchema = Yup.object()
	.shape({
		name: Yup.string().required(t('validation:required.field')).default(''),
		email: Yup.string().email(t('validation:required.email')).required(t('validation:required.field')).default(''),
		art: Yup.string().required(t('validation:required.field')).default(''),
		subject: Yup.string().default(''),
		body: Yup.string().required(t('validation:required.field')).default(''),
	})
	.required();

type Feedback = Yup.InferType<typeof supportFormSchema>;

interface ISupportForm {
	art?: string;
}

const SupportForm = observer((props: ISupportForm) => {
	const [isSent, setSent] = useState(false);
	const { authStore, computeApi } = useStore();

	const user = authStore.current;
	const defaultValues = {
		name: user ? `${user.firstName} ${user.lastName}` : '',
		email: user?.email ?? '',
		art: props.art ? props.art : 'Bug',
		subject: '',
		body: '',
	};

	const handleSubmit = useCallback(
		async (values: Feedback) => {
			let { name, email, art, body, subject } = values;

			const mail = new SupoortMailModel();
			mail.from = email;
			mail.subject = subject;
			if (user) {
				body += `\n\n----------------------------------------------`;
				body += `\n\nAbsender: ${email} ${name} `;
				body += `\n\nArt: ${art} `;
				mail.userId = user.id!;
			}
			mail.body = body;

			const res = await computeApi.sendSupportEmail(mail);
			if (res) {
				setSent(true);
			}
		},
		[user, computeApi],
	);

	// const handleBackToForm = () => setSent(false);
	if (isSent) {
		return (
			<>
				<p>{t('general:support.message.sent')}</p>
				{/* <Button className="button is-primary" onClick={handleBackToForm}>
					{t('SUPPORT_FORM_SEND_ANOTHER_FEEDBACK')}
				</Button> */}
			</>
		);
	}

	return (
		<Formik validationSchema={supportFormSchema} initialValues={defaultValues} onSubmit={handleSubmit}>
			{({ errors, touched }) => (
				<Form className="form is-support-form">
					<div className="field is-inline">
						<FormField label={t('general:support.name')} error={errors.name} touched={touched.name}>
							<Field type="text" name="name" />
						</FormField>

						<FormField label={t('general:support.email')} error={errors.email} touched={touched.email}>
							<Field type="text" name="email" />
						</FormField>
					</div>
					<div className="field is-inline">
						<FormField label={t('general:support.type')} error={errors.art} touched={touched.art}>
							<Field as="select" className="input" name="art">
								<option value="Other">{t('general:support.type.any')}</option>
								<option value="Bug">{t('general:support.type.bug')}</option>
								<option value="Improvement">{t('general:support.type.improvement')}</option>
								<option value="EnterpriseRequest">{t('general:support.type.enterpriserequest')}</option>
							</Field>
						</FormField>

						<FormField label={t('general:support.subject')} error={errors.subject} touched={touched.subject}>
							<Field type="text" name="subject" />
						</FormField>
					</div>
					<FormField label={t('general:support.body')} error={errors.body} touched={touched.body}>
						<Field as="textarea" name="body" className="input text-area" />
					</FormField>
					<Button type="submit" className="button is-primary" disabled={authStore.isEntityLoading}>
						{t('common:send')}
					</Button>
				</Form>
			)}
		</Formik>
	);
});

export default SupportForm;
