import { ExportTaskModel, GroupModel, ProjectModel } from 'shared-puredio';
import { computed } from 'mobx';
import t from 'app/common/translate';

export class RenderItemVM {
	id: string;
	task: ExportTaskModel;
	project?: ProjectModel;
	group?: GroupModel;
	constructor(task: ExportTaskModel, project?: ProjectModel, group?: GroupModel) {
		this.task = task;
		this.id = task.id!;
		this.project = project;
		this.group = group;
	}

	@computed
	get projectTitle() {
		if (this.project) {
			return this.project.title;
		}
		return '';
	}

	@computed
	get fmt() {
		if (this.project) {
			return this.project.fmt.id;
		}
		return '';
	}

	@computed
	get status() {
		return t('components:renderStatus.' + this.task.status);
	}

	@computed
	get groupTitle() {
		if (this.group) {
			return this.group.title;
		}
		return '';
	}
	@computed
	get created() {
		return this.task.created;
	}

	@computed
	get renderDuration() {
		if (!this.task.completed) {
			return false;
		}
		let c = this.task.created.getTime();
		let e = this.task.completed.getTime();
		return (e - c) / 1000;
	}

	@computed
	get outputVideokey() {
		if (this.task && this.task.result) {
			return this.task.result.outputKey;
		}
		return undefined;
	}
}
