import { ProjectModel } from './project.model';
import * as path from 'path';

export default class BucketPath {
    // - users
    //  - [userId]/preview.[projectId].png
    //  - [userId]/original/[filename]
    //  - [userId]/modified/[random_filename].[ext]
    //  - [userId]/exports/[exportId].mp4
    //  - [userId]/audio_prep/[audiofilename].m4a
    //  - [userId]/audio_prep/[audiofilename]/split0000000.wav

    static projectPreviewKey(project: ProjectModel) {
        return path.join('users', project.userId, 'preview_' + project.id + '.png');
    }
    static uploadFolder(userId: string): string {
        return path.join('users', userId, 'upload');
    }
    static modifiedFolder(userId: string) {
        return path.join('users', userId, 'modified');
    }
    static exportsFolder(userId: string) {
        return path.join('users', userId, 'exports');
    }
}
