import React from 'react';
import { ImageModel } from 'shared-puredio';
import ImageUploadWidget from 'app/components/common/ImageUploadWidget';

interface IImageUploadField {
	value: ImageModel;
	label: string;
	uploadFolder: string;
	onChange: (e: any) => void;
}

export const ImageUploadControl: React.FC<IImageUploadField> = ({ label, value, uploadFolder, onChange }) => {
	return (
		<div className="form-control">
			<label>{label}</label>
			<ImageUploadWidget value={value} storageFolder={uploadFolder} onChange={onChange} />
		</div>
	);
};
