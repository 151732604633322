import React, { useEffect, useState } from 'react';
import { useStore } from './../context';
import { observer } from 'mobx-react';
import ProjectEdit from '../components/Project/ProjectEdit';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import BaseContainer from 'app/containers/BaseContainer';
import * as qs from 'qs';
import { useLocation, useParams } from 'react-router';
import { runInAction } from 'mobx';
import t from '../common/translate';
import SideMenu from 'app/components/SideMenu';
import BusyScreen from 'app/components/common/BusyScreen';

export interface ProjectEditContainerParmTypes {
	id?: string;
}

export const ProjectEditContainer = observer(() => {
	const [project, setProject] = useState<ProjectVM>();
	const [notFound, setNotFound] = useState(false);
	const location = useLocation();
	const { id } = useParams<ProjectEditContainerParmTypes>();
	const { projectStore, groupStore } = useStore();
	useEffect(() => {
		if (id) {
			projectStore.getById(id).then((p) => {
				if (p) {
					setNotFound(false);
					groupStore.getById(p.groupId).then((g) => {
						const vm = new ProjectVM(p);
						vm.group = g;
						setProject(vm);
					});
				} else {
					setNotFound(true);
				}
			});
		}
	}, [id, groupStore, projectStore]);
	useEffect(() => {
		if (!project) {
			return;
		}
		runInAction(() => {
			const query = qs.parse(location.search, { ignoreQueryPrefix: true });
			if (query.panel) {
				project.currentSidePanel = query.panel as string;
			} else {
				const firstPanel = project.model.firstLayer;
				if (firstPanel) {
					project.currentSidePanel = firstPanel.key;
				}
			}
		});
	}, [project, location.search]);

	return (
		<>
			{project && (
				<BaseContainer hideBrand={true} contentPadding={false}>
					<div className="project-edit">
						<ProjectEdit project={project} />
					</div>
				</BaseContainer>
			)}
			{!project && notFound && (
				<BaseContainer menu={<SideMenu />} navbar={<></>}>
					<div className="project-edit">{t('general:project.notfound')}</div>
				</BaseContainer>
			)}

			{!project && !notFound && (
				<BaseContainer menu={<SideMenu />} navbar={<></>}>
					<BusyScreen />
				</BaseContainer>
			)}
		</>
	);
});
