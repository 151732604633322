import { FieldConfig } from 'formik';
import React from 'react';
import ImageUploadWidget from '../common/ImageUploadWidget';
import { IProps } from 'app/context';
import { ImageModel } from 'shared-puredio';

interface IImpageUploadField extends FieldConfig {
	uploadFolder: string;
	onChange: (image: ImageModel) => void;
}

export const ImageUploadField: React.FC<IImpageUploadField & Partial<IProps>> = ({ onChange, uploadFolder, ...rest }) => (
	<ImageUploadWidget onChange={onChange} storageFolder={uploadFolder} {...rest} />
);
