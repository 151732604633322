import { Button } from 'app/components/common/Button';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import * as React from 'react';
import t from 'app/common/translate';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { ProjectRenderModal } from 'app/components/Project/Render/ProjectRenderModal';
import { ProjectDeleteModal } from 'app/components/Project/ProjectDeleteModal';
import { useHistory } from 'react-router';
import routes from 'routes';
import Icon from 'app/components/common/Icon';

interface IRenderControlsProps {
	project: ProjectVM;
}

const ProjectRenderControls: React.FC<IRenderControlsProps> = observer((props: IRenderControlsProps) => {
	const { uiStore } = useStore();
	const history = useHistory();
	const project = props.project;

	const renderModalId = uiStore.modalIds.manageExports(project.id);
	const showRenderModal = () => {
		uiStore.showModal(renderModalId);
	};

	const deleteProjectModalId = uiStore.modalIds.projectDelete(project.id);

	const handleProjectDelete = () => {
		const groupId = project.group?.id;

		history.replace(groupId ? routes.LISTPROJECTS.getPath(groupId) : routes.LISTGROUPS.getPath());
	};

	return (
		<>
			<Button className="button is-primary is-smallish" onClick={showRenderModal}>
				<Icon iconName="download" faModifier="fal" />
				<span>{t('general:project.render')}</span>
			</Button>

			<ProjectRenderModal {...props} modalId={renderModalId} />
			<ProjectDeleteModal project={project.model} modalId={deleteProjectModalId} onDone={handleProjectDelete} />
		</>
	);
});

export default ProjectRenderControls;
