import React, { useCallback, useState } from 'react';
import * as path from 'path';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useStore } from 'app/context';
import Icon from './Icon';
import t from '../../common/translate';
import { observer } from 'mobx-react';
import ProgressRing from 'app/components/common/ProgressRing';
import { SvgIcon, SvgIconType } from './SvgIcon';
import { UploadResponse } from 'app/api/bucket.api';

export type FileUploaderProps = Pick<DropzoneOptions, 'accept' | 'minSize' | 'maxSize' | 'maxFiles' | 'multiple'> & {
	dropZoneText: string;
	uploadingText: string;
	dropZoneActiveText: string;
	storageFolder: string;
	filetype: 'audio' | 'image';
	onUploadSuccess: (res: UploadResponse) => void;
};

const FileUploader: React.FC<FileUploaderProps> = ({ accept, dropZoneText, uploadingText, dropZoneActiveText, filetype, storageFolder, onUploadSuccess, ...dropzoneConfig }) => {
	const { bucketStore, uiStore, fileStore } = useStore();
	const [progress, setProgress] = useState(0);
	const hochgeladen = t('components:audio.uploaded')!;
	const [uploadedText, setUploadedText] = useState(hochgeladen);

	const onDropAccepted = useCallback(
		(acceptedFiles) => {
			const file = acceptedFiles[0];

			if (!file) {
				return;
			}

			const fileName = acceptedFiles[0].name;
			const key = path.join(storageFolder, fileName);

			// TODO: Petr Grinevich 29.12.2020 - makes sense to move this logic to the proper store.
			uiStore.setUploading(true);

			bucketStore
				.uploadFile(file, key, (p) => setProgress(p))
				.then((res) => {
					if (filetype === 'audio') {
						setUploadedText(t('components:audio.reading'));
						fileStore.parseAudioByKey(res.key).then(() => {
							onUploadSuccess(res);
							uiStore.setUploading(false);
						});
					} else {
						setUploadedText(t('components:image.parsing'));
						fileStore.prepareImage(res.key).then((im) => {
							res.md5Hash = im.md5Hash;
							onUploadSuccess(res);
							uiStore.setUploading(false);
						});
					}
				});
		},
		[bucketStore, uiStore, storageFolder, onUploadSuccess, filetype, fileStore],
	);

	const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
		...dropzoneConfig,
		onDropAccepted,
		accept,
	});

	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<div className="file-error" key={file.name}>
			<div className="file-name">{file.name}</div>
			<div className="errors">
				<span className="error-count">{errors.length}</span>
				<div className="error-list">
					{errors.map((e) => (
						<span key={e.code}>{t('components:dropzone.' + e.code)}</span>
					))}
				</div>
			</div>
		</div>
	));

	return (
		<>
			{uiStore.isUploading && <ProgressRing percentage={progress} label={uploadedText} />}

			<div {...getRootProps()}>
				<input {...getInputProps()} />
				<div className="drop-area">
					<div className="message">
						{uiStore.isUploading && (
							<div className="overlay">
								<div className="icon">
									<Icon iconName="spinner fa-pulse" />
								</div>
								<div className="text">{uploadingText}</div>
							</div>
						)}

						{isDragActive && (
							<div className="overlay">
								<div className="icon">
									<Icon iconName="download" />
								</div>
								<div className="text">{dropZoneActiveText}</div>
							</div>
						)}

						<div className="body">
							<div className="icon">{filetype === 'audio' ? <SvgIcon iconName={SvgIconType.fileMp3} /> : <SvgIcon iconName={SvgIconType.fileImage} />}</div>
							<div className="text">{dropZoneText}</div>
						</div>
					</div>
				</div>
			</div>
			<div className="file-errors">{fileRejectionItems}</div>
		</>
	);
};

export default observer(FileUploader);
