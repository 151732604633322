import { Shape, Group, Color } from 'paper';
import BaseAudiogram from './BaseAudiogram';

export default class Skyscrapers extends BaseAudiogram {
    public drawAudiogram(points) {
        const centerY = this.anim.fmt.h / 2;
        const amplitudeModifier = 0.5;
        const amplitude = this.calc({ v: this.amplitude * amplitudeModifier, d: 'h' });

        // calculate the needed geo
        const barWidth = Math.floor(this.anim.fmt.w / points.length);
        const barHeight = Math.floor(barWidth / 2);
        const blockWidth = Math.floor(barWidth * 0.8);
        const blockHeight = Math.floor(barHeight * 0.5);
        const blockPadding = Math.floor(barWidth - blockWidth / 2);
        const color = this.color || '#ffffff';

        const group = this.group;
        group.removeChildren();

        for (let x = 0; x < points.length; x++) {
            let barVolume = 0;
            for (let q = 0; q < points[x].length; q++) {
                barVolume += points[x][q];
            }

            barVolume = Math.round((barVolume / this.toAverage) * 50); // because loudness falsely returns values over 1
            barVolume = Math.min(barVolume, 100);
            barVolume = Math.max(barVolume, 1);

            // draw blocks
            const blockCount = Math.max(1, Math.round((amplitude * (barVolume / 100)) / blockHeight));
            for (let block = 0; block < blockCount; block++) {
                group.addChild(
                    new Shape.Rectangle({
                        from: [x * barWidth + blockPadding, centerY - blockHeight - barHeight * (block + 1)],
                        size: [blockWidth, blockHeight],
                        fillColor: color,
                    }),
                );
            }
        }
        group.addChild(
            new Shape.Rectangle({
                from: [0, 0],
                to: [this.anim.fmt.w, this.anim.fmt.h],
                // fillColor: new Color(100, 200, 20, 0.4),
            }),
        );
        this.group = group;
    }
}
