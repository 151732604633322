import { AdminProjectApi } from 'app/api/project.api';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ProjectModel, TemplateManagers, UserModel } from 'shared-puredio';
import { AdminUserStore, AdminUserVM } from './admin.user.store';
import { FileStore } from './file.store';
import { ProjectStore } from './project.store';

export class AdminProjectVm {
	constructor(p: ProjectModel, u?: UserModel) {
		makeObservable(this);
		this.project = p;
		this.user = u;
	}
	@observable
	project: ProjectModel;
	user?: UserModel;
}

export class AdminProjectStore {
	adminProjectApi: AdminProjectApi;
	adminUserStore: AdminUserStore;
	projectStore: ProjectStore;
	fileStore: FileStore;

	constructor(adminProjectApi: AdminProjectApi, adminUserStore: AdminUserStore, projectStore: ProjectStore, fileStore: FileStore) {
		makeObservable(this);
		this.adminProjectApi = adminProjectApi;
		this.adminUserStore = adminUserStore;
		this.projectStore = projectStore;
		this.fileStore = fileStore;
	}

	@observable
	items: AdminProjectVm[] = observable([]);

	@computed
	get projectsOnly() {
		return this.items.filter((i) => !TemplateManagers.includes(i.project.userId!));
	}
	@computed
	get templatesOnly() {
		return this.items.filter((i) => TemplateManagers.includes(i.project.userId!));
	}

	@observable
	current?: AdminProjectVm = undefined;

	@action
	loadById(projectId: string) {
		this.current = this.items.find((i) => i.project.id === projectId);
	}

	async load() {
		if (this.items.length > 0) {
			return this.items;
		}
		const scope = {
			userId: 'userId',
		};
		const p: any = [];
		p.push(this.adminProjectApi.listAll(scope));
		p.push(this.adminUserStore.load());

		const res = await Promise.all(p);

		const projects = res[0] as ProjectModel[];
		const users = res[1] as AdminUserVM[];

		const items = projects.map((p) => {
			const uVM = users.find((u) => u.user.fakeId === p.userId);
			const u = uVM ? uVM.user : undefined;
			return new AdminProjectVm(p, u);
		});

		runInAction(() => {
			this.items = items;
		});
		return this.items;
	}
}
