import Icon from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { getLayerTypeIcon } from 'app/stores/viewmodels/project.menu.vm';
import { computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import { LayerType, UserModel } from 'shared-puredio';
import { ILayerItem } from '../Edit/ProjectLayers';

class LayerActionVM {
	@observable
	layerType: LayerType;
	@observable
	isDisabled: boolean;
	@observable
	user?: UserModel;
	constructor(layerType: LayerType, isDisabled: boolean, user?: UserModel) {
		makeObservable(this);
		this.layerType = layerType;
		this.isDisabled = isDisabled;
		this.user = user;
	}

	@computed
	get canToggleVisibilty() {
		if (this.layerType === LayerType.watermark) {
			if (!this.user || !this.user.canAccessPremiumFeatures) {
				return false;
			}
		}
		return true;
	}

	@computed
	get canDeleteLayer() {
		if (this.layerType === LayerType.background) {
			return false;
		}
		if (this.layerType === LayerType.watermark) {
			if (!this.user || !this.user.canAccessPremiumFeatures) {
				return false;
			}
		}
		return true;
	}

	@computed
	get canMoveLayer() {
		if (this.isDisabled || this.layerType === LayerType.background) {
			return false;
		}
		if (this.layerType === LayerType.watermark) {
			return false;
		}
		return true;
	}
}

export const ProjectLayerActions = observer((props: ILayerItem) => {
	const history = useHistory();
	const { animatorStore, authStore } = useStore();
	const layer = props.layer;
	const layerKey = layer.key;

	const selectLayer = (key: string) => {
		const p = routes.EDITPROJECT.getPath(props.project.id, key);
		history.push({
			pathname: p,
			search: '?panel=' + layerKey,
		});
	};

	const toggleVisible = () => {
		if (animatorStore.paperVm) {
			animatorStore.paperVm.toggleLayerVisiblity(layerKey);
		}
	};

	const moveBack = () => {
		if (animatorStore.paperVm) {
			animatorStore.paperVm.moveBack(layerKey);
		}
	};

	const moveFront = () => {
		if (animatorStore.paperVm) {
			animatorStore.paperVm!.moveFront(layerKey);
		}
	};
	// const duplicate = async () => {
	// 	if (animatorStore.paperVm) {
	// 		const newKey = await animatorStore.paperVm!.duplicate(layerKey);
	// 		selectLayer(newKey);
	// 	}
	// };

	const remove = () => {
		if (animatorStore.paperVm) {
			animatorStore.paperVm!.removeLayer(layerKey);
		}
	};

	const isDisabled = layer.isDisabled;
	const [isActive, setIsActive] = useState(false);
	const vm = new LayerActionVM(layer.layerType, layer.isDisabled, authStore.current ? authStore.current : undefined);
	useEffect(() => {
		if (layer.key === animatorStore.selectedLayerKey) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [animatorStore.selectedLayerKey, layer.key, layer.layerType, layer.isDisabled]);

	return (
		<div className="controls">
			<button className="button is-text is-black has-icon align-left not-underlined" onClick={() => selectLayer(layerKey)}>
				<Icon iconName={getLayerTypeIcon(layer.layerType)} faModifier={isActive ? 'fas' : 'fal'} />
			</button>
			<button className="button is-text is-black has-icon align-left not-underlined" onClick={toggleVisible} disabled={!vm.canToggleVisibilty}>
				{isDisabled ? <Icon iconName="eye-slash" faModifier="fal" /> : <Icon iconName="eye" faModifier="fal" />}
			</button>
			<button className="button is-text is-black has-icon align-left not-underlined" onClick={moveFront} disabled={!vm.canMoveLayer}>
				<Icon iconName="arrow-up" faModifier="fal" />
			</button>
			<button className="button is-text is-black has-icon align-left not-underlined" onClick={moveBack} disabled={!vm.canMoveLayer}>
				<Icon iconName="arrow-down" faModifier="fal" />
			</button>
			<button className="button is-text is-black has-icon align-left not-underlined" onClick={remove} disabled={!vm.canDeleteLayer}>
				<Icon iconName="trash-alt" faModifier="fal" />
			</button>
			{/* <button className="button is-text is-black has-icon align-left not-underlined" onClick={duplicate} disabled={!hasActions}>
				<Icon iconName="clone" faModifier="fal" />
			</button> */}
		</div>
	);
});
