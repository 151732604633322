import { computed, reaction } from 'mobx';
import { Group, Shape } from 'paper';
import { LayerType } from '../..';
import { Fonts } from '../../models/fonts';
import { BaseAnimation } from '../BaseAnimation';

export class BaseTextLayer extends BaseAnimation {
    public text: paper.PointText;
    public backgroundRect: paper.Shape;
    public layerType: LayerType = LayerType.text;
    animationEnabled: boolean = false;

    @computed
    get phrases() {
        return this.anim.animationLayer.opts.phrases;
    }

    @computed
    get content() {
        return this.anim.animationLayer.opts.content;
    }
    @computed
    get color() {
        return this.anim.animationLayer.opts.color;
    }

    @computed
    get googleFont() {
        const f = Fonts.find((f) => f.family === this.fontFamily);
        if (f) {
            f.setVariant(this.fontVariant);
            return f;
        }
        console.warn('Could not find fontFamilty' + this.fontFamily + ' in font collection');
        // Fallback / Default
        return Fonts[0];
    }
    @computed
    get fontFamily() {
        return this.anim.animationLayer.opts.fontFamily;
    }

    @computed
    get fontVariant() {
        return this.anim.animationLayer.opts.fontVariant;
    }

    @computed
    get textAlign() {
        return this.anim.animationLayer.opts.textAlign;
    }

    @computed
    get hasBackground() {
        return this.anim.animationLayer.opts.hasBackground;
    }
    @computed
    get backgroundColor() {
        return this.anim.animationLayer.opts.backgroundColor;
    }

    public getDefaultOpts = (ftmId: string) => {
        return {
            x: {
                v: 0.5,
                d: 'w',
            },
            y: {
                v: 0.5,
                d: 'h',
            },
            width: {
                v: 0.5,
                d: 'w',
            },
            height: {
                v: 0.25,
                d: 'h',
            },
            color: '#000000',
            content: 'Text',
            fontFamily: 'Roboto',
            rotation: 0,
            scale: 1,
            backgroundColor: '#FFFFFF',
            textAlign: 'center',
        };
    };

    public getTextForFrame = (currentframe?: number) => {
        return this.content;
    };

    public create = async () => {
        this.group = new Group();

        let disp = reaction(
            () => [
                this.color,
                this.fontFamily,
                this.fontVariant,
                this.textAlign,
                // this.font,
                // this.fontWeight,
                this.hasBackground,
                this.backgroundColor,
                this.x,
                this.y,
                this.width,
                this.height,
                this.scale,
                this.rotation,
            ],
            () => {
                this.drawText();
                this.setupControlGroup();
            },
            { fireImmediately: true },
        );
        disp = reaction(
            () => [this.content, this.phrases],
            () => {
                this.updateTextContent();
                this.setupControlGroup();
            },
        );
        this.disposers.push(disp);
    };

    public drawText = (currentframe?: number) => {
        console.warn('Text Layer drawText not extended');
    };

    public drawBackgroundRect = () => {
        if (this.backgroundRect) {
            this.backgroundRect.remove();
        }
        const text = this.text;
        const radius = this.calc({ v: 0.015, d: 'w' });
        const hasBackground = this.hasBackground;
        const backgroundColor = this.backgroundColor || 'white';
        const paddingX = this.calc({ v: 0.025, d: 'w' });
        const paddingY = this.calc({ v: 0.015, d: 'h' });

        this.backgroundRect = new Shape.Rectangle({
            from: [text.bounds.topLeft.x - paddingX, text.bounds.topLeft.y - paddingY],
            to: [text.bounds.bottomRight.x + paddingX, text.bounds.bottomRight.y + paddingY],
            radius: radius,
            fillColor: hasBackground ? backgroundColor : 'transparent',
        });

        this.backgroundRect.insertBelow(this.text);
    };

    public updateTextContent = () => {
        this.text.content = this.getTextForFrame();
        this.drawBackgroundRect();

        this.optsUpdate({
            x: { v: this.controlGroup.calcFromPixel('w', this.group.position.x), d: 'w' },
            y: { v: this.controlGroup.calcFromPixel('h', this.group.position.y), d: 'h' },
        });
    };
}
