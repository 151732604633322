import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import routes from '../../routes';
import { SignOutButton } from './auth/SignOutButton';
import { SvgIcon, SvgIconType } from './common/SvgIcon';
import { Roles } from './SideMenu';
import t from '../common/translate';

export const SideMenuAdmin = observer(() => {
	const { authStore } = useStore();
	const user = authStore.current;
	if (!user) {
		return <></>;
	}

	return (
		<>
			<div className="top">
				<nav>
					<NavLink className="nav-item" activeClassName="is-active" exact={false} to={routes.ADMINUSERS.getPath()}>
						Users
					</NavLink>
					<NavLink className="nav-item" activeClassName="is-active" exact={false} to={routes.ADMINPROJECTS.getPath()}>
						Episodes
					</NavLink>
					<NavLink className="nav-item" activeClassName="is-active" exact={false} to={routes.ADMINTEMPLATES.getPath()}>
						Templates
					</NavLink>
					<NavLink className="nav-item" activeClassName="is-active" exact={false} to={routes.ADMINUSAGE.getPath()}>
						Usage
					</NavLink>
					<NavLink className="nav-item" activeClassName="is-active" exact={false} to={routes.ADMINFILES.getPath()}>
						Files
					</NavLink>
					<NavLink className="nav-item" activeClassName="is-active" exact={false} to={routes.ADMINTASKS.getPath()}>
						Tasks
					</NavLink>
					<NavLink className="nav-item" activeClassName="is-active" exact={false} to={routes.ADMINMAILS.getPath()}>
						Mails
					</NavLink>
				</nav>
			</div>
			<div className="bottom">
				{user && (
					<Link className="profile-box" to={routes.ACCOUNTSETTINGS.getPath()}>
						<SvgIcon iconName={SvgIconType.profile} />
						<div className="profile-info">{user.firstName}</div>
						{user.activePlan && <span className="tag is-primary is-round is-small">{t(`plans:${user.activePlan}.title`)}</span>}
					</Link>
				)}

				<SignOutButton />
				<div className="debug">
					<Roles />
				</div>
			</div>
		</>
	);
});
