import { FileType, ProjectModel, VideoFormats } from 'shared-puredio';
import { BaseApi, IStoreScope } from './base.api';
import { AxiosInstance } from 'axios';
import { firestore } from './firebase-init';
import ComputeApi from './compute.api';
import { Timestamp } from '@google-cloud/firestore';

export class ProjectApi extends BaseApi<ProjectModel> {
	protected readonly axios: AxiosInstance;
	computeApi: ComputeApi;
	constructor(axios: AxiosInstance, computeApi: ComputeApi) {
		super();
		this.collection = 'projects';
		this.needsUserId = true;
		this.axios = axios;
		this.computeApi = computeApi;
	}

	createNewInstance(data?: any): ProjectModel {
		if (data) {
			return new ProjectModel(data, []);
		}
		// @ts-ignore
		const m = new ProjectModel(
			{
				id: 'test',
				created: new Date(),
				modified: new Date(),
				title: 'EMPTY',
				groupId: 'EMPTY',
				userId: '',
				start: 0,
				templateId: 'test',
				fmtId: VideoFormats.square.id,
				audioLayer: { key: 'audio' },
				layers: [],
			},
			[],
		);
		return m;
	}

	async liteLoad() {
		const res = await this.computeApi.getProjects();
		if (res && !res.data) {
			this.list.replace([]);
		} else {
			this.list.replace(
				res.data.map((d: any) => {
					if (d.created && d.created._seconds) {
						d.created = new Timestamp(d.created._seconds, d.created._nanoseconds);
					}
					if (d.modified && d.modified._seconds) {
						d.modified = new Timestamp(d.modified._seconds, d.modified._nanoseconds);
					}
					return new ProjectModel(d);
				}),
			);
		}
	}
	async isImageInUse(md5: string, type: FileType) {
		const res = await this.computeApi.isImageInUse(md5, type);
		return res.data === true;
	}

	async getWaveFormData(url: string) {
		this.setIsBusy(true);
		const res = await this.axios
			.get(url, {
				responseType: 'arraybuffer',
			})
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}
}

export class AdminProjectApi extends BaseApi<ProjectModel> {
	protected readonly axios: AxiosInstance;
	constructor(axios: AxiosInstance) {
		super();
		this.collection = 'projects';
		this.needsUserId = false;
		this.axios = axios;
	}
	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		const ref = firestore.collection(this.collection);
		return ref;
		// return ref.where('userId', '==', scope.userId);
	}
	createNewInstance(data?: any): ProjectModel {
		if (data) {
			return new ProjectModel(data, []);
		}
		// @ts-ignore
		const m = new ProjectModel(
			{
				id: 'test',
				created: new Date(),
				modified: new Date(),
				title: 'EMPTY',
				groupId: 'EMPTY',
				userId: '',
				start: 0,
				templateId: 'test',
				fmtId: VideoFormats.square.id,
				audioLayer: { key: 'audio' },
				layers: [],
			},
			[],
		);
		return m;
	}
}
