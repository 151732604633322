import Noty from 'noty';

Noty.overrideDefaults({
	layout: 'bottomRight',
	timeout: 2500,
	progressBar: false,
});

const getIcon = (type?: Noty.Type) => {
	let icon;
	switch (type) {
		case 'info':
		case 'information':
			icon = 'info-circle';
			break;
		case 'success':
			icon = 'check-circle';
			break;
		default:
			icon = 'exclamation-circle';
			break;
	}

	return `<i class="fa fa-${icon}"></i>`;
};

export const notify = (text: string, type?: Noty.Type) => {
	let timeout = 2500;
	switch (type) {
		case 'info':
		case 'information':
		case 'success':
			break;
		default:
			timeout = 5000;
			break;
	}

	new Noty({
		type: type ? type : 'info',
		text: `${getIcon(type)} ${text}`,
		timeout,
	}).show();
};
