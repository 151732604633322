import { observer } from 'mobx-react';
import React from 'react';

interface IInputControl {
	value: any;
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
}

@observer
export default class MultilineInputControl extends React.Component<IInputControl> {
	constructor(props: IInputControl) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(event: any) {
		this.props.onChange(event.target.name, event.target.value);
	}

	render() {
		const props = this.props;
		const id = this.props.id;
		return (
			<div className="form-control">
				{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
				<textarea autoFocus className="input" id={id} name={props.name} onChange={this.onChange} value={props.value} />
			</div>
		);
	}
}
