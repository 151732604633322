import { observer } from 'mobx-react';
import React from 'react';
import { AnimatedPhrase, AnimationTypes, LayerModel, LayerType } from 'shared-puredio';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { LayerFormBackground } from './LayerFormBackground';
import t from 'app/common/translate';
import { LayerFormAudiogram } from './LayerFormAudiogram';
import { LayerFormProgress } from './LayerFormProgress';
import { LayerFormText } from './LayerFormText';
import { LayerFormImage } from './LayerFormImage';
import { LayerFormAnimatedText } from './LayerFormAnimatedText';
import { LayerFormWatermark } from './LayerFormWatermark';
import { LayerFormTextArea } from './LayerFormTextArea';

interface IAnimLayerFormProps {
	title: string;
	projectVM: ProjectVM;
	animLayer: LayerModel;
}

export interface IElementName {
	name: string;
}

export const AnimLayerEditForm: React.FC<IAnimLayerFormProps> = observer(({ title, projectVM, animLayer }: IAnimLayerFormProps) => {
	if (!animLayer) {
		return <span>Not found</span>;
	}

	const { animClass } = animLayer;

	const setDefaultOpts = () => {
		const ba = AnimationTypes.getByBaseAnimationByName(animClass);
		const opts = ba.getDefaultOpts(animLayer.fmtId);
		animLayer.setOpts(opts);
	};

	return (
		<>
			<div className={`ui-control is-${animClass.toLowerCase()}`}>
				<div className="main-controls">
					<h2 className="title">
						{t(`components:layertype.${animLayer.layerType}`)}
						{/* <ProjectLayerActions layer={animLayer} project={projectVM} /> */}
					</h2>
					{animLayer.layerType === LayerType.background && <LayerFormBackground title={title} projectVM={projectVM} animLayer={animLayer} />}
					{animLayer.layerType === LayerType.audiogram && <LayerFormAudiogram title={title} projectVM={projectVM} animLayer={animLayer} />}
					{animLayer.layerType === LayerType.progress && <LayerFormProgress title={title} projectVM={projectVM} animLayer={animLayer} />}
					{animLayer.layerType === LayerType.text && <LayerFormText title={title} projectVM={projectVM} animLayer={animLayer} showInput={true} />}
					{animLayer.layerType === LayerType.textArea && <LayerFormTextArea title={title} projectVM={projectVM} animLayer={animLayer} showInput={true} />}
					{animLayer.layerType === LayerType.image && <LayerFormImage title={title} projectVM={projectVM} animLayer={animLayer} />}
					{animLayer.layerType === LayerType.animatedText && <LayerFormText title={title} projectVM={projectVM} animLayer={animLayer} showInput={false} />}
					{animLayer.layerType === LayerType.watermark && <LayerFormWatermark title={title} projectVM={projectVM} animLayer={animLayer} />}
				</div>

				{animLayer.layerType === LayerType.animatedText && (
					<>
						<LayerFormAnimatedText title={title} projectVM={projectVM} animLayer={animLayer} />
					</>
				)}
			</div>

			<div className="debug">
				<button onClick={setDefaultOpts}>SetDefaultOpts</button>
				Class: {animLayer.animClass}
				<br />
				Color: {animLayer.opts.color}
				<br />
				Start Color: {animLayer.opts.startColor}
				<br />
				End Color: {animLayer.opts.endColor}
				<br />
				background: {animLayer.opts.background}
				<br />
				BackgroundColor: {animLayer.opts.backgroundColor}
				<br />
				image:{' '}
				{animLayer.opts.image && (
					<>
						{animLayer.opts.image.md5Hash} {animLayer.opts.image.originalFileName}
					</>
				)}
				<br />
				amplitude: {animLayer.opts.amplitude}
				<br />
				{animLayer.layerType !== LayerType.animatedText && <>content: {animLayer.opts.content}</>}
				<br />
				phrases:{' '}
				{animLayer.opts.phrases && (
					<>
						{animLayer.opts.phrases.map((p: AnimatedPhrase) => (
							<div key={p.id}>
								{p.text} {p.start} {p.end}
							</div>
						))}
					</>
				)}
				<br />
				fontVariant: {animLayer.opts.fontVariant}
				<br />
				fontFamily: {animLayer.opts.fontFamily}
				<br />
				textAlign: {animLayer.opts.textAlign}
				<br />
				hasBackground: {animLayer.opts.hasBackground && <>true</>}
				<br />
			</div>
		</>
	);
});
