import { Point, Color, Path } from 'paper';
import BaseAudiogram from './BaseAudiogram';

export default class RoundedSquareWave extends BaseAudiogram {
    private pathLeft: paper.Path;
    private pathRight: paper.Path;

    public initAnimation = () => {
        this.pathLeft = new Path();
        this.pathRight = new Path();
        this.group.addChild(this.pathLeft);
        this.group.addChild(this.pathRight);
    };

    public drawAudiogram(points) {
        // calculate the needed geo

        const barWidth = this.anim.fmt.w / points.length / 2;
        const halfBar = barWidth / 2;
        const halfWidth = this.anim.fmt.w / 2;
        const halfHeight = this.anim.fmt.h / 2;
        const strokeWidth = this.calc({ v: 0.005, d: 'h' });
        const color = new Color(this.color || 'white');
        const amplitudeModifier = 0.25;
        const amplitude = this.calc({ v: this.amplitude * amplitudeModifier, d: 'h' });

        let barHeight: number;

        let pathLeft = this.pathLeft;
        pathLeft.removeSegments();
        pathLeft.strokeColor = color;
        pathLeft.strokeWidth = strokeWidth;

        let pathRight = this.pathRight;
        pathRight.removeSegments();
        pathRight.strokeColor = color;
        pathRight.strokeWidth = strokeWidth;

        // left side path
        for (let x = 0; x < points.length; x++) {
            const verticalSwitch = x % 2 == 0 ? 1 : -1; // reverses every second value
            const arcClockwise = verticalSwitch === 1 ? true : false;
            const compressEdges = x / points.length; // used to reduce effect on edges
            const reverseCompressEdges = 1 - compressEdges;

            barHeight = 0;
            for (let q = 0; q < points[x].length; q++) {
                barHeight += points[x][q];
            }

            barHeight = Math.round((barHeight / this.toAverage) * amplitude) * verticalSwitch;

            // draw left hand wave
            let startX = x * barWidth;
            let midX = startX + halfBar;
            let endX = startX + barWidth;
            let y = halfHeight - barHeight * compressEdges;
            let yVal = y - halfBar * verticalSwitch;

            // pathLeft.moveTo(new Point(startX, halfHeight));
            pathLeft.lineTo(new Point(startX, y));
            pathLeft.arcTo(new Point(endX, y), arcClockwise);
            pathLeft.lineTo(new Point(endX, halfHeight));

            // draw right side of the wave
            // startX = this.anim.fmt.w - startX;
            startX = halfWidth + startX;
            midX = startX + halfBar;
            endX = startX + barWidth;
            y = halfHeight - barHeight * reverseCompressEdges;
            yVal = y + halfBar * verticalSwitch;

            pathRight.moveTo(new Point(startX, halfHeight));
            pathRight.lineTo(new Point(startX, y));
            pathRight.arcTo(new Point(endX, y), arcClockwise);
            // pathRight.lineTo(new Point(endX, halfHeight));
        }
    }
}
