import { AxiosInstance } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { FileType, IAbortVideoRequest, IBigQueryRequest, IChangeSubscriptionRequest, IChangeSubscriptionRes, IExportVideoRequest, INormalizeFileResponse, INormalizeImageReq, SupoortMailModel } from 'shared-puredio';
import { firebaseAuth } from './firebase-init';
import Config from '../../config';

interface IAudioReadyCheck {
	audioIsReady: boolean;
}

class ComputeApi {
	protected readonly axios: AxiosInstance;

	@observable
	isBusy: boolean = false;

	constructor(axios: AxiosInstance) {
		makeObservable(this);
		this.axios = axios;
	}

	_buildUrl(url: string) {
		return new URL(url, Config.API_BASE_URL).href;
	}

	@action
	setIsBusy(b: boolean) {
		this.isBusy = b;
	}

	_handleError(error: any) {
		if (error.response) {
			if (error.response.data) {
				const res = error.response.data;
				if (res.statusCode && res.statusCode === 422) {
					res.message = 'api:' + res.message;
				}
				res.status = error.response.status;
				delete res.statusCode;
				return res;
			}
			console.log(error.response.status);
			console.log(error.response.headers);
			return {
				status: error.response.status,
			};
		} else if (error.request) {
			// The request was made but no response was received
			console.log(error.request);
			return {
				status: error.request.status,
			};
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
			debugger;
			return error.message;
		}
	}

	async helloWorld() {
		const url = this._buildUrl('/');
		const res = await this.axios.get(url);
		if (res.status === 200) {
			console.log('api connected', res.data);
		} else {
			console.warn('api error:', res);
		}
		return;
	}
	async getAuthHeader() {
		if (!firebaseAuth.currentUser) {
			console.log('not logged in??');
			return {};
		}
		const token = await firebaseAuth.currentUser!.getIdToken();
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		return config;
	}

	async deleteFile(md5: string) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/file/' + encodeURI(md5));
		this.setIsBusy(true);
		await this.axios
			.delete(url, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
	}

	async getProjects() {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/projects');
		this.setIsBusy(true);
		const res = await this.axios
			.get(url, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}
	async getTemplates() {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/templates');
		this.setIsBusy(true);
		const res = await this.axios
			.get(url, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async isImageInUse(md5: string, type: FileType) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl(`/projects/inuse/${md5}/${type}`);
		this.setIsBusy(true);
		const res = await this.axios
			.get(url, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async parseAudio(key: string, force: boolean = false) {
		const header = await this.getAuthHeader();
		const data = {
			key,
			force,
		};
		const url = this._buildUrl('/parseaudio');
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async checkAudioIsReady(md5: string) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/audioready/' + md5);
		this.setIsBusy(true);
		const res = await this.axios
			.get(url, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res.data as IAudioReadyCheck;
	}

	async checkSpeechToTextReady(taskId: string) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl(`/speechtotext/${taskId}/isready`);
		const res = await this.axios.get(url, header).catch(this._handleError);
		return res.data as IAudioReadyCheck;
	}

	async prepareImage(userId: string, key: string): Promise<INormalizeFileResponse> {
		const header = await this.getAuthHeader();
		const req: INormalizeImageReq = {
			userId,
			orgKey: key,
		};
		const url = this._buildUrl('/prepareimage');
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, req, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res.data;
	}

	async triggerRender(data: IExportVideoRequest) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/render');
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async biqQuery(data: IBigQueryRequest) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/bigquery');
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async getPlans() {
		const url = this._buildUrl('/paddle/plans');
		const header = await this.getAuthHeader();
		const data = {};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res.data;
	}

	async setPaddleUserId(paddleUserId: number) {
		const url = this._buildUrl('/paddle/user/id');
		const header = await this.getAuthHeader();
		const data = {
			paddleUserId,
		};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res.data;
	}

	async ensureSubscriptionUpToDate() {
		const url = this._buildUrl('/user/subscription');
		const header = await this.getAuthHeader();
		const data = {};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async listPaymentHistory() {
		const url = this._buildUrl('/user/payments');
		const header = await this.getAuthHeader();
		const data = {};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}
	async changeUserSubscription(data: IChangeSubscriptionRequest) {
		const url = this._buildUrl('/user/change-subscription');
		const header = await this.getAuthHeader();
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res.data as IChangeSubscriptionRes;
	}

	async cancelUserSubscription() {
		const url = this._buildUrl('/user/subscription/cancel');
		const header = await this.getAuthHeader();
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, {}, header)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res.data as IChangeSubscriptionRes;
	}

	async updateAllSubscriptions() {
		const url = this._buildUrl('/admin/user/subscription');
		const header = await this.getAuthHeader();
		const data = {};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res;
	}

	async updateUserPayment(userId: string, freeUntil: string, plan: string, paymentType: string) {
		const url = this._buildUrl('/admin/user/payment');
		const header = await this.getAuthHeader();
		const data = {
			userId,
			freeUntil,
			plan,
			paymentType,
		};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res;
	}

	async getAllUsersForAdmin() {
		const url = this._buildUrl('/admin/users');
		const header = await this.getAuthHeader();
		const data = {};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res;
	}

	async ensureAllTasksInExportUsage() {
		const url = this._buildUrl('/admin/tasks/2/exportusage');
		const header = await this.getAuthHeader();
		const data = {};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res;
	}

	async updateUserSubscription(userId: string) {
		const url = this._buildUrl('/admin/user/subscription/' + userId);
		const header = await this.getAuthHeader();
		const data = {};
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res;
	}

	renderDownloadUrl(taskId: string) {
		const userId = firebaseAuth.currentUser!.uid;
		return this._buildUrl('/render/download/' + userId + '/' + taskId);
	}
	async abortRender(data: IAbortVideoRequest) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/render/abort');
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async sendSupportEmail(mail: SupoortMailModel) {
		const url = this._buildUrl('/support-email');
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, mail)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async changeEmail(email: string, lang: string) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/auth/change-email');
		const data = { email, lang };
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async sendEmailVerification(lang: string) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/auth/send-verfiy-email/' + lang);
		this.setIsBusy(true);
		const res = await this.axios
			.get(url, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async verifyEmail(verifiyId: string) {
		const header = await this.getAuthHeader();
		const url = this._buildUrl('/auth/verify-email');
		const data = { verifiyId };
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data, header)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async triggerForgotPassword(email: string) {
		const url = this._buildUrl('/auth/forgot-password');
		const data = { email };
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data)
			.catch(this._handleError)
			.finally(() => this.setIsBusy(false));
		return res;
	}

	async changePassword(verfiyId: string, newPassword: string) {
		const url = this._buildUrl('/auth/change-password');
		const data = { verfiyId, newPassword };
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res;
	}
	async checkResetPassworId(verfiyId: string) {
		const url = this._buildUrl('/auth/change-password/check');
		const data = { verfiyId };
		this.setIsBusy(true);
		const res = await this.axios
			.post(url, data)
			.catch(this._handleError)
			.finally(() => {
				this.setIsBusy(false);
			});
		return res;
	}
}
export default ComputeApi;
