import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { AnimLayerEditForm } from '../AnimLayer/AnimLayerEditForm';
import { SidePanelItem } from 'app/stores/viewmodels/project.menu.vm';
import { AudioLayerEdit } from '../AudioLayer/AudioLayerEdit';

interface IProjectEdit {
	project: ProjectVM;
}
interface IProjectLayerEditProps {
	project: ProjectVM;
	layerKey: string;
	layerTitle: string;
}

const ProjectLayerEditBody = observer((props: IProjectLayerEditProps) => {
	const { project, layerKey, layerTitle } = props;
	const layers = project.model.animLayers;
	const layer = layers.find((l) => l.key === layerKey);

	if (layer) {
		return <AnimLayerEditForm title={layerTitle} key={layer.key} projectVM={project} animLayer={layer} />;
	}
	if (layerKey === 'audio') {
		return <AudioLayerEdit {...props} />;
	}

	return <div>{layerKey} not defined</div>;
});

export const ProjectSidePanel = observer((props: IProjectEdit) => {
	const project = props.project;

	const [sidePanelItem, setSidePanelItem] = useState<SidePanelItem>();
	useEffect(() => {
		if (project.menu) {
			const x = project.menu.getSidePanelItem(project.currentSidePanel);
			setSidePanelItem(x);
		}
	}, [project.currentSidePanel, project.menu]);

	return (
		<>
			{sidePanelItem && (
				<>
					<div className={`side-panel ${sidePanelItem.isWide ? 'is-wide' : ''}`}>
						<ProjectLayerEditBody key={sidePanelItem.key} layerKey={sidePanelItem.key} layerTitle={sidePanelItem.title} project={project} />
					</div>
				</>
			)}
		</>
	);
});
