import React from 'react';
import { IProps, useStore } from './../context';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { SideMenuAdmin } from 'app/components/SideMenuAdmin';
import { NotFound } from 'App';
import { AdminUsageList } from 'app/components/admin/usage/AdminUsageList';

export interface AdminUsersParamTypes {
	id?: string;
}

const AdminUsageContainer: React.FC<IProps> = observer(() => {
	const { adminUserStore } = useStore();

	return (
		<>
			{adminUserStore.isAdmin && (
				<BaseContainer menu={<SideMenuAdmin />}>
					<AdminUsageList />
					{/* {adminUserStore.current && <AdminUserDetailPanel />} */}
				</BaseContainer>
			)}
			{!adminUserStore.isAdmin && <NotFound />}
		</>
	);
});

export default AdminUsageContainer;
