import React from 'react';
import { IProps, useStore } from './../context';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { SideMenuAdmin } from 'app/components/SideMenuAdmin';
import { NotFound } from 'App';

const AdminContainer: React.FC<IProps> = observer(() => {
	const { adminUserStore } = useStore();

	return (
		<>
			{adminUserStore.isAdmin && (
				<BaseContainer menu={<SideMenuAdmin />} navbar={<></>}>
					<div className="section">
						<span>Hier nur Admins.</span>
					</div>
				</BaseContainer>
			)}
			{!adminUserStore.isAdmin && <NotFound />}
		</>
	);
});

export default AdminContainer;
