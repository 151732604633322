import { observer } from 'mobx-react';
import React, { ChangeEvent, useState } from 'react';

interface IFontSizeContol {
	value: number;
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
}

export const FontSizeControl: React.FC<IFontSizeContol> = observer((props: IFontSizeContol) => {
	const [text, setText] = useState(props.value);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setText(parseInt(e.target.value));
		props.onChange(props.name, e.target.value);
	};

	return (
		<>
			<div className="form-control">
				{props.label && <label>{props.label}</label>}
				<input type="number" className="input" id={props.id} name={props.name} onChange={handleChange} value={text} />
			</div>
		</>
	);
});
