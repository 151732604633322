import { FileModel } from 'shared-puredio';
import { BaseApi, IStoreScope } from './base.api';
import { firestore } from './firebase-init';
export class FileApi extends BaseApi<FileModel> {
	constructor() {
		super();
		this.collection = 'files';
		this.needsUserId = true;
	}

	getCollection() {
		return firestore.collection(this.collection);
	}

	createNewInstance(data?: any): FileModel {
		const m = new FileModel();
		Object.assign(m, data);
		m.fixDate('created');
		return m;
	}
}

export class AdminFileApi extends FileApi {
	constructor() {
		super();
		this.collection = 'files';
		this.needsUserId = false;
	}

	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		const ref = firestore.collection(this.collection);
		return ref;
		// return ref.where('userId', '==', scope.userId);
	}

	// getCollection() {
	// 	return firestore.collection(this.collection);
	// }

	// createNewInstance(data?: any): FileModel {
	// 	const m = new FileModel();
	// 	Object.assign(m, data);
	// 	m.fixDate('created');
	// 	return m;
	// }
}
