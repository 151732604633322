import { ProjectStore } from './project.store';
import { AuthStore } from './auth.store';
import Axios from 'axios';
import { autorun, computed, runInAction } from 'mobx';
import { AdminProjectApi, ProjectApi } from '../api/project.api';
import { TasksAudioPrepApi } from '../api/tasks.audio.prep.api';
import { UiStore } from './ui.store';
import { TrackingStore } from './tracking.store';
import { BucketStore as BucketApi } from './bucket.store';
import { AnimatorStore } from './animator.store';
import ComputeApi from '../api/compute.api';
import { GroupStore } from './group.store';
import { GroupApi } from '../api/group.api';
import { FileStore } from './file.store';
import { AdminFileApi, FileApi } from 'app/api/file.api';
import { RendersStore } from './renders.store';
import { TasksProjectApi } from 'app/api/tasks.project.api';
import { TasksMainApi } from 'app/api/tasks.main.api';
import { ProjectRenderStore } from './project.render.store';
import { UsageStore } from './usage.store';
import { ExportUsageApi } from 'app/api/export.usage.api';
import { AdminUserStore } from './admin.user.store';
import { UserApi } from 'app/api/user.api';
import { PaddleStore } from './paddle.store';
import { AdminProjectStore } from './admin.project.store';
import { TemplateApi } from 'app/api/template.api';
import { TemplateStore } from './template.store';
import { AudioPrepStore } from './audio.prep.store';
import { AdminUsageStore } from './admin.exportusage.store';
import { AdminExportUsageApi } from 'app/api/export.usage.api';
import { AdminTaskApi } from 'app/api/admin.task.api';
import { AdminTasksStore } from './admin.tasks.store';
import { AdminFilesStore } from './admin.files.store';
import { AdminMailApi } from 'app/api/mail.api';
import { AdminMailsStore } from './admin.mails.store';

const axios = Axios.create({});
const computeApi = new ComputeApi(axios);
const authStore = new AuthStore();
const tasksAudioPrepApi = new TasksAudioPrepApi();
const tasksMainApi = new TasksMainApi();
const tasksProjectApi = new TasksProjectApi();
const exportUsageApi = new ExportUsageApi();
const projectApi = new ProjectApi(axios, computeApi);
const adminProjectApi = new AdminProjectApi(axios);
const adminExportUsageApi = new AdminExportUsageApi();
const adminTasksApi = new AdminTaskApi();
const adminFilesApi = new AdminFileApi();
const adminMailApi = new AdminMailApi();
const groupsApi = new GroupApi();
const userApi = new UserApi();
const fileApi = new FileApi();
const templateApi = new TemplateApi(axios, computeApi);

export class GlobalStore {
	projectStore: ProjectStore;
	uiStore: UiStore;
	trackingStore: TrackingStore;
	authStore: AuthStore;
	bucketStore: BucketApi;
	animatorStore: AnimatorStore;
	computeApi: ComputeApi;
	groupStore: GroupStore;
	rendersStore: RendersStore;
	projectRenderStore: ProjectRenderStore;
	fileStore: FileStore;
	usageStore: UsageStore;
	adminUserStore: AdminUserStore;
	adminProjectStore: AdminProjectStore;
	adminUsageStore: AdminUsageStore;
	adminFilesStore: AdminFilesStore;
	adminTasksStore: AdminTasksStore;
	adminMailsStore: AdminMailsStore;

	paddleStore: PaddleStore;
	templateStore: TemplateStore;
	audioPrepStore: AudioPrepStore;
	constructor() {
		this.computeApi = computeApi;
		this.authStore = authStore;
		this.uiStore = new UiStore(authStore);
		this.trackingStore = new TrackingStore();
		this.bucketStore = new BucketApi(authStore);
		this.animatorStore = new AnimatorStore(authStore, this.bucketStore, projectApi);
		this.groupStore = new GroupStore(authStore, groupsApi);
		this.fileStore = new FileStore(authStore, fileApi, this.computeApi);
		this.projectStore = new ProjectStore(authStore, projectApi, this.fileStore, this.computeApi, templateApi);
		this.rendersStore = new RendersStore(authStore, tasksMainApi, projectApi, groupsApi);
		this.projectRenderStore = new ProjectRenderStore(authStore, tasksProjectApi);
		this.usageStore = new UsageStore(authStore, exportUsageApi, this.rendersStore);
		this.adminUserStore = new AdminUserStore(authStore, userApi, this.computeApi);
		this.adminUsageStore = new AdminUsageStore(adminProjectApi, userApi, this.projectStore, this.fileStore, adminExportUsageApi);
		this.adminProjectStore = new AdminProjectStore(adminProjectApi, this.adminUserStore, this.projectStore, this.fileStore);
		this.adminFilesStore = new AdminFilesStore(adminFilesApi, this.adminUserStore);
		this.adminTasksStore = new AdminTasksStore(this.adminUserStore, this.adminProjectStore, this.fileStore, adminTasksApi, this.adminFilesStore);
		this.adminMailsStore = new AdminMailsStore(adminMailApi, this.adminUserStore);
		this.paddleStore = new PaddleStore(authStore, this.computeApi);
		this.templateStore = new TemplateStore(authStore, templateApi);
		this.audioPrepStore = new AudioPrepStore(authStore, tasksAudioPrepApi);
		this.computeApi.helloWorld();
		console.time('auth login');

		autorun(() => {
			if (authStore.current && authStore.current.id && !authStore.isEntityLoading) {
				console.timeEnd('auth login');
				runInAction(() => {
					groupsApi.listenToList();
					tasksAudioPrepApi.listenToList();
					tasksMainApi.listenToList();
					fileApi.listenToList();
					exportUsageApi.listenToList();
					userApi.listenToList();
					projectApi.liteLoad();
					templateApi.liteLoad();
					// projectApi.listenToList();
					// templateApi.listenToList();
				});
			} else {
				projectApi.stopListeningToList();
				groupsApi.stopListeningToList();
				fileApi.stopListeningToList();
				tasksMainApi.stopListeningToList();
			}
		});
	}

	@computed
	get isBusy() {
		console.log(this.computeApi.isBusy, this.groupStore.isBusy, this.projectStore.isBusy, this.animatorStore.isBusy, this.uiStore.isUploading);
		return this.computeApi.isBusy || this.groupStore.isBusy || this.projectStore.isBusy || this.animatorStore.isBusy || this.uiStore.isUploading;
	}
}
