import React from 'react';
import { observer } from 'mobx-react';
import { AnimationTypes, LayerType } from 'shared-puredio';
import { SelectControl } from 'app/components/common/SelectControl';
import t from 'app/common/translate';

interface IAnimClassSelectControlProps {
	layerType: LayerType;
	animClass: string;
	onChange: (value: any) => void;
}

export const AnimClassSelectControl: React.FC<IAnimClassSelectControlProps> = observer((props: IAnimClassSelectControlProps) => {
	const classes = AnimationTypes.classNamesByLayerType(props.layerType);
	const opts = classes.map((c) => {
		return { value: c, label: t('components:animclass.' + c) };
	});
	const handleChange = (name: string, value: any) => {
		props.onChange(value);
	};
	if (opts.length <= 1) {
		return null;
	}
	return (
		<div className="layer-type-select">
			<SelectControl name={props.layerType} onChange={handleChange} opts={opts} value={props.animClass} />
		</div>
	);
});
