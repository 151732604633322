import { useStore } from 'app/context';
import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import t from 'app/common/translate';
import { observer } from 'mobx-react';
import FormField from '../Form/FormField';
import CrudEntityErrors from '../Form/CrudEntityErrors';
import { Button } from '../common/Button';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { Trans } from 'react-i18next';
import { passwordRegex } from '../../utils';
import { SvgIcon, SvgIconType } from '../common/SvgIcon';
import Icon from '../common/Icon';

const registerFormSchema = Yup.object()
	.shape({
		email: Yup.string().email(t('validation:invalid.email')).required(t('validation:required.field')).default(''),
		password: Yup.string().matches(passwordRegex, t('validation:password.complexity')).required(t('validation:required.field')).default(''),
		firstName: Yup.string().required(t('validation:required.field')).default(''),
		lastName: Yup.string().required(t('validation:required.field')).default(''),
		legal: Yup.boolean().oneOf([true], t('auth:register.tosmustbechecked')).required(t('validation:required.field')).default(false),
	})
	.required();

type Register = Yup.InferType<typeof registerFormSchema>;

export const RegisterForm: React.FC = observer(() => {
	const { authStore, groupStore, projectStore } = useStore();

	const [registered, setRegisterd] = useState(false);

	const handleSubmit = async (values: Register) => {
		await authStore.register({ ...values });
		if (authStore.current) {
			// create demo content!
			const group = await groupStore.createDemoGroup();
			if (group) {
				await projectStore.createDemoProject(group);
			}

			setRegisterd(true);
		}
	};

	if (registered) {
		return (
			<div className="auth-form is-success">
				<section className="section">
					<div className="auth-form-header">
						<img src="/images/brand-with-signet.svg" alt="Puredio"></img>
						<h1 className="title is-3">{t('auth:register.success')}</h1>
					</div>

					<div className="align-center">
						<Trans i18nKey={'auth:register.success.calltoaction'}>
							Newlines <br /> for <br /> render <br />
						</Trans>
					</div>
				</section>
				<div className="text-links">
					<a href={t('impressum.link')} target="_blank" rel="noopener noreferrer">
						{t('impressum')}
					</a>
					<a href={t('datenschutz.link')} target="_blank" rel="noopener noreferrer">
						{t('datenschutz')}
					</a>
					<a href={t('agb.link')} target="_blank" rel="noopener noreferrer">
						{t('agb')}
					</a>
				</div>
			</div>
		);
	}

	return (
		<div className="auth-form is-register">
			<div className="form-container">
				<div className="form-container-bg">
					<div className="auth-form-header">
						<img src="/images/brand-with-signet.svg" alt="Puredio"></img>
					</div>
					<div className="content pad-bottom-3rem">
						<p>Aufgrund fehlender personeller und zeitlicher Ressourcen sehen wir uns dazu gezwungen, den Betrieb von Puredio zum 15.12.2022 einzustellen.</p>
						<p>Neue Registrierungen sind leider nicht mehr möglich.</p>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className="auth-form is-register">
			<div className="form-container">
				<div className="form-container-bg">
					<div className="auth-form-header">
						<img src="/images/brand-with-signet.svg" alt="Puredio"></img>
						<h1 className="title is-1">{t('auth:register.welcome')}</h1>
					</div>

					<Formik validationSchema={registerFormSchema} initialValues={{ email: '', password: '', firstName: '', lastName: '', legal: false }} onSubmit={handleSubmit}>
						{({ errors, touched }) => (
							<Form className="form">
								<div className="field is-inline">
									<FormField error={errors.firstName} touched={touched.firstName}>
										<Field type="text" name="firstName" autoComplete="given-name" placeholder={t('auth:firstName')} />
									</FormField>
									<FormField error={errors.lastName} touched={touched.lastName}>
										<Field type="text" name="lastName" placeholder={t('auth:lastName')} autoComplete="family-name" />
									</FormField>
								</div>
								<FormField error={errors.email} touched={touched.email}>
									<Field type="text" name="email" placeholder={t('auth:email')} autoComplete="email" />
								</FormField>
								<FormField error={errors.password} touched={touched.password}>
									<Field type="password" name="password" placeholder={t('auth:password')} autoComplete="current-password" />
								</FormField>
								<FormField error={errors.legal} touched={touched.legal} className="legal-optin">
									<Field type="checkbox" name="legal" />
									<small className="legal">
										<Trans i18nKey="auth:aggreeToTos">
											Here I agree to
											<a href={t('agb.link')} target="_blank" rel="noreferrer noopener">
												{t('agb')}
											</a>
											<a href={t('datenschutz.link')} target="_blank" rel="noreferrer noopener">
												{t('datenschutz')}
											</a>
										</Trans>
									</small>
								</FormField>

								<Button type="submit" className="button is-primary is-block">
									<span>{t('auth:register')}</span>
									<Icon iconName="arrow-right" faModifier="far" />
								</Button>
								<CrudEntityErrors store={authStore} />
							</Form>
						)}
					</Formik>
					<div className="auth-links">
						<div className="auth-link">
							<Trans i18nKey={'auth:allreadyHaveAnAccount.linked'}>
								Bla<Link to={routes.AUTH_LOGIN.getPath()}>Blaa</Link>
							</Trans>
						</div>
					</div>
				</div>

				<div className="icon-grid">
					<div className="trust-icon">
						<SvgIcon iconName={SvgIconType.flagDe} />
						<span>{t('madeInGermany')}</span>
					</div>
					<div className="trust-icon">
						<SvgIcon iconName={SvgIconType.support} />
						<span>{t('realSupport')}</span>
					</div>
					<div className="trust-icon">
						<SvgIcon iconName={SvgIconType.smile} />
						<span>{t('fairPricing')}</span>
					</div>
				</div>
			</div>

			<div className="text-links">
				<a href={t('impressum.link')} target="_blank" rel="noopener noreferrer">
					{t('impressum')}
				</a>
				<a href={t('datenschutz.link')} target="_blank" rel="noopener noreferrer">
					{t('datenschutz')}
				</a>
				<a href={t('agb.link')} target="_blank" rel="noopener noreferrer">
					{t('agb')}
				</a>
			</div>
		</div>
	);
});
