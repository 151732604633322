import { useStore } from 'app/context';
import { formatDate } from 'app/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { PaddlePayment } from 'shared-puredio';
import { Button } from '../common/Button';
import Icon from '../common/Icon';
import Notice, { NoticeType } from '../common/Notice';
import { Table } from '../Table/table';
import { TableModel } from '../Table/table.model';
import t from './../../common/translate';

export const PaymentHistory = observer(() => {
	const { paddleStore } = useStore();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [hasPayments, setHasPayments] = useState<boolean>(false);

	useEffect(() => {
		paddleStore.loadPaymentHistory().then(() => {
			tm.setData(paddleStore.paddlePayments);
			setHasPayments(paddleStore.paddlePayments.length > 0);
			setLoaded(true);
		});
	});

	const openInvoice = (url: string) => {
		window.open(url, '_blank');
	};

	const tm = new TableModel<PaddlePayment, string>();
	const cols = [
		{
			label: t('general:settings.payment.invoiceDate'),
			path: 'payoutDate',
			render: (row: PaddlePayment) => {
				return <>{formatDate(row.payoutDate)}</>;
			},
		},
		{
			label: t('general:settings.payment.amount'),
			path: 'amount',
			render: (row: PaddlePayment) => {
				return (
					<>
						{row.amount} {row.currency}
					</>
				);
			},
		},
		{
			label: t('general:settings.payment.isPaid'),
			path: 'is_paid',
			render: (row: PaddlePayment) => {
				return (
					<>
						{row.is_paid > 0 && (
							<>
								<Icon iconName="check" />{' '}
							</>
						)}
					</>
				);
			},
		},
		{
			label: '',
			path: 'id',
			render: (row: PaddlePayment) => {
				return (
					<>
						{row.is_paid > 0 && (
							<>
								<Button className="button is-small" onClick={() => openInvoice(row.receipt_url)}>
									{t('general:settings.payment.downloadInvoice')}
								</Button>
							</>
						)}
					</>
				);
			},
		},
	];

	tm.setCols(cols);
	tm.sortBy = 'name';
	tm.idProperty = 'id';
	tm.idType = 'string';

	return (
		<>
			<div className="section">
				{loaded && hasPayments && <Table className="render-list" tm={tm}></Table>}
				{loaded && !hasPayments && (
					<Notice type={NoticeType.info}>
						<p>{t('general:settings.payment.noinvoices')}</p>
					</Notice>
				)}
			</div>
		</>
	);
});
