import { IVideoFormat, ILayerBase, VideoFormats, LayerType, ILayer, AnimatedPhrase, ImageModel } from '..';
import { action, computed, makeObservable, observable, ObservableMap, override, runInAction, toJS } from 'mobx';
import { SnapshotBase } from './snapshot.base';
import { FileModel } from './file.model';
import _ from 'lodash';
import { IPoint } from '../animations/utils/ControlGroup';

export class LayerProperties {
    constructor() {
        makeObservable(this);
    }
    @observable disabled: boolean = false;
    @observable content: string;
    @observable phrases: AnimatedPhrase[] = [];
    @observable x: IPoint;
    @observable y: IPoint;
    @observable width: IPoint;
    @observable height: IPoint;
    @observable rotation: number;
    @observable scale: number;
    @observable color: string;
    @observable amplitude: number;

    // googleFont
    @observable fontFamily: string;
    @observable fontVariant: string;
    @observable fontSize: number;
    @observable textAlign: string;
    @observable hasBackground: boolean;
    @observable backgroundColor: string;
    // phrases

    @observable isRound: boolean;
    @observable borderColor: string;
    @observable hasBorder: boolean;
    @observable borderWidth: number;
    // imageUrl: string;

    @observable background: string;

    @observable startColor: string;
    @observable endColor: string;
    @observable image: ImageModel = new ImageModel();

    serializePhrases() {
        const noPause = this.phrases.filter((x) => !x.isPause);
        return noPause.map((p) => p.serialize());
    }

    serialize() {
        let content: any = this.content;
        if (this.phrases && this.phrases.length > 0) {
            content = this.serializePhrases();
        }
        return {
            disabled: this.disabled,
            content: content,
            // phrases:this.phrases,
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
            rotation: this.rotation,
            scale: this.scale,
            color: this.color,
            amplitude: this.amplitude,

            fontFamily: this.fontFamily,
            fontVariant: this.fontVariant,
            fontSize: this.fontSize,
            textAlign: this.textAlign,
            hasBackground: this.hasBackground,
            backgroundColor: this.backgroundColor,

            isRound: this.isRound,
            borderColor: this.borderColor,
            hasBorder: this.hasBorder,
            borderWidth: this.borderWidth,

            background: this.background,

            startColor: this.startColor,
            endColor: this.endColor,
            image: this.image.serialize(),
        };
    }

    static fromObject(opts: any, layerType: LayerType) {
        const props = new LayerProperties();
        if (opts) {
            const keys = Object.keys(opts);
            keys.forEach((key) => {
                if (key === 'image') {
                    props.image = ImageModel.fromStringOrObject(opts[key]);
                } else {
                    props[key] = opts[key];
                }
            });
            if (layerType === LayerType.animatedText) {
                if (opts['content'] && typeof opts['content'] !== 'string') {
                    props.phrases = opts['content'].map((i) => new AnimatedPhrase(i));
                }
            }
        }
        return props;
    }
}

export class LayerModel extends SnapshotBase implements ILayerBase {
    constructor(layer: ILayer) {
        super();
        makeObservable(this);
        if (!layer) {
            debugger;
            return;
        }
        this.key = layer.key;
        this.snapshotKey = this.key;
        this.sort = layer.sort;
        this.fmtId = layer.fmtId;
        this.animClass = layer.animClass;

        if (layer.history) {
            this.history.replace(layer.history);
        }

        this.layerType = layer.layerType;
        if (this.animClass === '' || !this.animClass) {
            this.layerType = LayerType.audio;
        } else {
            if (layer.layerType) {
            } else {
                console.warn(`layerType deprecated for ${this.animClass}`);
                this.layerType = LayerType.deprecated;
                // throw new Error('layerType not defined');
            }
        }
        this.opts = LayerProperties.fromObject(layer.opts, this.layerType);
        if (layer.audioFile) {
            const start = layer.start ? layer.start : 0;
            const duration = layer.duration ? layer.duration : 15;
            this.audioFile = new FileModel();
            Object.assign(this.audioFile, layer.audioFile);
            this.setStartDuration(start, duration);
        }
    }

    @observable
    opts: LayerProperties;

    @observable
    key: string;

    @observable
    sort: number;

    @observable
    fmtId: string;

    @observable
    _splitSize?: number;

    @observable
    audioFile?: FileModel;

    @computed
    get isDisabled() {
        return this.opts.disabled;
    }

    @computed
    get audioFileMd5Hash() {
        if (this.audioFile) {
            return this.audioFile.md5Hash;
        }
        return undefined;
    }

    @observable layerType: LayerType;

    @computed
    get isAnimatedLayer() {
        return this.layerType === LayerType.animatedText;
    }

    @computed
    get data() {
        if (this.audioFile) {
            return this.audioFile.audioData;
        }
        console.warn('no audioFile!!');
        return undefined;
    }

    @computed
    get audioFileDuration() {
        if (this.audioFile && this.audioFile.audioData) {
            return this.audioFile.audioData.duration;
        }
        console.warn('no duration return 9.9');
        return 9.9;
    }

    @action
    setStartDuration(start: number, duration: number) {
        const audioData = this.data;
        let fileDuration = 10;
        if (audioData) {
            fileDuration = audioData.duration;
        }
        let end = start + duration;
        if (fileDuration && start > fileDuration) {
            return;
        }
        if (fileDuration && end > fileDuration) {
            return;
        }
        this.start = start;
        this.duration = duration;
    }

    @observable
    start: number;

    @observable
    duration: number;

    @computed
    get end(): number {
        const start = parseFloat(this.start.toString());
        const duration = parseFloat(this.duration.toString());
        const totalDuration = parseFloat(this.data.duration.toString());
        const end = start + duration;
        if (end > totalDuration) {
            return totalDuration;
        }
        return end;
    }

    @observable
    animClass: string = '';

    ////////////// NEW STUFFF

    @action
    setOpts(vals: any) {
        // console.log('set opts: layout', vals);
        const keys = Object.keys(vals);

        for (const key of keys) {
            runInAction(() => {
                this.opts[key] = vals[key];
                // console.log('setOpt', key, vals[key]);
            });
        }
    }

    ////////////// NEW STUFFF

    @action
    setAnimClass(animClass: string) {
        this.animClass = animClass;
    }

    @computed
    get fmt(): IVideoFormat {
        if (!this.fmtId) {
            debugger;
            throw 'no fmtId given';
        }
        return VideoFormats.findById(this.fmtId);
    }

    getSnapshotData(): any {
        return {
            sort: this.sort,
            key: this.key,
            layerType: this.layerType,
            animClass: this.animClass,
            start: this.start,
            duration: this.duration,
            // opts: mapToJs(this.opts),
            opts: this.opts.serialize(),
            audioFile: this.audioFile ? this.audioFile.serialize() : {},
        };
    }

    @observable
    appliedSnapShot?: Date;

    @override
    applySnapshot(s: any) {
        this.sort = s.sort;
        this.animClass = s.animClass;
        this.start = s.start;
        this.duration = s.duration;
        this.opts = LayerProperties.fromObject(s.opts, this.layerType);
        // if (s.opts) {
        //     this.opts.clear();
        //     Object.keys(s.opts).forEach((k) => {
        //         this.opts.set(k, s.opts[k]);
        //     });
        // }
        this.audioFile = new FileModel();
        Object.assign(this.audioFile, s.audioFile);
        this.appliedSnapShot = new Date();
    }

    serialize() {
        return {
            key: this.key,
            animClass: this.animClass,
            sort: this.sort,
            fmtId: this.fmtId,
            history: _.map(this.history, (h) => h),
            // opts: mapToJs(this.opts),
            opts: this.opts.serialize(),
            audioFile: this.audioFile ? this.audioFile.serialize() : {},
            start: this.start,
            duration: this.duration,
            layerType: this.layerType,
        };
    }
}
