import { Button } from 'app/components/common/Button';
import DetailPanel from 'app/components/common/DetailPanel';
import { Payment } from 'app/components/payment/Payment';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import ReactJson from 'react-json-view';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { AdminUserDetail } from './AdminUserDetail';
import { AdminUserDetailEditForm } from './AdminUserDetailEditForm';
import { AdminUserPaymentEditForm } from './AdminUserPaymentEditForm';

export const AdminUserDetailPanel = observer(() => {
	const { adminUserStore } = useStore();
	const history = useHistory();
	if (!adminUserStore.current) {
		return <></>;
	}
	const user = adminUserStore.current;

	const onClose = () => {
		history.push(routes.ADMINUSERS.getPath());
	};

	const editUser = () => {
		adminUserStore.setEditDetailsMode(true);
	};

	const editPayment = () => {
		adminUserStore.setEditPaymentMode(true);
	};

	const onEditUserDone = () => {
		adminUserStore.setEditDetailsMode(false);
	};

	const onEditPaymentDone = () => {
		adminUserStore.setEditPaymentMode(false);
	};

	const onUpdateUserSubscription = () => {
		adminUserStore.updateUserSubscription(user.user.id!);
	};

	return (
		<DetailPanel onClose={onClose} title={user.user.fullName + '  -  ' + user.user.email + ' - ' + user.user.fakeId} width={'70%'}>
			<div className="body ">
				<AdminUserDetail />

				<br />
				<br />
				<br />
				<Button onClick={editUser} className="button is-primary">
					Edit User Details
				</Button>

				{adminUserStore.editDetailsMode && <AdminUserDetailEditForm onDone={onEditUserDone} user={user.user} />}

				{!user.user.isTemplateManager && !user.user.isAdmin && (
					<Button onClick={editPayment} className="button is-primary">
						Edit Payment
					</Button>
				)}
				{adminUserStore.editPaymentMode && <AdminUserPaymentEditForm user={user.user} onDone={onEditPaymentDone} />}

				<Button onClick={onUpdateUserSubscription} className="button is-primary">
					Update/Sync Paddle Subscription
				</Button>

				<ReactJson src={user} collapsed={1} />

				<br />
				<br />
				<hr></hr>
				<strong>User Payment Preview</strong>
				<ul>
					<li>Usage is inkorrekt.</li>
					<li>DO NOT Click on Upgrade / Cancel Subscription etc.</li>
				</ul>
				<hr></hr>
				<br />

				<Payment user={user.user} />
			</div>
		</DetailPanel>
	);
});
