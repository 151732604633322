import React from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { reaction } from 'mobx';
import { IProps, withStore } from 'app/context';
import * as _ from 'lodash';
import { observer } from 'mobx-react';

interface IProjectEdit {
	project: ProjectVM;
}

@withStore
@observer
export class ProjectAutoSave extends React.Component<IProjectEdit & Partial<IProps>> {
	lastHistory?: any;
	disposer?: any;

	constructor(props: IProjectEdit & Partial<IProps>) {
		super(props);
		this.disposer = reaction(
			() => props.project.history,
			async () => {
				const history = JSON.parse(JSON.stringify(props.project.history));
				if (!this.lastHistory) {
					this.lastHistory = history;
					return await this.saveProject();
				}
				if (_.isEqual(history, this.lastHistory)) {
					console.log('skip save');
					return;
				}
				await this.saveProject();
				this.lastHistory = history;
			},
			{ delay: 1000 },
		);
	}
	componentWillUnmount() {
		if (this.disposer) {
			this.disposer();
		}
	}

	async saveProject() {
		const store = this.props.store!;
		const currentUser = store.authStore.current;
		const vm = this.props.project;
		if (vm.model.userId !== currentUser!.id) {
			console.log('not your project. not saving');
			return;
		}
		// console.log('saving project');
		await store.projectStore.save(this.props.project.model);
		console.log('saved project');
	}
	render() {
		return <></>;
	}
}
