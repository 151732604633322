import { Color, Rectangle, Shape, Group, Point } from 'paper';
import { BaseAnimation } from '../BaseAnimation';

export interface IPoints {
    [key: string]: IPoint;
}

export interface IPoint {
    v: number;
    d: 'w' | 'h';
}

export class ControlGroup {
    private controlGroup: paper.Group;
    private handles: paper.Group;
    private areaControl: paper.Shape;
    private parent: BaseAnimation;
    public isDragging: boolean = false;
    public isSelected: boolean = false;
    private rotationStart: number = 0;

    private scaleDist: number;
    private targetScale: number;

    public unbind() {
        this.controlGroup.removeChildren();
    }

    public toggleSelect(isSelected: boolean) {
        this.isSelected = isSelected;

        if (this.parent) {
            this.drawHandles(this.parent.group);
        }
    }

    public calcFromPixel(d: 'w' | 'h', pixel: number) {
        return pixel / this.parent.anim.fmt[d];
    }

    private calcAngle = (p1: paper.Point, p2: paper.Point) => {
        const x = p1.x - p2.x;
        const y = p1.y - p2.y;
        let angle = Math.atan2(-y, -x);
        angle *= 180 / Math.PI;

        return angle;
    };

    private initScale = (e: paper.MouseEvent, target: paper.Group) => {
        this.scaleDist = e.point.getDistance(target.bounds.center);
        this.targetScale = target.scaling.x;
    };

    private dragScale = (e: paper.MouseEvent, base: BaseAnimation, target: paper.Group) => {
        if (base.constrainProportions) {
            this.dragScaleConstrained(e, base, target);
        } else {
            this.dragScaleFree(e, base, target);
        }
    };

    private dragScaleConstrained = (e: paper.MouseEvent, base: BaseAnimation, target: paper.Group) => {
        target.applyMatrix = false;
        this.isDragging = true;

        const scaleRatio = this.targetScale * (e.point.getDistance(target.bounds.center) / this.scaleDist);
        target.scaling = new Point(scaleRatio, scaleRatio);
        this.areaControl.scaling = target.scaling;
        base.onScaleUpdate();
        this.drawHandles(target);
    };

    private dragScaleFree = (e: paper.MouseEvent, base: BaseAnimation, target: paper.Group) => {
        const anchorsMap = new Map([
            ['scaleTopLeft', 'bottomRight'],
            ['scaleTopRight', 'bottomLeft'],
            ['scaleBottomLeft', 'topRight'],
            ['scaleBottomRight', 'topLeft'],
        ]);

        this.isDragging = true;
        target.applyMatrix = true;

        const anchorPoint = target.bounds[anchorsMap.get(e.target.name)];
        const areaCalc = new Rectangle({
            from: e.point,
            to: anchorPoint,
        });

        target.bounds.width = areaCalc.width;
        target.bounds.height = areaCalc.height;
        target.bounds.left = areaCalc.left;
        target.bounds.top = areaCalc.top;

        base.onScaleUpdate();
        this.drawHandles(target);
    };

    private initRotation = (e: paper.MouseEvent, target: paper.Group) => {
        this.rotationStart = this.calcAngle(target.bounds.center, e.point) - target.rotation;
    };
    private dragRotate = (e: paper.MouseEvent, base: BaseAnimation, target: paper.Group) => {
        this.isDragging = true;
        target.applyMatrix = false;
        this.controlGroup.applyMatrix = false;
        const angle = this.calcAngle(target.bounds.center, e.point);
        target.rotation = angle - this.rotationStart;
        this.areaControl.rotation = target.rotation;
        base.onRotationUpdate();
        this.drawHandles(target);
    };

    private dragMove = (e: paper.MouseEvent, base: BaseAnimation, target: paper.Group) => {
        if (base.isLayerTransformable === false) return;

        this.isDragging = true;
        this.controlGroup.position.x += e.delta.x;
        this.controlGroup.position.y += e.delta.y;
        target.position.x += e.delta.x;
        target.position.y += e.delta.y;
        this.areaControl.position = target.position;
        this.setCursor('all-scroll');
        base.onPositionUpdate();
        this.drawHandles(target);
    };

    private updateBase = (e: paper.MouseEvent, base: BaseAnimation, target: paper.Group) => {
        e.preventDefault();
        e.stopPropagation();
        this.isDragging = false;

        base.optsUpdate({
            x: { v: this.calcFromPixel('w', target.position.x), d: 'w' },
            y: { v: this.calcFromPixel('h', target.position.y), d: 'h' },
            width: { v: this.calcFromPixel('w', target.internalBounds.width), d: 'w' },
            height: { v: this.calcFromPixel('h', target.internalBounds.height), d: 'h' },
            scale: target.scaling.x,
            rotation: target.rotation,
        });

        this.setCursor('pointer');
        this.bind(base);
    };

    private setCursor(cursor: string, rotation?: number) {
        if (!this.isDragging) {
            this.parent.setCursor(cursor, rotation);
        }
    }

    private drawHandles = (target: paper.Item) => {
        if (this.handles) {
            this.handles.remove();
        }

        if (!this.isSelected) return;

        const frame = new Shape.Rectangle({
            from: [target.internalBounds.topLeft.x, target.internalBounds.topLeft.y],
            to: [target.internalBounds.bottomRight.x, target.internalBounds.bottomRight.y],
            strokeColor: '#00A4F6',
            strokeWidth: 1,
            rotation: target.rotation,
            position: target.position,
            scaling: target.scaling,
            strokeScaling: false,
        });

        const corners = new Group();
        const p = frame.toPath();
        p.segments.forEach((segment) => {
            corners.addChild(
                new Shape.Rectangle({
                    from: [segment.point.x, segment.point.y],
                    size: [20, 20],
                    strokeColor: '#00A4F6',
                    strokeWidth: 1,
                    fillColor: '#FFFFFF',
                }),
            );
        });
        p.remove();
        this.handles = new Group([frame, corners]);
    };

    public bind(base: BaseAnimation) {
        const areaColor = new Color(255, 255, 0, 0.001);
        const rotateColor = new Color(255, 0, 255, 0.001);
        const shapeColor = new Color(0, 255, 255, 0.001);

        this.parent = base;
        const target = base.group;
        const handleSize = 30;

        target.applyMatrix = false;

        if (this.controlGroup) {
            this.controlGroup.remove();
        }
        if (!this.parent.isLayerSelectable) {
            return;
        }

        const rot = target.rotation;
        target.rotation = 0;

        const widthAdjust = target.bounds.width < 50 ? 50 : 0;
        const heightAdjust = target.bounds.height < 50 ? 50 : 0;

        // add click area
        const areaControl = new Shape.Rectangle({
            topLeft: [target.internalBounds.topLeft.x - widthAdjust, target.internalBounds.topLeft.y - widthAdjust],
            bottomRight: [target.internalBounds.bottomRight.x + widthAdjust, target.internalBounds.bottomRight.y + heightAdjust],
            fillColor: areaColor,
            scaling: target.scaling,
            position: target.position,

            onMouseDown: (e: paper.MouseEvent) => {
                base.onLayerSelect();
                e.stopPropagation();
            },
            onMouseDrag: (e: paper.MouseEvent) => {
                this.dragMove(e, base, target);
            },
            onMouseUp: (e: paper.MouseEvent) => {
                this.updateBase(e, base, target);
            },
            onMouseEnter: (e: paper.MouseEvent) => {
                this.setCursor('pointer');
            },
            onMouseLeave: (e: paper.MouseEvent) => {
                base.resetCursor();
            },
        });
        target.rotation = rot;
        // areaControl.selected = this.isSelected;
        areaControl.scaling = target.scaling;

        this.areaControl = areaControl;

        this.controlGroup = new Group([areaControl]);

        // ONLY ADD TRANSFORM BOXES IF REALLY ALLOWED
        if (base.isLayerTransformable !== false) {
            // add rotation boxes
            const rotateTopLeft = new Shape.Rectangle({
                topLeft: [areaControl.bounds.topLeft.x - handleSize, areaControl.bounds.topLeft.y - handleSize],
                size: [handleSize * 2, handleSize * 2],
                fillColor: rotateColor,

                onMouseDown: (e: paper.MouseEvent) => {
                    this.initRotation(e, target);
                    base.onLayerSelect();
                },
                onMouseDrag: (e: paper.MouseEvent) => {
                    this.dragRotate(e, base, target);
                },
                onMouseUp: (e: paper.MouseEvent) => {
                    this.updateBase(e, base, target);
                },
                onMouseEnter: (e: paper.MouseEvent) => {
                    this.setCursor('rotation', target.rotation);
                },
                onMouseLeave: (e: paper.MouseEvent) => {
                    base.resetCursor();
                },
            });
            const rotateTopRight = new Shape.Rectangle({
                topRight: [areaControl.bounds.topRight.x + handleSize, areaControl.bounds.topRight.y - handleSize],
                size: [handleSize * 2, handleSize * 2],
                fillColor: rotateColor,

                onMouseDown: (e: paper.MouseEvent) => {
                    this.initRotation(e, target);
                    base.onLayerSelect();
                },
                onMouseDrag: (e: paper.MouseEvent) => {
                    this.dragRotate(e, base, target);
                },
                onMouseUp: (e: paper.MouseEvent) => {
                    this.updateBase(e, base, target);
                },
                onMouseEnter: (e: paper.MouseEvent) => {
                    this.setCursor('rotation', target.rotation + 90);
                },
                onMouseLeave: (e: paper.MouseEvent) => {
                    base.resetCursor();
                },
            });
            const rotateBottomLeft = new Shape.Rectangle({
                bottomLeft: [areaControl.bounds.bottomLeft.x - handleSize, areaControl.bounds.bottomLeft.y + handleSize],
                size: [handleSize * 2, handleSize * 2],
                fillColor: rotateColor,

                onMouseDown: (e: paper.MouseEvent) => {
                    this.initRotation(e, target);
                    base.onLayerSelect();
                },
                onMouseDrag: (e: paper.MouseEvent) => {
                    this.dragRotate(e, base, target);
                },
                onMouseUp: (e: paper.MouseEvent) => {
                    this.updateBase(e, base, target);
                },
                onMouseEnter: (e: paper.MouseEvent) => {
                    this.setCursor('rotation', target.rotation - 90);
                },
                onMouseLeave: (e: paper.MouseEvent) => {
                    base.resetCursor();
                },
            });
            const rotateBottomRight = new Shape.Rectangle({
                bottomRight: [areaControl.bounds.bottomRight.x + handleSize, areaControl.bounds.bottomRight.y + handleSize],
                size: [handleSize * 2, handleSize * 2],
                fillColor: rotateColor,

                onMouseDown: (e: paper.MouseEvent) => {
                    this.initRotation(e, target);
                    base.onLayerSelect();
                },
                onMouseDrag: (e: paper.MouseEvent) => {
                    this.dragRotate(e, base, target);
                },
                onMouseUp: (e: paper.MouseEvent) => {
                    this.updateBase(e, base, target);
                },
                onMouseEnter: (e: paper.MouseEvent) => {
                    this.setCursor('rotation', target.rotation + 180);
                },
                onMouseLeave: (e: paper.MouseEvent) => {
                    base.resetCursor();
                },
            });

            // add scale boxes
            const scaleTopLeft = new Shape.Rectangle({
                topLeft: [areaControl.bounds.topLeft.x, areaControl.bounds.topLeft.y],
                size: [handleSize * 2, handleSize * 2],
                fillColor: shapeColor,
                name: 'scaleTopLeft',

                onMouseDown: (e: paper.MouseEvent) => {
                    this.initScale(e, target);
                    base.onLayerSelect();
                },
                onMouseDrag: (e: paper.MouseEvent) => {
                    this.dragScale(e, base, target);
                },
                onMouseUp: (e: paper.MouseEvent) => {
                    this.updateBase(e, base, target);
                },
                onMouseEnter: (e: paper.MouseEvent) => {
                    this.setCursor('scale', target.rotation - 45);
                },
                onMouseLeave: (e: paper.MouseEvent) => {
                    base.resetCursor();
                },
            });
            const scaleTopRight = new Shape.Rectangle({
                topRight: [areaControl.bounds.topRight.x, areaControl.bounds.topRight.y],
                size: [handleSize * 2, handleSize * 2],
                fillColor: shapeColor,
                name: 'scaleTopRight',

                onMouseDown: (e: paper.MouseEvent) => {
                    this.initScale(e, target);
                    base.onLayerSelect();
                },
                onMouseDrag: (e: paper.MouseEvent) => {
                    this.dragScale(e, base, target);
                },
                onMouseUp: (e: paper.MouseEvent) => {
                    this.updateBase(e, base, target);
                },
                onMouseEnter: (e: paper.MouseEvent) => {
                    this.setCursor('scale', target.rotation + 45);
                },
                onMouseLeave: (e: paper.MouseEvent) => {
                    base.resetCursor();
                },
            });
            const scaleBottomLeft = new Shape.Rectangle({
                bottomLeft: [areaControl.bounds.bottomLeft.x, areaControl.bounds.bottomLeft.y],
                size: [handleSize * 2, handleSize * 2],
                fillColor: shapeColor,
                name: 'scaleBottomLeft',

                onMouseDown: (e: paper.MouseEvent) => {
                    this.initScale(e, target);
                    base.onLayerSelect();
                },
                onMouseDrag: (e: paper.MouseEvent) => {
                    this.dragScale(e, base, target);
                },
                onMouseUp: (e: paper.MouseEvent) => {
                    this.updateBase(e, base, target);
                },
                onMouseEnter: (e: paper.MouseEvent) => {
                    this.setCursor('scale', target.rotation + 45);
                },
                onMouseLeave: (e: paper.MouseEvent) => {
                    base.resetCursor();
                },
            });
            const scaleBottomRight = new Shape.Rectangle({
                bottomRight: [areaControl.bounds.bottomRight.x, areaControl.bounds.bottomRight.y],
                size: [handleSize * 2, handleSize * 2],
                fillColor: shapeColor,
                name: 'scaleBottomRight',

                onMouseDown: (e: paper.MouseEvent) => {
                    this.initScale(e, target);
                    base.onLayerSelect();
                },
                onMouseDrag: (e: paper.MouseEvent) => {
                    this.dragScale(e, base, target);
                },
                onMouseUp: (e: paper.MouseEvent) => {
                    this.updateBase(e, base, target);
                },
                onMouseEnter: (e: paper.MouseEvent) => {
                    this.setCursor('scale', target.rotation - 45);
                },
                onMouseLeave: (e: paper.MouseEvent) => {
                    base.resetCursor();
                },
            });
            this.controlGroup.addChildren([rotateTopLeft, rotateTopRight, rotateBottomLeft, rotateBottomRight, scaleTopLeft, scaleTopRight, scaleBottomLeft, scaleBottomRight]);
        }
        // END IF

        this.controlGroup.insertAbove(target);
        this.controlGroup.rotation = target.rotation;
        this.drawHandles(areaControl);
    }
}
