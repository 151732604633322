import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import { observer } from 'mobx-react';
import BaseContainer from '../containers/BaseContainer';
import SideMenu from '../components/SideMenu';
import { RenderList } from 'app/components/Renders/RenderList';
import { useParams } from 'react-router';
import { RenderDetail } from 'app/components/Renders/RenderDetail';
import t from 'app/common/translate';
import Breadcrumb, { IBreadcrumbItem } from 'app/components/Breadcrumb';

interface IRendersParms {
	id?: string;
}

const RendersLoader = () => {
	const parms = useParams<IRendersParms>();
	const { rendersStore } = useStore();

	useEffect(() => {
		rendersStore.setSelectedById(parms.id);
	});

	return <></>;
};

const crumbs: IBreadcrumbItem[] = [
	{
		title: t('nav:renders'),
	},
];

const RendersNavbar = () => {
	return (
		<div className="app-navbar">
			<Breadcrumb crumbs={crumbs} />
		</div>
	);
};

export const RendersContainer: React.FC<IProps> = observer((props: IProps) => {
	return (
		<BaseContainer menu={<SideMenu />} navbar={<RendersNavbar />}>
			<RendersLoader />
			<RenderList />
			<RenderDetail />
		</BaseContainer>
	);
});
