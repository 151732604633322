import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { ExportViewer } from './Edit/ExportViewer';
import ProjectEditNavbar from '../Project/Edit/ProjectEditNavbar';
import PreviewControls from './Edit/PreviewControls';
import { ProjectAutoSave } from './ProjectAutoSave';
import { ProjectSidePanel } from './Edit/ProjectSidePanel';
import { Brand } from 'app/components/Brand';
import { ProjectAutoSaveImageOnExit } from './ProjectAutoSaveImageOnExit';

interface IProjectEdit {
	project: ProjectVM;
}

const ProjectEdit: React.FC<IProjectEdit> = observer((props: IProjectEdit) => {
	const project = props.project;
	return (
		<>
			<Brand />
			<ProjectAutoSaveImageOnExit project={project} />
			<ProjectAutoSave project={project} />
			<ProjectEditNavbar project={project} />
			<ExportViewer projectVM={project} />
			<ProjectSidePanel {...props} />
			<PreviewControls projectVM={project} />

			{/*
			<div className="debug">
				{exp && <ExportDebug export={exp} projectVM={project} />}
				<ProjectDebug project={project} />
			</div> */}
		</>
	);
});

export default ProjectEdit;
