export enum VideoOrientation {
    landscape = 'landscape',
    portrait = 'portrait',
    square = 'square',
}

export interface IVideoFormat {
    id: string;
    orientation: VideoOrientation;
    w: number;
    h: number;
    ratio: string;
    maxLength: number;
    fps: number;
}

const landscape: IVideoFormat = {
    id: 'landscape',
    orientation: VideoOrientation.landscape,
    w: 1920,
    h: 1080,
    ratio: '16:9',
    fps: 30,
    maxLength: 30,
};

const square: IVideoFormat = {
    id: 'square',
    orientation: VideoOrientation.square,
    w: 1080,
    h: 1080,
    ratio: '1:1',
    fps: 30,
    maxLength: 30,
};

const portrait: IVideoFormat = {
    id: 'portrait',
    orientation: VideoOrientation.portrait,
    w: 1080,
    h: 1920,
    ratio: '4:5',
    fps: 30,
    maxLength: 30,
};

class _VideoFormats {
    square: IVideoFormat = square;
    landscape: IVideoFormat = landscape;
    portrait: IVideoFormat = portrait;
    findById(id: string): IVideoFormat {
        const keys = Object.keys(this);
        const fmtKey = keys.find((k) => this[k].id === id);
        if (fmtKey) {
            return this[fmtKey];
        }
        throw new Error('VideoFormat with id ' + id + ' not found');
    }

    asArray(): IVideoFormat[] {
        const keys = Object.keys(this);
        return keys.map((key) => this[key]);
    }
}

export const VideoFormats = new _VideoFormats();
