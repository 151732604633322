import { observer } from 'mobx-react';
import React, { useState } from 'react';

interface IInputControl {
	value: any;
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
	type?: string;
}

const CheckboxControl = observer((props: IInputControl) => {
	const { id, name, label, onChange } = props;
	const [value, setValue] = useState(props.value);
	const htmlId = id ? id : 'checkbox' + name;
	const handleChange = (event: any) => {
		setValue(event.target.checked);
		onChange(event.target.name, event.target.checked);
	};
	return (
		<div className="form-control is-inline is-checkbox">
			<input id={htmlId} name={name} onChange={handleChange} type={'checkbox'} checked={value} />
			{label && <label htmlFor={htmlId}>{label}</label>}
		</div>
	);
});

export default CheckboxControl;
