import { Button } from 'app/components/common/Button';
import DetailPanel from 'app/components/common/DetailPanel';
import { ProjectPreviewImage } from 'app/components/Project/ProjectPreviewImage';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminProjectDetailPanel = observer(() => {
	const { adminProjectStore } = useStore();
	const history = useHistory();
	if (!adminProjectStore.current) {
		return <></>;
	}
	const vm = adminProjectStore.current;
	const p = vm.project;

	const onClose = () => {
		history.push(routes.ADMINPROJECTS.getPath());
	};

	const openEpisode = () => {
		const url = '/#' + routes.EDITPROJECT.getPath(p.id);
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<DetailPanel onClose={onClose} title={vm.project.title} width={'70%'}>
			<div className="body ">
				<Button onClick={openEpisode} className="button is-primary">
					Episode in neuem Fenster öffnen
				</Button>
				<ProjectPreviewImage project={p} />
			</div>
		</DetailPanel>
	);
});
