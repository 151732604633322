import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';

interface IRangeControl {
	value: any;
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
	type?: string;
	numbersOnly?: boolean;
	debounceTime?: number;
	min: number;
	max: number;
	step: number;
}

const RangeControl: React.FC<IRangeControl> = observer((props: IRangeControl) => {
	const type = 'range';
	const id = props.id ? props.id : type + '' + props.name;
	// const numbersOnly = props.numbersOnly || false;
	const debounceTime = props.debounceTime || 0;
	const [value, setValue] = useState<number>(props.value);

	const min = props.min || 0;
	const max = props.max || 100;
	const step = props.step || 5;

	const debounceValueChange = (name: string, value: number) => {
		// if (numbersOnly) {
		// 	value = value.replace(/\D/, '');
		// }
		props.onChange(name, value);
	};
	const handler = useCallback(debounce(debounceValueChange, debounceTime), []);
	const onChange = (event: any) => {
		setValue(event.target.value);
		handler(event.target.name, event.target.value);
	};

	return (
		<div className="form-control">
			{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
			<input className="input range" id={id} name={props.name} onChange={onChange} type={type} value={value} min={min} max={max} step={step} />
		</div>
	);
});

export default RangeControl;
