import { ProjectModel } from 'shared-puredio';
import React from 'react';
import { FieldConfig, useField, useFormikContext } from 'formik';
import { TemplateList } from '../Template/TemplateList';

interface IOnChange {
	onChange: any;
	fmtId: string;
}
export function TemplateSelectField(props: FieldConfig & IOnChange) {
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	const onSelect = (t: ProjectModel) => {
		setFieldValue(field.name, t.id);
		props.onChange();
	};

	return <TemplateList {...field} {...props} currentTemplateId={props.value} onSelect={onSelect} fmtId={props.fmtId} />;
}
