import { useStore } from 'app/context';
import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import t from 'app/common/translate';
import { observer } from 'mobx-react';
import FormField from '../Form/FormField';
import { Button } from '../common/Button';
import CrudEntityErrors from '../Form/CrudEntityErrors';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { Trans } from 'react-i18next';
import Icon from '../common/Icon';
import { notify } from 'app/common/notify';

const forgotPasswordSchema = Yup.object()
	.shape({
		email: Yup.string().email(t('validation:invalid.email')).required(t('validation:required.field')).default(''),
	})
	.required();
const block = 'register2';

type ForgotPassword = Yup.InferType<typeof forgotPasswordSchema>;

export const ForgotPasswordForm = observer(() => {
	const { authStore } = useStore();

	const handleSubmit = async (values: ForgotPassword) => {
		await authStore.sendPasswordResetEmail(values.email, 'de');
		notify(t('auth:forgotpassword.request.sent'), 'success');
		notify(t('auth:forgotpassword.request.sent.note'), 'warning');
	};

	return (
		<div className="auth-form is-forgot-password">
			<div className="form-container">
				<div className="form-container-bg">
					<div className="auth-form-header">
						<img src="/images/brand-with-signet.svg" alt="Puredio"></img>
						<h1 className="title is-2">{t('auth:requestNewPassword')}</h1>
						<p>{t('auth:forgotpassword.instructions')}</p>
						<p>&nbsp;</p>
					</div>

					<Formik validationSchema={forgotPasswordSchema} initialValues={{ email: '' }} onSubmit={handleSubmit}>
						{({ errors, touched, isValidating }) => (
							<Form className={block}>
								<FormField label={t('auth:forgotpassword.email')} error={errors.email} touched={touched.email}>
									<Field type="text" name="email" autoComplete="username" />
								</FormField>
								<div className="form-control">
									<Button type="submit" className="button is-primary is-block" disabled={authStore.isEntityLoading}>
										<span>{t('auth:requestNewPassword')}</span>
										<Icon iconName="arrow-right" faModifier="far" />
									</Button>
								</div>
							</Form>
						)}
					</Formik>
					<CrudEntityErrors store={authStore} />
					<div className="auth-links">
						<div className="auth-link">
							<Trans i18nKey={'auth:allreadyHaveAnAccount.linked'}>
								Bla<Link to={routes.AUTH_LOGIN.getPath()}>Blaa</Link>
							</Trans>
							<br />
							<br />
							<Trans i18nKey={'auth:needhelp.linked'}>
								Bla<Link to={routes.CONTACT.getPath()}>Blaa</Link>
							</Trans>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
