import React from 'react';
import t from 'app/common/translate';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import { GroupNewForm } from './GroupNewForm';
import { GroupModel } from 'shared-puredio';

interface IGroupNewModalProps {
	modalId: string;
	onDone: (g: GroupModel) => void;
}

export const GroupNewModal: React.FC<IGroupNewModalProps> = observer((props: IGroupNewModalProps) => {
	return (
		<Modal title={t('general:group.new')} size="large" modalId={props.modalId}>
			<GroupNewForm onDone={props.onDone} />
		</Modal>
	);
});
