import DetailPanel from 'app/components/common/DetailPanel';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminFileDetailPanel = observer(() => {
	const { adminFilesStore } = useStore();
	const history = useHistory();
	if (!adminFilesStore.current) {
		return <></>;
	}
	const file = adminFilesStore.current;

	const onClose = () => {
		history.push(routes.ADMINFILES.getPath());
	};

	// const editUser = () => {
	// 	adminUserStore.setEditDetailsMode(true);
	// };

	// const onEditUserDone = () => {
	// 	adminUserStore.setEditDetailsMode(false);
	// };

	return (
		<DetailPanel onClose={onClose} title={file.file.originalFileName} width={'70%'}>
			<div className="body ">
				md5: {file.file.md5Hash}
				{/* <AdminTaskDetail /> */}
				{/* <Button onClick={editUser} className="button is-primary">
					Details Bearbeiten
				</Button>
				{adminUserStore.editDetailsMode && (
					<>
						<AdminUserDetailEditForm onDone={onEditUserDone} user={user} />
					</>
				)} */}
			</div>
		</DetailPanel>
	);
});
