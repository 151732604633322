import React, { useEffect, useState } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { FileModel } from 'shared-puredio';

interface IAudioFileProgress {
	file: FileModel;
}

export const AudioFileWaveDataInfo = observer((props: IAudioFileProgress) => {
	const { animatorStore, bucketStore } = useStore();

	const [waveDataUri, setWaveDataUri] = useState<string>('');
	const [data, setData] = useState<any>();

	// const waveDataUri = await bucketStore.getDownloadUrl(props.file!.wavedataKey);

	useEffect(() => {
		bucketStore.getDownloadUrl(props.file.wavedataKey).then((uri) => {
			setWaveDataUri(uri);
			animatorStore.getWaveData(uri).then((res) => {
				setData(res.data);
			});
		});
		// setInUse(projectStore.isFileInUse(props.file));
	}, [props.file, animatorStore, bucketStore]);

	return (
		<p>
			{waveDataUri && (
				<>
					waveDataUri {waveDataUri}
					<br />
					{data && <>Has Data</>}
					{!data && <>NO Data</>}
					<br />
					<br />
				</>
			)}
		</p>
	);
});
