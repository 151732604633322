import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { BillingInterval, IPaddleSubscription, PaddleAmount, PaddleSubscription, Perk } from 'shared-puredio';

export interface IChangeSubscriptionRequest {
    newPlanId: number;
    isDowngrade: boolean;
}
export interface IChangeSubscriptionRes {
    error?: string;
}

interface IPerk {
    id: Perk;
    text: string;
}
const perks: IPerk[] = [
    {
        id: Perk.level1,
        text: 'perk.level1',
    },
    {
        id: Perk.level15,
        text: 'perk.level15',
    },
    {
        id: Perk.level50,
        text: 'perk.level50',
    },
    {
        id: Perk.level999,
        text: 'perk.level999',
    },
    {
        id: Perk.nowatermark,
        text: 'perk.nowatermark',
    },
    {
        id: Perk.watermark,
        text: 'perk.watermark',
    },
    {
        id: Perk.template25,
        text: 'perk.template25',
    },
    {
        id: Perk.saveInfinite,
        text: 'perk.saveInfinite',
    },
    {
        id: Perk.hd,
        text: 'perk.hd',
    },
    {
        id: Perk.customFonts,
        text: 'perk.customFonts',
    },
    {
        id: Perk.terminateMonthly,
        text: 'perk.terminateMonthly',
    },
];

interface IPlanPerk {
    perk: Perk;
    included: boolean;
}

export class PlanPerk {
    constructor(opts: IPlanPerk) {
        this.perk = opts.perk;
        this.included = opts.included;
        const p = perks.find((p) => p.id === opts.perk);
        if (p) {
            this.title = p.text;
        } else {
            this.title = 'undefined';
            console.log('could not find perk with id  ' + opts.perk);
        }
    }

    perk: Perk;
    title: string;
    included: boolean;
}

export interface IPaymentPlan {
    // productId: number;
    planType: PlanType;
    title: string;
    message: string;
    price: number;
    //interval: BillingInterval;
    includesPerks: Perk[];
    excludesPerks: Perk[];
    rank: number;
}

export class PaymentPlan {
    constructor(opts: IPaymentPlan) {
        makeObservable(this);
        this.planType = opts.planType;
        this.title = opts.title;
        this.message = opts.message;
        this.price = opts.price;
        this.includesPerks = opts.includesPerks;
        this.excludesPerks = opts.excludesPerks;
    }

    @computed
    get rank() {
        switch (this.planType) {
            case PlanType.starter:
                return 0;
            case PlanType.pocaster:
                return 1;
            case PlanType.studio:
                return 2;
            case PlanType.custom:
                return 10;
        }
    }

    @observable
    monthlyPlanId: number;

    @action
    setMonthlyPlanId(id: number) {
        this.monthlyPlanId = id;
    }

    @observable
    yearlyPlanId: number;

    @action
    setYearlyPlanId(id: number) {
        this.yearlyPlanId = id;
    }

    planType: PlanType;
    title: string;
    message: string;
    price: number;
    // interval: BillingInterval;
    includesPerks: Perk[];
    excludesPerks: Perk[];

    @computed
    get perks() {
        const perks: PlanPerk[] = [];

        this.includesPerks.forEach((perk) => {
            perks.push(new PlanPerk({ perk, included: true }));
        });

        this.excludesPerks.forEach((perk) => {
            perks.push(new PlanPerk({ perk, included: false }));
        });

        return perks;
    }
}

export enum PaymentType {
    paddle = 'paddle',
    invoice = 'invoice',
    free = 'free',
}

interface IPlanDef {
    PADDLE_PLAN_PODCASTER_MONTHLY_ID: number;
    PADDLE_PLAN_STUDIO_MONTHLY_ID: number;
    PADDLE_PLAN_PODCASTER_YEARLY_ID: number;
    PADDLE_PLAN_STUDIO_YEARLY_ID: number;
}

export enum PlanType {
    starter = 'starter',
    pocaster = 'podcaster',
    studio = 'studio',
    custom = 'custom',
}

export interface IPaddlePlan {
    id: number;
    name: string;
    billing_type: string;
    billing_period: number;
    initial_price: any;
    recurring_price: any;
    trial_days: number;
}

export class PaddlePlan {
    constructor(opts: IPaddlePlan) {
        this.id = opts.id;
        this.name = opts.name;
        this.billing_type = opts.billing_type;
        this.billing_period = opts.billing_period;
        this.initial_price = opts.initial_price;
        this.recurring_price = opts.recurring_price;
        this.trial_days = opts.trial_days;
    }

    id: number;
    name: string;
    billing_type: string;
    billing_period: number;

    @computed
    get interval() {
        if (this.billing_type === 'month') {
            return BillingInterval.monthly;
        }
        return BillingInterval.yearly;
    }

    initial_price: any;
    recurring_price: any;
    trial_days: number;

    @computed
    get recuringPrice() {
        return new PaddleAmount(this.recurring_price);
    }

    static getPlantTypeFromName(name: string) {
        const n = name.toLowerCase();
        if (n.indexOf(PlanType.pocaster) >= 0) {
            return PlanType.pocaster;
        }
        if (n.indexOf(PlanType.studio) >= 0 || n.indexOf('agency') >= 0) {
            return PlanType.studio;
        }
        if (n.indexOf(PlanType.custom) >= 0) {
            return PlanType.custom;
        }
        if (n.indexOf('trial') >= 0 || n.indexOf('starter') >= 0) {
            return PlanType.starter;
        }
        console.log('unkonwn plantype', n);
        return PlanType.starter;
    }

    @computed
    get planType() {
        return PaddlePlan.getPlantTypeFromName(this.name);
    }

    static getVideoMinuteQuota(type: PlanType) {
        switch (type) {
            case PlanType.starter:
                return 2;
            case PlanType.pocaster:
                return 150;
            case PlanType.studio:
                return 500;
            case PlanType.custom:
                return 10;
        }
    }

    @computed
    get videoSecondsQuota() {
        return PaddlePlan.getVideoMinuteQuota(this.planType) * 60;
    }

    @computed
    get planRank() {
        switch (this.planType) {
            case PlanType.starter:
                return 0;
            case PlanType.pocaster:
                return 1;
            case PlanType.studio:
                return 2;
            case PlanType.custom:
                return 10;
        }
    }

    serialize() {
        return {
            id: this.id,
            name: this.name,
            billing_type: this.billing_type,
            planType: this.planType,
            billing_period: this.billing_period,
            initial_price: this.initial_price,
            recurring_price: this.recurring_price,
            trial_days: this.trial_days,
        };
    }
}

export const getFreePlan = (plans: PaddlePlan[]) => {
    return plans.find((p) => p.planType === PlanType.starter)!;
};
export const getHighesRankingSubscription = (plans: PaddlePlan[], subs: PaddleSubscription[]) => {
    subs = _.sortBy(subs, 'plan.planRank');
    return subs[subs.length - 1];
};

const plans = [];
plans.push(
    new PaymentPlan({
        rank: 1,
        planType: PlanType.pocaster,
        title: 'plans:podcaster.title',
        message: 'plans:podcaster.message',
        price: 8.99,
        // interval: BillingInterval.monthly,
        includesPerks: [Perk.level15, Perk.nowatermark, Perk.template25, Perk.hd, Perk.saveInfinite, Perk.terminateMonthly],
        excludesPerks: [],
    }),
);

plans.push(
    new PaymentPlan({
        rank: 2,
        planType: PlanType.studio,
        title: 'plans:studio.title',
        message: 'plans:studio.message',
        price: 24.99,
        // interval: BillingInterval.monthly,
        includesPerks: [Perk.level50, Perk.nowatermark, Perk.template25, Perk.hd, Perk.saveInfinite, Perk.terminateMonthly],
        excludesPerks: [],
    }),
);

plans.push(
    new PaymentPlan({
        rank: 0,
        planType: PlanType.starter,
        title: 'plans:starter.title',
        message: 'plans:starter.message',
        price: 0.0,
        // interval: BillingInterval.monthly,
        includesPerks: [Perk.level1, Perk.watermark, Perk.hd],
        excludesPerks: [Perk.template25, Perk.saveInfinite],
    }),
);

plans.push(
    new PaymentPlan({
        rank: 10,
        planType: PlanType.custom,
        title: 'plans:enterprise.title',
        message: 'plans:enterprise.message',
        price: 0.0,
        // interval: BillingInterval.monthly,
        includesPerks: [Perk.level999, Perk.nowatermark, Perk.template25, Perk.hd, Perk.saveInfinite, Perk.terminateMonthly],
        excludesPerks: [],
    }),
);

export const PlanDefinitions = plans;
