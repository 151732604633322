import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { SideMenuAdmin } from 'app/components/SideMenuAdmin';
import { AdminUsersList } from 'app/components/admin/users/AdminUsersList';
import { NotFound } from 'App';
import { AdminUserDetailPanel } from 'app/components/admin/users/AdminUserDetailPanel';

export interface AdminUsersParamTypes {
	id?: string;
}

const AdminUserContainer: React.FC<IProps> = observer(() => {
	const { adminUserStore } = useStore();

	const { id } = useParams<AdminUsersParamTypes>();

	useEffect(() => {
		adminUserStore.load().then(() => {
			if (id) {
				adminUserStore.loadById(id);
			} else {
				adminUserStore.setCurrent();
			}
		});
	}, [id, adminUserStore]);

	return (
		<>
			{adminUserStore.isAdmin && (
				<BaseContainer menu={<SideMenuAdmin />}>
					<AdminUsersList />
					{adminUserStore.current && <AdminUserDetailPanel />}
				</BaseContainer>
			)}
			{!adminUserStore.isAdmin && <NotFound />}
		</>
	);
});

export default AdminUserContainer;
