import { IVideoFormat } from './../models/video-formats';
import { LayerModel } from '..';
import { computed, makeObservable, observable } from 'mobx';

export interface IBaseAnimationOptions {
    userId: string;
    key: string;
    totalDuration: number;
    startAt: number;
    endAt: number;
    fmt: IVideoFormat;
    audioLayer: LayerModel;
    animationLayer: LayerModel;
}

export class BaseAnimationOptions {
    userId: string;
    key: string;
    @observable
    totalDuration: number;
    @observable
    startAt: number;
    @observable
    endAt: number;
    fmt: IVideoFormat;
    audioLayer: LayerModel;

    @observable
    animationLayer: LayerModel;

    @computed
    get startAtFrame() {
        return this.startAt * this.fmt.fps;
    }
    @computed
    get endAtFrame() {
        return this.endAt * this.fmt.fps;
    }

    constructor(parms: IBaseAnimationOptions) {
        makeObservable(this);
        this.userId = parms.userId;
        this.totalDuration = parms.totalDuration;
        this.startAt = parms.startAt;
        this.endAt = parms.endAt;
        this.fmt = parms.fmt;
        this.audioLayer = parms.audioLayer;
        this.animationLayer = parms.animationLayer;
        this.key = parms.key;
    }
}
