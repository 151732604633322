export const FEEDBACK_FORM_LINK = 'https://docs.google.com/forms/d/1qtujJuwZf87h6LNZyTpxVb9eFkNh8bqEulaMFaDXXy0';

var firebaseDevelopmentConfig = {
	apiKey: 'AIzaSyBeN3qZ0UE0zTBOBkOOQt0aZVjUMXffOFI',
	authDomain: 'puredio-development.firebaseapp.com',
	projectId: 'puredio-development',
	storageBucket: 'puredio-development.appspot.com',
	messagingSenderId: '94209808590',
	appId: '1:94209808590:web:c9d0b2ae98b4ee7396d89f',
	measurementId: 'not used',
};

var firebaseProductionConfig = {
	apiKey: 'AIzaSyCNW4_FOM5l_u_jmVI2iJ-4f9DEpgOM-B0',
	authDomain: 'puredio-production.firebaseapp.com',
	projectId: 'puredio-production',
	storageBucket: 'puredio-production.appspot.com',
	messagingSenderId: '259551167259',
	appId: '1:259551167259:web:266b9ca17b69f3db27c9be',
	measurementId: 'G-TW5H9MTBT5',
};

const Config = {
	FB_CONFIG: firebaseDevelopmentConfig,
	FEEDBACK_FORM_LINK: 'https://docs.google.com/forms/d/1qtujJuwZf87h6LNZyTpxVb9eFkNh8bqEulaMFaDXXy0',
	ENV: 'dev',
	API_BASE_URL: 'https://dev-api.puredio.com/',
	GTM: '',
	PADDLE_VENDOR_ID: 3472,
	FEATURE_PAYMENT: true,
	FEATURE_WATERMARK: true,
};

if (process.env.REACT_APP_USE_EMU === '1') {
	Config.API_BASE_URL = 'http://localhost:3003/';
	Config.FEATURE_PAYMENT = true;
}

if (process.env.REACT_APP_USE_DEV === '1') {
	Config.API_BASE_URL = 'http://localhost:3003/';
	Config.FEATURE_PAYMENT = true;
}

console.log('ENV:', process.env.REACT_APP_FB_PROJECT);
switch (process.env.REACT_APP_FB_PROJECT) {
	case 'puredio-development':
		Config.FB_CONFIG = firebaseDevelopmentConfig;

		break;
	case 'puredio-production':
		Config.FB_CONFIG = firebaseProductionConfig;
		Config.API_BASE_URL = 'https://api.puredio.com/';
		Config.ENV = 'prod';
		Config.GTM = 'GTM-TS4VR7M';
		Config.PADDLE_VENDOR_ID = 136140;
		break;
}

export default Config;
