import { makeObservable, observable } from 'mobx';
import { TaskStatus } from '..';
import { BaseModel } from './base.model';

interface IExportUsage {
    id: string;
    userId: string;
    year: number;
    month: number;
    day: number;
    created: Date;
    modified: Date;
    taskGroupId: string;
    projectId: string;
    groupId: string;
    duration: number;
    status: TaskStatus;
    success: boolean;
    outputKey: string;
}

export class ExportUsageModel extends BaseModel {
    constructor(opts: IExportUsage) {
        super();
        makeObservable(this);
        this.id = opts.id;
        this.userId = opts.userId;
        this.created = opts.created;
        this.modified = opts.modified;
        this.taskGroupId = opts.taskGroupId;
        this.projectId = opts.projectId;
        this.groupId = opts.groupId;
        this.duration = opts.duration;
        this.status = opts.status;
        this.success = opts.success;
        this.outputKey = opts.outputKey;
        this.fixDate('modified');
        this.fixDate('created');
    }
    @observable id: string;
    @observable userId: string;
    @observable year: number;
    @observable month: number;
    @observable day: number;
    @observable created: Date;
    @observable modified: Date;
    @observable taskGroupId: string;
    @observable projectId: string;
    @observable groupId: string;
    @observable duration: number;
    @observable status: TaskStatus;
    @observable success: boolean;
    @observable outputKey: string;

    serialize(): any {
        return {
            id: this.id,
            userId: this.userId,
            year: this.year,
            month: this.month,
            day: this.day,
            taskGroupId: this.taskGroupId,
            projectId: this.projectId,
            groupId: this.groupId,
            duration: this.duration,
            status: this.status,
            created: this.created,
            success: this.success,
            outputKey: this.outputKey,
        };
    }
}
