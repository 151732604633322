import { observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/context';
import { autorun, runInAction } from 'mobx';
import Icon from './Icon';
import { Button } from './Button';
// These two containers are siblings in the DOM
const appRoot = document.body;
const escIgnore = ['input', 'select', 'textarea'];

export interface IModalButton {
	title: string;
	className: string;
	onClick?: any;
	type?: 'button' | 'submit';
	formName?: string;
}

export interface IModalProps {
	onClose?: (event?: React.SyntheticEvent) => void;
	title: string;
	size?: 'small' | 'medium' | 'large' | 'fullscreen';
	buttons?: IModalButton[];
	modalId?: string;
	children: any;
	modifier?: string;
}

export const Modal = observer((props: IModalProps) => {
	const { uiStore } = useStore();

	const [isVisible, setIsVisible] = useState<boolean>(false);
	useEffect(() => {
		return autorun(() => {
			if (props.modalId) {
				setIsVisible(uiStore.visibleModals.includes(props.modalId));
			}
		});
	});

	const onClose = () => {
		runInAction(() => {
			if (props.modalId) {
				uiStore.hideModal(props.modalId);
				if (props.onClose) {
					props.onClose();
				}
			}
		});
	};

	if (isVisible === false) {
		return <> </>;
	}
	return <P_MODAL {...props} onClose={onClose} />;
});

const P_MODAL = observer((props: IModalProps) => {
	const { uiStore } = useStore();
	const el = document.createElement('div');
	el.className = 'modal-container';
	const modalId = props.modalId;

	const removeModal = useCallback(() => {
		if (appRoot.contains(el)) {
			appRoot.removeChild(el);
		}
	}, [el]);
	const closeModal = useCallback(() => {
		if (props.onClose) {
			props.onClose();
		}
		removeModal();
	}, [props, removeModal]);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key !== 'Escape') {
				return;
			}
			if (modalId) {
				const visibleModals = uiStore.visibleModals;
				const currentModalId = visibleModals[visibleModals.length - 1];
				if (currentModalId !== modalId) {
					return;
				}
			}

			const targetElement = e.target as HTMLElement;
			if (escIgnore.indexOf(targetElement.tagName.toLowerCase()) === -1) {
				closeModal();
			}
		},
		[closeModal, modalId, uiStore.visibleModals],
	);

	useEffect(() => {
		appRoot.appendChild(el);
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			removeModal();
		};
	}, [el, handleKeyDown, removeModal]);

	const { size, title, buttons, modifier } = props;
	let modalClass = `modal is-${size ? size : 'medium'} ${modifier || ''}`;
	modalClass += !buttons ? ' no-footer' : '';

	return ReactDOM.createPortal(
		<>
			<div className="modal-background" onClick={() => closeModal()}></div>
			<div className={modalClass}>
				<div className="modal-header">
					<div className="title">{title}</div>
					<button className="modal-close" onClick={() => closeModal()}>
						<Icon iconName="times" />
					</button>
				</div>
				<div className="modal-body">{props.children}</div>

				{buttons && (
					<div className="modal-footer">
						{buttons.map((b) => {
							const buttonType = b.type ? b.type : 'button';
							const form = b.formName ? b.formName : '';
							return (
								<Button key={b.title} form={form} type={buttonType} className={b.className} onClick={b.onClick}>
									{b.title}
								</Button>
							);
						})}
					</div>
				)}
			</div>
		</>,
		el,
	);
});
