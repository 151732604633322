import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import Icon from 'app/components/common/Icon';
import { formatTime } from '../../../utils/utils';
import t from 'app/common/translate';

interface IAudioFileName {
	projectVM: ProjectVM;
}

export const AudioFileName = observer((props: IAudioFileName) => {
	const [fileName, setFileName] = useState<string>();
	const [duration, setDuration] = useState<number>();
	const audioLayer = props.projectVM.model.audioLayer;

	useEffect(() => {
		if (audioLayer.audioFile) {
			setFileName(audioLayer.audioFile.originalFileName);
			setDuration(audioLayer.audioFileDuration);
		}
	}, [audioLayer.audioFile, audioLayer.audioFileDuration]);

	return (
		<>
			{fileName && (
				<div className="file-name audio-file">
					<label>Audio</label>
					<div className="file-name">
						<Icon iconName="volume" />
						<span>{fileName}</span>
						{duration && (
							<small>
								{t('components:audio.duration')}: {formatTime(duration)}
							</small>
						)}
					</div>
				</div>
			)}
		</>
	);
});
