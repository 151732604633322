import React from 'react';
import { withStore, IProps } from './../context';
import { observer } from 'mobx-react';
import LoginForm from 'app/components/auth/LoginForm';
import { RegisterForm } from 'app/components/auth/RegisterForm';
import { ForgotPasswordForm } from 'app/components/auth/ForgotPasswordForm';
import BaseContainer from './BaseContainer';

@withStore
@observer
export class RegisterContainer extends React.Component<IProps> {
	public render() {
		return (
			<BaseContainer hideBrand={true} classModifier="is-auth">
				<RegisterForm />
			</BaseContainer>
		);
	}
}

@withStore
@observer
export class LoginContainer extends React.Component<IProps> {
	public render() {
		return (
			<BaseContainer hideBrand={true} classModifier="is-auth">
				<LoginForm />
			</BaseContainer>
		);
	}
}

@withStore
@observer
export class ForgotPasswordContainer extends React.Component<IProps> {
	public render() {
		return (
			<BaseContainer hideBrand={true} classModifier="is-auth">
				<ForgotPasswordForm />
			</BaseContainer>
		);
	}
}
