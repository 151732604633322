import DetailPanel from 'app/components/common/DetailPanel';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { AdminTaskDetail } from './AdminTaskDetail';

export const AdminTaskDetailPanel = observer(() => {
	const { adminTasksStore } = useStore();
	const history = useHistory();
	if (!adminTasksStore.current) {
		return <></>;
	}
	const task = adminTasksStore.current;

	const onClose = () => {
		history.push(routes.ADMINTASKS.getPath());
	};

	// const editUser = () => {
	// 	adminUserStore.setEditDetailsMode(true);
	// };

	// const onEditUserDone = () => {
	// 	adminUserStore.setEditDetailsMode(false);
	// };

	return (
		<DetailPanel onClose={onClose} title={task.task.type + ' ' + task.projectTitle} width={'70%'}>
			<div className="body ">
				<AdminTaskDetail />

				{/* <Button onClick={editUser} className="button is-primary">
					Details Bearbeiten
				</Button>
				{adminUserStore.editDetailsMode && (
					<>
						<AdminUserDetailEditForm onDone={onEditUserDone} user={user} />
					</>
				)} */}
			</div>
		</DetailPanel>
	);
});
