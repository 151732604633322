import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import routes from '../../routes';
import t from '../common/translate';
import { useStore } from 'app/context';
import { observer } from 'mobx-react-lite';
import { SignOutButton } from './auth/SignOutButton';
import { SvgIcon, SvgIconType } from './common/SvgIcon';

export const Roles = observer(() => {
	const { authStore } = useStore();
	const user = authStore.current;
	if (!user || !(user.isTemplateManager || user.isAdmin)) {
		return <></>;
	}

	return (
		<>
			<br />
			<br />
			Roles:
			{user.isAdmin && <div>Admin</div>}
			{user.isTemplateManager && <div>Template Manager</div>}
		</>
	);
});

const SideMenu = observer(() => {
	const { authStore } = useStore();

	const user = authStore.current;
	if (!user) {
		return <></>;
	}

	return (
		<>
			<div className="top">
				<nav>
					<NavLink className="nav-item" activeClassName="is-active" to={routes.LISTGROUPS.getPath()}>
						{t('nav:podcasts')}
					</NavLink>

					<NavLink className="nav-item" activeClassName="is-active" to={routes.RENDERS.getPath()}>
						{t('nav:renders')}
					</NavLink>
					<NavLink className="nav-item" activeClassName="is-active" to={routes.CONTACT.getPath()}>
						{t('nav:support')}
					</NavLink>
					<NavLink className="nav-item" activeClassName="is-active" to={routes.ACCOUNTSETTINGS.getPath()}>
						{t('nav:account')}
					</NavLink>
				</nav>
			</div>

			<div className="bottom">
				{user && (
					<Link className="profile-box" to={routes.ACCOUNTSETTINGS.getPath()}>
						<SvgIcon iconName={SvgIconType.profile} />
						<div className="user-details">
							<div className="profile-name">{user.firstName}</div>
							<div className="profile-email">{user.email}</div>
						</div>

						{user.activePlan && <span className="tag is-primary is-round is-small">{t(`plans:${user.activePlan}.title`)}</span>}
					</Link>
				)}

				<SignOutButton />
				<div className="debug">
					<Roles />
				</div>
			</div>
		</>
	);
});

export default SideMenu;
