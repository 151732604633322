import { useStore } from 'app/context';
import { AuthRoute } from 'auth.route';
import { observer } from 'mobx-react';
import React from 'react';
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';
import routes, { IRoute } from './routes';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import CacheBuster from 'CacheBuster';
import BusyScreen from 'app/components/common/BusyScreen';

if (window.location.hostname !== 'localhost') {
	Sentry.init({
		dsn: 'https://6a39b3522f69444d859f2859a6b004fb@o287519.ingest.sentry.io/5549183',
		integrations: [new Integrations.BrowserTracing()],
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});
} else {
	console.warn('Localhost detected, Sentry not initialised');
}

export const NotFound: React.FC = observer(() => {
	return (
		<>
			<h1>NISCHTS GEFUNDEN</h1>
			<h1>Dafür gibts eine Katzenbild</h1>
			<img src="http://placekitten.com/g/200/300" alt="cat" />
			<Link to="/">Zur Startseite</Link>
		</>
	);
});

const appClass = () => {
	const s = window.localStorage.getItem('debug');
	return s !== 'true' ? 'application' : 'application allow-debug';
};

const App = observer(() => {
	const { authStore: auth, isBusy, animatorStore, uiStore } = useStore();
	if (auth.isSettingUp) {
		return (
			<div className={appClass()}>
				<BusyScreen />
			</div>
		);
	}

	return (
		<div className={appClass()}>
			<CacheBuster />
			<Router>
				<Switch>
					{Object.keys(routes).map((index) => {
						const r = (routes as any)[index] as IRoute;
						if (r.private) {
							return <AuthRoute key={index} exact={r.exact ? r.exact! : true} component={r.container} path={r.route} />;
						}
						return <Route key={index} exact={r.exact ? r.exact : true} component={r.container} path={r.route} />;
					})}
					<Route component={NotFound} />
				</Switch>
			</Router>
			{isBusy && !uiStore.isUploading && <BusyScreen />}
			<audio controls ref={animatorStore.audioRef} crossOrigin={'anonymous'}></audio>
		</div>
	);
});

export default App;
