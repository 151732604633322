import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import { useStore } from 'app/context';
import { ProjectDeleteForm } from './ProjectDeleteForm';
import { ProjectModel } from 'shared-puredio';

interface IGroupDeleteModalProps {
	modalId: string;
	project: ProjectModel;
	onDone: () => void;
}

export const ProjectDeleteModal: React.FC<IGroupDeleteModalProps> = observer((props: IGroupDeleteModalProps) => {
	const store = useStore();
	const onDone = () => {
		store.uiStore.hideModal(props.modalId);
		props.onDone();
	};
	return (
		<Modal title="" size="medium" modalId={props.modalId}>
			<ProjectDeleteForm project={props.project} onDone={onDone} />
		</Modal>
	);
});
