import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import * as Meyda from 'meyda';
import { ProjectVM } from './viewmodels/project.vm';

//// audio Context
// must live here as it can only be created ony user click
// audioSourceNode
// must also live here as reconnecting the audioEl after first usage sucks.
export class AudioStore {
	constructor() {
		makeObservable(this);
	}
	url?: string;
	audioEl?: HTMLAudioElement;
	analyser?: Meyda.MeydaAnalyzer;

	@observable
	audioCtx?: AudioContext;
	audioSourceNode?: MediaElementAudioSourceNode;

	@observable
	isLoading: boolean = false;

	@observable
	project?: ProjectVM;

	@observable
	currentTime: number = 0;

	@computed
	get currentRelativeTime() {
		const absTime = this.currentTime;
		if (!this.project) {
			return absTime;
		}
		const start = this.project!.model.audioLayer.start;
		return absTime - start;
	}

	@computed
	get currentDuration() {
		if (this.project) {
			return this.project.model.audioLayer.duration;
		}
		return 0;
	}

	@action
	async setup(audioEl: HTMLAudioElement, url: string, project: ProjectVM) {
		this.audioEl = audioEl;
		this.url = url;
		this.project = project;
		this.isLoading = true;
		await this.setupAudioContext();
		await this.loadAudio();
		runInAction(() => {
			this.isLoading = false;
		});
	}

	audioContextCheck() {
		return typeof AudioContext === 'function' && typeof MediaElementAudioSourceNode === 'function';
	}

	@action
	async loadAudio() {
		if (!this.audioEl) {
			console.warn('no audioel');
			return;
		}
		this.audioEl.src = this.url!;
		this.audioEl.load();
		this.isLoading = true;
		const audioEl = this.audioEl!;
		await new Promise((resolve) => {
			audioEl.onloadeddata = () => {
				this.isLoading = false;
				resolve(true);
			};
		});
		// audioEl.volume = 0;
	}

	@computed
	get canStartAudio() {
		return !!this.audioCtx && !this.isLoading;
	}

	@action
	async setupAudioContext() {
		if (this.audioCtx !== undefined) {
			return;
		}

		this.audioCtx = new AudioContext();
		this.isLoading = true;
		this.audioSourceNode = new MediaElementAudioSourceNode(this.audioCtx, {
			mediaElement: this.audioEl!,
		});

		this.audioSourceNode.connect(this.audioCtx.destination);
		this.audioCtx.resume();

		if (this.audioEl !== undefined) {
			this.audioEl!.addEventListener('timeupdate', () => {
				let time = this.audioEl!.currentTime;
				runInAction(() => {
					this.currentTime = time;
				});
			});
		}

		this.analyser = Meyda.createMeydaAnalyzer({
			audioContext: this.audioCtx,
			source: this.audioSourceNode,
			bufferSize: 512,
			windowingFunction: 'blackman',
			featureExtractors: ['amplitudeSpectrum', 'buffer'],
		});
		this.analyser.start();
		this.isLoading = false;
	}
}
