import Config from 'config';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

export class TrackingStore {
	public tagManager;

	public constructor() {
		if (!Config.GTM) return;

		const trackingArgs: TagManagerArgs = {
			gtmId: Config.GTM,
		};

		this.tagManager = TagManager.initialize(trackingArgs);
	}
}
