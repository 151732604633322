import React from 'react';
import { withStore, IProps } from './../context';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ProjectEdit from '../components/Project/ProjectEdit';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import BaseContainer from 'app/containers/BaseContainer';
import * as qs from 'qs';

@withStore
@observer
export default class DebugTemplateContainer extends React.Component<IProps> {
	@observable
	project: ProjectVM | undefined;

	@observable
	notFound: boolean = false;

	async componentDidMount() {
		const store = this.props.store!;
		const userId = store.authStore.current!.id!;

		const f = await store.fileStore.parseAudioByKey('test-fixtures/audio_10s.mp3');
		let vm = await store.projectStore.newFromEmptyProject('foo', userId, 'landscape', 'test', f!);
		vm = vm!;

		if (f) {
			vm.model.setAudioData(f);
			const projectModel = await store.projectStore.create(vm.model);
			this.project = new ProjectVM(projectModel);
			this.onRouteChanged();
		}
	}
	componentDidUpdate(prevProps: IProps) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}
	onRouteChanged() {
		const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
		if (!this.project) {
			return;
		}

		if (query.panel) {
			console.log('setting route', query.panel);
			this.project.currentSidePanel = query.panel as string;
		}
	}

	public render() {
		return (
			<BaseContainer hideBrand={true} contentPadding={false}>
				<div className="project-edit">{this.project ? <ProjectEdit project={this.project} /> : <>{this.notFound ? <div>Episode nicht gefunden</div> : <div>Loading</div>}</>}</div>
			</BaseContainer>
		);
	}
}
