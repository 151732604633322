import React, { useEffect, useState } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { FileModel } from 'shared-puredio';

interface IAudioFileProgress {
	file: FileModel;
}

export const AudioFileInUse = observer((props: IAudioFileProgress) => {
	const { projectStore } = useStore();

	const [inUse, setInUse] = useState(false);

	useEffect(() => {
		setInUse(projectStore.isFileInUse(props.file));
	}, [props.file, projectStore]);

	return (
		<p>
			{inUse && (
				<>
					File <strong>IS</strong> in Use{' '}
				</>
			)}
			{!inUse && <> File is NOT in Use </>}
		</p>
	);
});
