import { Button } from 'app/components/common/Button';
import Icon from 'app/components/common/Icon';
import { PlanName } from 'app/components/payment/PlanName';
import { Table } from 'app/components/Table/table';
import { TableModel, TableRow } from 'app/components/Table/table.model';
import { useStore } from 'app/context';
import { AdminUserVM } from 'app/stores/admin.user.store';
import { formatDate, formatTime } from 'app/utils';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminUsersList: React.FC = observer(() => {
	const history = useHistory();
	const { adminUserStore } = useStore();

	const tm = useMemo(() => {
		const viewDoc = (u: AdminUserVM) => {
			runInAction(() => {
				history.push(routes.ADMINUSERS.getPath(u.user.fakeId));
			});
		};

		const tm = new TableModel<AdminUserVM, string>();
		let cols = [
			{
				label: 'Email',
				path: 'user.email',
			},
			{
				label: 'FirstName',
				path: 'user.firstName',
			},
			{
				label: 'LastName',
				path: 'user.lastName',
			},
			{
				label: ['email', 'verified'],
				path: 'user.emailVerified',
				render: (row: AdminUserVM) => {
					return <>{row.user.emailVerified && <Icon iconName="check" />}</>;
				},
			},
			{
				label: ['Pymnt', 'Type'],
				path: 'user.paymentType',
			},
			{
				label: 'Plan',
				path: 'user.activePlan',
				render: (row: AdminUserVM) => {
					return <PlanName planType={row.user.activePlan} />;
				},
			},
			{
				label: 'Quota',
				path: 'user.videoSecondsQuota',
				render: (row: AdminUserVM) => {
					return <>{row.user.videoSecondsQuota / 60}</>;
				},
			},
			// {
			// 	label: 'Period',
			// 	path: 'user.activeSubscription.plan.billing_period',
			// },
			{
				label: ['Last', 'Pymnt'],
				path: 'user.activeSubscription.last_payment.amount',
			},
			{
				label: ['Next', 'Pymnt'],
				path: 'user.activeSubscription.next_payment.amount',
			},
			{
				label: 'Subs.',
				path: 'user._subscriptions.length',
			},

			{
				label: 'Pdcsts',
				path: 'groupCount',
			},
			{
				label: 'Epsds',
				path: 'projectCount',
			},
			{
				label: 'Tmplts',
				path: 'templateCount',
			},
			{
				label: 'Fmts',
				path: 'fmtCount',
			},
			{
				label: 'DrtnAvg',
				path: 'durationAvg',
				render: (row: AdminUserVM) => {
					return <>{row.durationAvg && <>{Math.round(row.durationAvg)}s</>}</>;
				},
			},
			{
				label: ['User', 'Created'],
				path: 'user.created',
				render: (row: AdminUserVM) => {
					return <>{row && row.user.created && <>{formatDate(row.user.created).toString()}</>}</>;
				},
			},
			{
				label: 'Modified',
				path: 'user.modified',
				render: (row: AdminUserVM) => {
					return <>{row && row.user.modified && <>{formatDate(row.user.modified).toString()}</>}</>;
				},
			},
			{
				label: 'lastLogin',
				path: 'user.lastLogin',
				render: (row: AdminUserVM) => {
					return <>{row && row.user.lastLogin && <>{formatDate(row.user.lastLogin).toString()}</>}</>;
				},
			},
		];

		tm.onRowClick = (row: TableRow<AdminUserVM>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols as any);
		tm.sortBy = 'user.created';
		tm.sortAsc = false;
		tm.idProperty = 'fakeId';
		tm.idType = 'string';

		tm.addTextFilter('Plan', 'user.activePlan');
		tm.addTextFilter('PaymentType', 'user.paymentType');
		tm.addTextFilter('Name, Email or Id', 'user.nameAndEmailAndId');

		return tm;
	}, [history]);

	autorun(() => {
		tm.setData(adminUserStore.vmItems);
	});

	const updateSubscriptions = () => {
		adminUserStore.updateAllSubscriptions();
	};
	const ensureAllTasksInExportUsage = () => {
		adminUserStore.ensureAllTasksInExportUsage();
	};

	return (
		<>
			<div className="section">
				<div className="section-title is-size-4 grid is-col-auto">
					<div className="align-left">Users ({adminUserStore.vmItems.length})</div>
					<div className="align-right">
						<Button className="button is-smallish" onClick={updateSubscriptions}>
							Refresh all Subscriptions
						</Button>
						<Button className="button is-smallish" onClick={ensureAllTasksInExportUsage}>
							Ensure all Tasks in ExportUsage
						</Button>
					</div>
				</div>
			</div>

			<div className="section">
				<Table tm={tm}></Table>
			</div>
		</>
	);
});
