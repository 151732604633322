import * as React from 'react';
import classNames from 'classnames';
import { Brand } from 'app/components/Brand';
import { useStore } from 'app/context';
import { BrowserNotSupportedModal } from 'app/components/BrowserNotSupportedModal';
import { useEffect } from 'react';
import { VerfiyEmailReminderBanner } from 'app/components/auth/VerifyEmailReminder';
import Icon from 'app/components/common/Icon';

interface IProps {
	navbar?: React.ReactNode;
	menu?: React.ReactNode;
	subMenu?: React.ReactNode;
	sidePanel?: React.ReactNode;
	contentPadding?: boolean;
	noAuth?: boolean;
	hideBrand?: boolean;
	classModifier?: string;
}

const BaseContainer: React.FC<IProps> = ({ children, classModifier, navbar, menu, subMenu, sidePanel, hideBrand, contentPadding }) => {
	const classes = classNames('app-container', classModifier, { 'has-navbar': navbar }, { 'has-menu': menu }, { 'has-sub-menu': subMenu }, { 'has-side-panel': sidePanel });
	const store = useStore();

	const browserNotSupportedModalId = store.uiStore.modalIds.browserNotSupported;
	const closeBrowserNotSupportedModal = () => {
		store.uiStore.hideModal(browserNotSupportedModalId);
	};

	useEffect(() => {
		if (!store.animatorStore.audioContextSupported && store.authStore.current) {
			store.uiStore.showModal(browserNotSupportedModalId);
		}
	}, [store, browserNotSupportedModalId]);

	const openInfo = () => {
		window.open('https://puredio.com/goodbye', '_blank');
	};

	return (
		<>
			<div className="sad-message" onClick={openInfo}>
				<Icon iconName="exclamation-circle" /> <span>Puredio wird zum 15.12.2022 eingestellt.</span>
			</div>

			<div className={classes}>
				{hideBrand !== true && <Brand />}
				{menu && <div className="menu">{menu}</div>}
				{subMenu && <div className="sub-menu">{subMenu}</div>}
				<div className={`app-content ${contentPadding === false ? 'no-padding' : ''}`}>
					{/* 
				Remove VerifyEmail for the time being
				<VerfiyEmailReminderBanner /> 
				*/}
					{navbar && (
						<>
							<div className="navbar-shadow"></div>
							<div className="navbar">{navbar}</div>
						</>
					)}
					{children}
				</div>
				<BrowserNotSupportedModal modalId={browserNotSupportedModalId} onDone={closeBrowserNotSupportedModal} />
			</div>
		</>
	);
};

export default BaseContainer;
