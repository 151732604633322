import React, { useState } from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { observer } from 'mobx-react';
import InputControl from '../../common/InputControl';
import t from 'app/common/translate';

interface IProjectEditProps {
	project: ProjectVM;
}

export const ProjectEditForm = observer((props: IProjectEditProps) => {
	const [value, setValue] = useState(props.project.model.title);
	const onChange = (name: string, value: any) => {
		setValue(value);
		props.project.model.title = value;
	};

	return (
		<>
			<div className="form-group">
				<InputControl name="title" label={t('general:project.title')} value={value} onChange={onChange} />
			</div>
		</>
	);
});
