import { gsap } from 'gsap';
import { Shape, Point, Size } from 'paper';
import { Color } from 'paper/dist/paper-core';
import { computed, makeObservable, observable } from 'mobx';
import { BaseAnimationOptions } from './LayerAnimationOptions';
import { IAssetResolver } from './IAssetResolver';
import { ImageModel, LayerType, TemplateManagers } from '..';
import { ControlGroup } from './utils/ControlGroup';
import icons from './assets/icons/Icons';

export interface IPoints {
    [key: string]: IPoint;
}
export interface IPoint {
    v: number;
    d: 'w' | 'h';
}

export class BaseAnimation {
    public sort: number;
    public timeline: GSAPTimeline;
    public anim: BaseAnimationOptions;

    private foo;
    public assetResolver?: IAssetResolver;

    public layerType: LayerType;
    public supportsDisabling: boolean = true;
    public isLayerSelectable: boolean = true;
    public isLayerTransformable: boolean = true;
    public constrainProportions: boolean = true;
    public group: paper.Group;
    public controlGroup: ControlGroup;
    public animationEnabled: boolean = true;

    @observable
    selected: boolean = false;

    @computed
    get x() {
        return this.anim.animationLayer.opts.x;
    }
    @computed
    get y() {
        return this.anim.animationLayer.opts.y;
    }
    @computed
    get width() {
        return this.anim.animationLayer.opts.width;
    }
    @computed
    get height() {
        return this.anim.animationLayer.opts.height;
    }
    @computed
    get rotation() {
        return this.anim.animationLayer.opts.rotation;
    }
    @computed
    get scale() {
        return this.anim.animationLayer.opts.scale;
    }

    public constructor(anim: BaseAnimationOptions) {
        makeObservable(this);
        this.anim = anim;
        this.setTimeLine();
        this.controlGroup = new ControlGroup();
    }

    public setTimeLine() {
        if (this.timeline) {
            this.timeline.clear();
        }
        this.timeline = gsap.timeline();
        if (this.anim && this.anim.animationLayer) {
            this.timeline.labels['sort'] = this.anim.animationLayer.sort;
            this.sort = this.anim.animationLayer.sort;
        }
    }

    isDisposed: boolean = false;
    disposers = [];
    public dispose = () => {
        this.disposers.forEach((d) => {
            d();
        });
        this.disposers = [];
        this.timeline.clear();
        this.timeline.remove(this.onTimelineProgress.bind(this));
        this.timeline.eventCallback('onUpdate', null);
        this.controlGroup.toggleSelect(false);
        this.isDisposed = true;
    };

    public setAssetResolver(assetResolver: IAssetResolver) {
        this.assetResolver = assetResolver;
    }

    // get the pixel value based on the format. Needed for scaling.
    public calc = (opts: IPoint) => {
        if (!opts) {
            return;
        }

        if (typeof opts !== 'object') {
            console.error('Animation: calc() call on non-IPoint property', opts);
        }

        return this.anim.fmt[opts.d] * opts.v;
    };

    public calcFromPixel(d: 'w' | 'h', pixel: number) {
        return pixel / this.anim.fmt[d];
    }

    public ready: boolean = false;

    public create = async (project: paper.Project) => {
        let foo = new Shape.Rectangle(new Point(5, 5), new Size(5, 5));
        foo.fillColor = new Color('black');
        this.foo = foo;
        this.ready = true;
        return this.foo;
    };

    public onTimelineProgress = function () {
        if (this.isDisposed || !this.animationEnabled) {
            return;
        }
        const time = gsap.globalTimeline.time() - this.anim.audioLayer.start;
        const frame = Math.round(time * 30);
        // const time = this.timeline.time();
        // const frame = Math.round(time * 30);
        this.tick(frame);
        return true;
    };

    public tick(currentframe) {
        if (this.isDisposed) {
            return;
        }
        // override to implement custom behaviour per tick
        // will only be called if animationEnabled = true

        return;
    }

    public stop() {
        if (this.timeline) {
            this.timeline.paused(true);
        }
    }

    public optsUpdate(vals: any) {
        console.log('optsUpdate needs to be implemented in ' + this.anim.key);
    }
    public onLayerSelect = () => {
        console.log('onLayerSelect needs to be implemented in ' + this.anim.key);
    };
    public setCursor = (c: string, r?: number) => {
        console.log('setCursor needs to be implemented in ' + this.anim.key);
    };
    public resetCursor = () => {
        console.log('resetCursor needs to be implemented in ' + this.anim.key);
    };

    public onScaleUpdate = () => {
        // do nothing
    };
    public onRotationUpdate = () => {
        // do nothing
    };
    public onPositionUpdate = () => {
        // do nothing
    };

    public getDefaultOpts = (ftmId: string) => {
        console.warn('getDefaultOpts not implemented for ', this);
        return {};
    };

    public animate = (onUpdate?: () => void, onComplete?: () => void) => {
        if (!this.animationEnabled) {
            return;
        }
        if (!this.timeline) {
            console.log('no timeline');
            return;
        }

        this.timeline.tweenFromTo(this.anim.startAt, this.anim.endAt, {
            onUpdate: this.onTimelineProgress.bind(this),
            // onComplete: onComplete,
        });
        this.timeline.pause();
    };

    // control group
    setupControlGroup() {
        if (!this.group) {
            console.warn('no group to bind to in', this.layerType);
            return;
        }
        this.controlGroup.bind(this);
    }

    /// IMAGE / RASTER HANDLING
    @computed
    get image() {
        return this.anim.animationLayer.opts.image;
    }
    async loadImage(raster: paper.Raster) {
        const imageUrl = await this.getImageUrl(this.image);
        raster.source = imageUrl;
    }
    async getImageUrl(imageStringOrObj: any) {
        const emptyImage = icons.icons.defaultImage;
        if (!imageStringOrObj || imageStringOrObj === '') {
            return emptyImage;
        }
        if (typeof imageStringOrObj === 'string') {
            if (imageStringOrObj.startsWith('public/template')) {
                return this.assetResolver.getDownloadUrl(imageStringOrObj);
            }
        }
        const image = ImageModel.fromStringOrObject(imageStringOrObj);
        if (!image.md5Hash || image.userId === '') {
            return emptyImage;
        }

        if (image.key.startsWith('public/template')) {
            return this.assetResolver.getDownloadUrl(imageStringOrObj);
        }
        // images create via the template manager, should stay the same
        if (!TemplateManagers.includes(image.userId)) {
            image.userId = this.anim.userId;
        }

        return await this.assetResolver.getDownloadUrl(image.key);
    }
}
