import React, { useEffect, useState } from 'react';
import { useStore } from 'app/context';
import { humanFileSize } from 'app/utils';
import { observer } from 'mobx-react';
import { FileModel } from 'shared-puredio';
import DetailPanel from '../common/DetailPanel';
import { useHistory } from 'react-router';
import routes from 'routes';
import { ImageViewer } from '../common/ImageViewer';
import { ImageFileInUse } from './ImageFileInUse';

export const ImageFileDetail = observer(() => {
	const { fileStore } = useStore();
	const history = useHistory();
	const [file, setFile] = useState<FileModel | undefined>();

	useEffect(() => {
		setFile(fileStore.selected);
	}, [fileStore.selected]);
	const onClose = () => {
		history.push(routes.IMAGFILES.getPath());
	};

	return (
		<>
			{file && (
				<DetailPanel title={'audio'} subtitle={file ? file.id : ''} onClose={onClose}>
					<>
						{file.originalFileName} {humanFileSize(file.metadata.size)}
						<ImageFileInUse file={file} />
						<ImageViewer bucketKey={file.normalizedKey} />
					</>
				</DetailPanel>
			)}
		</>
	);
});
