import { computed, reaction } from 'mobx';
import { Shape, Point, Size, Color, Gradient, GradientStop, Group } from 'paper';
import { LayerType } from '../..';
import { BaseAnimation } from '../BaseAnimation';

export default class GradientBackground extends BaseAnimation {
    public layerType: LayerType = LayerType.background;
    public supportsDisabling = false;
    isLayerTransformable = false;
    animationEnabled: boolean = false;

    public getDefaultOpts = (ftmId: string) => {
        return {
            startColor: '#444444',
            endColor: '#888888',
        };
    };

    @computed
    get startColor() {
        return this.anim.animationLayer.opts.startColor;
    }

    @computed
    get endColor() {
        return this.anim.animationLayer.opts.endColor;
    }

    public create = async (project: paper.Project) => {
        let origin = 'topLeft';
        let destination = 'bottomLeft';
        let bg = new Shape.Rectangle(new Point(0, 0), new Size(this.anim.fmt.w, this.anim.fmt.h));
        this.group = new Group([bg]);

        const disp = reaction(
            () => [this.startColor, this.endColor],
            () => {
                let stops = [new GradientStop(new Color(this.startColor)), new GradientStop(new Color(this.endColor))];
                let grad = new Gradient();
                grad.stops = stops;
                bg.fillColor = new Color(grad, bg.bounds[origin], bg.bounds[destination]);
                console.log(this.startColor, this.endColor);
            },
            { fireImmediately: true },
        );
        this.disposers.push(disp);

        return bg;
    };
}
