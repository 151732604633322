import { PaperScope } from 'paper';
import gsap from 'gsap';
import { AnimationOptions, AnimationTypes, BaseAnimation, IAssetResolver, LayerModel, LayerProperties, LayerType, TemplateManagers, UserModel } from 'shared-puredio';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { PaperLayerVM } from './paper.layer.vm';
import { ProjectVM } from './project.vm';
import short from 'short-uuid';
import Config from 'config';
import { isPaymentTestUser } from '../ui.store';

export interface IPaperVMParms {
	audioRef: React.RefObject<HTMLAudioElement>;
	canvasRef: React.RefObject<HTMLCanvasElement>;
	anim: AnimationOptions;
	assetResolver: IAssetResolver;
	user?: UserModel;
	onLayerSelect: (key: string) => void;
	onStop: () => Promise<number | undefined>;
	onAnimate: (t: number) => void;
	project: ProjectVM;
}

export interface IAudioRelatedLayer {
	layer: paper.Layer;
	baseAnimation: BaseAnimation;
}

export class PaperVM {
	paperScope?: paper.PaperScope;
	audioRef: React.RefObject<HTMLAudioElement>;
	canvasRef: React.RefObject<HTMLCanvasElement>;

	@observable
	anim: AnimationOptions;

	assetResolver: IAssetResolver;

	@observable
	isReady: boolean = false;

	@observable
	layers: PaperLayerVM[] = [];

	disposers: any[] = [];

	onLayerSelect: (key: string) => void;
	onStop: () => Promise<number | undefined>;
	onAnimate: (t: number) => void;
	projectVM: ProjectVM;
	user?: UserModel;

	constructor(opts: IPaperVMParms) {
		makeObservable(this);
		this.isReady = false;
		this.audioRef = opts.audioRef;
		this.canvasRef = opts.canvasRef;
		this.anim = opts.anim;
		this.assetResolver = opts.assetResolver;
		this.projectVM = opts.project;
		this.user = opts.user;

		// bubbles selectedLayer to router
		this.onLayerSelect = (key: string) => {
			this.selectLayer(key);
			opts.onLayerSelect(key);
		};
		this.onStop = opts.onStop;
		this.onAnimate = opts.onAnimate;
		const pPaper = this.setupPaper();
		pPaper.then(() => {
			runInAction(() => {
				this.isReady = true;
			});
		});
	}

	@action
	selectLayer(key: string) {
		// if (this.selectedLayer && this.selectedLayer.key === key) {
		// 	return;
		// }
		this.layers.forEach((l) => {
			l.baseAnimation.selected = l.key === key;
			if (l.baseAnimation.selected) {
				const pl = this.paperScope!.project.layers.find((l) => l.name === key);
				if (pl) {
					pl.activate();
				}
			}
			if (l.baseAnimation.controlGroup) {
				l.baseAnimation.controlGroup.toggleSelect(l.key === key);
			}
		});
	}

	async tearDown() {}

	@computed
	get selectedLayer() {
		return this.layers.find((l) => l.baseAnimation.selected);
	}

	@computed
	get animations() {
		return this.anim.animations;
	}

	@action
	async setupPaper() {
		this.layers = [];
		gsap.globalTimeline.clear(); // most important!. does some black magick!
		gsap.globalTimeline.pause();
		this.paperScope = new PaperScope();
		this.paperScope.setup(this.canvasRef.current!);

		const fmt = this.anim.fmt;
		const size = new this.paperScope.Size(fmt.w, fmt.h);
		this.paperScope.view.viewSize = size;
		gsap.ticker.fps(this.anim.fmt.fps);
		this.paperScope.activate();

		if (!this.anim || !this.paperScope) {
			return;
		}
		this.paperScope.project.clear();
		this.paperScope.activate();
		let disp;

		for (let baseAnimation of this.animations) {
			baseAnimation.setAssetResolver(this.assetResolver);
			baseAnimation.anim.startAt = 0;
			const plvm = new PaperLayerVM({
				parent: this,
				anim: this.anim,
				baseAnimation,
				assetResolver: this.assetResolver,
				paperScope: this.paperScope!,
				onLayerSelect: this.onLayerSelect,
				projectVM: this.projectVM,
			});
			runInAction(() => {
				this.layers.push(plvm);
			});
			await plvm.updateLayer();
		}
		if (Config.FEATURE_WATERMARK) {
			this.ensureWatermark();
		}

		// updated audio: start or end -> rerender audio related layers & reset global timeline
		disp = reaction(
			() => this.anim.audioLayer.start || this.anim.audioLayer.end,
			() => {
				gsap.globalTimeline.clear(true);
				const audioLayer = this.anim.audioLayer;
				const start = audioLayer.start;
				const end = audioLayer.end;
				const totalDuration = end - start;

				this.layers.forEach((al) => {
					al.baseAnimation.anim.startAt = start;
					al.baseAnimation.anim.endAt = end;
					al.baseAnimation.anim.totalDuration = totalDuration;
					al.baseAnimation.setTimeLine();
					if (al.isAudioRelatedLayer) {
						al.updateLayer();
					}
				});
			},
			{
				fireImmediately: false,
				delay: 50,
			},
		);
		this.disposers.push(disp);

		disp = reaction(
			() => this.projectVM.currentSidePanel,
			() => {
				this.selectLayer(this.projectVM.currentSidePanel!);
			},
			{
				fireImmediately: false,
				delay: 25,
			},
		);
		this.disposers.push(disp);
	}

	dispose() {
		for (const disp of this.disposers) {
			disp();
		}
		for (const layer of this.layers) {
			layer.dispose();
		}
		this.disposers = [];
	}

	resume(t: number) {
		console.log('paper resume @', t);
		gsap.globalTimeline.resume();
	}

	seek(t: number) {
		gsap.globalTimeline.seek(t);
		this.layers.forEach((l) => l.baseAnimation.tick(t * 30));
	}

	animate(t: number) {
		console.log('paper animate play @', t);
		if (gsap.globalTimeline.paused()) {
			gsap.globalTimeline.play(t);
		} else {
			gsap.globalTimeline.resume(t);
		}
		for (const l of this.animations) {
			if (l.anim.animationLayer.isDisabled) {
				continue;
			}
			l.controlGroup.toggleSelect(false);
			// essentially resets the timeline. works better than l.timeline.restart or seek(0)
			l.animate();
		}
	}

	@action
	async pause() {
		gsap.globalTimeline.pause();
		for (const l of this.animations) {
			if (l.anim.animationLayer.isDisabled) {
				continue;
			}
			// essentially resets the timeline. works better than l.timeline.restart or seek(0)
			l.timeline.pause();
		}
	}

	@action
	async stop() {
		gsap.globalTimeline.pause(0);
		// frome 1: otherwise the animated text disapears on stop
		this.layers.forEach((l) => l.baseAnimation.tick(1));
		for (const l of this.animations) {
			if (l.anim.animationLayer.isDisabled) {
				continue;
			}
			// essentially resets the timeline. works better than l.timeline.restart or seek(0)
			l.animate();
		}
	}

	//// Layer manipulation

	@action
	toggleLayerVisiblity(layerKey: string) {
		const layer = this.projectVM.model.layers.find((l) => l.key === layerKey);
		if (!layer) {
			return;
		}
		this.projectVM.model.beginSnapshot(this.projectVM.navState);
		layer!.opts.disabled = !layer!.opts.disabled;
		this.projectVM.model.commitSnapshot(1);
	}

	@action
	moveBack(layerKey: string) {
		this.projectVM.model.beginSnapshot(this.projectVM.navState);
		const layerVm = this.layers.find((l) => l.key === layerKey);
		const pl = layerVm!.pl;
		pl.insertBelow(pl.previousSibling);
		this.setLayerSortFromPaperLayers();
		this.projectVM.model.commitSnapshot(1);
	}

	@action
	moveFront(layerKey: string) {
		this.projectVM.model.beginSnapshot(this.projectVM.navState);
		const layerVm = this.layers.find((l) => l.key === layerKey);
		const pl = layerVm!.pl;
		pl.insertAbove(pl.nextSibling);
		this.setLayerSortFromPaperLayers();
		this.projectVM.model.commitSnapshot(1);
	}
	@action
	async duplicate(layerKey: string) {
		this.projectVM.model.beginSnapshot(this.projectVM.navState);
		const layer = this.projectVM.model.layers.find((l) => l.key === layerKey);
		const layerVm = this.layers.find((l) => l.key === layerKey);
		const pl = layerVm!.pl;

		const newKey = await this.addLayer(layer!.layerType, layer!.animClass);
		const newLayer = this.projectVM.model.layers.find((l) => l.key === newKey);
		newLayer!.opts = LayerProperties.fromObject(layer!.opts.serialize(), newLayer!.layerType);
		const newLayerVm = this.layers.find((l) => l.key === newKey);
		const newPl = newLayerVm!.pl;
		newPl.insertAbove(pl);

		this.setLayerSortFromPaperLayers();
		this.projectVM.model.commitSnapshot(1);
		return newKey;
	}

	@action
	removeLayer(layerKey: string) {
		if (!layerKey) {
			debugger;
			console.warn('missing layer key, for layer remove');
			return;
		}
		this.projectVM.model.beginSnapshot(this.projectVM.navState);
		const replaced = this.projectVM.model.layers.filter((l) => l.key !== layerKey);
		this.projectVM.model.layers.replace(replaced);
		this.setLayerSortFromPaperLayers();
		this.projectVM.model.commitSnapshot(1);

		const plvm = this.layers.find((l) => l.key === layerKey);
		if (plvm) {
			plvm.dispose();
			plvm.pl.remove();
		}
		const replacedPvlvms = this.layers.filter((l) => l.key !== layerKey);
		this.layers = replacedPvlvms;
		this.anim.animations.replace(this.animations.filter((a) => a.anim.key !== layerKey));
		this.onLayerSelect(replacedPvlvms[0].key);
	}

	@action
	async addLayer(layerType: LayerType, animClass: string, key?: string) {
		this.projectVM.model.beginSnapshot(this.projectVM.navState);
		const _key = key ? key : layerType + '_' + short.generate();

		const sort = this.layers.length;
		const ba = AnimationTypes.getByBaseAnimationByName(animClass);
		const opts = ba.getDefaultOpts(this.projectVM.model.fmtId);

		const layer = new LayerModel({
			layerType,
			animClass,
			key: _key,
			sort,
			fmtId: this.layers[0].baseAnimation.anim.fmt.id,
			opts,
		});
		this.projectVM!.model.layers.push(layer);
		this.setLayerSortFromPaperLayers();
		this.projectVM.model.commitSnapshot(1);

		const baseAnimation = this.anim.getBaseAnimation(layer);
		baseAnimation.setAssetResolver(this.assetResolver);
		baseAnimation.anim.startAt = 0;
		this.animations.push(baseAnimation);
		const plvm = new PaperLayerVM({
			parent: this,
			anim: this.anim,
			baseAnimation,
			assetResolver: this.assetResolver,
			paperScope: this.paperScope!,
			onLayerSelect: this.onLayerSelect,
			projectVM: this.projectVM,
		});
		this.layers.push(plvm);

		await plvm.updateLayer();
		this.onLayerSelect(layer.key);

		return layer.key;
	}

	@action
	setLayerSortFromPaperLayers() {
		const proj = this.projectVM!.model;
		let i = 0;
		this.paperScope!.project.layers.forEach((pl) => {
			const l = proj.layers.find((l) => l.key === pl.name);
			if (l) {
				if (l.sort !== i) {
					console.log(l.key, l.sort, 'to', i);
					if (l.layerType === LayerType.watermark) {
						l.sort = 99;
					} else {
						l.sort = i;
					}
				}
				i++;
			}
		});
		proj.layers.replace(proj.layers.slice().sort((l) => l.sort));
	}

	@action
	ensureWatermark() {
		if (TemplateManagers.includes(this.projectVM.model.userId)) {
			return;
		}

		// if (!isPaymentTestUser(this.user!.email)) {
		// 	return;
		// }

		if (this.user && this.user.canAccessPremiumFeatures) {
			return;
		}

		const water = this.layers.find((l) => l.key === 'watermark');
		if (!water) {
			const animTypes = AnimationTypes.classNamesByLayerType(LayerType.watermark);
			this.addLayer(LayerType.watermark, animTypes[0], 'watermark');
		}
	}
}
