import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import Config from '../../config';

const analyticsMock = {
	logEvent: (a: any, e: any) => {},
	setCurrentScreen: () => {},
	setUserId: () => {},
};

const fireStoreSettings: firebase.firestore.Settings = {
	ignoreUndefinedProperties: true,
};
let useEmualtor = process.env.NODE_ENV === 'test' || process.env.REACT_APP_USE_EMU === '1';

if (useEmualtor) {
	fireStoreSettings.host = 'localhost:8080';
	fireStoreSettings.ssl = false;
	console.log('using firestore emulator');
}
if (firebase.apps.length === 0) {
	firebase.initializeApp(Config.FB_CONFIG);
	firebase.firestore().settings(fireStoreSettings);

	console.log('firebase initialized');
}
const _functions = firebase.app().functions('europe-west3');
if (useEmualtor) {
	_functions.useFunctionsEmulator('http://localhost:5001');
	console.log('using function emulator');
}
export const functions = _functions;
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export const firebaseApp = firebase.app();
export const firebaseAuth = firebase.auth();
export const analytics: Partial<firebase.analytics.Analytics> = process.env.NODE_ENV !== 'test' ? firebase.analytics() : analyticsMock!;
