import { observer } from 'mobx-react';
import React from 'react';

interface IYesNo {
	value?: boolean;
}
export const YesNo = observer((props: IYesNo) => {
	if (props.value) {
		return <>Yes</>;
	}
	return <>No</>;
});
