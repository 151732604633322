import { action, computed, IObservableArray, makeAutoObservable, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import InputControl from '../common/InputControl';
import moment from 'moment';

interface ITableColumn<T, P> {
	label: string;
	path: string;
	sortBy?: string;
	render?: (row: T) => JSX.Element;
	agg?: ITableAggregateColumn<T, P> | ITableAggregateColumn<T, P>[];
}

enum TableFilterItemType {
	text = 'text',
	dateAfter = 'dateAfter',
	dateBefore = 'dateBefore',
}

export class TableColumn<T, P> {
	constructor(opts: ITableColumn<T, P>, tm: TableModel<T, P>) {
		// makeAutoObservable(this);
		this.label = opts.label;
		this.path = opts.path;
		this.render = opts.render;
		this.sortBy = opts.sortBy;
		if (opts.agg) {
			if (Array.isArray(opts.agg)) {
				this.aggs = opts.agg.map((o) => new TableAggregrateColumn(o, tm));
			} else {
				this.aggs = [];
				const agg = new TableAggregrateColumn(opts.agg, tm);
				this.aggs.push(agg);
			}
		}
	}
	label: string;
	path: string;
	sortBy?: string;

	aggs?: TableAggregrateColumn<T, P>[];

	@computed
	get sortProp() {
		return this.sortBy ? this.sortBy : this.path;
	}

	render?: (row: T) => JSX.Element;
}

export class TableRow<T> {
	constructor(data: T) {
		makeObservable(this);
		this.data = data;
	}
	@observable data: T;
	@observable selected: boolean = false;
}

export class TableModel<T, P> {
	constructor() {
		makeObservable(this);
		this.rows = observable([]);
	}
	idProperty: string = 'id';
	idType: 'number' | 'string' = 'number';
	@observable columns: TableColumn<T, P>[] = [];
	rows: IObservableArray<TableRow<T>>;

	_showIndx: boolean = false;

	@observable selectedId?: P;
	@action
	setSelectedId(id?: P) {
		this.selectedId = id;
		this.doSelectRow();
	}

	@observable sortBy?: string;
	@observable sortAsc: boolean = true;

	@computed
	get showSum() {
		const aggCols = this.columns.filter((c) => c.aggs);
		if (aggCols.length > 0) {
			return true;
		}
		return false;
	}

	@computed
	get showFooter() {
		return this.showSum;
	}

	@action
	setSortBy(path: string) {
		if (this.sortBy === path) {
			this.sortAsc = !this.sortAsc;
			return;
		}
		this.sortBy = path;
		this.sortAsc = true;
	}

	@action sort() {
		if (!this.sortBy) {
			return;
		}
		const s = this.sortBy;

		// let sorted = _.sortBy(this.rows, (r) => {
		// 	const v = _.get(r.data, s);
		// 	return v ? v : 0;
		// });
		let sorted = _.sortBy(this.rows, 'data.' + s);
		if (!this.sortAsc) {
			sorted = _.reverse(sorted);
		}
		runInAction(() => {
			this.rows.replace(sorted);
		});
	}

	@action
	setCols(cols: ITableColumn<T, P>[]) {
		cols.forEach((c) => {
			const tc = new TableColumn<T, P>(c, this);
			this.columns.push(tc);
		});
	}

	@observable data: T[] = [];

	@action
	setData(data: T[]) {
		this.data = data;
		const activeFilters = this.activeFilters;
		if (activeFilters && activeFilters.length > 0) {
			data = data.filter((d) => {
				let hit = false;
				activeFilters.forEach((f) => {
					if (hit) {
						return;
					}
					hit = f.isHit(d);
				});
				return hit;
			});
		}
		const newRows = data.map((d) => {
			return new TableRow<T>(d);
		});
		runInAction(() => {
			this.rows.replace(newRows);
			this.sort();
			// this.doSelectRow();
		});
	}

	onRowClick = (row: TableRow<T>) => {};

	@action
	doSelectRow() {
		const id = this.selectedId;
		this.rows.forEach((d) => {
			runInAction(() => {
				if (this.compareById(d.data, id)) {
					d.selected = true;
					return;
				}
				d.selected = false;
			});
		});
	}

	compareById(row1: T, id?: P) {
		const p = this.idProperty;
		return (row1 as any)[p] === id;
	}

	@observable
	filters: TableFilterItem[] = observable([]);

	get activeFilters() {
		return this.filters.filter((f) => f.activeFilter);
	}

	addTextFilter(label: string, path: string) {
		const item = new TableFilterItem({ label, path, type: TableFilterItemType.text });
		item.component = <TableTextFilterComponent filter={item} onFilterChanged={() => {}} />;
		this.filters.push(item);
	}
	addDateBeforeFilter(label: string, path: string) {
		const item = new TableFilterItem({ label, path, type: TableFilterItemType.dateBefore });
		item.component = <TableDateFilterComponent filter={item} onFilterChanged={() => {}} />;
		this.filters.push(item);
	}
	addDateAfterFilter(label: string, path: string) {
		const item = new TableFilterItem({ label, path, type: TableFilterItemType.dateAfter });
		item.component = <TableDateFilterComponent filter={item} onFilterChanged={() => {}} />;
		this.filters.push(item);
	}

	applyFilter() {}
}

enum TableFilterType {
	text = 'text',
	collection = 'collection',
}

interface ITableFilterItem {
	label: string;
	path: string;
	type: TableFilterItemType;
	component?: any;
}

interface IFilterComponent {
	filter: TableFilterItem;
	onFilterChanged: () => void;
}

const TableTextFilterComponent = observer((props: IFilterComponent) => {
	const filter = props.filter;
	const [value, setValue] = useState<string>(filter.value ? filter.value : '');
	const onSelect = (name: string, value: string) => {
		setValue(value);
		filter.onSelect(value);
		props.onFilterChanged();
	};
	return (
		<div>
			{filter.label}
			<InputControl name={filter.path} onChange={onSelect} value={value} />
		</div>
	);
});

const TableDateFilterComponent = observer((props: IFilterComponent) => {
	const filter = props.filter;

	const [value, setValue] = useState<string>(filter.value ? filter.value : '');
	const onSelect = (name: string, value: string) => {
		setValue(value);
		filter.onSelect(value);
		props.onFilterChanged();
	};
	return (
		<div>
			{filter.label}
			<InputControl type={'date'} name={filter.path + filter.label} onChange={onSelect} value={value} />
		</div>
	);
});

export class TableFilterItem {
	constructor(opts: ITableFilterItem) {
		makeObservable(this);
		this.label = opts.label;
		this.path = opts.path;
		this.component = opts.component;
		this.type = opts.type;
	}
	@observable
	value?: any;

	type: TableFilterItemType = TableFilterItemType.text;

	@computed
	get activeFilter() {
		return !!this.value;
	}

	isHit(rowData: any) {
		const p = this.path;
		let rowVal = _.get(rowData, p);
		if (rowVal && this.value) {
			let v = this.value;
			switch (this.type) {
				case TableFilterItemType.text:
					v = v.toLowerCase();
					rowVal = rowVal.toLowerCase();
					if (rowVal.indexOf(v) >= 0) {
						return true;
					}
					break;
				case TableFilterItemType.dateAfter:
					const afterDate = moment(rowVal);
					const currDate = moment(v);
					if (currDate.isSameOrBefore(afterDate)) {
						return true;
					}
					break;
				case TableFilterItemType.dateBefore:
					const beforeDate = moment(rowVal);
					const currDate2 = moment(v);
					if (currDate2.isSameOrAfter(beforeDate)) {
						return true;
					}
					break;
			}
		}
		return false;
	}

	@observable
	label: string;

	@observable
	path: string;

	@action
	onSelect(val: any) {
		this.value = val;
	}

	@observable
	component: any;

	// filterType:TableFilterType;
	// filterPath: string;
}

export class TableFilter {
	constructor() {
		makeObservable(this);
	}
}

export enum AggregrationType {
	empty = 'empty',
	avg = 'avg',
	sum = 'sum',
	count = 'count',
}

export interface ITableAggregateColumn<T, P> {
	render?: (tm: TableModel<T, P>) => JSX.Element;
	path: string | string[];
	format?: { (val: any): string };
	aggType?: AggregrationType;
	aggIf?: (val: any) => boolean;
	visible?: boolean;
}

export class TableAggregrateColumn<T, P> {
	constructor(opts: ITableAggregateColumn<T, P>, tm: TableModel<T, P>) {
		makeAutoObservable(this);
		this.tm = tm;
		this.format = opts.format;
		this.path = opts.path;
		this.render = opts.render;
		if (opts.aggType) {
			this.aggType = opts.aggType;
		}
		if (opts.aggIf) {
			this.aggIf = opts.aggIf;
		}
		if (opts.visible !== undefined) {
			this.visible = opts.visible;
		}
	}

	aggIf(val: any) {
		if (val > 0) {
			return true;
		}
		return false;
	}

	visible: boolean = true;

	path: string | string[];
	aggType: AggregrationType = AggregrationType.sum;
	format?: { (val: any): string };
	tm: TableModel<T, P>;
	render?: (tm: TableModel<T, P>) => JSX.Element;
	doFormat(val: any) {
		if (!this.format) {
			return val;
		}
		return this.format(val);
	}
	@computed
	get aggValue() {
		if (this.aggType === AggregrationType.sum) {
			let sum = 0;
			this.tm.data.forEach((d) => {
				const prop = this.path! as string;
				const val = _.get(d, prop);
				if (this.aggIf(val)) {
					sum += val;
				}
			});
			return sum;
		}
		if (this.aggType === AggregrationType.avg) {
			let sum = 0;
			let count = 0;
			this.tm.data.forEach((d) => {
				const prop = this.path! as string;
				const val = _.get(d, prop);
				if (this.aggIf(val)) {
					count++;
					sum += val;
				}
			});
			return Math.round(sum / count);
		}

		if (this.aggType === AggregrationType.count) {
			let count = 0;
			this.tm.data.forEach((d) => {
				const prop = this.path! as string;
				const val = _.get(d, prop);
				if (this.aggIf(val)) {
					count++;
				}
			});
			return count;
		}
		return undefined;
	}
}
