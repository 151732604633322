import { computed } from 'mobx';
import { BaseModel } from '..';

export interface IMailMessage {
    subject: string;
    text: string;
    html: string;
}
export interface IMail {
    to: string;
    type: string;
    userId?: string;
    from?: string;
    verifyId?: string;
    verifyDate?: Date;
    created?: Date;
    replyTo?: string;
    toUids?: string[];
    headers?: any;
    message: IMailMessage;
}

export class SupoortMailModel {
    from: string;
    subject: string;
    body: string;
    userId: string;
}

export class MailModel {
    constructor(parms: IMail) {
        this.type = parms.type;
        this.to = parms.to;
        this.userId = parms.userId;
        this.from = parms.from;
        this.replyTo = parms.replyTo;
        this.toUids = parms.toUids;
        this.headers = parms.headers;
        this.message = parms.message;
        this.verifyId = parms.verifyId;
        this.created = parms.created;
        this.verifyDate = parms.verifyDate;
        this.bcc = 'hello@puredio.com';
    }

    @computed
    get delivered() {
        return this.delivery && this.delivery.state === 'SUCCESS';
    }

    delivery?: any;

    id?: string;
    userId?: string;
    type: string;
    to: string;
    bcc: string;
    from?: string;
    replyTo?: string;
    toUids?: string[];
    headers?: any;
    verifyId?: string;
    created?: Date;
    verifyDate?: Date;
    message: IMailMessage;
    fixDate(prop: string) {
        if (this[prop] && this[prop].toDate) {
            this[prop] = this[prop].toDate();
        }
    }
    serialize() {
        return {
            id: this.id,
            userId: this.userId,
            to: this.to,
            from: this.from,
            verifyId: this.verifyId,
            verifyDate: this.verifyDate,
            replyTo: this.replyTo,
            toUids: this.toUids,
            headers: this.headers,
            message: JSON.parse(JSON.stringify(this.message)),
        };
    }
}
