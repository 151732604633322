import React from 'react';
import { ExportTaskModel } from 'shared-puredio';
import { Button } from './Button';
import t from 'app/common/translate';
import { useStore } from 'app/context';

interface IRenderDownloadVideo {
	mainTask?: ExportTaskModel;
}

export const DownloadVideo = (props: IRenderDownloadVideo) => {
	const mainTask = props.mainTask;
	const store = useStore();
	async function downloadRenderResult() {
		const downloadUrl = store.computeApi.renderDownloadUrl(mainTask!.id!);
		const link = document.createElement('a');
		if (link.download !== undefined) {
			link.setAttribute('href', downloadUrl);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	return (
		<>
			{mainTask && mainTask.result && mainTask.result.outputKey && (
				<Button className="button is-primary" onClick={downloadRenderResult}>
					<span>{t('components:download.video')}</span>
				</Button>
			)}
		</>
	);
};
