import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Font, FontVariant } from 'shared-puredio';
import { Fonts } from 'shared-puredio';
import t from 'app/common/translate';
import WebFont from 'webfontloader';
import Select from 'react-select';

interface IFontSelectControl {
	value: string;
	// opts: ISelectOpt[];
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
}

export const FontFamiliySelectControl = observer((props: IFontSelectControl) => {
	const [font, setFont] = useState<Font>();

	useEffect(() => {
		const f = Fonts.find((f) => f.family === props.value);
		setFont(f);
	}, [props.value]);
	const id = props.id ? props.id : props.name;

	const onChange = (value: Font | null, action?: any) => {
		if (value) {
			props.onChange('fontFamily', value.family);
		}
	};

	return (
		<div className="form-control">
			{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
			<Select
				// comment to prevent
				// styles={customStyles}
				className="react-select-container"
				classNamePrefix="react-select"
				value={font}
				isClearable={false}
				isSearchable={true}
				getOptionValue={(option) => option.family}
				getOptionLabel={(option) => option.family}
				name="color"
				options={Fonts}
				onChange={onChange}
			/>
		</div>
	);
});

interface IFontVariantSelectControl {
	value: string;
	label?: string;
	font: Font;
	name: string;
	onChange: (name: string, value: any) => void;
}

export const FontVariantSelectControl = observer((props: IFontVariantSelectControl) => {
	const [value, setValue] = useState(props.value);
	const [variants, setVariants] = useState(props.font.variants);

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	useEffect(() => {
		setVariants(props.font.variants);
	}, [props.font.variants]);

	const onChange = (event: any) => {
		setValue(event.target.value);
		props.onChange(event.target.name, event.target.value);
	};

	const id = props.name;
	return (
		<div className="form-control">
			{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
			<div className="select">
				<select onChange={onChange} id={id} value={value} name={props.name}>
					{variants.map((opt) => {
						return (
							<option key={opt.id} value={opt.id}>
								{t('fonts:' + opt.id)}
							</option>
						);
					})}
				</select>
			</div>
		</div>
	);
});

interface IGoogleFontSelector {
	fontFamily: string;
	fontVariant: string;
	onChange: (name: string, val: any) => void;
}

export const GoogleFontSelector = observer((props: IGoogleFontSelector) => {
	const [fontFamily, setFontFamily] = useState<string>('Roboto');
	const [font, setFont] = useState<Font>(Fonts[0]);
	const [fontVariant, setFontVariant] = useState<string>(Fonts[0].defaultVariant!.id);

	const loadFont = useCallback(
		(fontFamily: string, variant: string) => {
			let _font = Fonts.find((i) => i.family === fontFamily)!;
			if (!_font) {
				_font = Fonts.find((i) => i.family === 'Roboto')!;
			}
			const hasVariant = _font.variants.find((v) => v.id === variant);
			if (!hasVariant) {
				// fallback to default variant
				const defaultVariant = _font!.defaultVariant!.id;
				setFontVariant(defaultVariant);
				props.onChange('fontVariant', defaultVariant);
			}
			setFont(_font);
			new Promise((resolve) => {
				WebFont.load({
					google: {
						families: [`${_font.family}:${_font.currentVariant.id}`],
					},
					active: () => {
						resolve(undefined);
					},
				});
			});
		},
		[props],
	);
	useEffect(() => {
		// let isMounted = true; // note this flag denote mount status
		setFontFamily(props.fontFamily);
		setFontVariant(props.fontVariant);
		loadFont(props.fontFamily, props.fontVariant);
		// return props.opts.observe_((x: any) => {
		// 	setFontFamily(props.opts.get('fontFamily'));
		// 	setFontVariant(props.opts.get('fontVariant'));
		// });
	}, [props.fontFamily, props.fontVariant, loadFont]);

	const onFamiliyChange = async (name: string, val: any) => {
		loadFont(val, fontVariant);
		props.onChange('fontFamily', val);
	};
	const onVariantChange = async (name: string, val: any) => {
		await new Promise((resolve) => {
			const variant = new FontVariant(val);
			WebFont.load({
				google: {
					families: [`${font.family}:${variant.id}`],
				},
				fontactive: () => {
					resolve(undefined);
				},
			});
		});
		props.onChange('fontVariant', val);
	};

	return (
		<>
			<FontFamiliySelectControl name="fontFamily" label={t('components:uie.font')} value={fontFamily} onChange={onFamiliyChange} />
			<FontVariantSelectControl name="fontVariant" label={t('components:uie.fontVariant')} font={font} value={fontVariant} onChange={onVariantChange} />
		</>
	);
});
