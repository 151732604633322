import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import SideMenu from 'app/components/SideMenu';
import BaseContainer from 'app/containers/BaseContainer';
import MainTitle from 'app/components/common/MainTitle';
import { Image } from 'app/components/Image';
import { observer } from 'mobx-react';
import { NavLink, useHistory } from 'react-router-dom';
import routes from 'routes';
import { GroupNewModal } from 'app/components/Group/GroupNewModal';
import { GroupModel } from 'shared-puredio';
import t from '../common/translate';
import { WelcomeModal } from 'app/components/WelcomeModal';

const HomeContainer: React.FC<IProps> = observer(() => {
	const store = useStore();
	const history = useHistory();
	const groups = store.groupStore.list;
	const user = store.authStore.current;

	// redirect to groups container
	history.replace(routes.LISTGROUPS.getPath());

	const modalId = store.uiStore.modalIds.groupNew;
	const showNewGroupModal = () => {
		store.uiStore.showModal(modalId);
	};
	const onNewGroupDone = (g: GroupModel) => {
		store.uiStore.hideModal(modalId);
		history.push(routes.NEWPROJECT.getPath(g.id));
	};

	const welcomeModalId = store.uiStore.modalIds.welcomeMessage;
	const hideWelcomeModal = () => {
		if (!user) {
			return;
		}

		user.betaMessageSeen = true;
		store.authStore.updateUser(user.id!, user).then(() => {
			store.uiStore.hideModal(store.uiStore.modalIds.welcomeMessage);
		});
	};

	useEffect(() => {
		if (user && !user.betaMessageSeen) {
			store.uiStore.showModal(store.uiStore.modalIds.welcomeMessage);
		}
	}, [store, user]);

	return (
		<BaseContainer menu={<SideMenu />} navbar={<MainTitle title={t('nav:home')} />}>
			<div className="dashboard">
				<section className="section is-small">
					<div className="banner is-green has-image text-grey">
						<div className="content">
							<h3 className="title is-3 text-green">{t('general:home.welcome')}</h3>
							<p>{t('general:home.intro')}</p>
							<div className="button-container">
								<NavLink className="button is-green is-large" to={routes.CONTACT.getPath()}>
									{t('general:home.sendfeedback')}
								</NavLink>
							</div>
						</div>
						<div className="image-container">
							<Image src="selfie-couple.svg" />
						</div>
					</div>
				</section>

				<section className="section quick-start">
					<div className="content">
						<h3 className="title is-5">{t('general:home.quickstart')}</h3>
						<div className="quick-start-buttons">
							<button type="button" className="button is-giant is-primary" onClick={showNewGroupModal}>
								{groups.length === 0 ? <>{t('general:home.firstpodcast')}</> : <>{t('general:home.newpodcast')}</>}
							</button>
						</div>
					</div>
				</section>
				<GroupNewModal onDone={onNewGroupDone} modalId={modalId} />
			</div>
			<WelcomeModal onDone={hideWelcomeModal} modalId={welcomeModalId} />
		</BaseContainer>
	);
});

export default HomeContainer;
