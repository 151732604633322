import { YesNo } from 'app/components/common/YesNo';
import { Table } from 'app/components/Table/table';
import { TableModel, TableRow } from 'app/components/Table/table.model';
import { useStore } from 'app/context';
import { AdminMailVm } from 'app/stores/admin.mails.store';
import { formatDate } from 'app/utils';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminMailsList: React.FC = observer(() => {
	const history = useHistory();
	const { adminMailsStore } = useStore();

	const tm = useMemo(() => {
		const viewDoc = (doc: AdminMailVm) => {
			runInAction(() => {
				history.push(routes.ADMINMAILS.getPath(doc.mail.id));
			});
		};

		const tm = new TableModel<AdminMailVm, string>();
		let cols = [
			{
				label: 'User',
				path: 'user.fullName',
				sortBy: 'user.lastName',
			},
			{
				label: 'to',
				path: 'mail.to',
				sortBy: 'mail.to',
			},
			{
				label: 'Delivered',
				path: 'mail.delivered',
				render: (row: AdminMailVm) => {
					return <YesNo value={row.mail.delivered} />;
				},
			},
			{
				label: 'Subject',
				path: 'mail.message.subject',
			},
			{
				label: 'Created',
				path: 'mail.created',
				sortBy: 'mail.createdTime',
				render: (row: AdminMailVm) => {
					return <>{row && row.mail.created && <>{formatDate(row.mail.created).toString()}</>}</>;
				},
			},
		];

		tm.onRowClick = (row: TableRow<AdminMailVm>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols as any);
		tm.sortBy = 'mail.createdTime';
		tm.sortAsc = false;
		tm.idProperty = 'fakeId';
		tm.idType = 'string';

		tm.addTextFilter('Name, Email or Id', 'user.nameAndEmailAndId');
		tm.addTextFilter('Subject', 'mail.subject');

		return tm;
	}, [history]);

	autorun(() => {
		tm.setData(adminMailsStore.items);
	});

	useEffect(() => {
		adminMailsStore.load();
	}, [adminMailsStore]);

	return (
		<>
			<div className="section">
				<div className="section-title is-size-4"> Mails ({adminMailsStore.items.length})</div>
			</div>
			<div className="section">
				<Table tm={tm}></Table>
			</div>
		</>
	);
});
