import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { Modal } from '../common/Modal';
import { AudioLayerUpload } from './AudioLayer/AudioLayerUpload';
import t from 'app/common/translate';
import { Button } from '../common/Button';
import { useStore } from 'app/context';

interface IProjectEditModal {
	project: ProjectVM;
	modalId: string;
}

export const ProjectAudioSettingsModal = observer((props: IProjectEditModal) => {
	const project = props.project;
	const store = useStore();
	const onClose = () => {
		store.uiStore.hideModal(props.modalId);
	};
	return (
		<Modal title={t('general:project.audiofile')} size="medium" modalId={props.modalId}>
			<AudioLayerUpload projectVM={project} />
			<Button className="button" onClick={onClose}>
				{t('common:close')}
			</Button>
		</Modal>
	);
});
