import React, { useEffect, useState } from 'react';
import BaseContainer from '../BaseContainer';
import { RouteComponentProps } from 'react-router';
import { useStore } from 'app/context';
import { ResetPasswordForm } from 'app/components/auth/ResetPasswordForm';

export const ResetPasswordContainer: React.FC<RouteComponentProps> = (props) => {
	const [, setError] = useState(false);
	const [, setLoading] = useState(true);
	const [, setSuccess] = useState(false);

	const id = (props.match.params as any).id;
	const { computeApi } = useStore();
	useEffect(() => {
		computeApi
			.verifyEmail(id)
			.then(() => {
				setLoading(false);
				setSuccess(true);
			})
			.catch(() => {
				setError(true);
				setLoading(false);
			});
	}, [id, computeApi]);

	return (
		<BaseContainer hideBrand={true} classModifier="is-auth">
			<ResetPasswordForm />
		</BaseContainer>
	);
};
