import { ProjectModel, VideoFormats, TemplateManagers } from 'shared-puredio';
import { AxiosInstance } from 'axios';
import { firestore } from './firebase-init';
import { BaseApi, IStoreScope } from './base.api';
import ComputeApi from './compute.api';
import { runInAction } from 'mobx';
import { Timestamp } from '@google-cloud/firestore';

export class TemplateApi extends BaseApi<ProjectModel> {
	protected readonly axios: AxiosInstance;
	protected readonly computeApi: ComputeApi;
	constructor(axios: AxiosInstance, computeApi: ComputeApi) {
		super();
		this.collection = 'projects';
		this.needsUserId = false;
		this.axios = axios;
		this.computeApi = computeApi;
	}

	async liteLoad() {
		this.setIsBusy(true);
		const res = await this.computeApi.getTemplates();
		runInAction(() => {
			this.list.replace(
				res.data.map((d: any) => {
					if (d.created && d.created._seconds) {
						d.created = new Timestamp(d.created._seconds, d.created._nanoseconds);
					}
					if (d.modified && d.modified._seconds) {
						d.modified = new Timestamp(d.modified._seconds, d.modified._nanoseconds);
					}
					return new ProjectModel(d);
				}),
			);
			console.log('loaded templates', this.list.length);
			this.setIsBusy(false);
		});
	}

	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		const ref = firestore.collection(this.collection);
		// return ref.where('_projectTemplate.published', '==', true);
		return ref.where('userId', 'in', TemplateManagers).where('_projectTemplate.published', '==', true);
	}
	createNewInstance(data?: any): ProjectModel {
		if (data) {
			return new ProjectModel(data, []);
		}
		// @ts-ignore
		const m = new ProjectModel(
			{
				id: 'test',
				created: new Date(),
				modified: new Date(),
				title: 'EMPTY',
				groupId: 'EMPTY',
				userId: '',
				start: 0,
				templateId: 'test',
				fmtId: VideoFormats.square.id,
				audioLayer: { key: 'audio' },
				layers: [],
			},
			[],
		);
		return m;
	}
}
