import { toJS } from 'mobx';
import { Path } from 'paper';
import BaseAudiogram from './BaseAudiogram';

export default class WaveAnimation extends BaseAudiogram {
    public initAnimation = () => {
        this.toAverage = 5;
    };

    public drawAudiogram(points) {
        this.group.removeChildren();

        const color = this.color || '#FFFFFF';
        const centerX = this.anim.fmt.w / 2;
        const centerY = this.anim.fmt.h / 2;
        const amplitudeModifier = 0.05;
        const amplitude = this.calc({ v: this.amplitude * amplitudeModifier, d: 'h' });
        const width = this.anim.fmt.w;
        const strokeWidth = this.calc({ v: 0.007, d: 'w' });

        for (let l = 1; l < points.length; l++) {
            let yVal = 0;
            for (let x = 0; x < points[l].length; x++) {
                yVal += points[l][x];
            }

            const verticalSwitch = l % 2 == 0 ? 1 : -1; // reverses every second value
            yVal *= verticalSwitch;
            yVal *= amplitude;

            const path = new Path.Arc({
                from: [0, centerY],
                through: [centerX, centerY + yVal],
                to: [width, centerY],
                strokeColor: color,
                strokeWidth,
                opacity: 0.3,
            });

            this.group.addChild(path);
        }
    }
}
