import { computed, reaction } from 'mobx';
import { Shape, Point, Size, Color, Group } from 'paper';
import { LayerType } from '../..';
import { BaseAnimation } from '../BaseAnimation';

export default class SolidBackground extends BaseAnimation {
    public layerType: LayerType = LayerType.background;
    public supportsDisabling = false;
    isLayerTransformable = false;
    animationEnabled: boolean = false;

    public getDefaultOpts = (ftmId: string) => {
        return {
            color: '#818CA1',
        };
    };

    @computed
    get color() {
        return this.anim.animationLayer.opts.color;
    }

    public create = async (project: paper.Project) => {
        let bg = new Shape.Rectangle(new Point(0, 0), new Size(this.anim.fmt.w, this.anim.fmt.h));
        bg.fillColor = new Color(this.color);
        bg.pivot = bg.parentToLocal(bg.bounds.topLeft);

        this.group = new Group([bg]);

        const disp = reaction(
            () => this.color,
            () => {
                bg.fillColor = new Color(this.color);
            },
        );
        this.disposers.push(disp);

        return bg;
    };
}
