import { BaseAnimation } from './../animations/BaseAnimation';
import { AnimationTypes } from './../animations/AnimationTypes';
import { IVideoFormat } from './../models/video-formats';
import { LayerModel } from '..';
import { BaseAnimationOptions } from './LayerAnimationOptions';
import { IObservableArray, makeObservable, observable } from 'mobx';

export interface IAnimationOptions {
    userId: string;
    totalDuration: number;
    startAt: number;
    endAt: number;
    fmt: IVideoFormat;
    audioLayer: LayerModel;
    animationLayers: LayerModel[];
}

export class AnimationOptions {
    animations: IObservableArray<BaseAnimation>;
    totalDuration: number;
    userId: string;
    startAt: number;
    endAt: number;
    fmt: IVideoFormat;
    @observable
    audioLayer: LayerModel;
    layers: LayerModel[];
    // assetResolver: IAssetResolver;
    constructor(opts: IAnimationOptions) {
        makeObservable(this);
        this.totalDuration = opts.totalDuration;
        this.startAt = opts.startAt;
        this.userId = opts.userId;
        this.endAt = opts.endAt;
        this.fmt = opts.fmt;
        this.animations = observable([]);
        this.audioLayer = opts.audioLayer;
        const anims = [];
        opts.animationLayers.forEach((animLayer) => {
            anims.push(this.getBaseAnimation(animLayer));
        });
        this.animations.replace(anims.sort((a, b) => a.sort - b.sort));
    }

    getBaseAnimation(layer: LayerModel) {
        const c = AnimationTypes[layer.animClass];
        if (c === undefined) {
            throw `Animation of type ${layer.animClass} not found. Pls check `;
        }
        const ao = new BaseAnimationOptions({
            key: layer.key,
            userId: this.userId,
            startAt: this.startAt,
            endAt: this.endAt,
            totalDuration: this.totalDuration,
            fmt: this.fmt,
            audioLayer: this.audioLayer,
            animationLayer: layer,
        });
        const ba = new c(ao) as BaseAnimation;
        return ba;
    }
}
