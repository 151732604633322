import { Table } from 'app/components/Table/table';
import { TableModel, TableRow } from 'app/components/Table/table.model';
import { useStore } from 'app/context';
import { AdminFileVm } from 'app/stores/admin.files.store';
import { formatDate, formatTime, humanFileSize } from 'app/utils';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminFilesList: React.FC = observer(() => {
	const history = useHistory();
	const { adminFilesStore } = useStore();

	const tm = useMemo(() => {
		const viewDoc = (doc: AdminFileVm) => {
			runInAction(() => {
				history.push(routes.ADMINFILES.getPath(doc.file.id));
			});
		};

		const tm = new TableModel<AdminFileVm, string>();
		let cols = [
			{
				label: 'User',
				path: 'user.fullName',
				sortBy: 'user.lastName',
			},
			{
				label: 'Created',
				path: 'project.created',
				sortBy: 'project.createdTime',
				render: (row: AdminFileVm) => {
					return <>{row && row.file.created && <>{formatDate(row.file.created).toString()}</>}</>;
				},
			},
			{
				label: 'type',
				path: 'file.fileType',
			},
			{
				label: 'size',
				path: 'file.metadata.size',
				render: (row: AdminFileVm) => {
					return <>{row && row.file.metadata && <>{humanFileSize(row.file.metadata.size).toString()}</>}</>;
				},
			},
			{
				label: 'duration',
				path: 'file.audioDuration',
				render: (row: AdminFileVm) => {
					return <>{row && row.file.audioDuration > 0 && <>{formatTime(row.file.audioDuration).toString()}</>}</>;
				},
			},
			{
				label: 'Name',
				path: 'file.originalFileName',
			},
		];

		tm.onRowClick = (row: TableRow<AdminFileVm>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols as any);
		tm.sortBy = 'project.createdTime';
		tm.sortAsc = false;
		tm.idProperty = 'fakeId';
		tm.idType = 'string';

		tm.addTextFilter('Type (audio or image)', 'file.fileType');
		tm.addTextFilter('Name, Email or Id', 'user.nameAndEmailAndId');

		// tm.setSortBy('project.created');

		return tm;
	}, [history]);

	autorun(() => {
		tm.setData(adminFilesStore.items);
	});

	useEffect(() => {
		adminFilesStore.load();
	}, [adminFilesStore]);

	return (
		<>
			<div className="section">
				<div className="section-title is-size-4">Files ({adminFilesStore.items.length}) aka User Uploads</div>
			</div>
			<div className="section">
				<Table tm={tm}></Table>
			</div>
		</>
	);
});
