import { watermark_landscape, watermark_portrait, watermark_square } from './watermarks';

class Icons {
    public icons = {
        microphone: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M160 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S64 42.98 64 96v160c0 53.02 42.98 96 96 96zM96 96c0-35.29 28.71-64 64-64s64 28.71 64 64v160c0 35.29-28.71 64-64 64s-64-28.71-64-64V96zm216 96h-16c-4.42 0-8 3.58-8 8v56c0 73.46-62.2 132.68-136.73 127.71C83.3 379.18 32 319.61 32 251.49V200c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v50.34c0 83.39 61.65 156.12 144 164.43V480H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h176c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-72v-65.01C256.71 406.9 320 338.8 320 256v-56c0-4.42-3.58-8-8-8z"/></svg>`,
        defaultImage: `<svg width="310" height="310" viewBox="0 0 310 310" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.5"><path d="M305 20C305 16.0218 303.42 12.2064 300.607 9.3934C297.794 6.58035 293.978 5 290 5H20C16.0218 5 12.2064 6.58035 9.3934 9.3934C6.58035 12.2064 5 16.0218 5 20V290C5 293.978 6.58035 297.794 9.3934 300.607C12.2064 303.42 16.0218 305 20 305H290C293.978 305 297.794 303.42 300.607 300.607C303.42 297.794 305 293.978 305 290V20Z" stroke="white" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M267.5 245L227.645 138.665C227.157 137.375 226.323 136.244 225.234 135.398C224.145 134.551 222.844 134.021 221.473 133.866C220.102 133.711 218.716 133.937 217.465 134.519C216.215 135.101 215.149 136.017 214.385 137.165L162.5 215L131.765 178.115C130.998 177.195 130.023 176.472 128.919 176.006C127.816 175.541 126.617 175.346 125.423 175.438C124.23 175.531 123.075 175.908 122.057 176.538C121.038 177.168 120.186 178.033 119.57 179.06L80 245" stroke="white" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M102.5 109.956C114.926 109.956 125 99.8825 125 87.4561C125 75.0296 114.926 64.9561 102.5 64.9561C90.0736 64.9561 80 75.0296 80 87.4561C80 99.8825 90.0736 109.956 102.5 109.956Z" stroke="white" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M305 244.956H5" stroke="white" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/></g></svg>`,
        watermark_landscape: watermark_landscape,
        watermark_square: watermark_square,
        watermark_portrait: watermark_portrait,
    };

    public getIcon = (name: string) => {
        return this.icons[name];
    };
}

const icons = new Icons();
export default icons;
