import * as React from 'react';

interface ITitleProps {
	title: string;
}

const MainTitle: React.FC<ITitleProps> = ({ title }) => {
	return (
		<section className="section is-nav">
			<h2 className="title is-main">{title}</h2>
		</section>
	);
};
export default MainTitle;
