import React from 'react';
import { observer } from 'mobx-react';

export const Spinner: React.FC = observer(() => {
	return (
		<div className="spinner">
			<svg viewBox="0 0 50 50">
				<circle className="background" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
				<circle className="progress" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
			</svg>
		</div>
	);
});
