import { BaseAnimation } from '../BaseAnimation';
import { Point, Group } from 'paper';
import { LayerType } from '../..';
import { computed, reaction } from 'mobx';
import { IPoint } from '../utils/ControlGroup';

export default class BaseAudiogram extends BaseAnimation {
    public layerType: LayerType = LayerType.audiogram;

    public clickArea: paper.Shape;

    public toAverage: number = 3;
    public points = [];
    public audioDataOption: string = 'loudness';
    public currentframe: number = 0;

    dbgCreated: Date = new Date();

    @computed
    get color() {
        return this.anim.animationLayer.opts.color;
    }
    @computed
    get amplitude() {
        return this.anim.animationLayer.opts.amplitude;
    }

    public create = async () => {
        // create group before starting anything async,
        // otherwise layer order ist not guranteed!
        this.group = new Group();
        await this.assetResolver.loadAudioData(this.anim.audioLayer, this.anim.startAtFrame, this.anim.endAtFrame);
        // apparently timeline needs at least one object for it to respect timeline.tween*
        this.timeline.to(this.group, {}, 0);
        this.initAnimation();
        this.tick(0);

        let disp = reaction(
            () => [this.color, this.x, this.y, this.width, this.height, this.amplitude],
            () => {
                this.tick(this.currentframe);
            },
            { fireImmediately: true },
        );
        this.disposers.push(disp);

        return this.group;
    };

    public getDefaultOpts = (ftmId: string) => {
        return {
            x: { v: 0.5, d: 'w' } as IPoint,
            y: { v: 0.5, d: 'h' } as IPoint,
            scale: 1,
            rotation: 0,
            amplitude: 0.3,
            color: '#ffffff',
        };
    };

    public initAnimation = () => {
        // init stuff here
    };

    public positionGroup = () => {
        const group = this.group;
        if (!group) return;

        const defaultOpts = this.getDefaultOpts(this.anim.fmt.id);
        let x = this.x || defaultOpts.x;
        let y = this.y || defaultOpts.y;
        const scale = this.scale || defaultOpts.scale;
        const rotation = this.rotation || defaultOpts.rotation;

        if (typeof x !== 'object') x = defaultOpts.x;
        if (typeof y !== 'object') y = defaultOpts.y;

        this.group.position.x = this.calc(x);
        this.group.position.y = this.calc(y);
        this.group.rotation = rotation;
        this.group.applyMatrix = false;
        this.group.scaling = new Point(scale, scale);
    };

    public tick(currentframe) {
        if (!this.assetResolver) {
            debugger;
        }
        this.currentframe = currentframe;
        // if (currentframe % 10 == 0) {
        //     console.log(this.anim.animationLayer.key, this.dbgCreated.getTime(), currentframe);
        // }
        let val = this.assetResolver.getAudioDataForFrame(currentframe + 1, this.audioDataOption) as Float32Array;
        const layer = this.group;

        if (!val || !layer) {
            return;
        }

        const dataPoints = this.getPoints(val);
        this.drawAudiogram(dataPoints);
        this.positionGroup();
        return true;
    }

    public getPoints(loudness) {
        if (!loudness.specific) {
            loudness.specific = new Array(24).fill(0, 0, 24);
        }

        var loudnessArr = Array.from(loudness.specific);
        // remove first and last, since they're mostly noise
        loudnessArr.shift();
        loudnessArr.pop();

        let points = this.points;
        // average out the values to make the animation less jittery
        for (let p = 0; p < loudnessArr.length; p++) {
            const lval = loudnessArr[p];
            if (!points[p]) {
                points[p] = new Array(this.toAverage).fill(0, 0);
            }

            points[p].push(lval);
            if (points[p].length > this.toAverage) {
                points[p].shift();
            }
        }

        return points;
    }

    // public onTimelineProgress = function () {
    //     if (this.isDisposed) {
    //         return;
    //     }
    //     const time = gsap.globalTimeline.time();
    //     const frame = Math.round(time * 30);

    //     this.tick(frame);
    //     return true;
    // };

    public drawAudiogram(points) {
        // audiogram code here
    }
}
