import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';

export const Brand: React.FC = () => {
	return (
		<Link to={routes.LISTGROUPS.getPath()} className="brand">
			<img src={process.env.PUBLIC_URL + '/images/brand-signet.svg'} alt="Puredio" />
		</Link>
	);
};
