import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { SideMenuAdmin } from 'app/components/SideMenuAdmin';
import { NotFound } from 'App';
import { runInAction } from 'mobx';
import { AdminTemplatesList } from 'app/components/admin/projects/AdminTemplatesList';
import { AdminTemplateDetailPanel } from 'app/components/admin/projects/AdminTemplateDetailPanel';

export interface AdminProjectParamTypes {
	id?: string;
}

const AdminTemplatesContainer: React.FC<IProps> = observer(() => {
	const { adminUserStore, adminProjectStore } = useStore();

	const { id } = useParams<AdminProjectParamTypes>();

	useEffect(() => {
		adminProjectStore.load().then(() => {
			if (id) {
				console.log('loading', id);
				adminProjectStore.loadById(id);
			} else {
				runInAction(() => {
					adminProjectStore.current = undefined;
				});
			}
		});
	}, [id, adminProjectStore]);

	return (
		<>
			{adminUserStore.isAdmin && (
				<BaseContainer menu={<SideMenuAdmin />}>
					<AdminTemplatesList />
					{adminProjectStore.current && <AdminTemplateDetailPanel />}
				</BaseContainer>
			)}
			{!adminUserStore.isAdmin && <NotFound />}
		</>
	);
});

export default AdminTemplatesContainer;
