import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export interface IBreadcrumbItem {
	title: string;
	url?: string;
}

interface IBreadcrumbProps {
	crumbs: IBreadcrumbItem[];
}

const Breadcrumb: React.FC<IBreadcrumbProps> = observer((props: IBreadcrumbProps) => {
	return (
		<div className="breadcrumb">
			{props.crumbs.map((crumb, i) => (
				<div key={i} className="breadcrumb-item">
					{crumb.url ? <Link to={crumb.url}>{crumb.title}</Link> : <span>{crumb.title}</span>}
				</div>
			))}
		</div>
	);
});

export default Breadcrumb;
