import { ConvertResult } from './convert-result';

export enum TaskType {
    export = 'export',
    renderAnimationPart = 'renderAnimationPart',
    mergeSplits = 'mergeSplits',

    encodeAudio = 'encodeAudio',
    splitAudio = 'splitAudio',

    triggerTextToSpeech = 'triggerTextToSpeech',
}

export enum TaskStatus {
    created = 'created',
    ignore4test = 'ignore4test',
    processing = 'processing',
    completed = 'completed',
}

export interface IDbExportTask {
    id?: string;
    userId: string;
    taskGroupId: string;
    projectId: string;
    type: TaskType;
    key: string;
    start?: number;
    splitSize?: number;
    duration?: number;
    progress?: number;
    opts?: any;
    status: TaskStatus;
    created: Date;
    completed?: Date;
    result?: ConvertResult;
}
