import * as React from 'react';

interface IIconProps {
	iconName: string;
	faModifier?: string;
}

const Icon: React.FC<IIconProps> = ({ iconName, faModifier }) => {
	const fa = faModifier ? faModifier : 'fa';
	return <i className={`icon ${fa} fa-fw fa-${iconName}`}></i>;
};
export default Icon;
