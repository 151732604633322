import { action, IObservableArray, makeObservable, observable, runInAction, toJS } from 'mobx';
import { firestore, firebaseAuth } from './firebase-init';
import { IEntity } from 'shared-puredio';
const auth = firebaseAuth;

export interface IStoreScope {
	userId: string;
	projectId?: string;
	taskGroupId?: string;
	taskType?: string | string[];
	id?: string;
}

export class BaseApi<TEntity extends IEntity> {
	constructor() {
		makeObservable(this);
	}

	/// OVERRIDE THESE IN IMPLEMENTING CLASS as needed
	_name: string = 'FOOOO';
	collection: string = 'FOOOO';
	createNewInstance(data?: any): TEntity {
		return {} as TEntity;
	}
	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		const ref = firestore.collection(this.collection);
		return ref.where('userId', '==', scope.userId);
	}
	/// OVERRIDE END

	public list: IObservableArray<TEntity> = observable([]);

	needsUserId: boolean = false;

	getCollection() {
		return firestore.collection(this.collection);
	}

	async findById(docId: string): Promise<TEntity | undefined> {
		try {
			const snapShot = await firestore.collection(this.collection).doc(docId).get();
			if (snapShot.exists) {
				return this._map(snapShot);
			}
		} catch (ex) {
			console.log('findById', ex);
			throw ex;
		}
	}
	__handleDateProps(data: any) {
		if (!data) {
			return;
		}
		if (data.created && data.created.toDate) {
			data.created = data.created.toDate();
		}
		if (data.modified && data.modified.toDate) {
			data.modified = data.modified.toDate();
		}
		if (data.completed && data.completed.toDate) {
			data.completed = data.completed.toDate();
		}
	}
	_mapChidlren(m: TEntity) {}
	_map(qs: firebase.firestore.DocumentSnapshot): TEntity {
		const data = qs.data();
		const m = this.createNewInstance(data);
		m.id = qs.id;
		this.__handleDateProps(data);
		// if (data) {
		//     Object.assign(m, data);
		// }
		this._mapChidlren(m);
		return m;
	}

	_handleError(error: any) {
		if (error.response) {
			if (error.response.data) {
				const res = error.response.data;
				if (res.statusCode && res.statusCode === 422) {
					res.message = 'api:' + res.message;
				}
				res.status = error.response.status;
				delete res.statusCode;
				return res;
			}
			console.log(error.response.status);
			console.log(error.response.headers);
			return {
				status: error.response.status,
			};
		} else if (error.request) {
			// The request was made but no response was received
			console.log(error.request);
			return {
				status: error.request.status,
			};
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
			debugger;
			return error.message;
		}
	}

	@action
	async create(data: Partial<TEntity>): Promise<TEntity> {
		let _data: any;
		Object.assign(data, {
			created: new Date(),
			modified: new Date(),
		});

		if (data.serialize) {
			_data = toJS(data.serialize());
			// _data = toJS(_data, { recurseEverything: true });
		} else {
			_data = JSON.parse(JSON.stringify(data));
		}
		// _data = JSON.parse(JSON.stringify(_data));
		delete _data.id;
		if (this.needsUserId && !_data.userId) {
			throw Error('no userId given to entity');
		}
		const documentReference = firestore.collection(this.collection).doc();
		await documentReference.set({ ..._data });
		const m = await this.findById(documentReference.id);
		if (!m) {
			throw new Error('could not return created entity');
		}
		return m;
	}

	async save(docId: string, data: Partial<TEntity>): Promise<TEntity> {
		let _data: any;

		if (data.serialize) {
			_data = data.serialize();
			// _data = toJS(_data, { recurseEverything: true });
		} else {
			_data = data;
		}
		//_data = JSON.parse(JSON.stringify(_data));
		delete _data.id;
		_data.modified = new Date();
		const snapShot = await firestore.collection(this.collection).doc(docId).get();
		await snapShot.ref.set({ ..._data }, { merge: true });
		const m = await this.findById(docId);
		if (!m) {
			throw new Error('could not return saved entity');
		}
		return m;
	}

	async listAll(scope: IStoreScope): Promise<TEntity[]> {
		const query = this.getScopedListQuery(scope);
		try {
			const doc = await query.get();
			if (doc.empty) {
				return [];
			} else {
				return doc.docs.map(this._map.bind(this));
			}
		} catch (ex) {
			throw ex;
		}
	}

	@observable
	isBusy: boolean = false;
	@action
	setIsBusy(v: boolean) {
		this.isBusy = v;
	}

	unsubscribeListListening: any;
	async listenToList(_scope?: Partial<IStoreScope>) {
		if (!auth.currentUser) {
			return;
		}

		let scope: any = { userId: auth.currentUser.uid };
		if (_scope) {
			Object.assign(scope, _scope);
		}
		const self = this;
		const query = this.getScopedListQuery(scope);
		// console.log('listenTo', this.collection, scope);
		self.setIsBusy(false);
		return new Promise((resolve) => {
			try {
				if (this.unsubscribeListListening) {
					console.warn('allready listening to ' + this.collection);
					return;
				}
				this.unsubscribeListListening = query.onSnapshot(
					function (snapshot: any) {
						// console.log('docChanges', self.collection);
						self.setIsBusy(true);

						let added = 0;
						let modified = 0;
						let removed = 0;
						const start = new Date().getTime();
						runInAction(() => {
							snapshot.docChanges().forEach(function (change: any) {
								const d = self._map(change.doc);
								if (change.type === 'added') {
									added++;
									self.list.push(d);
								}
								if (change.type === 'modified') {
									modified++;
									self.list.replace(self.list.map((obj) => (obj.id === d.id ? d : obj)) as TEntity[]);
								}
								if (change.type === 'removed') {
									// remove.push(d);
									const filtered = self.list.filter((x) => d.id !== x.id);
									if (filtered) {
										removed++;
										self.list.replace(filtered);
									}
								}
							});
						});
						const t = new Date().getTime() - start;
						console.debug('docChanges', self.collection, added, modified, removed, t + 'ms');
						self.setIsBusy(false);
					},
					(err: any) => {
						// @ts-ignore
						if (err.code !== 'permission-denied') {
							throw err;
						}
					},
				);
			} catch (ex) {
				resolve(null);
				throw ex;
			}
			resolve(null);
		});
	}

	@action
	stopListeningToList() {
		this.list.replace([]);
		if (this.unsubscribeListListening) {
			this.unsubscribeListListening();
			this.unsubscribeListListening = null;
		}
	}

	// // only used to test permisssion
	async __delete(docId: string) {
		return new Promise((resolve, reject) => {
			firestore
				.collection(this.collection)
				.doc(docId)
				.delete()
				.then(resolve)
				.catch((ex) => {
					console.log('__delete ex', ex);
					reject(ex);
				});
		});
	}

	async __listAll() {
		return new Promise((resolve, reject) => {
			firestore
				.collection(this.collection)
				.get()
				.then((querySnapshot) => {
					const data = querySnapshot.docs.map((doc) => {
						const d = doc.data();
						d.id = doc.id;
						return d;
					});
					resolve(data);
				})
				.catch((ex) => {
					reject(ex);
				});
		});
	}
}
