import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { SideMenuAdmin } from 'app/components/SideMenuAdmin';
import { NotFound } from 'App';
import { AdminTasksList } from 'app/components/admin/tasks/AdminTasksList';
import { runInAction } from 'mobx';
import { useParams } from 'react-router';
import { AdminTaskDetailPanel } from 'app/components/admin/tasks/AdminTaskDetailPanel';

export interface AdminUsersParamTypes {
	id?: string;
}

const AdminTasksContainer: React.FC<IProps> = observer(() => {
	const { adminUserStore, adminTasksStore } = useStore();

	const { id } = useParams<AdminUsersParamTypes>();

	useEffect(() => {
		if (id) {
			adminTasksStore.loadById(id);
		} else {
			runInAction(() => {
				adminTasksStore.current = undefined;
			});
		}
	}, [id, adminTasksStore]);

	return (
		<>
			{adminUserStore.isAdmin && (
				<BaseContainer menu={<SideMenuAdmin />}>
					<AdminTasksList />
					{adminTasksStore.current && <AdminTaskDetailPanel />}
				</BaseContainer>
			)}
			{!adminUserStore.isAdmin && <NotFound />}
		</>
	);
});

export default AdminTasksContainer;
