import { firestore } from './firebase-init';
import './firebase-init';
import { BaseApi, IStoreScope } from './base.api';
import { UserModel } from 'shared-puredio';

export class UserApi extends BaseApi<UserModel> {
	collection: string = 'users';
	_name: string = 'users';
	createNewInstance(data?: any): UserModel {
		const m = new UserModel(data);
		return m;
	}

	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		return firestore.collection(this.collection);
	}
}
