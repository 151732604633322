import { useStore } from 'app/context';
import classNames from 'classnames';
import * as path from 'path';
import React, { useEffect, useState } from 'react';
import { Button } from './Button';
import Icon from './Icon';
import { ImageViewer } from './ImageViewer';
import { IModalButton, Modal } from './Modal';
import t from 'app/common/translate';
import { FileModel } from 'shared-puredio';

interface IFileItemRowProps {
	type: string;
	file: FileModel;
	isSelected: boolean;
	onSelect: (file: FileModel) => void;
	onRemove: (file: FileModel) => void;
}

const FileItemRow: React.FC<IFileItemRowProps> = ({ type, file, isSelected, onSelect, onRemove }) => {
	const fileName = path.basename(file.originalFileName);

	const handleClick = () => {
		onSelect(file);
	};

	const handleRemove = (e: React.MouseEvent) => {
		e.stopPropagation();
		onRemove(file);
	};

	return (
		<tr className={classNames('file-list-item', { 'is-active': isSelected })} onClick={handleClick}>
			<td
				className={classNames({
					'icon-td': type === 'audio',
					'image-td': type === 'image',
					'is-active': isSelected,
				})}
			>
				{type === 'image' && (
					<>
						<ImageViewer isBackground={true} bucketKey={file.thumbKey} />
						{isSelected && (
							<div className="active-icon">
								<Icon iconName="check-circle" />
							</div>
						)}
					</>
				)}
				{type === 'audio' && (isSelected ? <Icon iconName="check-circle" /> : <Icon iconName="file-audio" faModifier="far" />)}
			</td>
			<td>{fileName}</td>
			<td>
				<Button className="button is-icon" onClick={handleRemove}>
					<Icon iconName="trash"></Icon>
				</Button>
			</td>
		</tr>
	);
};

interface IUploadedFilesTableProps {
	fileType: 'audio' | 'image';
	currentKey?: string;
	onSelect: (key: string) => void;
}

const UploadedFilesTable: React.FC<IUploadedFilesTableProps> = ({ fileType, currentKey, onSelect }) => {
	const [files, setFiles] = useState<FileModel[]>([]);
	const [modalContent, setModalContent] = useState<{ title: string; text: string; buttons: IModalButton[] }>();
	const { uiStore, bucketStore, fileStore, projectStore, groupStore, computeApi } = useStore();

	useEffect(() => {
		const loadFiles = async () => {
			let list: FileModel[] = [];
			switch (fileType) {
				case 'audio':
					list = fileStore.listAudio();
					break;
				case 'image':
					list = fileStore.listImages();
					break;
			}
			if (mounted) {
				setFiles(list);
			}
		};
		let mounted = true;

		loadFiles();
		return () => {
			mounted = false;
		};
	}, [fileType, bucketStore, fileStore]);

	const handleSelect = (file: FileModel) => {
		onSelect(file.md5Hash!);
	};

	const handleModalClose = () => {
		setModalContent(undefined);
	};

	const handleRemove = (file: FileModel) => {
		const isInUse = projectStore.isFileInUse(file) || (fileType === 'image' && groupStore.isImageInUse(file));

		const handleRemoveFile = () => {
			computeApi.deleteFile(file.md5Hash!).then((res) => {
				setFiles((state) => state.filter((item) => item.md5Hash !== file.md5Hash));
				handleModalClose();
			});
		};

		if (isInUse) {
			setModalContent({
				title: '',
				text: t('components:upload.cannot.delete'),
				buttons: [
					{
						title: t('common:cancel'),
						className: 'button is-secondary',
						onClick: handleModalClose,
					},
				],
			});
		} else {
			setModalContent({
				title: t('components:upload.delete.title'),
				text: t('components:upload.delete.confirmation'),
				buttons: [
					{
						title: t('common:cancel'),
						className: 'button is-secondary',
						onClick: handleModalClose,
					},
					{
						title: t('common:delete'),
						className: 'button is-primary is-red',
						onClick: handleRemoveFile,
					},
				],
			});
		}

		uiStore.showModal(uiStore.modalIds.deleteFile);
	};

	return (
		<div>
			{files.length > 0 && (
				<table className={`table is-file-list is-${fileType}`}>
					<tbody>
						{files.map((file) => (
							<FileItemRow type={fileType} key={file.md5Hash} isSelected={file.md5Hash === currentKey} file={file} onSelect={handleSelect} onRemove={handleRemove} />
						))}
					</tbody>
				</table>
			)}
			{modalContent && (
				<Modal title={modalContent.title} size="medium" modalId={uiStore.modalIds.deleteFile} buttons={modalContent.buttons} onClose={handleModalClose}>
					{modalContent.text}
				</Modal>
			)}
		</div>
	);
};

export default UploadedFilesTable;
