import { Path } from 'paper';
import BaseAudiogram from './BaseAudiogram';

export default class RoundBars extends BaseAudiogram {
    public drawAudiogram(points) {
        const defaultOpts = this.getDefaultOpts(this.anim.fmt.id);
        const color = this.color || defaultOpts.color;
        const width = this.anim.fmt.w;
        const height = this.anim.fmt.h;

        const centerX = width / 2;
        const centerY = height / 2;

        // calculate the needed geo
        const barSpacing = width / points.length / 2;
        const amplitudeModifier = 0.25;
        const amplitude = this.calc({ v: this.amplitude * amplitudeModifier, d: 'h' });

        const barWidth = barSpacing / 2;
        let barHeight: number;

        const group = this.group;
        group.removeChildren();

        // left side path
        for (let x = 0; x < points.length; x++) {
            barHeight = 0;
            for (let q = 0; q < points[x].length; q++) {
                barHeight += points[x][q];
            }

            barHeight = Math.round((barHeight / this.toAverage) * amplitude);

            // draw left hand bar
            let startX = x * barSpacing + barSpacing;
            let y = centerY - barHeight / 2;

            var pathLeft = new Path.Line({
                from: [startX, y],
                to: [startX, y + barHeight],
                strokeColor: color,
                strokeWidth: barWidth,
                strokeCap: 'round',
            });

            group.addChild(pathLeft);

            // draw right hand bar
            let rightX = this.anim.fmt.w - startX;

            var pathRight = new Path.Line({
                from: [rightX, y],
                to: [rightX, y + barHeight],
                strokeColor: color,
                strokeWidth: barWidth,
                strokeCap: 'round',
            });

            group.addChild(pathRight);
        }
    }
}
