import { ExportTaskModel, TaskType } from 'shared-puredio';
import { IDbExportTask } from 'shared-puredio';
import { BaseApi, IStoreScope } from './base.api';
import { firestore } from './firebase-init';

export class TasksMainApi extends BaseApi<ExportTaskModel> {
	constructor() {
		super();
		this.collection = 'tasks';
		this._name = 'exports_tasks';
		this.needsUserId = true;
	}

	createNewInstance(data: IDbExportTask): ExportTaskModel {
		const m = new ExportTaskModel(data);
		return m;
	}

	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		const ref = firestore.collection(this.collection);
		return ref.where('userId', '==', scope.userId).where('type', '==', TaskType.export);
	}
}
