import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import { GroupModel } from 'shared-puredio';
import { GroupEditForm } from './GroupEditForm';
import { useStore } from 'app/context';
import t from 'app/common/translate';
import routes from 'routes';
import { useHistory } from 'react-router-dom';

interface IGroupEditModalProps {
	modalId: string;
	group: GroupModel;
	onDone: (g: GroupModel) => void;
}

export const GroupEditModal: React.FC<IGroupEditModalProps> = observer((props: IGroupEditModalProps) => {
	const store = useStore();
	const history = useHistory();
	const onDone = (g: GroupModel) => {
		store.uiStore.hideModal(props.modalId);
		props.onDone(g);
	};

	const onDeleted = () => {
		history.push(routes.LISTGROUPS.getPath());
	};

	return (
		<Modal title={t('general:group.edit.title')} size="large" modalId={props.modalId}>
			<GroupEditForm group={props.group} onDone={onDone} onDeleted={onDeleted} />
		</Modal>
	);
});
