import { action, IObservableArray, makeObservable, observable, runInAction, toJS } from 'mobx';
import _ from 'lodash';

export interface ISnapshot {
    key: string;
    navState: ISnapshotNavState;
    time: number;
    data: any;
}
export interface ISnapshotNavState {
    projectId: string;
    panel?: string;
}

export class SnapshotBase {
    constructor() {
        makeObservable(this);
    }
    _snapShotSpeed: number = 2000;

    _currentSnapShot?: ISnapshot;

    @observable
    snapshotKey: string;

    @action
    beginSnapshot(navState: ISnapshotNavState, key?: string) {
        this._currentSnapShot = {
            navState,
            key: key ? key : this.snapshotKey,
            time: new Date().getTime(),
            data: this.getSnapshotData(),
        };
    }

    getSnapshotData(): any {}

    @action
    cancelSnapshot() {
        const current = this._currentSnapShot;
        if (!current) {
            return;
        }
        this.applySnapshot(current.data);
        this._currentSnapShot = undefined;
    }

    @action
    commitSnapshot(snapShotSpeed?: number) {
        const speed = snapShotSpeed ? snapShotSpeed : this._snapShotSpeed;
        const now = new Date().getTime();
        const last = this.history[this.history.length - 1];
        if (last && now - last.time < speed) {
            // console.log(now - last.time, speed);
            // do nothing. we just need the last snapshot
            // console.log('do nothing. we just need the last snapshot', now - last.time);
            runInAction(() => {
                last.time = now;
            });
            return;
        }

        if (!this._currentSnapShot) {
            return;
        }
        const currentData = this.getSnapshotData();
        const d1 = toJS(this._currentSnapShot.data);
        const d2 = toJS(currentData);
        // no history, if data did not change
        if (_.isEqual(d1, d2)) {
            // console.log('no chnage d1', d1);
            // console.log('no chnage d2', d2);
            return;
        }
        // console.log('this.history.lenght', this.history.length);
        // console.log('commit this._currentSnapShot', this._currentSnapShot);
        runInAction(() => {
            this._currentSnapShot.time = now;
            this.history.push(this._currentSnapShot);
            this._currentSnapShot = undefined;
        });
    }

    @observable
    history: IObservableArray<ISnapshot> = observable([]);

    @action
    historyBack(): ISnapshot | undefined {
        if (this.history.length > 0) {
            const d = this.history.pop();
            this.applySnapshot(d.data);
            return d;
        }
    }

    @action
    applySnapshot(s: any) {}
}
