import { observer } from 'mobx-react';
import React from 'react';
import { PaymentPlan, PlanPerk, UserModel } from 'shared-puredio';
import t from '../../common/translate';
import { SvgIcon } from '../common/SvgIcon';

interface IPlanElement {
	plan: PaymentPlan;
	showButtons?: boolean;
	user: UserModel;
}

interface IPerks {
	plan: PaymentPlan;
	showExcluded: boolean;
}
export const Perks = observer((props: IPerks) => {
	const { plan, showExcluded } = props;

	return (
		<div className={`perks-list ${showExcluded ? 'show-excluded' : 'hide-excluded'}`}>
			{plan.perks.map((perk) => (
				<PerkThing key={perk.perk} perk={perk} />
			))}
		</div>
	);
});

interface IPerk {
	perk: PlanPerk;
}
export const PerkThing = observer((props: IPerk) => {
	const icon = props.perk.included ? 'check' : 'times';

	return (
		<div className={`perk ${props.perk.included ? 'is-included' : 'not-included'}`}>
			<SvgIcon iconName={icon} />
			<span>{t(`payment:${props.perk.title}`)}</span>
		</div>
	);
});
