import { BaseAnimation } from '../BaseAnimation';
import { Group, Path, Color, Raster } from 'paper';
import { LayerType } from '../..';
import { computed, reaction } from 'mobx';
import { Point } from 'paper/dist/paper-core';

export default class ImageLayer extends BaseAnimation {
    public layerType: LayerType = LayerType.image;
    animationEnabled: boolean = false;

    public getDefaultOpts = (ftmId: string) => {
        return {
            // image: `public/templatePlaceholders/microphone-bg.png`,
            image: `public/templatePlaceholders/default.img.svg`,
            x: {
                v: 0.5,
                d: 'w',
            },
            y: {
                v: 0.5,
                d: 'h',
            },
            width: {
                v: 0.5,
                d: 'h',
            },
            height: {
                v: 0.5,
                d: 'h',
            },
            scale: 0.5,
            borderWidth: 0.25,
            borderColor: '#000000',
        };
    };

    private getMask(isRound: boolean, x: number, y: number, width: number, height?: number) {
        if (isRound) {
            return new Path.Circle({
                center: [x, y],
                radius: width / 2,
            });
        } else {
            return new Path.Rectangle({
                point: [x - width / 2, y - height / 2],
                size: [width, height],
            });
        }
    }

    @computed
    get isRound() {
        return this.anim.animationLayer.opts.isRound;
    }

    @computed
    get borderColor() {
        return this.anim.animationLayer.opts.borderColor;
    }

    @computed
    get hasBorder() {
        return this.anim.animationLayer.opts.hasBorder;
    }

    @computed
    get borderWidth() {
        return this.anim.animationLayer.opts.borderWidth;
    }

    imageGroup: paper.Group;
    raster: paper.Raster;
    borderPath: paper.Path;

    buildMask() {
        if (this.imageGroup) {
            this.imageGroup.remove();
        }

        const raster = this.raster;
        const templateWidth = this.anim.fmt.w;
        const templateHeight = this.anim.fmt.h;
        const x = this.calc(this.x);
        const y = this.calc(this.y);
        const border = this.calc({ v: this.borderWidth * 0.05, d: 'w' });
        const scale = this.scale || 1;

        const width = this.isRound ? templateWidth : raster.width;
        const height = this.isRound ? templateHeight : raster.height;

        raster.scaling = new Point(1, 1);
        if (this.isRound) {
            raster.scale(Math.max(templateHeight / raster.height, templateWidth / raster.width));
        } else {
            raster.scale(1);
        }

        const imageMask = this.getMask(this.isRound, 0, 0, width, height);
        this.imageGroup = new Group({
            children: [imageMask, this.raster],
            name: 'imageGroup',
            clipped: true,
        });

        let borderPath: paper.Path;
        if (this.hasBorder) {
            borderPath = this.getMask(this.isRound, 0, 0, width, height);
            borderPath.strokeWidth = border;
            borderPath.strokeColor = new Color(this.borderColor) || new Color('#000000');
        }

        this.borderPath = borderPath;

        this.group.removeChildren();
        this.group.addChild(this.imageGroup);
        this.group.addChild(this.borderPath);
        this.group.position.x = x;
        this.group.position.y = y;
        this.group.rotation = this.rotation;
        this.group.scaling = new Point(scale, scale);
    }

    public create = async (project: paper.Project) => {
        this.ready = false;

        if (!this.assetResolver) {
            throw 'ImageLayer needs assetResolver!';
        }

        this.group = new Group();
        this.group.applyMatrix = false;

        const raster = new Raster({ project, crossOrigin: 'anonymous' });
        this.raster = raster;
        this.raster.applyMatrix = false;

        let initialLoad = true;

        await new Promise((resolve) => {
            raster.on('load', () => {
                this.ready = true;
                this.buildMask();
                this.setupControlGroup();

                if (initialLoad) {
                    raster.position.x = 0;
                    raster.position.y = 0;
                    initialLoad = false;
                    resolve(undefined);
                }
            });
            raster.on('error', (err) => {
                // console.error('Could not load image. using fallback ', err);
                this.getImageUrl('public/templatePlaceholders/default.img.svg').then((url) => {
                    raster.source = url;
                    initialLoad = false;
                    resolve(undefined);
                });
            });

            // raster.on('resize', () => {
            //     console.log('image resize');
            // });

            this.loadImage(raster);
        });
        // image change
        let disp = reaction(
            () => {
                return { image: this.image };
            },
            async () => {
                this.loadImage(raster);
            },
            { fireImmediately: false },
        );
        this.disposers.push(disp);

        // mask change: color, border, isRound
        disp = reaction(
            () => [this.borderColor, this.hasBorder, this.borderWidth],
            () => this.buildMask(),
            { fireImmediately: true },
        );
        this.disposers.push(disp);

        // position update
        disp = reaction(
            () => [this.x, this.y, this.scale, this.rotation],
            () => {
                this.buildMask();
            },
            { fireImmediately: true },
        );
        this.disposers.push(disp);

        disp = reaction(
            () => [this.isRound],
            () => {
                this.buildMask();
                this.controlGroup.bind(this);
            },
            { fireImmediately: true },
        );
        this.disposers.push(disp);

        return raster;
    };

    public tick(currentframe) {
        return;
    }
}
