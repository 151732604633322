import { AuthStore } from './auth.store';
import { UserModel } from 'shared-puredio';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { UserApi } from 'app/api/user.api';
import ComputeApi from 'app/api/compute.api';
import Config from 'config';

export class AdminUserVM {
	constructor(user: UserModel) {
		makeObservable(this);
		this.user = user;
	}
	user: UserModel;
	@observable
	groupCount: number = 0;
	@observable
	projectCount: number = 0;
	@observable
	templateCount: number = 0;
	@observable
	fmtCount: number = 0;
	@observable
	durationAvg: number = 0;

	@action
	setStats(s: any) {
		if (!s) {
			return;
		}
		this.groupCount = s.groupCount;
		this.projectCount = s.projectCount;
		this.templateCount = s.templateCount;
		this.fmtCount = s.fmtCount;
		this.durationAvg = s.durationAvg;
	}
}

export class AdminUserStore {
	//extends CRUDSrvStore<UserModel> {
	authStore: AuthStore;
	userApi: UserApi;
	computeApi: ComputeApi;

	constructor(authStore: AuthStore, userApi: UserApi, computeApi: ComputeApi) {
		makeObservable(this);
		this.authStore = authStore;
		this.userApi = userApi;
		this.computeApi = computeApi;
	}

	async loadUserStats() {
		const query = `select userId, count(DISTINCT groupId) as groupCount, count(DISTINCT projectId) as projectCount,
						count(DISTINCT templateId) as templateCount, count(DISTINCT fmtId) as fmtCount, avg(duration) as durationAvg
 						from ${Config.FB_CONFIG.projectId}.puredio.v_tasks_exports_raw
 						group by 1;
 		`;
		return await this.computeApi.biqQuery({ query });
	}

	@observable
	current?: AdminUserVM;

	@action
	setCurrent(u?: AdminUserVM) {
		this.current = u;
	}

	async load() {
		if (this.vmItems.length > 0) {
			return this.vmItems;
		}
		const p = await Promise.all([this.computeApi.getAllUsersForAdmin(), this.loadUserStats()]);
		const res = p[0];
		const stats = p[1];

		const users: UserModel[] = res.data.map((u: any) => new UserModel(u));
		const vms = users.map((u) => {
			const vm = new AdminUserVM(u);
			if (stats && stats.data) {
				const s = stats.data.find((s: any) => s.userId === u.fakeId);
				vm.setStats(s);
			}
			return vm;
		});
		runInAction(() => {
			this.vmItems = vms;
		});
		return this.vmItems;
	}

	@observable
	vmItems: AdminUserVM[] = [];

	@observable
	isBusy: boolean = false;

	@observable
	editDetailsMode: boolean = false;

	@action
	setEditDetailsMode(v: boolean) {
		this.editDetailsMode = v;
	}

	@observable
	editPaymentMode: boolean = false;

	@action
	setEditPaymentMode(v: boolean) {
		this.editPaymentMode = v;
	}

	@computed
	get isAdmin() {
		return this.authStore.current && this.authStore.current.isAdmin;
	}

	async loadById(userId: string) {
		await this.load();
		const u = this.vmItems.find((u) => u.user.fakeId === userId);
		if (u) {
			this.setCurrent(u);
		}
	}

	// @action
	// async saveNew(model: UserModel): Promise<UserModel> {
	// 	debugger;
	// 	this.isBusy = true;
	// 	try {
	// 		const g = await this.service.create(model);
	// 		return g;
	// 	} finally {
	// 		runInAction(() => {
	// 			this.isBusy = false;
	// 		});
	// 	}
	// }

	@action
	async updateAllSubscriptions() {
		await this.computeApi.updateAllSubscriptions();
		runInAction(() => {
			this.vmItems = [];
		});
		await this.load();
	}

	@action
	async ensureAllTasksInExportUsage() {
		await this.computeApi.ensureAllTasksInExportUsage();
		runInAction(() => {
			this.vmItems = [];
		});
		await this.load();
	}

	@action
	async updateUserSubscription(userId: string) {
		await this.computeApi.updateUserSubscription(userId);
		runInAction(() => {
			this.vmItems = [];
		});
		await this.load();
	}
}
