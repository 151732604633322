import { ExportTaskModel } from 'shared-puredio';
import { IDbExportTask } from 'shared-puredio';
import { BaseApi, IStoreScope } from './base.api';
import { firestore } from './firebase-init';

export class AdminTaskApi extends BaseApi<ExportTaskModel> {
	constructor() {
		super();
		this.collection = 'tasks';
		this._name = 'admin_tasks';
		this.needsUserId = true;
	}

	createNewInstance(data: IDbExportTask): ExportTaskModel {
		const m = new ExportTaskModel(data);
		return m;
	}

	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		console.log('admintasks', scope);
		const ref = firestore.collection(this.collection);
		let q: any = ref;
		if (scope.id) {
			q = ref.where('id', '==', scope.id);
		}
		if (scope.userId && scope.userId !== 'userId') {
			q = ref.where('userId', '==', scope.userId);
		}
		if (scope.projectId) {
			q = q.where('projectId', '==', scope.projectId);
		}
		if (scope.taskGroupId) {
			q = q.where('taskGroupId', '==', scope.taskGroupId);
		}
		if (scope.taskType) {
			if (typeof scope.taskType === 'string') {
				q = q.where('type', '==', scope.taskType);
			} else {
				q = q.where('type', 'in', scope.taskType);
			}
		}
		if (!q) {
			throw 'No Scope passed to admin tasks';
		}
		return q;
	}
}
