import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import { observer } from 'mobx-react';
import BaseContainer from '../containers/BaseContainer';
import SideMenu from '../components/SideMenu';
import { useHistory, useParams } from 'react-router';
import t from 'app/common/translate';
import Breadcrumb, { IBreadcrumbItem } from 'app/components/Breadcrumb';
import routes from 'routes';
import { UploadResponse } from 'app/api/bucket.api';
import { ImageFilesList } from 'app/components/Image/ImageFilesList';
import { ImageFileDetail } from 'app/components/Image/ImageFileDetail';
import ImageUploadWidget from 'app/components/common/ImageUploadWidget';

interface IRendersParms {
	id?: string;
}

const ImageLoader = () => {
	const parms = useParams<IRendersParms>();
	const { fileStore } = useStore();

	useEffect(() => {
		fileStore.setSelectedById(parms.id);
	}, [fileStore, parms.id]);

	return <></>;
};

const crumbs: IBreadcrumbItem[] = [
	{
		title: t('nav:imageFiles'),
	},
];

const Navbar = () => {
	const { fileStore, authStore } = useStore();
	const history = useHistory();
	const uploadFolder = authStore.uploadFolder;

	const onUpload = async (res: UploadResponse) => {
		const file = await fileStore.findByMd5Hash(res.md5Hash!);
		if (file) {
			history.push(routes.IMAGFILES.getPath(file.id));
		}
	};
	return (
		<div className="app-navbar">
			<Breadcrumb crumbs={crumbs} />
			<ImageUploadWidget storageFolder={uploadFolder} onChange={onUpload} />
		</div>
	);
};

export const ImageFilesContainer: React.FC<IProps> = observer((props: IProps) => {
	return (
		<BaseContainer menu={<SideMenu />} navbar={<Navbar />}>
			<ImageLoader />
			<ImageFilesList />
			<ImageFileDetail />
		</BaseContainer>
	);
});
