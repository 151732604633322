import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { LayerModel } from 'shared-puredio';
import { ColorPickerControl } from 'app/components/common/ColorPickerControl';
import t from 'app/common/translate';
import { runInAction } from 'mobx';

interface IAnimLayerFormProps {
	title: string;
	projectVM: ProjectVM;
	animLayer: LayerModel;
}

export const LayerFormProgress = observer((props: IAnimLayerFormProps) => {
	const { animLayer, projectVM } = props;

	const onColorChange = (name: string, value: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			if (name === 'color') {
				animLayer.opts.color = value;
			}
			if (name === 'background') {
				animLayer.opts.background = value;
			}
		});
		animLayer.commitSnapshot();
	};

	return (
		<>
			<div className="ui-control-group">
				<ColorPickerControl name={'color'} label={t('components:uie.color')} value={animLayer.opts.color} onChange={onColorChange} />
				<ColorPickerControl name={'background'} label={t('components:uie.background')} value={animLayer.opts.background} onChange={onColorChange} />
			</div>
		</>
	);
});
