import React from 'react';
import { PaymentType, PlanType, UserModel } from 'shared-puredio';
import * as Yup from 'yup';
import t from 'app/common/translate';
import { Formik, Field, Form, FieldConfig } from 'formik';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Button } from 'app/components/common/Button';
import FormField from 'app/components/Form/FormField';

const userEditSchema = Yup.object()
	.shape({
		paymentType: Yup.string().required(t('validation:required.field')).default(''),
		plan: Yup.string().required(t('validation:required.field')).default(''),
		freeUntil: Yup.date().required(t('validation:required.field')).default(new Date()),
	})
	.required();
type UserEdit = Yup.InferType<typeof userEditSchema>;

interface IUserEditProps {
	user: UserModel;
	onDone: (g: UserModel) => void;
}

export const AdminUserPaymentEditForm: React.FC<IUserEditProps> = observer((props: IUserEditProps) => {
	const { adminUserStore } = useStore();
	const user = props.user;

	async function handleSubmit(values: UserEdit) {
		if (values.paymentType === PaymentType.paddle) {
			alert('Cannot set paddle payment type from here. User has to do it himself');
			return;
		}

		if (values.paymentType === PaymentType.invoice) {
			alert('Setting Payment type invoice is not implemented yet!');
			return;
		}

		user.firstName = values.firstName;
		user.lastName = values.lastName;
		user.email = values.email;
		const m = await adminUserStore.computeApi.updateUserPayment(user.fakeId, values.freeUntil, values.plan, values.paymentType);
		props.onDone(m);
	}

	const initialValues = {
		paymentType: user.paymentType,
		plan: user.activePlan,
		freeUntil: user.freeUntil,
	};

	const onCancel = () => {
		props.onDone(user);
	};

	return (
		<>
			<div className="form-group">
				<Formik validationSchema={userEditSchema} initialValues={initialValues} onSubmit={handleSubmit}>
					{({ errors, touched, values }) => {
						return (
							<Form className="group-edit-form">
								<PaymentTypeField name={'paymentType'} />
								<PlanField name={'plan'} />
								{values.plan !== PlanType.starter && values.paymentType === PaymentType.free && (
									<div role="group" className="form-control">
										<FormField className="" label={'freeUntil'}>
											<Field type="date" name="freeUntil" />
										</FormField>
									</div>
								)}

								<div className="actions">
									<Button type="submit" className="button is-primary">
										{t('common:save')}
									</Button>
									<Button type="button" className="button is-secondary" onClick={onCancel}>
										Abbrechen
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</>
	);
});

const PlanField = (props: FieldConfig) => {
	return (
		<div role="group" aria-labelledby="my-radio-group" className="form-control">
			<strong>Plan</strong>
			<div>
				<label>
					<Field type="radio" name={props.name} value={PlanType.starter} />
					{PlanType.starter}
				</label>
			</div>
			<div>
				<label>
					<Field type="radio" name={props.name} value={PlanType.pocaster} />
					{PlanType.pocaster}
				</label>
			</div>
			<div>
				<label>
					<Field type="radio" name={props.name} value={PlanType.studio} />
					{PlanType.studio}
				</label>
			</div>
		</div>
	);
};

const PaymentTypeField = (props: FieldConfig) => {
	return (
		<div role="group" aria-labelledby="my-radio-group" className="form-control">
			<strong>PaymentType</strong>
			<div>
				<label>
					<Field type="radio" name={props.name} value={PaymentType.free} />
					{PaymentType.free}
				</label>
			</div>
			<div>
				<label>
					<Field type="radio" name={props.name} value={PaymentType.invoice} />
					{PaymentType.invoice}
				</label>
			</div>
			<div>
				<label>
					<Field type="radio" name={props.name} value={PaymentType.paddle} />
					{PaymentType.paddle}
				</label>
			</div>
		</div>
	);
};
