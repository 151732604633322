import React, { useState } from 'react';
import { observer } from 'mobx-react';

interface IGroupToggleProps {
	value: any;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
	type?: string;
}

export const GroupToggle = observer((props: IGroupToggleProps) => {
	const { id, name, onChange } = props;
	const [value, setValue] = useState(props.value);
	const htmlId = id ? id : 'checkbox' + name;
	const handleChange = (event: any) => {
		setValue(event.target.checked);
		onChange(event.target.name, event.target.checked);
	};

	return (
		<>
			<label htmlFor={htmlId} className={`group-toggle ${value ? 'is-checked' : 'is-unchecked'}`}>
				<input id={htmlId} name={name} onChange={handleChange} type={'checkbox'} checked={value} />
				<span>{props.name}</span>
			</label>
		</>
	);
});
