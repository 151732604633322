import { useStore } from 'app/context';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { AnimationOptions } from 'shared-puredio';
import { BaseTextLayer } from 'shared-puredio';
import WebFont from 'webfontloader';
import { ProjectUndo } from '../ProjectUndo';
import * as qs from 'qs';
import { useLocation } from 'react-router';

interface IExportViewerProps {
	projectVM: ProjectVM;
}
interface IExportViewerCtrlProps {
	projectVM: ProjectVM;
	audioRef: React.RefObject<HTMLAudioElement>;
	canvasRef: React.RefObject<HTMLCanvasElement>;
}

export const ExportViewer: React.FC<IExportViewerProps> = observer((props: IExportViewerProps) => {
	const store = useStore();

	let previewAreaRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleResize = () => {
			const fmt = props.projectVM.model.fmt;
			const areaRect = previewAreaRef.current?.getBoundingClientRect();
			if (!areaRect || !previewAreaRef || !previewAreaRef.current) {
				return;
			}
			const computedStyle = getComputedStyle(previewAreaRef.current as Element);
			if (!computedStyle) return;

			const paddingLeft = parseFloat(computedStyle.paddingLeft);
			const paddingRight = parseFloat(computedStyle.paddingRight);
			const paddingTop = parseFloat(computedStyle.paddingTop);
			const paddingBottom = parseFloat(computedStyle.paddingBottom);

			const areaWidth = areaRect.width - paddingLeft - paddingRight;
			const areaHeight = areaRect.height - paddingTop - paddingBottom;

			const scaleRatio = Math.min(areaWidth / fmt.w, areaHeight / fmt.h);

			runInAction(() => {
				props.projectVM.setScaleRatio(scaleRatio);
			});
		};

		window.addEventListener('resize', handleResize);

		// @ts-ignore
		const resizeObserver = new ResizeObserver(() => {
			window.dispatchEvent(new Event('resize'));
		});
		resizeObserver.observe(previewAreaRef.current!);

		return () => {
			window.removeEventListener('resize', handleResize);
			resizeObserver.disconnect();
		};
	}, [props, store]);

	const w = props.projectVM.model.fmt.w * props.projectVM.scaleRatio;
	const h = props.projectVM.model.fmt.h * props.projectVM.scaleRatio;
	const canvasCursor = props.projectVM.canvasCursor;

	const cursorStyle = {
		cursor: canvasCursor,
	};

	return (
		<>
			<ProjectUndo project={props.projectVM} />
			<div className={`preview ${canvasCursor}`} ref={previewAreaRef} style={cursorStyle}>
				<canvas width={w} height={h} ref={props.projectVM.canvasRef} data-paper-resize={true} data-paper-hidpi="off" />
				<ExportViewerCtrl {...props} audioRef={store.animatorStore.audioRef} canvasRef={props.projectVM.canvasRef} />
			</div>
		</>
	);
});

export const ExportViewerCtrl: React.FC<IExportViewerCtrlProps> = observer((props: IExportViewerCtrlProps) => {
	const { animatorStore, uiStore } = useStore();
	useEffect(() => {
		const audioLayer = props.projectVM.model.audioLayer;
		if (!audioLayer) {
			return;
		}

		reaction(
			// () => props.projectVM.model.layers.length,
			() => props.projectVM.projectLevelSnapShotApplied,
			async () => {
				animatorStore.dispose();
				console.log('triggered full setup.');

				if (animatorStore.paperVm && animatorStore.paperVm.paperScope) {
					animatorStore.paperVm.paperScope.project.clear();
					animatorStore.paperVm.paperScope.project.remove();
				}

				const duration = audioLayer.duration;
				const fmt = JSON.parse(JSON.stringify(props.projectVM.model.fmt));
				const animationOpts = new AnimationOptions({
					// important: keep the userId from the model. otherwise we fuck things up for the admin
					// userId: authStore.current!.id!,
					userId: props.projectVM.model.userId,
					startAt: 0,
					endAt: duration,
					totalDuration: duration,
					fmt,
					audioLayer,
					animationLayers: props.projectVM.model.animLayers,
				});

				for (const ba of animationOpts.animations) {
					if (ba instanceof BaseTextLayer) {
						const tl = ba as BaseTextLayer;
						const googleFont = tl.googleFont;
						await new Promise((resolve) => {
							WebFont.load({
								google: {
									families: [`${googleFont.family}:${googleFont.currentVariant.id}`],
								},
								fontactive: () => {
									resolve(undefined);
								},
							});
						});
					}
				}
				const audioUrl = await animatorStore.bucketStore.getDownloadUrl(animationOpts.audioLayer.audioFile!.originalKey);
				if (audioUrl) {
					animatorStore.setup(props.audioRef, props.canvasRef, animationOpts, props.projectVM);
				} else {
					// fix to force replacement of audio if it does not exist
					// happens when importing project from prod to dev
					uiStore.showModal(uiStore.modalIds.audioSetting);
					animatorStore.project = props.projectVM;
					animatorStore.project!.currentSidePanel = 'audio';
				}
			},
			{ fireImmediately: true },
		);
	});
	// aka willUnMount
	useEffect(() => {
		return () => {
			animatorStore.stop();
			animatorStore.dispose();
		};
	});

	return (
		<>
			<OpenCropperOnCreate />
		</>
	);
});

export const OpenCropperOnCreate = () => {
	const { animatorStore, uiStore } = useStore();
	const location = useLocation();

	useEffect(() => {
		const query = qs.parse(location.search, { ignoreQueryPrefix: true });
		const disp = reaction(
			() => animatorStore.isBusy,
			() => {
				if (query.openCropper && !animatorStore.isBusy) {
					uiStore.showModal(uiStore.modalIds.projectCropAudio);
				}
			},
			{ fireImmediately: false },
		);

		return () => {
			disp();
		};
	});
	return <></>;
};
