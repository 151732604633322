import { Button } from 'app/components/common/Button';
import Icon from 'app/components/common/Icon';
import InputControl from 'app/components/common/InputControl';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { IElementName } from './AnimLayerEditForm';
import { AnimatedPhrase, LayerAnimatedTextModel } from 'shared-puredio';
import t from 'app/common/translate';
import { stripIllegalChars } from 'app/utils';

interface ISubtitleControl {
	name: string;
	value: AnimatedPhrase[];
	onChange: (el: IElementName, val: any) => void;
}

export const AnimatedTextControl = observer((props: ISubtitleControl) => {
	const animText = useRef<LayerAnimatedTextModel>(new LayerAnimatedTextModel());

	useEffect(() => {
		const phrases = props.value;
		if (!phrases) {
			animText.current.phrases = [];
		} else {
			animText.current.clonePhrases(phrases);
		}
	}, [props.value]);
	const onAdd = () => {
		animText.current.addPhraseAtEnd(t('components:animatedText.defaultText'));
		props.onChange({ name: 'content' }, animText.current.phrases);
		animText.current.setPausesAndBoundries();
	};

	const onRemove = (p: AnimatedPhrase) => {
		animText.current.removePhrase(p);
		props.onChange({ name: 'content' }, animText.current.phrases);
		animText.current.setPausesAndBoundries();
	};

	const onTextChange = (p: AnimatedPhrase) => {
		props.onChange({ name: 'content' }, animText.current.phrases);
		animText.current.setPausesAndBoundries();
	};

	return (
		<div className="animated-text-control">
			<div className="phrases">
				{animText.current.phrases.map((p) => (
					<PhraseItem key={p.id} phrases={animText.current} phrase={p} onAdd={onAdd} onRemove={onRemove} onTextChange={onTextChange} />
				))}
			</div>

			<Button className="button is-primary is-inverted is-block" onClick={onAdd}>
				<Icon iconName={'plus'} faModifier="fal" />
				<span>{t('components:animtext.addText')}</span>
			</Button>
		</div>
	);
});

interface IPhraseItem {
	phrases: LayerAnimatedTextModel;
	phrase: AnimatedPhrase;
	onAdd: (phrase: AnimatedPhrase) => void;
	onRemove: (phrase: AnimatedPhrase) => void;
	onTextChange: (phrase: AnimatedPhrase) => void;
}

const timePickerToSec = (val: string) => {
	const v = val.toString().split(':');
	const m = parseInt(v[0]);
	const s = parseInt(v[1]);
	return m * 60 + s;
};

const PhraseItem = observer((props: IPhraseItem) => {
	const { animatorStore } = useStore();

	const [text, setText] = useState(props.phrase.text);
	const [start, setStart] = useState(props.phrase.startTimePicker);
	const [end, setEnd] = useState(props.phrase.endTimePicker);
	const phrase = props.phrase;
	const phrases = props.phrases;
	const onRemove = () => {
		props.onRemove(phrase);
	};

	useEffect(() => {
		setText(phrase.text);
	}, [phrase.text]);

	const onTextChange = (event: any) => {
		let value = event.target.value;
		// only keep - ascii chars
		value = stripIllegalChars(value);
		runInAction(() => {
			phrase.text = value;
			props.onTextChange(phrase);
			setText(value);
		});
	};

	// const onPauseToText = () => {
	// 	runInAction(() => {
	// 		phrase.isPause = false;
	// 		phrase.text = 'Text';
	// 		props.onTextChange(phrase);
	// 	});
	// };
	const onStartTimeChange = (name: string, val: string) => {
		setStart(val);
		phrases.setStartForPhrase(phrase, timePickerToSec(val));
		setStart(phrase.startTimePicker);
		props.onTextChange(phrase);
	};

	const onEndTimeChange = (name: string, val: string) => {
		setEnd(val);
		phrases.setEndForPhrase(phrase, timePickerToSec(val));
		setEnd(phrase.endTimePicker);
		props.onTextChange(phrase);
	};

	const focusTextArea = (phrase: AnimatedPhrase) => {
		const curr = animatorStore.audioStore.currentTime;
		if (curr < phrase.start || curr > phrase.end) {
			animatorStore.seek(Math.round((phrase.start + phrase.end) / 2));
		}
	};

	runInAction(() => {
		phrase.isActive = animatorStore.audioStore.currentTime > phrase.start && animatorStore.audioStore.currentTime < phrase.end;
	});

	return (
		<>
			<div className={`phrase-item ${phrase.isActive ? 'is-active' : ''} ${phrase.isPause ? 'is-pause' : ''}`}>
				{!phrase.isPause && (
					<>
						<div className="text-area">
							<textarea className="input" name={'text'} onChange={onTextChange} value={text} rows={3} onFocus={() => focusTextArea(phrase)} />
							<Button className="delete-button" onClick={onRemove}>
								<Icon iconName={'trash-alt'} faModifier="fal" />
							</Button>
						</div>
						<div className="time">
							<div className="time-start">
								<InputControl type="time" name={'start'} value={start} onChange={onStartTimeChange} debounceTime={2000} />
							</div>
							<div className="time-end">
								<InputControl type="time" id={phrase.id + 'end'} name={'end'} value={end} onChange={onEndTimeChange} debounceTime={2000} />
							</div>
						</div>
					</>
				)}
				{/* {phrase.isPause && (
					<Button className="pause-add-button" onClick={onPauseToText}>
						<span className="icon-container">
							<Icon iconName={'plus'} />
						</span>
					</Button>
				)} */}
			</div>
		</>
	);
});
