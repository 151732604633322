import React, { useEffect, useState } from 'react';
import { ExportTaskModel } from 'shared-puredio';
import { storage } from '../../api/firebase-init';
import { observer } from 'mobx-react';

interface IVideoPreview {
	mainTask: ExportTaskModel;
}

export const VideoPreview = observer((props: IVideoPreview) => {
	const task = props.mainTask;
	const key = task.result ? task.result.outputKey : undefined;
	const [url, setUrl] = useState<string | undefined>();

	useEffect(() => {
		if (!key) {
			return;
		}
		storage
			.ref(key)
			.getDownloadURL()
			.then((u) => {
				setUrl(u);
			});
	});

	return (
		<>
			{url && (
				<div className="video-container">
					<video src={url} controls={true} />
				</div>
			)}
		</>
	);
});
