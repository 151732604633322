import { Layer } from 'paper';
import { AnimationOptions, BaseAnimation, IAssetResolver, LayerType } from 'shared-puredio';
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { ProjectVM } from './project.vm';
import { rotationCursor, scaleCursor } from 'app/components/Project/Edit/cursors/Cursors';
import { PaperVM } from './paper.vm';

export interface IPaperLayerVMParms {
	paperScope: paper.PaperScope;
	baseAnimation: BaseAnimation;
	assetResolver: IAssetResolver;
	onLayerSelect: (key: string) => void;
	projectVM: ProjectVM;
	anim: AnimationOptions;
	parent: PaperVM;
}

export class PaperLayerVM {
	paperScope: paper.PaperScope;
	disposers: any[] = [];
	assetResolver: IAssetResolver;

	@observable
	baseAnimation: BaseAnimation;

	@observable
	pl: paper.Layer;

	@observable
	projectVM: ProjectVM;

	@observable
	anim: AnimationOptions;

	@observable
	parent: PaperVM;

	@computed
	get key() {
		return this.baseAnimation.anim.key;
	}

	vmOpts: IPaperLayerVMParms;

	constructor(opts: IPaperLayerVMParms) {
		makeObservable(this);
		this.anim = opts.anim;
		this.vmOpts = opts;
		this.paperScope = opts.paperScope;
		this.assetResolver = opts.assetResolver;
		this.baseAnimation = opts.baseAnimation;
		this.parent = opts.parent;
		const layerKey = opts.baseAnimation.anim.animationLayer.key;
		let pl = new Layer({ name: layerKey, index: this.baseAnimation.sort });
		this.projectVM = opts.projectVM;

		this.pl = pl;

		let disp;

		// toggle visibilty
		disp = reaction(
			() => this.baseAnimation.anim.animationLayer.isDisabled,
			() => {
				console.log('layer.vm reaction visibitly', this.pl.name);
				this.pl.visible = !this.baseAnimation.anim.animationLayer.isDisabled;
			},
			{ fireImmediately: false },
		);
		this.disposers.push(disp);

		// update animclass: replace baseanimation for paper layer
		disp = reaction(
			() => this.baseAnimation.anim.animationLayer.animClass,
			async () => {
				console.log('--- layer ANIMCLASS changed', this.pl.name, this.baseAnimation.anim.animationLayer.animClass, this.baseAnimation.anim.animationLayer.appliedSnapShot);
				this.pl.activate();
				this.pl.removeChildren();

				const replacement = this.anim.getBaseAnimation(this.baseAnimation.anim.animationLayer);
				if (replacement) {
					// stop the animation if running
					// otherwise, the new created timeline will be out of sync
					const t = await this.parent.onStop();
					this.baseAnimation.dispose();
					runInAction(() => {
						this.baseAnimation = replacement;
					});
					await this.updateLayer();
					if (t) {
						// restart the anmiation if it was running
						this.parent.onAnimate(t);
					}
				} else {
					console.warn('no replacment found for layer ' + this.baseAnimation.layerType + this.baseAnimation.sort);
				}
			},
			{
				fireImmediately: false,
				delay: 250,
			},
		);
		this.disposers.push(disp);

		// undo / appliedSnapshot
		disp = reaction(
			() => {
				return {
					y: this.baseAnimation.anim.animationLayer.appliedSnapShot, // aka undo
				};
			},
			async () => {
				console.log('--- layer Snapshot applied', this.pl.name, this.baseAnimation.anim.animationLayer.animClass, this.baseAnimation.anim.animationLayer.appliedSnapShot);
				await this.updateLayer();
			},
			{
				fireImmediately: false,
				delay: 100,
			},
		);
		this.disposers.push(disp);
	}
	dispose() {
		this.baseAnimation.dispose();
		for (const disp of this.disposers) {
			disp();
		}
		this.disposers = [];
	}

	@computed
	get isAudioRelatedLayer() {
		if (this.baseAnimation.layerType === LayerType.progress || this.baseAnimation.layerType === LayerType.audiogram) {
			return true;
		}
		return false;
	}

	async updateLayer() {
		const pp = this.paperScope!.project;
		if (!pp) {
			console.warn(this.pl.name, 'no project active project on update. cannot update layer');
			return;
		}

		this.baseAnimation.optsUpdate = (vals: any) => {
			const model = this.projectVM.model;
			const layer = model.layers.find((l) => l.key === this.baseAnimation.anim.key);
			if (layer) {
				runInAction(() => {
					layer.beginSnapshot(this.projectVM.navState);
					layer.setOpts(vals);
					layer.commitSnapshot();
				});
			}
		};
		this.baseAnimation.onLayerSelect = () => {
			this.vmOpts.onLayerSelect(this.pl.name);
		};
		this.baseAnimation.setCursor = (cursorClass: string, rotation?: number) => {
			runInAction(() => {
				switch (cursorClass) {
					case 'rotation':
						this.projectVM.canvasCursor = rotationCursor(rotation);
						break;
					case 'scale':
						this.projectVM.canvasCursor = scaleCursor(rotation);
						break;
					default:
						this.projectVM.canvasCursor = cursorClass;
						break;
				}
			});
		};
		this.baseAnimation.resetCursor = () => {
			runInAction(() => {
				this.projectVM.canvasCursor = '';
			});
		};

		this.pl.visible = !this.baseAnimation.anim.animationLayer.isDisabled;
		this.pl.removeChildren();
		this.pl.activate();
		this.baseAnimation.setAssetResolver(this.assetResolver);
		await this.baseAnimation.create(pp);
		this.baseAnimation.animate();
		this.baseAnimation.setupControlGroup();
	}
}
