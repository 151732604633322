import React from 'react';
import BaseContainer from './BaseContainer';
import AccountSettingsForm from 'app/components/auth/AccountSettingsForm';
import SideMenu from 'app/components/SideMenu';
import t from 'app/common/translate';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import routes from 'routes';
import { Payment } from 'app/components/payment/Payment';
import { useStore } from 'app/context';
import Breadcrumb, { IBreadcrumbItem } from 'app/components/Breadcrumb';
import { NavLink } from 'react-router-dom';
import { PaymentHistory } from 'app/components/payment/PaymentHistory';

export interface AdminUsersParamTypes {
	sub?: string;
}

interface IButt {
	title: string;
	sub?: string;
}

const buttons: IButt[] = [
	{
		title: t('auth:settings'),
		sub: undefined,
	},
	{
		title: t('auth:payment'),
		sub: 'payment',
	},
	{
		title: t('auth:invoices'),
		sub: 'invoices',
	},
];

const AccountSettingsNavButton = observer((props: IButt) => {
	const b = props;

	return (
		<NavLink exact={true} className="nav-item" activeClassName="is-active" to={routes.ACCOUNTSETTINGS.getPath(b.sub)}>
			{b.title}
		</NavLink>
	);
});

const AccountSettingsNav = observer(() => {
	return (
		<>
			<h2 className="title is-5">{t('auth:account')}</h2>
			<nav>
				{buttons.map((b) => (
					<AccountSettingsNavButton key={b.title} {...b} />
				))}
			</nav>
		</>
	);
});

export const AccountSettingsContainer = observer(() => {
	const { uiStore, authStore } = useStore();
	const { sub } = useParams<AdminUsersParamTypes>();

	const title = sub ? t(`auth:${sub}`) : t('auth:settings');

	const crumbs: IBreadcrumbItem[] = [
		{
			title,
		},
	];

	const AccountNavbar = () => {
		return (
			<div className="app-navbar">
				<Breadcrumb crumbs={crumbs} />
			</div>
		);
	};

	return (
		<BaseContainer menu={<SideMenu />} subMenu={<AccountSettingsNav />} navbar={<AccountNavbar />}>
			<>
				{uiStore.showPayments && (
					<>
						{sub === 'payment' && <Payment user={authStore.current!} />}
						{sub === 'invoices' && <PaymentHistory />}
					</>
				)}
				{sub === undefined && <AccountSettingsForm />}
			</>
		</BaseContainer>
	);
});
