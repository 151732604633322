import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import t from '../../common/translate';
import { useStore } from 'app/context';
import { PaymentPlan } from 'shared-puredio';
import { Perks } from './Perks';
import { PaddleInfo } from './PaddleInfo';
import { notify } from 'app/common/notify';
import { Trans } from 'react-i18next';

interface IChangeSubscriptionModal {
	onDone: () => void;
	modalId: string;
	newPlan: PaymentPlan;
	isDowngrade: boolean;
}

export const PaymentChangeSubscriptionModal: React.FC<IChangeSubscriptionModal> = observer((props: IChangeSubscriptionModal) => {
	const { paddleStore } = useStore();
	const { isDowngrade, newPlan } = props;

	const user = paddleStore.authStore.current!;
	const activeSubscription = user.activeSubscription;
	if (!activeSubscription) {
		return <></>;
	}

	const currency = activeSubscription.last_payment.currency;

	const onApprove = async () => {
		const res = await paddleStore.changeUserSubscription(props.newPlan.monthlyPlanId, isDowngrade);
		if (!res.error) {
			notify('Wir haben Deinen Auftrag entgegengenommen. Du solltest in Kürze eine Bestätigungsmail erhalten.', 'info');
		} else {
			notify('Es ist ein Fehler aufgetreten. Bitte setze dich mit dem Support in Verbindung', 'error');
		}
		props.onDone();
	};

	return (
		<Modal title={''} size="medium" modalId={props.modalId} onClose={props.onDone}>
			<div className="content ">
				<h2 className="title is-3 align-center">{isDowngrade ? <>Downgrade auf {t(newPlan.title)}</> : <>Upgrade auf {t(newPlan.title)}</>}</h2>

				<div className="message pad-top-3rem pad-bottom-3rem">
					<Trans i18nKey={isDowngrade ? 'payment:message.downgrade.fyi' : 'payment:message.upgrade.fyi'} values={{ plan: t(newPlan.title) }}>
						Bitte bestätige...
						<strong>PLAN</strong>
					</Trans>

					{!isDowngrade && (
						<>
							<strong>{t('payment:plan.yourBenefits')}</strong>
							<Perks plan={newPlan} showExcluded={false} />
						</>
					)}
				</div>

				<div className="box has-light-bg has-text-centered">
					<h3 className="title is-5">{t(newPlan.title)}</h3>
					<strong>
						{newPlan.price} {currency}
					</strong>{' '}
					<small>{t('payment:perMonth')}</small>
					<div className="buttons pad-1rem">
						<button onClick={props.onDone} className="button is-primary is-inverted">
							{t('common:cancel')}
						</button>
						<button onClick={onApprove} className="button is-primary">
							Zahlungspflichtig Bestätigen
						</button>
					</div>
					<div className="pad-top-1rem">
						<PaddleInfo modifier="is-centered" />
					</div>
				</div>
			</div>
		</Modal>
	);
});
