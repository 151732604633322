import { useStore } from 'app/context';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import WaveFormData from 'waveform-data';
import colors from 'assets/scss/colors.module.scss';
import routes from 'routes';
import { useHistory } from 'react-router';

interface IWaveform {
	project: ProjectVM;
}

export const ProjectWaveform = observer((props: IWaveform) => {
	const { animatorStore } = useStore();
	const history = useHistory();
	const project = props.project;
	const layerKey = 'audio';

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const [width, setWidth] = useState(containerRef.current?.clientWidth || 0);

	const selectLayer = (e: React.MouseEvent<HTMLDivElement>) => {
		const offset = e.currentTarget.getBoundingClientRect();
		const x = Math.floor(((e.pageX - offset.left) / offset.width) * 10000) / 100;

		const audioLayer = project.model.audioLayer;
		const s = audioLayer.start + (audioLayer.duration * x) / 100;

		animatorStore.seek(s);

		const p = routes.EDITPROJECT.getPath(props.project.id, layerKey);
		history.push({
			pathname: p,
			search: '?panel=' + layerKey,
		});
	};

	// draw wave
	useEffect(() => {
		const uri = animatorStore.waveDataUri;
		if (!uri || !canvasRef.current || !containerRef.current) {
			return;
		}

		const canvas = canvasRef.current!;
		setWidth(containerRef.current.clientWidth);

		const drawWaveform = (waveform: WaveFormData) => {
			const scaleY = (amplitude: number, height: number) => {
				const range = 256;
				const offset = 128;

				return height - ((amplitude + offset) * height) / range;
			};

			const channel = waveform.channel(0);

			const ctx = canvas.getContext('2d')!;
			ctx.beginPath();
			ctx.fillStyle = colors.grey;

			for (let x = 0; x < canvas.offsetWidth; x++) {
				const val = channel.max_sample(Math.round((waveform.length / canvas.offsetWidth) * x));
				ctx.lineTo(x + 0.5, scaleY(val, canvas.height) + 0.5);
			}
			for (let x = canvas.offsetWidth - 1; x >= 0; x--) {
				const val = channel.min_sample(Math.round((waveform.length / canvas.offsetWidth) * x));
				ctx.lineTo(x + 0.5, scaleY(val, canvas.height) + 0.5);
			}

			ctx.closePath();
			ctx.fill();
		};

		animatorStore.getWaveData(uri).then((d) => {
			try {
				const waveform = WaveFormData.create(d.data);
				drawWaveform(waveform);
			} catch (ex) {
				console.error('Could not load wavadata from ' + uri);
			}
		});
	}, [animatorStore, animatorStore.waveDataUri]);

	return (
		<div ref={containerRef} className={'waveform-data'} onClick={selectLayer}>
			<canvas ref={canvasRef} width={width} height={30}></canvas>
		</div>
	);
});
