import { IObservableArray } from 'mobx';
import { FileModel, LayerModel, ProjectTemplate } from '..';
import { IAudioData } from './iaudio.data';

export interface IDbProject {
    id: string;
    userId: string;
    groupId: string;
    title: string;
    created?: Date;
    templateId?: string;
    fmtId: string;
    modified?: Date;
    previewKey?: string;
    start: number;
    layers: LayerModel[] | IObservableArray<LayerModel>;
    audioLayer: ILayer;
    _splitSize?: number;
    history?: any[];
    _projectTemplate?: ProjectTemplate;
}

export type ILayer = ILayerBase | Partial<ILayerBase>;

export enum LayerType {
    audio = 'audio',
    audiogram = 'audiogram',
    background = 'background',
    badge = 'badge',
    image = 'image',
    progress = 'progress',
    text = 'text',
    textArea = 'textArea',
    animatedText = 'animatedText',
    util = 'util',
    watermark = 'watermark',
    deprecated = 'deprecated',
}

export function isAnimationLayer(layer: ILayer): boolean {
    if (layer && layer.key !== 'audio' && layer.layerType !== LayerType.deprecated) {
        return true;
    }
}

export interface ILayerBase {
    key: string;
    sort: number;
    fmtId: string;
    disabled?: boolean;

    // audio only
    audioFile?: FileModel;
    data: IAudioData;
    start: number;
    duration: number;

    // animation only
    animClass: string;
    opts: any;
    history?: any[];
    layerType?: LayerType;
}
