import { ProjectApi } from '../api/project.api';
import { action, makeObservable, observable, override } from 'mobx';
import { AuthStore } from './auth.store';
import { IExportVideoRequest, IDbExportTask, IAbortVideoRequest, FileModel, FileType, VideoFormats, ExportTaskModel, FileStatus, LayerType, GroupModel } from 'shared-puredio';
import CRUDSrvStore from './common/crud.service.store';
import { ProjectVM } from './viewmodels/project.vm';
import { ProjectModel } from 'shared-puredio';
import ComputeApi from '../api/compute.api';
import { FileStore } from './file.store';
import { RenderState } from 'app/components/Project/Render/ProjectRender';
import { RenderItemVM } from './viewmodels/render.item.vm';
import { TemplateApi } from 'app/api/template.api';

export class ProjectStore extends CRUDSrvStore<ProjectModel> {
	authStore: AuthStore;
	fileStore: FileStore;
	computeApi: ComputeApi;
	projectApi: ProjectApi;
	templateApi: TemplateApi;

	constructor(authStore: AuthStore, projectApi: ProjectApi, fileStore: FileStore, computeApi: ComputeApi, templateApi: TemplateApi) {
		super(authStore, projectApi);
		makeObservable(this);
		this.authStore = authStore;
		this.projectApi = projectApi;
		this.computeApi = computeApi;
		this.fileStore = fileStore;
		this.templateApi = templateApi;
	}

	@observable
	isBusy: boolean = false;

	@action
	setIsBusy(v: boolean) {
		this.isBusy = v;
	}

	@action
	async newFromEmptyProject(groupId: string, userId: string, fmtId: string, title: string, audioFile: FileModel): Promise<ProjectVM | undefined> {
		const templateId = 'emptytemplate';
		if (!userId) {
			const errMessage = { message: 'no-user-id', code: 123 };
			throw errMessage;
		}
		this.setIsBusy(true);
		if (!fmtId) {
			fmtId = VideoFormats.square.id;
		}
		let layers: any[] = [
			{
				sort: 2,
				key: 'background',
				animClass: 'SolidBackground',
				layerType: LayerType.background,
				opts: {
					color: '#7990F1',
				},
			},
		];

		const proj = new ProjectModel(
			{
				id: 'new',
				userId,
				groupId,
				fmtId,
				layers,
				audioLayer: { key: 'audio' },
				title: title,
				templateId,
				start: 0,
			},
			[],
		);
		proj.setAudioData(audioFile);

		// @ts-ignore
		proj.id = null;
		const m = await this.create(proj);
		const vm = new ProjectVM(m);
		this.projectApi.liteLoad();
		this.setIsBusy(false);
		return vm;
	}

	@action
	async newFromTemplate(model: ProjectModel, newTitle: string, groupId: string, file: FileModel): Promise<ProjectVM> {
		this.setIsBusy(true);
		const template = await this.templateApi.findById(model.id);
		const d = template!.serialize();
		delete d.id;
		d.title = newTitle;
		d.userId = this.authStore.current!.id!;
		d.groupId = groupId;
		d.templateId = model._projectTemplate.templateId;
		d._projectTemplate = undefined;
		d.created = new Date();
		d.modified = new Date();
		d.audioLayer.history = [];
		d.audioLayer.audioFile = file.serialize();
		d.audioLayer.start = 0;
		d.audioLayer.duration = file.audioData!.duration;
		d.layers.forEach((l: any) => (l.history = []));
		d.history = [];
		const m = await this.create(d);
		this.projectApi.liteLoad();
		this.setIsBusy(false);
		return new ProjectVM(m);
	}

	@action
	async duplicate(model: ProjectModel, newTitle: string): Promise<ProjectVM> {
		this.setIsBusy(true);
		const d = model.serialize();
		delete d.id;
		d.title = newTitle;
		d.userId = this.authStore.current!.id!;
		d.created = new Date();
		d.modified = new Date();
		d.audioLayer.history = [];
		d.layers.forEach((l: any) => (l.history = []));
		d.history = [];
		const m = await this.create(d);
		this.projectApi.liteLoad();
		this.setIsBusy(false);
		return new ProjectVM(m);
	}

	async getRenderStatus(project: ProjectModel, mainTask?: ExportTaskModel): Promise<RenderState> {
		if (mainTask && mainTask.result && mainTask.result.outputKey) {
			return RenderState.success;
		}

		if (mainTask && mainTask.isAborted) {
			return RenderState.aborted;
		}

		if (mainTask && mainTask.result && mainTask.result.errorCode) {
			return RenderState.error;
		}
		if (mainTask && mainTask.status !== 'completed') {
			return RenderState.started;
		}
		if (!project.audioLayer.audioFile || !project.audioLayer.audioFile.md5Hash) {
			return RenderState.error;
		}
		if (project.audioLayer.audioFile!.fileStatus === FileStatus.completed) {
			const audioRes = await this.computeApi.checkAudioIsReady(project.audioLayer.audioFile!.md5Hash!);
			if (!audioRes || !audioRes.audioIsReady) {
				return RenderState.waitingForAudio;
			}
		}
		return RenderState.canStart;
	}

	@action
	async triggerRender(model: ProjectModel): Promise<IDbExportTask> {
		this.setIsBusy(true);
		await this.save(model);
		let _splitSize = 10;
		const s = window.localStorage.getItem('_splitSize');
		if (s) {
			_splitSize = parseInt(s);
		}
		const opts: IExportVideoRequest = {
			projectId: model.id,
			userId: this.authStore.current!.id!,
			//duration: 10,
			_splitSize: _splitSize,
		};
		console.log('triggerRender', JSON.stringify(opts));
		const res = await this.computeApi.triggerRender(opts);
		console.log('triggerRender', res.data);
		this.setIsBusy(false);
		return res.data;
	}

	@action
	async abortRender(model: RenderItemVM): Promise<IDbExportTask> {
		this.setIsBusy(true);
		const opts: IAbortVideoRequest = {
			projectId: model.project!.id,
			userId: this.authStore.current!.id!,
			taskId: model.task.id!,
		};
		const res = await this.computeApi.abortRender(opts);
		this.setIsBusy(false);
		console.log('abortRender', res.data);
		return res.data;
	}

	@override
	async save(m: Partial<ProjectModel>): Promise<ProjectModel> {
		return await this.service.save(m.id!, m);
	}

	isFileInUse(f: FileModel) {
		switch (f.fileType) {
			case FileType.audio:
				return this.isAudioInUse(f.md5Hash!);
			case FileType.image:
				return this.isImageInUse(f.md5Hash!);
			default:
				throw new Error(`File type ${f.fileType} not supported.`);
		}
	}

	private isAudioInUse(md5: string) {
		return this.projectApi.list.some((project) => project.audioLayer?.audioFile?.md5Hash === md5);
	}

	private isImageInUse(md5: string) {
		return this.projectApi.list.some((model) =>
			model.layers.some((layer) => {
				if (layer.opts.image) {
					return layer.opts.image.md5Hash === md5;
				}
				return false;
			}),
		);
	}

	@override
	async deleteById(id: string) {
		await this.service.__delete(id);
		await this.projectApi.liteLoad();
	}

	@action
	async createDemoProject(group: GroupModel): Promise<ProjectVM> {
		this.setIsBusy(true);
		const template = await this.templateApi.findById('bj4NwDhM1rNXWWRfDZzG');
		const d = template!.serialize();
		delete d.id;
		d.title = 'Demo Episode';
		d.userId = this.authStore.current!.id!;
		d.groupId = group.id;
		// d.templateId = template?.templateId;
		d._projectTemplate = undefined;
		d.created = new Date();
		d.modified = new Date();
		// d.audioLayer.history = [];
		// d.audioLayer.audioFile = file.serialize();
		// d.audioLayer.start = 0;
		// d.audioLayer.duration = file.audioData!.duration;
		d.layers.forEach((l: any) => (l.history = []));
		d.history = [];
		const m = await this.create(d);
		this.projectApi.liteLoad();
		this.setIsBusy(false);
		return new ProjectVM(m);
	}
}
