import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import { GroupModel } from 'shared-puredio';
import { useStore } from 'app/context';
import { GroupDeleteForm } from './GroupDeleteForm';
import t from 'app/common/translate';

interface IGroupDeleteModalProps {
	modalId: string;
	group: GroupModel;
	onDone: () => void;
}

export const GroupDeleteModal: React.FC<IGroupDeleteModalProps> = observer((props: IGroupDeleteModalProps) => {
	const store = useStore();
	const onDone = () => {
		store.uiStore.hideModal(props.modalId);
		props.onDone();
	};
	return (
		<Modal title={t('general:group.delete')} size="medium" modalId={props.modalId}>
			<GroupDeleteForm group={props.group} onDone={onDone} />
		</Modal>
	);
});
