import React, { useEffect, useState } from 'react';
import { FileModel, ProjectModel } from 'shared-puredio';
import * as Yup from 'yup';
import t from 'app/common/translate';
import { Formik, Field, Form } from 'formik';
import { useStore } from 'app/context';
import { INewProjectParms } from 'app/containers/ProjectNewContainer';
import { useHistory, useParams } from 'react-router';
import FormField from 'app/components/Form/FormField';
import { Button } from 'app/components/common/Button';
import routes from 'routes';
import { AudioUploadField } from 'app/components/fields/AudioUploadField';
import { EmptyTemplateProject, TemplateListItem } from 'app/components/Template/TemplateList';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { notify } from 'app/common/notify';
import Icon from 'app/components/common/Icon';

const projecNewSchema = Yup.object()
	.shape({
		title: Yup.string().required(t('validation:required.field')).default(''),
		audioMd5: Yup.string().required(t('validation:required.audiofile')),
	})
	.required();
type NewProject = Yup.InferType<typeof projecNewSchema>;

export const ProjectNewSelectAudio = () => {
	const history = useHistory();
	const parms = useParams<INewProjectParms>();
	const { authStore, projectStore, templateStore, fileStore } = useStore();
	const [template, setTemplate] = useState<ProjectModel | undefined>();
	const { groupId, fmtId, templateId } = parms;

	useEffect(() => {
		if (templateId === 'emptytemplate') {
			setTemplate(EmptyTemplateProject);
			return;
		}
		const t = templateStore.list.find((p) => p.id === templateId);
		setTemplate(t);
	}, [templateId, fmtId, templateStore.list]);

	const projects = projectStore.list.filter((p) => p.groupId === groupId);
	const title = 'Episode ' + (projects.length + 1);
	const uploadFolder = authStore.uploadFolder!;

	async function handleSubmit(values: NewProject) {
		let vm: ProjectVM | undefined;
		projectStore.setIsBusy(true);
		notify(t('general:project.isbeingcreated'));
		let file: FileModel | undefined;
		const audioMd5 = values.audioMd5;
		if (audioMd5) {
			file = await fileStore.findByMd5Hash(audioMd5);
		}
		if (!file) {
			projectStore.setIsBusy(false);
			console.warn('no audio file recieve', values);
			notify(t('general:project.create.error'), 'error');
			return;
		}

		if (templateId === 'emptytemplate') {
			vm = await projectStore.newFromEmptyProject(groupId, authStore.current!.id!, fmtId, values.title, file);
			if (!vm) {
				notify(t('general:project.create.error'), 'error');
				projectStore.setIsBusy(false);
				return;
			}
		} else {
			vm = await projectStore.newFromTemplate(template!, values.title, groupId, file);
			if (!vm) {
				notify(t('general:project.create.error'), 'error');
				projectStore.setIsBusy(false);
				return;
			}
		}

		let url = routes.EDITPROJECT.getPath(vm.id);
		url += '?openCropper=1&panel=audio';
		history.push(url);
		projectStore.setIsBusy(false);
	}

	if (!template) {
		return <></>;
	}

	return (
		<Formik
			validationSchema={projecNewSchema}
			initialValues={{
				title: title,
				audioMd5: '',
			}}
			validateOnMount={true}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid, values }) => {
				return (
					<Form className="section is-small new-project select-audio">
						<div className="project-title">
							<FormField className="title" label={t('general:project.title')} error={errors.title} touched={touched.title}>
								<Field type="text" name="title" autoComplete="username" />
							</FormField>

							<div className="debug">
								Selected Template:
								<br />
								<TemplateListItem template={template} key={template.id} onSelect={() => {}} selected={false} fmtId={fmtId!} />
							</div>
						</div>

						<div className="audio-upload">
							<FormField className="audio align-center" label={t('general:project.audiofile')} error={errors.audioMd5} touched={true}>
								<AudioUploadField name="audioMd5" uploadFolder={uploadFolder} />
							</FormField>
						</div>

						<div className="is-small align-right">
							<Button type="submit" className="button is-primary" isFormInvalid={!isValid} disabled={projectStore.isBusy}>
								<span>{t('general:project.selectCrop')}</span>
								<Icon iconName="arrow-right" faModifier="fal" />
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
