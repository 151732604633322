import { IProps } from 'app/context';
import { ProjectEditContainer } from 'app/containers/ProjectEditContainer';
import GroupsContainer from 'app/containers/GroupsContainer';
import ProjectsContainer from 'app/containers/ProjectsContainer';
import { ForgotPasswordContainer, LoginContainer, RegisterContainer } from 'app/containers/AuthContainers';
import TemplatesContainer from 'app/containers/TemplatesContainer';
import { ContactContainer } from 'app/containers/ContactContainer';
import { AccountSettingsContainer } from 'app/containers/AccountSettingsContainer';
import DebugTemplateContainer from 'app/containers/DebugTemplateContainer';
import { VerifyEmailContainer } from 'app/containers/auth/VerifyEmailContainer';
import { ResetPasswordContainer } from 'app/containers/auth/ResetPasswordContainer';
import { RendersContainer } from 'app/containers/RendersContainer';
import AdminContainer from 'app/containers/AdminContainer';
import AdminUserContainer from 'app/containers/AdminUserContainer';
import ProjectNewContainer from 'app/containers/ProjectNewContainer';
import AdminProjectsContainer from 'app/containers/AdminProjectsContainer';
import AdminTemplatesContainer from 'app/containers/AdminTemplatesContainer';
import HomeContainer from 'app/containers/HomeContainer';
import { AudioFilesContainer } from 'app/containers/AudioFilesContainer';
import { ImageFilesContainer } from 'app/containers/ImageFilesContainer';
import AdminUsageContainer from 'app/containers/AdminUsageContainer';
import AdminTasksContainer from 'app/containers/AdminTaskContainer';
import AdminFilesContainer from 'app/containers/AdminFilesContainer';
import AdminMailsContainer from 'app/containers/AdminMailsContainer';
export interface IRoute {
	route: string;
	getPath(p1?: string, p2?: string, p3?: string): string;
	name?: string;
	container: React.FC<IProps> | React.FunctionComponent | React.ComponentClass | React.ComponentClass<IProps>;
	exact?: boolean;
	private: boolean;
}

let routes = {
	HOME: {
		route: '/',
		getPath: () => '/',
		name: 'Home',
		container: HomeContainer,
		private: true,
	} as IRoute,
	LISTTEMPLATES: {
		route: '/templates/:templateId?',
		getPath: (templateId: string) => `/templates/${templateId}`,
		name: 'Templates',
		container: TemplatesContainer,
		private: true,
	} as IRoute,
	LISTGROUPS: {
		route: '/groups',
		getPath: () => '/groups',
		name: 'Podcasts',
		container: GroupsContainer,
		private: true,
	} as IRoute,
	LISTPROJECTS: {
		route: '/projects/:groupId',
		getPath: (groupId: string) => `/projects/${groupId}`,
		name: 'Episoden',
		container: ProjectsContainer,
		private: true,
	} as IRoute,
	NEWPROJECT: {
		route: '/projects/:groupId/new/:fmtId?/:templateId?',
		exact: false,
		getPath: (groupId: string, fmtId: string, templateId: string) => {
			const p = ['/projects'];
			if (groupId) {
				p.push(groupId);
				p.push('new');
				if (fmtId) {
					p.push(fmtId);
					if (templateId) {
						p.push(templateId);
					}
				}
			}
			return p.join('/');
		},
		name: 'Edit Project',
		container: ProjectNewContainer,
		private: true,
	} as IRoute,
	EDITPROJECT: {
		route: '/project/:id',
		exact: false,
		getPath: (projectId: string) => `/project/${projectId}`,
		name: 'Edit Project',
		container: ProjectEditContainer,
		private: true,
	} as IRoute,
	RENDERS: {
		route: '/renders/:id?',
		exact: false,
		getPath: (id?: string) => (id ? `/renders/${id}` : '/renders'),
		name: 'Edit Project',
		container: RendersContainer,
		private: true,
	} as IRoute,
	AUDIOFILES: {
		route: '/audiofiles/:id?',
		exact: false,
		getPath: (id?: string) => (id ? `/audiofiles/${id}` : '/audiofiles'),
		name: 'Edit Project',
		container: AudioFilesContainer,
		private: true,
	} as IRoute,
	IMAGFILES: {
		route: '/imagefiles/:id?',
		exact: false,
		getPath: (id?: string) => (id ? `/imagefiles/${id}` : '/imagefiles'),
		name: 'Edit Project',
		container: ImageFilesContainer,
		private: true,
	} as IRoute,
	DEBUG_TEMPLATE: {
		route: '/template/:templateId',
		exact: false,
		getPath: (templateId: string) => `/template/${templateId}`,
		name: 'Edit Project',
		container: DebugTemplateContainer,
		private: true,
	} as IRoute,
	ACCOUNTSETTINGS: {
		route: '/account/:sub?/:id?',
		getPath: (sub: string, id: string) => {
			let p = '/account';
			if (sub) {
				p += '/' + sub;
				if (id) {
					p += '/' + id;
				}
			}
			return p;
		},
		container: AccountSettingsContainer,
		private: true,
	} as IRoute,
	AUTH_REGISTER: {
		route: '/register',
		getPath: () => '/register',
		container: RegisterContainer,
		private: false,
	} as IRoute,
	CONTACT: {
		route: '/contact',
		getPath: () => '/contact',
		container: ContactContainer,
		private: false,
	} as IRoute,
	AUTH_LOGIN: {
		route: '/login',
		getPath: () => '/login',
		container: LoginContainer,
		private: false,
	} as IRoute,
	AUTH_FORGOTPASSWORD: {
		route: '/forgotpassword',
		getPath: () => '/forgotpassword',
		container: ForgotPasswordContainer,
		private: false,
	} as IRoute,
	EMAILVERIFIED: {
		route: '/verify-email/:id',
		getPath: (id: string) => '/verify-email/' + id,
		container: VerifyEmailContainer,
		private: false,
	},
	RESETPASSWORD: {
		route: '/set-password/:id',
		getPath: (id: string) => '/set-password/' + id,
		container: ResetPasswordContainer,
		private: false,
	} as IRoute,

	ADMIN: {
		route: '/admin/',
		getPath: () => '/admin/',
		container: AdminContainer,
		private: false,
	} as IRoute,
	ADMINUSERS: {
		route: '/admin/users/:id?',
		getPath: (id: string) => {
			let p = '/admin/users';
			if (id) {
				p += '/' + id;
			}
			return p;
		},
		container: AdminUserContainer,
		private: false,
	} as IRoute,
	ADMINPROJECTS: {
		route: '/admin/projects/:id?',
		getPath: (id: string) => {
			let p = '/admin/projects';
			if (id) {
				p += '/' + id;
			}
			return p;
		},
		container: AdminProjectsContainer,
		private: false,
	} as IRoute,
	ADMINUSAGE: {
		route: '/admin/usage/:id?',
		getPath: (id: string) => {
			let p = '/admin/usage';
			if (id) {
				p += '/' + id;
			}
			return p;
		},
		container: AdminUsageContainer,
		private: false,
	} as IRoute,
	ADMINFILES: {
		route: '/admin/files/:id?',
		getPath: (id: string) => {
			let p = '/admin/files';
			if (id) {
				p += '/' + id;
			}
			return p;
		},
		container: AdminFilesContainer,
		private: false,
	} as IRoute,

	ADMINTASKS: {
		route: '/admin/tasks/:id?',
		getPath: (id: string) => {
			let p = '/admin/tasks';
			if (id) {
				p += '/' + id;
			}
			return p;
		},
		container: AdminTasksContainer,
		private: false,
	} as IRoute,

	ADMINTEMPLATES: {
		route: '/admin/templates/:id?',
		getPath: (id: string) => {
			let p = '/admin/templates';
			if (id) {
				p += '/' + id;
			}
			return p;
		},
		container: AdminTemplatesContainer,
		private: false,
	} as IRoute,
	ADMINMAILS: {
		route: '/admin/mails/:id?',
		getPath: (id: string) => {
			let p = '/admin/mails';
			if (id) {
				p += '/' + id;
			}
			return p;
		},
		container: AdminMailsContainer,
		private: false,
	} as IRoute,
};

export default routes;
