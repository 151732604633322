import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';

interface IColorPickerControlProps {
	id?: string;
	name: string;
	label: string;
	value: string;
	onChange: (name: string, value: any) => void;
}
interface IPaletetColorProps {
	color: string;
	name: string;
	selected: boolean;
	onSelect: (color: any) => void;
	selectable: boolean;
}

export const PaletteColor: React.FC<IPaletetColorProps> = observer((props: IPaletetColorProps) => {
	const [color, setColor] = useState(props.color);
	const debouncedColorSelect = useCallback(
		debounce((color) => {
			setColor(color);
			props.onSelect(color);
		}, 100),
		[], // will be created only once initially
	);

	const onColorChange = (evt: any) => {
		debouncedColorSelect(evt.target.value);
	};

	return (
		<div className="color-picker" onClick={() => props.onSelect(props.color)}>
			<div className="swatch" style={{ backgroundColor: color }}></div>
			<div className="value">{props.name}</div>
			<input type={'color'} value={color} disabled={!props.selectable} onChange={onColorChange} />
		</div>
	);
});

export const ColorPickerControl: React.FC<IColorPickerControlProps> = observer((props: IColorPickerControlProps) => {
	const onSelect = (color: string) => {
		props.onChange(props.name, color);
	};

	const isCustomColor = true;
	const id = props.id ? props.id : 'colorpicker' + props.name;

	return (
		<div className="form-control">
			{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
			<PaletteColor color={props.value} name={props.value} selected={isCustomColor} onSelect={onSelect} selectable={true} />
		</div>
	);
});
