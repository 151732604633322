import React from 'react';
import { withStore, IProps } from './../context';
import { observer } from 'mobx-react';
import BaseContainer from '../containers/BaseContainer';
import SideMenu from '../components/SideMenu';
import { GroupList } from 'app/components/Group/GroupList';
import { GroupNavbar } from 'app/components/Group/GroupNavbar';

@withStore
@observer
export default class GroupsContainer extends React.Component<IProps> {
	componentDidMount() {
		this.props.store?.projectStore.resetEntity();
	}

	public render() {
		return (
			<BaseContainer menu={<SideMenu />} navbar={<GroupNavbar />}>
				<GroupList />
			</BaseContainer>
		);
	}
}
