import { IVideoFormat, VideoFormats } from 'shared-puredio';
import React from 'react';
import { FieldConfig, useField, useFormikContext } from 'formik';
import t from '../../common/translate';
import { Button } from 'app/components/common/Button';
import Icon from 'app/components/common/Icon';
import { ReactComponent as SquareIcon } from '../../../assets/images/format-square.svg';
import { ReactComponent as LandscapeIcon } from '../../../assets/images/format-landscape.svg';
import { ReactComponent as PortraitIcon } from '../../../assets/images/format-portrait.svg';

interface IOnChange {
	onChange?: any;
}
export function VideoFormatSelectField(props: FieldConfig & IOnChange) {
	const { setFieldValue, getFieldProps } = useFormikContext();
	const [field] = useField(props);
	const p = getFieldProps(props);

	const onSelect = (id: string) => {
		setFieldValue(field.name, id);
		if (props.onChange) {
			props.onChange(id);
		}
	};

	const getFormatImage = (format: IVideoFormat) => {
		switch (format.id) {
			case VideoFormats.landscape.id:
				return <LandscapeIcon />;
			case VideoFormats.portrait.id:
				return <PortraitIcon />;
			default:
				return <SquareIcon />;
		}
	};

	const getFormatIcons = (format: IVideoFormat) => {
		switch (format.id) {
			case VideoFormats.landscape.id:
				return (
					<>
						<Icon iconName="youtube" />
						<Icon iconName="linkedin" />
						<Icon iconName="facebook" />
						{/* <Icon iconName="twitter" /> */}
					</>
				);
			case VideoFormats.portrait.id:
				return (
					<>
						<Icon iconName="instagram" />
						<Icon iconName="facebook" />
						<Icon iconName="tiktok" faModifier="fab" />
					</>
				);
			default:
				// aka square
				return (
					<>
						<Icon iconName="instagram" />
						<Icon iconName="linkedin" />
						<Icon iconName="facebook" />
						{/* <Icon iconName="globe" /> */}
					</>
				);
		}
	};

	return (
		<div className={`format-selector is-${p.value}`}>
			{Object.values(VideoFormats.asArray()).map((vf) => {
				const isSelected = vf.id === p.value;
				return (
					<div className={`format-option ${isSelected ? 'is-active' : ''}`} key={vf.id}>
						<Button type="button" className={`button blank-button format-select-button ${isSelected ? 'is-active' : ''}`} onClick={() => onSelect(vf.id)}>
							{getFormatImage(vf)}
							<div className="details">
								<div className="title">{t('components:fmt.' + vf.id)}</div>
								<div className="icons">{getFormatIcons(vf)}</div>
							</div>
						</Button>
					</div>
				);
			})}
		</div>
	);
}
