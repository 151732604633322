import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { SideMenuAdmin } from 'app/components/SideMenuAdmin';
import { NotFound } from 'App';
import { AdminProjectsList } from 'app/components/admin/projects/AdminProjectsList';
import { runInAction } from 'mobx';
import { AdminProjectDetailPanel } from 'app/components/admin/projects/AdminProjectDetailPanel';

export interface AdminProjectParamTypes {
	id?: string;
}

const AdminProjectsContainer: React.FC<IProps> = observer(() => {
	const { adminUserStore, adminProjectStore } = useStore();

	const { id } = useParams<AdminProjectParamTypes>();

	useEffect(() => {
		adminProjectStore.load().then(() => {
			if (id) {
				adminProjectStore.loadById(id);
			} else {
				runInAction(() => {
					adminProjectStore.current = undefined;
				});
			}
		});
	}, [id, adminProjectStore]);

	return (
		<>
			{adminUserStore.isAdmin && (
				<BaseContainer menu={<SideMenuAdmin />}>
					<AdminProjectsList />
					{adminProjectStore.current && <AdminProjectDetailPanel />}
				</BaseContainer>
			)}
			{!adminUserStore.isAdmin && <NotFound />}
		</>
	);
});

export default AdminProjectsContainer;
