import { BaseAnimation } from '../BaseAnimation';
import { Color, Group, Path } from 'paper';
import { LayerType } from '../..';
import icons from '../assets/icons/Icons';
import { computed, reaction } from 'mobx';

export default class IconLayer extends BaseAnimation {
    public layerType: LayerType = LayerType.image;
    animationEnabled: boolean = false;

    @computed
    get icon() {
        //return this.anim.animationLayer.opts.get('icon');
        // will not wark at the moment
        return this.anim.animationLayer.opts.content;
    }
    @computed
    get color() {
        return this.anim.animationLayer.opts.color;
    }

    boundingRect: paper.Path;
    iconGroup: paper.Group;

    public create = async () => {
        this.group = new Group();

        let disp = reaction(
            () => [this.x, this.y, this.width, this.height],
            () => {
                this.drawBoundingRect();
            },
            { fireImmediately: true },
        );
        this.disposers.push(disp);

        disp = reaction(
            () => [this.icon],
            () => {
                this.drawIcon();
            },
            { fireImmediately: true },
        );
        this.disposers.push(disp);

        disp = reaction(
            () => [this.color],
            () => {
                this.colorIcon();
            },
            { fireImmediately: true },
        );
        this.disposers.push(disp);
        this.setupControlGroup();
    };

    public getDefaultOpts = (ftmId: string) => {
        return {
            icon: 'microphone',
        };
    };

    public drawBoundingRect() {
        if (this.boundingRect) {
            this.boundingRect.remove();
        }

        const x = this.calc(this.x);
        const y = this.calc(this.y);
        const width = this.calc(this.width);
        const height = this.calc(this.height);

        this.boundingRect = new Path.Rectangle({
            point: [x - width / 2, y - height / 2],
            size: [width, height],
            name: 'boundingRect',
        });

        // this.drawIcon();
    }

    public drawIcon() {
        if (this.iconGroup) {
            this.iconGroup.remove();
        }
        const icon = this.icon || 'microphone';

        this.iconGroup = new Group({
            name: 'iconGroup',
        });
        this.iconGroup.importSVG(icons.getIcon(icon));
        this.iconGroup.fitBounds(this.boundingRect.bounds);

        if (this.group['iconGroup']) {
            this.group['iconGroup'].replaceWith(this.iconGroup);
        } else {
            this.group.addChild(this.iconGroup);
        }
    }

    private colorIcon() {
        if (this.layerType === LayerType.watermark) return;
        this.group.fillColor = new Color(this.color);
    }

    public tick(currentframe) {
        // const fps = this.anim.fmt.fps;
        // const tlDuration = this.timeline.duration();
        // this.timeline.progress(currentframe / (tlDuration * fps));
        return;
    }
}
