import { useStore } from 'app/context';
import { formatDate, formatTime } from 'app/utils';
import { observer } from 'mobx-react';
import { Image } from 'app/components/Image';
import React, { useEffect, useState } from 'react';
import { CopyLinkToClipboard } from '../common/CopyLinkToClipboard';
import { DownloadVideo } from '../common/DownloadVideo';
import { RenderFormat } from '../common/RenderFormat';
import { RenderFormatLogos } from '../common/RenderFormatLogos';
import { VideoPreview } from '../common/VideoPreview';
import { RenderStatus } from '../common/RenderStatus';
import t from './../../common/translate';
import { RenderItemVM } from 'app/stores/viewmodels/render.item.vm';
import DetailPanel from 'app/components/common/DetailPanel';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Link } from 'react-router-dom';
import { RenderState } from '../Project/Render/ProjectRender';
import { RenderAbort } from '../common/RenderAbort';

export const RenderDetail = observer(() => {
	const { rendersStore, projectRenderStore, projectStore } = useStore();
	const [item, setItem] = useState<RenderItemVM | undefined>();
	const [videoKey, setVideoKey] = useState<string | undefined>();
	const [showProgress, setShowProgress] = useState<boolean>(false);

	useEffect(() => {
		setItem(rendersStore.selected);
		setVideoKey(rendersStore.selectedeOutputVideokey);
	}, [rendersStore.selected, rendersStore.selectedeOutputVideokey]);

	useEffect(() => {
		setShowProgress(false);
		let isCancelled = false;
		if (!item || !item.project) {
			return;
		}

		projectStore.getRenderStatus(item.project!, item!.task).then((status) => {
			if (isCancelled || status !== RenderState.started || !rendersStore.selectedProjectId) {
				return;
			}
			projectRenderStore.listenToProjectId(rendersStore.selectedProjectId).then(() => {
				if (isCancelled || status !== RenderState.started) {
					return;
				}
				setShowProgress(true);
			});
		});
		return () => {
			isCancelled = true;
			projectRenderStore.stopListeningToList();
		};
	}, [projectRenderStore, rendersStore.selectedProjectId, item, projectStore]);

	const audioLayer = item && item.project ? item.project.audioLayer : undefined;
	const history = useHistory();

	const onClose = () => {
		history.push(routes.RENDERS.getPath());
	};

	if (!item) return null;

	const groupLink = () => {
		return <Link to={routes.LISTPROJECTS.getPath(item?.group?.id)}>{item?.groupTitle}</Link>;
	};

	const projectLink = () => {
		return <Link to={routes.EDITPROJECT.getPath(item?.project?.id)}>{item?.projectTitle}</Link>;
	};

	return (
		<DetailPanel title={groupLink()} subtitle={projectLink()} onClose={onClose}>
			<div className="body render-detail">
				<div className="debug">
					id:{item.task.id}
					<br />
					taskGroupId:{item.task.taskGroupId}
				</div>
				{showProgress && (
					<>
						<div className="render-modal has-text-centered content">
							{/* <TaskDebug task={mainTask} /> */}
							<div className="heading">
								<h2 className="title is-2">
									{t('general:render.title.started')}{' '}
									<span role="img" aria-label="thumbsup">
										{t('general:render.title.startedEmoji')}
									</span>
								</h2>
							</div>

							<Image src="render-modal-image.svg" />

							<div>
								<p>
									<strong>{t('general:render.youcanclose')}</strong> <br />
									<span>{t('general:render.wewillinformyou')}</span>
								</p>
							</div>
							<div className="progress">
								<div className="value" style={{ width: `${projectRenderStore.currentProgress}%` }}></div>
							</div>
							<div className="render-details">
								<p>{t('general:render.estimatedDuration')}</p>
							</div>
							{item.project && <RenderAbort renderItem={item} />}
						</div>
					</>
				)}

				{!showProgress && (
					<>
						<table className="table is-content-table">
							<tbody>
								<tr>
									<td>
										<strong>{t('general:renderdetail.created')}</strong>
									</td>
									<td>{formatDate(item.created, 'DD.MM.YYYY HH:mm')}</td>
								</tr>
								<tr>
									<td>
										<strong>{t('status')}</strong>
									</td>
									<td>
										<RenderStatus mainTask={item.task} project={item.project} />
									</td>
								</tr>
								{item.project && (
									<tr>
										<td>
											<strong>{t('general:renderdetail.format')}</strong>
										</td>
										<td>
											<RenderFormat fmt={item.project.fmt} />
											<RenderFormatLogos fmt={item.project.fmt} />
										</td>
									</tr>
								)}
								{audioLayer && (
									<tr>
										<td>
											<strong>{t('general:renderdetail.duration')}</strong>
										</td>
										<td>{formatTime(audioLayer.duration)}</td>
									</tr>
								)}
							</tbody>
						</table>
						<div className="video-links grid is-col-half pad-top-1rem">
							<CopyLinkToClipboard storageKey={videoKey} />
							<DownloadVideo mainTask={item.task} />
						</div>
					</>
				)}
				{!showProgress && (
					<>
						<div className="render-preview">
							<div className="pad-top-1rem pad-bottom-1rem">
								<strong>{t('general:renderdetail.preview')}</strong>
							</div>
							<VideoPreview mainTask={item.task} />
						</div>
					</>
				)}
			</div>
		</DetailPanel>
	);
});
