import { observer } from 'mobx-react';
import React from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { useStore } from 'app/context';
import { SelectUploadedFileControlButton } from 'app/components/common/SelectUploadedFileControl';
import AudioUploadWidget from '../../../components/common/AudioUploadWidget';
import { FileModel } from 'shared-puredio';
import { AudioFileName } from './AudioFileName';
import { UploadResponse } from 'app/api/bucket.api';

interface IAudioLayerEdit {
	projectVM: ProjectVM;
}

export const AudioLayerUpload = observer((props: IAudioLayerEdit) => {
	const { authStore, fileStore } = useStore();
	const projectVM = props.projectVM;
	const uploadFolder = authStore.uploadFolder;

	const onUpload = async (res: UploadResponse) => {
		const audioFile = await fileStore.findByMd5Hash(res.md5Hash!);
		if (audioFile) {
			_setAudioFile(audioFile);
		}
	};

	const onSelectPrevious = async (md5: string) => {
		const audioFile = await fileStore.findByMd5Hash(md5);
		if (audioFile) {
			_setAudioFile(audioFile);
		}
	};

	const _setAudioFile = async (audioFile: FileModel) => {
		projectVM.model.audioLayer!.beginSnapshot(projectVM.navState);
		projectVM.model.setAudioData(audioFile);
		projectVM.model.audioLayer!.commitSnapshot();
	};

	return (
		<div className="form-control audio-selector">
			<AudioFileName {...props} />
			<div className="uploader is-audio">
				<AudioUploadWidget storageFolder={uploadFolder} onUploadSuccess={onUpload} />
			</div>
			<div className="previous-upload">
				<SelectUploadedFileControlButton fileType={'audio'} onSelect={onSelectPrevious} />
			</div>
		</div>
	);
});
