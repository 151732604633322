import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { firebaseAuth, analytics } from './../api/firebase-init';
import { UserApi } from '../api/user.api';
import CRUDStore from './common/crud.store';
import { UserModel } from 'shared-puredio';
import BucketPath from 'shared-puredio/src/models/bucket.path';
import ComputeApi from '../api/compute.api';
import Axios from 'axios';

const auth = firebaseAuth;
const userService = new UserApi();

export interface IRegisterRequest {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
}
interface ILoginRequest {
	email: string;
	password: string;
}

export class AuthStore extends CRUDStore<UserModel> {
	@observable
	isSettingUp: boolean = true;

	computeApi: ComputeApi;

	constructor() {
		super();
		makeObservable(this);
		this.computeApi = new ComputeApi(Axios.create({}));

		auth.onAuthStateChanged(async (user) => {
			if (user) {
				console.log('sub check done');
				// await this.computeApi.ensureSubscriptionUpToDate();
				await this.loadCurrUser();
			} else {
				await this.signedOut();
			}
			runInAction(() => {
				this.isSettingUp = false;
			});
		});
	}

	@computed
	get uploadFolder() {
		if (this.current && this.current.id) {
			return BucketPath.uploadFolder(this.current.id);
		}
		return 'unauthorized';
	}
	@computed
	get modifiedFolder() {
		if (this.current && this.current.id) {
			return BucketPath.modifiedFolder(this.current.id);
		}
		return 'unauthorized';
	}

	@action
	async loadCurrUser() {
		if (auth.currentUser) {
			const uid = auth.currentUser.uid;

			try {
				let user = await userService.findById(uid);
				user = await this._completeUserLogin(auth.currentUser!, user);
				runInAction(() => {
					this.current = user!;
					this.setEntityLoading(false);
				});
			} catch (ex) {
				console.warn(ex);
				runInAction(() => {
					this.resetEntity();
					this.setEntityLoading(false);
				});
			}
		}
	}

	@action
	async register(data: IRegisterRequest) {
		const firstName = data.firstName;
		const lastName = data.lastName;
		const email = data.email;
		const password = data.password;
		if (!firstName || !lastName || !email || !password) {
			return;
		}

		this.resetEntity();
		this.setEntityLoading(true);

		try {
			const value: firebase.auth.UserCredential = await new Promise((resolve, reject) => {
				firebaseAuth
					.createUserWithEmailAndPassword(email, password)
					.then((userCreds) => resolve(userCreds))
					.catch((reason) => reject(reason));
			});
			if (!value.user) {
				throw new Error('no-user-recieved');
			}
			const uid = value.user.uid;
			const user = await userService.save(uid, { firstName, lastName });
			await this._completeUserLogin(value.user!, user);
			this.current = user;
			await this.sendEmailVerficiation('de');
			this.completeEntityAction(user, 'register');
		} catch (ex: any) {
			runInAction(() => {
				this.setEntityErrors([ex.code]);
				this.setEntityLoading(false);
			});
		}
	}

	@action
	async deleteAccount() {
		if (!firebaseAuth.currentUser || !this.current) {
			return;
		}
		const user = this.current;
		user.deleted = true;
		user.deletedDate = new Date();
		await userService.save(user.id!, user);
		await firebaseAuth.currentUser!.delete();
	}

	@action
	async updateUser(uid: string, user: Partial<UserModel>) {
		return await userService.save(uid, user);
	}

	@action
	async sendEmailVerficiation(lang: string) {
		try {
			await this.computeApi.sendEmailVerification(lang);
		} catch (ex: any) {
			this.setEntityErrors([ex.code]);
			throw ex;
		}
	}

	@action
	async sendPasswordResetEmail(email: string, lang: string) {
		try {
			return await this.computeApi.triggerForgotPassword(email);
		} catch (ex: any) {
			this.setEntityErrors([ex.code]);
			throw ex;
		}
	}

	@action
	async checkResetPassworId(verifiyId: string): Promise<any> {
		try {
			return await this.computeApi.checkResetPassworId(verifiyId);
		} catch (ex: any) {
			this.setEntityErrors([ex.code]);
			return false;
		}
	}

	@action
	async resetPassword(verifiyId: string, newPassword: string): Promise<any> {
		try {
			return await this.computeApi.changePassword(verifiyId, newPassword);
		} catch (ex: any) {
			this.setEntityErrors([ex.code]);
			return false;
		}
	}

	@action
	async updatePassword(newPassword: string): Promise<any> {
		try {
			return await firebaseAuth.currentUser!.updatePassword(newPassword);
		} catch (ex: any) {
			this.setEntityErrors([ex.code]);
			return ex.code;
		}
	}

	@action
	async updateEmail(newEmail: string): Promise<any> {
		try {
			return await this.computeApi.changeEmail(newEmail, 'de');
		} catch (ex: any) {
			this.setEntityErrors([ex.code]);
			return ex.code;
		}
	}

	@action
	async login(data: ILoginRequest) {
		let { email, password } = data;

		if (!email || !password) {
			return;
		}
		email = email.trim();
		if (auth.currentUser) {
			await this.loadCurrUser();
			return;
		}
		this.resetEntity();
		this.setEntityLoading(true);

		try {
			const value: firebase.auth.UserCredential = await new Promise((resolve, reject) => {
				firebaseAuth
					.signInWithEmailAndPassword(email, password)
					.then((userCreds) => resolve(userCreds))
					.catch((reason) => reject(reason));
			});
			const _user = value.user;
			if (!_user) {
				this.setEntityErrors(['user-not-found']);
				this.setEntityLoading(false);
				return;
			}
			const uid = _user.uid;
			let user = await userService.findById(uid);
			user = await this._completeUserLogin(_user, user);
			await this.completeEntityAction(user!, 'login');
		} catch (ex: any) {
			runInAction(() => {
				this.setEntityErrors([ex.code]);
				this.setEntityLoading(false);
			});
		}
	}

	async _completeUserLogin(fbUser: firebase.User, dbUser?: UserModel): Promise<UserModel> {
		if (!dbUser) {
			dbUser = new UserModel({
				id: fbUser.uid,
			});
		}
		if (auth.currentUser) {
			const res = await auth.currentUser.getIdTokenResult(true);
			dbUser.getPropsFromClaim(res.claims);
		}
		dbUser.getPropsFromFirebaseObject(fbUser);

		return dbUser;
	}

	@action
	async signOut() {
		if (!auth.currentUser) {
			return;
		}
		await auth.signOut().catch((err) => {
			console.error('Sign Out failed', err);
			return;
		});
		// @ts-ignore
		analytics.logEvent('sign_out');
		runInAction(async () => {
			await this.signedOut();
		});
	}

	@action
	async signedOut() {
		this.resetEntity();
	}
}
