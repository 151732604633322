import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import t from 'app/common/translate';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from '../Form/FormField';
import { Button } from '../common/Button';
import routes from 'routes';
import { notify } from 'app/common/notify';
import { useHistory } from 'react-router';
import { ProjectModel } from 'shared-puredio';

const projecDuplicateSchema = Yup.object()
	.shape({
		title: Yup.string().required(t('validation:required.field')).default(''),
	})
	.required();
type DuplicateProject = Yup.InferType<typeof projecDuplicateSchema>;

interface IProjectDeleteModalProps {
	project: ProjectModel;
	onDone: () => void;
}

export const ProjectDuplicateForm: React.FC<IProjectDeleteModalProps> = observer((props: IProjectDeleteModalProps) => {
	const { projectStore } = useStore();
	const project = props.project;
	const history = useHistory();

	const newTitle = `${t('general:project.copyof')}  ${project.title}`;

	const handleSubmit = async (values: DuplicateProject) => {
		// get the project from the store, since the list doesn't seem to have all the data we need.
		const prj = await projectStore.getById(project.id);

		if (!prj) {
			notify(t('general:project.duplicate.error'), 'error');
		} else {
			const res = await projectStore.duplicate(prj, values.title);
			notify(t('general:project.duplicate.success'), 'info');
			history.push(routes.EDITPROJECT.getPath(res.id));
		}

		props.onDone();
	};
	const onCancel = () => {
		props.onDone();
	};

	return (
		<>
			<Formik
				validationSchema={projecDuplicateSchema}
				initialValues={{
					title: newTitle,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid }) => {
					return (
						<Form className="new-project">
							<div className="project-details">
								<FormField className="title" label={t('general:project.title')} error={errors.title} touched={touched.title}>
									<Field type="text" name="title" />
								</FormField>
							</div>

							<div className="button-container">
								<Button type="button" className="button is-secondary" onClick={onCancel}>
									{t('common:cancel')}
								</Button>
								<Button type="submit" className="button is-primary" isFormInvalid={!isValid} disabled={!isValid}>
									{t('general:project.duplicate')}
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
