import React, { useState } from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { LayerModel } from 'shared-puredio';
import { ColorPickerControl } from 'app/components/common/ColorPickerControl';
import t from 'app/common/translate';
import MultilineInputControl from './MultilineInputControl';
import { GoogleFontSelector } from './GoogleFontSelector';
import { TextAlignControl } from './TextAlignControl';
import { GroupToggle } from './GroupToggle';
import { runInAction } from 'mobx';
import { FontSizeControl } from './FontSizeControl';

interface IAnimLayerFormProps {
	title: string;
	projectVM: ProjectVM;
	animLayer: LayerModel;
	showInput: boolean;
}

export const LayerFormText = (props: IAnimLayerFormProps) => {
	const { animLayer, projectVM } = props;

	const [hasBackground, setHasBackground] = useState(animLayer.opts.hasBackground);

	const onColorChange = (name: string, value: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			if (name === 'color') {
				animLayer.opts.color = value;
			}
			if (name === 'backgroundColor') {
				animLayer.opts.backgroundColor = value;
			}
		});
		animLayer.commitSnapshot();
	};

	const onTextChange = (name: string, value: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			animLayer.opts.content = value;
		});
		animLayer.commitSnapshot();
	};

	const onFontChange = (name: string, value: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			if (name === 'fontFamily') {
				animLayer.opts.fontFamily = value;
			}
			if (name === 'fontVariant') {
				animLayer.opts.fontVariant = value;
			}
			if (name === 'textAlign') {
				animLayer.opts.textAlign = value;
			}
			if (name === 'fontSize') {
				animLayer.opts.fontSize = value;
			}
		});
		animLayer.commitSnapshot();
	};

	const onGroupToggle = (name: string, value: boolean) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			animLayer.opts.hasBackground = value;
			setHasBackground(value);
		});
		animLayer.commitSnapshot();
	};

	return (
		<>
			<div className="ui-control-group">{props.showInput && <MultilineInputControl name={'text'} value={animLayer.opts.content} onChange={onTextChange} />}</div>
			<div className="ui-control-group">
				<GoogleFontSelector fontFamily={animLayer.opts.fontFamily} fontVariant={animLayer.opts.fontVariant} onChange={onFontChange} />
				<TextAlignControl name={'textAlign'} label={t('components:uie.textAlign')} value={animLayer.opts.textAlign} onChange={onFontChange} />
				<ColorPickerControl name={'color'} label={t('components:uie.color')} value={animLayer.opts.color} onChange={onColorChange} />
				<FontSizeControl name="fontSize" label={t('components:uie.fontSize')} value={animLayer.opts.fontSize} onChange={onFontChange} />
			</div>
			<div className="ui-control-group">
				<GroupToggle name={t('components:uie.backgroundColor')} value={hasBackground} onChange={onGroupToggle} />
				{hasBackground && <ColorPickerControl name={'backgroundColor'} label={t('components:uie.backgroundColor')} value={animLayer.opts.backgroundColor} onChange={onColorChange} />}
			</div>
		</>
	);
};
