import { Modal } from 'app/components/common/Modal';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import t from 'app/common/translate';
import React, { useState, useEffect, useCallback } from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { PeaksVM } from 'app/stores/viewmodels/peaks.vm';
import { Button } from 'app/components/common/Button';
import Icon from 'app/components/common/Icon';
import { ProjectPlaybackControls } from './ProjectPlaybackControls';
import { formatPlayerTime } from 'app/utils';
import { AudioFileName } from '../AudioLayer/AudioFileName';
interface IProjectCutAudio {
	project: ProjectVM;
	onCancel: () => void;
	onApply: () => void;
}

export const ProjectCutAudioModal = observer((props: IProjectCutAudio) => {
	const { uiStore } = useStore();
	const cropAudioModalId = uiStore.modalIds.projectCropAudio;
	return (
		<Modal title={t('general:project.crop')} size="large" modalId={cropAudioModalId}>
			<ProjectCutAudioDlg {...props} />
		</Modal>
	);
});

export const ProjectCutAudioDlg = observer((props: IProjectCutAudio) => {
	const { uiStore, animatorStore } = useStore();
	const cropAudioModalId = uiStore.modalIds.projectCropAudio;
	const [peaksZoomElement, setPeaksZoomElement] = useState<HTMLDivElement>();

	const peaksZoomRef = useCallback((node) => {
		setPeaksZoomElement(node);
		return node;
	}, []);

	const onCancel = () => {
		props.onCancel();
		uiStore.hideModal(cropAudioModalId);
	};
	const onApply = () => {
		props.onApply();
		uiStore.hideModal(cropAudioModalId);
	};

	return (
		<div className="project-cut-audio">
			<AudioFileName projectVM={props.project} />
			<div id="peaks-container" className={animatorStore.isBusy ? 'is-busy' : 'is-ready'}>
				<div className="waveform is-zoom" ref={peaksZoomRef}>
					{'_'}
					{/* zoom peaks does not like it if this div is empty on init */}
				</div>
			</div>
			<div className="edit-controls">
				<div className="left">
					<ProjectPlaybackControls />
				</div>
				<div className="right">{peaksZoomElement && <ProjectCutAudio {...props} peaksZoomElement={peaksZoomElement} />}</div>
			</div>
			<div className="trim-controls">
				<Button className="button" onClick={onCancel}>
					{t('common:cancel')}
				</Button>
				<Button className="button is-primary" onClick={onApply}>
					{t('common:apply')}
				</Button>
			</div>
		</div>
	);
});

interface IZoomies {
	project: ProjectVM;
	peaksVm: PeaksVM;
}

const Zoommies = observer((props: IZoomies) => {
	const zoomIn = () => {
		props.peaksVm!.zoomIn();
	};
	const zoomOut = () => {
		props.peaksVm!.zoomOut();
	};

	return (
		<>
			<>
				<div className="time-info">
					<Icon iconName="arrow-to-left" />
					{formatPlayerTime(props.project.model.audioLayer.start)}
				</div>
				<div className="time-info">
					<Icon iconName="arrow-to-right" />
					{formatPlayerTime(props.project.model.audioLayer.end)}
				</div>
				{/* <div className="time-info">
					<Icon iconName="stopwatch" />
					{formatPlayerTime(props.project.model.audioLayer.duration)}
				</div> */}
			</>

			<Button className="button is-icon is-primary" onClick={zoomIn} disabled={props.peaksVm!.isZoomedMax}>
				<Icon iconName="search-minus" faModifier="far" />
			</Button>
			<Button className="button is-icon is-primary" onClick={zoomOut} disabled={props.peaksVm!.isZoomedMin}>
				<Icon iconName="search-plus" faModifier="far" />
			</Button>
		</>
	);
});

interface ICut {
	project: ProjectVM;
	//peaksZoomRef: React.RefObject<HTMLDivElement>;
	peaksZoomElement: HTMLDivElement;
}

export const ProjectCutAudio = observer((props: ICut) => {
	const { animatorStore } = useStore();
	const [vm, setVM] = useState<PeaksVM>();

	useEffect(() => {
		const _vm = animatorStore.getPeaksVM(props.project, props.peaksZoomElement);
		setVM(_vm);
		return () => {
			if (_vm && _vm.peaks) {
				_vm.peaks!.destroy();
			}
		};
	}, [animatorStore.anim, animatorStore.audioStore, animatorStore.bucketStore, props.peaksZoomElement, props.project]);

	return <>{vm && <Zoommies {...props} peaksVm={vm} />}</>;
});
