import { observer } from 'mobx-react';
import React, { useState } from 'react';

export interface ISelectOpt {
	value: any;
	label: string;
}
interface ISelectControl {
	value: any;
	opts: ISelectOpt[];
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
}

export const SelectControl = observer((props: ISelectControl) => {
	const [value, setValue] = useState(props.value);

	const onChange = (event: any) => {
		setValue(event.target.value);
		props.onChange(event.target.name, event.target.value);
	};

	const id = props.id ? props.id : props.name;
	return (
		<div className="form-control">
			{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
			<div className="select">
				<select onChange={onChange} id={id} value={value} name={props.name}>
					{props.opts.map((opt) => {
						return (
							<option key={opt.value} value={opt.value}>
								{opt.label}
							</option>
						);
					})}
				</select>
			</div>
		</div>
	);
});
