import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import t from 'app/common/translate';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from '../../Form/FormField';
import { Button } from '../../common/Button';
import { notify } from 'app/common/notify';
import { ProjectModel } from 'shared-puredio';

const projectTemplateEditSchema = Yup.object()
	.shape({
		templateId: Yup.string().required(t('validation:required.field')).default(''),
		templateTitle: Yup.string().required(t('validation:required.field')).default(''),
		isPremium: Yup.boolean().default(false),
	})
	.required();
type ProjectTemplate = Yup.InferType<typeof projectTemplateEditSchema>;

interface IProjectTemplateEditProps {
	project: ProjectModel;
}

export const ProjectTemplateEditForm = observer((props: IProjectTemplateEditProps) => {
	const { projectStore } = useStore();
	const model = props.project;
	const handleSubmit = async (values: ProjectTemplate) => {
		projectStore.setIsBusy(true);
		model._projectTemplate.templateId = values.templateId;
		model._projectTemplate.templateTitle = values.templateTitle;
		model._projectTemplate.published = values.published;
		model._projectTemplate.isPremium = values.isPremium;
		await projectStore.save(model).finally(() => projectStore.setIsBusy(false));

		notify('Gespeichert');
	};

	const templateTitle = model._projectTemplate.templateTitle ? model._projectTemplate.templateTitle : model.title;
	let templateId = model._projectTemplate.templateId;
	if (!templateId) {
		templateId = templateTitle.toLowerCase().replace(' ', '');
		templateId = templateId.toLowerCase().replace('-', '');
	}
	console.log('p', model.id);

	return (
		<>
			<Formik
				validationSchema={projectTemplateEditSchema}
				enableReinitialize
				initialValues={{
					templateId,
					templateTitle,
					published: model._projectTemplate.published,
					isPremium: model._projectTemplate.isPremium,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid }) => {
					return (
						<Form className="grid align-between">
							<div>
								<FormField className="title" label={'templateId'} error={errors.templateId} touched={touched.templateId}>
									<Field type="text" name="templateId" />
								</FormField>

								<FormField className="title" label={'templateTitle'} error={errors.templateTitle} touched={touched.templateTitle}>
									<Field type="text" name="templateTitle" />
								</FormField>
								<FormField className="title" label={'isPremium'} error={errors.isPremium} touched={touched.isPremium}>
									<Field type="checkbox" name="isPremium" />
								</FormField>
								{model.previewKey && (
									<FormField className="title" label={'published'} error={errors.published} touched={touched.published}>
										<Field type="checkbox" name="published" />
									</FormField>
								)}
								{!model.previewKey && (
									<FormField className="title" label={'published'} error={errors.published} touched={touched.published}>
										<div> Project kann erst publizert werden, wenn ein Screen shot erstellt wurde </div>
									</FormField>
								)}
							</div>

							<div className="button-container align-right">
								<Button type="submit" className="button is-primary" isFormInvalid={!isValid} disabled={!isValid}>
									Speichern
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
