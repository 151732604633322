import DetailPanel from 'app/components/common/DetailPanel';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import ReactJson from 'react-json-view';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminMailDetailPanel = observer(() => {
	const { adminMailsStore } = useStore();
	const history = useHistory();
	if (!adminMailsStore.current) {
		return <></>;
	}
	const mail = adminMailsStore.current;

	const onClose = () => {
		history.push(routes.ADMINFILES.getPath());
	};

	return (
		<DetailPanel onClose={onClose} title={mail.mail.id} width={'70%'}>
			<div className="body ">
				from: {mail.mail.from}
				<br />
				to: {mail.mail.to}
				<br />
				<strong>{mail.mail.message.subject}</strong>
				<hr />
				HTML
				<hr />
				<div>
					<iframe title="HTML Mail" srcDoc={mail.mail.message.html} width={'100%'} height={'600px'} />
				</div>
				<hr />
				Text
				<hr />
				<pre>{mail.mail.message.text}</pre>
				<hr />
				Data
				<hr />
				<ReactJson src={mail} collapsed={1} />
			</div>
		</DetailPanel>
	);
});
