import { GroupModel } from "shared-puredio";
import { BaseApi } from "./base.api";

export class GroupApi extends BaseApi<GroupModel> {
    constructor() {
        super();
        this.collection = 'groups';
        this.needsUserId = true;
    }

    createNewInstance(data?:any): GroupModel {
        if (data){            
            return new GroupModel(data);
        }
        // @ts-ignore
        const m = new GroupModel({
            created: new Date(),
            modified: new Date(),
            title: 'EMPTY',
            userId: '',
        });
        return m;
    };

}
