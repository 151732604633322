import * as React from 'react';
import { observer } from 'mobx-react';
import t from '../../common/translate';
import { Button } from '../common/Button';
import Icon from '../common/Icon';
import { useStore } from 'app/context';
import Breadcrumb, { IBreadcrumbItem } from '../Breadcrumb';

export const GroupNavbar = observer(() => {
	const { uiStore, groupStore } = useStore();

	const showNewGroupModal = () => {
		uiStore.showModal(uiStore.modalIds.groupNew);
	};

	const showCreateButton = groupStore.list.length !== 0;

	const crumbs: IBreadcrumbItem[] = [
		{
			title: t('general:groups.title'),
		},
	];

	return (
		<>
			<div className="app-navbar">
				<Breadcrumb crumbs={crumbs} />

				<div className="actions">
					{showCreateButton && (
						<Button className="button is-primary" onClick={showNewGroupModal}>
							<Icon iconName="plus" faModifier="fal" />
							<span>{t('general:groups.add')}</span>
						</Button>
					)}
				</div>
			</div>
		</>
	);
});
