import React from 'react';
import { storage } from '../../api/firebase-init';
import { notify } from 'app/common/notify';
import { Button } from './Button';
import t from 'app/common/translate';

interface IRenderCopyLinkToClipboard {
	storageKey?: string;
}

export const CopyLinkToClipboard = (props: IRenderCopyLinkToClipboard) => {
	const key = props.storageKey;

	async function copyRenderVideoLink() {
		if (!key) {
			return;
		}

		const url = await storage.ref(key).getDownloadURL();
		if (url) {
			navigator.clipboard.writeText(url);
			notify(t('components:link.copied'), 'info');
		}
	}

	return (
		<>
			{key && (
				<Button className="button is-primary is-inverted" onClick={copyRenderVideoLink}>
					<span>{t('components:link.copy')}</span>
				</Button>
			)}
		</>
	);
};
