import { BucketApi, BucketFile, UploadResponse } from '../api/bucket.api';
import { AuthStore } from './auth.store';
import * as path from 'path';
import { action, computed } from 'mobx';
import moment from 'moment';

class DownloadUrl {
	constructor(key: string) {
		this.key = key;
		this.created = new Date();
	}
	key: string;
	url?: string;
	created: Date;
	@computed
	get age() {
		return new Date().getTime() - this.created.getTime();
	}

	@computed
	get isValid() {
		return this.age < 3600 * 24 * 1000;
	}

	static fromJson(j: string) {
		const o = JSON.parse(j);
		const x = new DownloadUrl(o.key);
		Object.assign(x, o);
		x.created = moment(x.created).toDate();
		return x;
	}
}

export class BucketStore {
	bucketApi: BucketApi;
	authStore: AuthStore;
	constructor(authStore: AuthStore) {
		this.authStore = authStore;
		this.bucketApi = new BucketApi();
	}

	//

	findKeyInStorage(key: string) {
		const x = localStorage.getItem(key);
		if (x) {
			return DownloadUrl.fromJson(x);
		}
	}
	deleteKeyInStorage(key: string) {
		const x = localStorage.getItem(key);
		if (x) {
			localStorage.removeItem(key);
		}
	}

	saveKeyToStorage(x: DownloadUrl) {
		localStorage.setItem(x.key, JSON.stringify(x));
	}

	@action
	invalidateUrl(key: string) {
		localStorage.removeItem(key);
	}

	ensureKeyIsNotPublicFolder(key: string) {
		if (key.startsWith('public')) {
			const baseName = path.basename(key);
			return path.join(this.authStore.modifiedFolder, baseName);
		}
		return key;
	}

	async uploadFile(file: any, key: string, onProgress?: (progress: number) => void): Promise<UploadResponse> {
		key = this.ensureKeyIsNotPublicFolder(key);
		this.deleteKeyInStorage(key);
		return this.bucketApi.uploadFile(file, key, onProgress);
	}

	async uploadDataUrl(dataUrl: any, key: string, randFileName: boolean = true): Promise<UploadResponse> {
		key = this.ensureKeyIsNotPublicFolder(key);
		this.deleteKeyInStorage(key);
		return this.bucketApi.uploadDataUrl(dataUrl, key, randFileName);
	}

	async getDownloadUrl(key: string) {
		let d = this.findKeyInStorage(key);
		if (key.indexOf('wavedata.dat') > 0) {
			console.log('ignoring wavedata key cache');
			// don't use cached wavedata..
			// see bug kulturzentrum.
			d = undefined;
		}
		if (key.toLocaleLowerCase().indexOf('.mp3') > 0) {
			console.log('ignoring wavedata mp3 key');
			// don't use cached wavedata..
			// see bug kulturzentrum.
			d = undefined;
		}

		if (d && d.isValid) {
			return d.url!;
		}
		const p = await this.bucketApi.getDownloadUrl(key);
		d = new DownloadUrl(key);
		if (p === undefined) {
			console.warn('no url recieved for key' + key);
		} else {
			d.url = p;
		}
		this.saveKeyToStorage(d);
		return d.url!;
	}

	async listImages(): Promise<BucketFile[]> {
		if (!this.authStore.current) {
			return [];
		}
		const all = await this.bucketApi.listFolder(this.authStore.uploadFolder);
		return all.filter((i) => i.isImage && i.isOriginalImage);
	}

	async listAudioFiles(): Promise<BucketFile[]> {
		if (!this.authStore.current) {
			return [];
		}
		const all = await this.bucketApi.listFolder(this.authStore.uploadFolder);
		return all.filter((i) => i.isAudio);
	}
}
