import { AdminTaskApi } from 'app/api/admin.task.api';
import { IStoreScope } from 'app/api/base.api';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { ExportTaskModel, FileModel, ProjectModel, TaskType, UserModel } from 'shared-puredio';
import { AdminFilesStore, AdminFileVm } from './admin.files.store';
import { AdminProjectStore, AdminProjectVm } from './admin.project.store';
import { AdminUserStore, AdminUserVM } from './admin.user.store';
import { FileStore } from './file.store';

export class AdminTaskVM {
	constructor(task: ExportTaskModel, p?: ProjectModel, u?: UserModel, f?: FileModel) {
		makeObservable(this);
		this.task = task;
		this.project = p;
		this.user = u;
		this.audioFile = f;
	}
	@observable
	task: ExportTaskModel;
	@observable
	project?: ProjectModel;

	@observable
	audioFile?: FileModel;

	@computed
	get taskDuration() {
		const created = moment(this.task.created);
		const completed = moment(this.task.completed);
		const t = completed.diff(created) / 1000;
		return Math.round(t * 10) / 10;
	}

	@computed
	get projectTitle() {
		if (this.project) {
			return this.project.title;
		}
		return '';
	}

	user?: UserModel;
}

export class AdminTasksStore {
	adminUserStore: AdminUserStore;
	adminProjectStore: AdminProjectStore;
	fileStore: FileStore;
	adminTasksApi: AdminTaskApi;
	adminFileStore: AdminFilesStore;

	constructor(adminUserStore: AdminUserStore, adminProjectStore: AdminProjectStore, fileStore: FileStore, tasksApi: AdminTaskApi, adminFileStore: AdminFilesStore) {
		makeObservable(this);
		this.adminUserStore = adminUserStore;
		this.adminProjectStore = adminProjectStore;
		this.fileStore = fileStore;
		this.adminTasksApi = tasksApi;
		this.adminFileStore = adminFileStore;
	}

	@observable
	items: AdminTaskVM[] = observable([]);

	@observable
	current?: AdminTaskVM = undefined;

	// @action
	// loadById(projectId: string) {
	// 	this.current = this.items.find((i) => i.project.id === projectId);
	// }

	async loadById(id: string) {
		const items = await this._load({ userId: 'userId', id: id });
		if (items) {
			runInAction(() => {
				this.current = items[0];
			});
		}
	}

	async load(scope: IStoreScope) {
		this.adminTasksApi.isBusy = true;
		const items = await this._load(scope);
		runInAction(() => {
			this.items = items;
		});
		this.adminTasksApi.isBusy = true;
	}

	async _load(scope: IStoreScope) {
		const p: any = [];
		p.push(this.adminTasksApi.listAll(scope));
		p.push(this.adminProjectStore.load());
		p.push(this.adminUserStore.load());
		p.push(this.adminFileStore.load());

		const res = await Promise.all(p);

		const tasks = res[0] as ExportTaskModel[];
		const projects = res[1] as AdminProjectVm[];
		const users = res[2] as AdminUserVM[];
		const files = res[3] as AdminFileVm[];

		const items = tasks.map((task) => {
			const uVM = users.find((u) => u.user.fakeId === task.userId);
			const u = uVM ? uVM.user : undefined;
			const oVM = projects.find((p) => p.project.id === task.projectId);
			const p = oVM ? oVM.project : undefined;
			let f: FileModel | undefined;
			if (task.type === TaskType.export && p) {
				const fVM = files.find((f) => f.file.md5Hash === p.audioLayer.audioFileMd5Hash);
				f = fVM ? fVM.file : undefined;
			}
			if (task.type === TaskType.splitAudio || task.type === TaskType.encodeAudio) {
				const fVM = files.find((f) => f.file.md5Hash === task.opts.md5Hash);
				f = fVM ? fVM.file : undefined;
			}

			return new AdminTaskVM(task, p, u, f);
		});

		return items;
	}
}
