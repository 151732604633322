import { IVideoFormat, LayerType } from '../..';
import { override, reaction } from 'mobx';
import IconLayer from './IconLayer';
import { BaseAnimation, IPoint } from '../BaseAnimation';
import { Group, Path } from 'paper/dist/paper-core';
import icons from '../assets/icons/Icons';
import { BaseAnimationOptions } from '../LayerAnimationOptions';

export default class WatermarkLayer extends IconLayer {
    public layerType: LayerType = LayerType.watermark;
    animationEnabled = false;
    isLayerSelectable = false;
    isLayerTransformable = false;

    @override
    get icon() {
        return `watermark_${this.anim.fmt.id}`;
    }

    @override
    get x(): IPoint {
        return {
            v: 0.5,
            d: 'w',
        };
    }
    @override
    get y(): IPoint {
        return {
            v: 0.5,
            d: 'h',
        };
    }
    @override
    get width(): IPoint {
        return {
            v: 1,
            d: 'w',
        };
    }
    @override
    get height(): IPoint {
        return {
            v: 1,
            d: 'h',
        };
    }

    public getDefaultOpts = (fmtId: string) => {
        return {
            icon: `watermark_${fmtId}`,
        };
    };

    // public drawIcon() {
    //     if (this.iconGroup) {
    //         this.iconGroup.remove();
    //     }
    //     const icon = this.icon || `watermark_${this.anim.fmt.id}`;

    //     this.iconGroup = new Group({
    //         name: 'iconGroup',
    //     });

    //     console.log('GET ICON', icon);
    //     this.iconGroup.importSVG(icons.getIcon(icon));
    //     // this.iconGroup.fitBounds(this.boundingRect.bounds);

    //     if (this.group['iconGroup']) {
    //         this.group['iconGroup'].replaceWith(this.iconGroup);
    //     } else {
    //         this.group.addChild(this.iconGroup);
    //     }
    // }
}
