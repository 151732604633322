import { AnimationOptions } from 'shared-puredio';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import Peaks, { Point } from 'peaks.js';
import { AudioStore } from '../audio.store';
import { ProjectVM } from './project.vm';
import colors from 'assets/scss/colors.module.scss';
import { BucketStore } from '../bucket.store';

export interface IPeaksVMParms {
	audioEl: HTMLAudioElement;
	peaksZoomRef: HTMLDivElement;
	anim: AnimationOptions;
	bucketStore: BucketStore;
	project: ProjectVM;
	audioStore: AudioStore;
	onEnd: () => void;
}

export class PeaksVM {
	paperScope?: paper.PaperScope;
	peaksZoomRef: HTMLDivElement;
	audioEl: HTMLAudioElement;
	peaks?: Peaks.PeaksInstance;
	audioStore: AudioStore;

	@observable
	anim: AnimationOptions;

	bucketStore: BucketStore;

	@observable
	isReady: boolean = false;

	@observable
	loadingAudio: boolean = false;

	@observable
	isRunning: boolean = false;

	@observable
	zoomSeconds: number = 10;

	project: ProjectVM;

	onEnd: () => void;

	disposers: any[] = [];

	draggerColorEnabled: string = 'rgba(0, 0, 0, .75)';
	draggerColorDisabled: string = 'rgba(0, 0, 0, .25)';

	constructor(opts: IPeaksVMParms) {
		makeObservable(this);
		this.peaksZoomRef = opts.peaksZoomRef;
		this.anim = opts.anim;
		this.project = opts.project;
		this.bucketStore = opts.bucketStore;
		this.audioStore = opts.audioStore;
		this.audioEl = opts.audioEl;
		this.audioEl.volume = 1;
		this.audioEl.crossOrigin = 'anonymous';
		this.onEnd = opts.onEnd;

		const pPeaks = this.setupPeaks();
		pPeaks.then(() => {
			runInAction(() => {
				this.isReady = true;
			});
		});
	}

	async dispose() {
		this.disposers.forEach((disp) => {
			disp();
		});
	}
	async tearDown() {
		this.stop();
	}

	async setupPeaks() {
		const dataUri = await this.bucketStore.getDownloadUrl(this.anim.audioLayer.audioFile!.wavedataKey);

		let start = 0;
		let end = this.anim.audioLayer.audioFileDuration;

		if (this.anim.audioLayer.start >= 0) {
			start = parseFloat(this.anim.audioLayer.start.toString());
			end = this.anim.audioLayer.end;
		}

		const options: Peaks.PeaksOptions = {
			containers: {
				zoomview: this.peaksZoomRef,
			},
			dataUri: {
				arraybuffer: dataUri,
			},

			zoomWaveformColor: colors.greyLight,

			mediaElement: this.audioEl,
			segmentStartMarkerColor: 'rgba(0, 0, 0, 0)',
			segmentEndMarkerColor: 'rgba(0, 0, 0, 0)',
			overviewHighlightColor: 'rgba(0, 0, 0, .3)',
			overviewHighlightOffset: 20,
			playheadColor: colors.primary,
			playheadTextColor: colors.text,
			showPlayheadTime: false,
		};

		await new Promise<void>((resolve, reject) => {
			Peaks.init(options, (err, peaks) => {
				if (err) {
					console.error('peaks could not start', err);
					reject();
				}
				this.peaks = peaks;
				console.log('peaks init done');
				resolve();
			});
		});

		this.peaks!.segments.add({
			startTime: start,
			endTime: end,
			id: 'puredio',
			label: 'puredio',
			editable: true,
			color: 'rgba(0, 0, 0, .25)',
		});
		this.peaks!.points.add([
			{
				time: start,
				id: 'start',
				color: this.draggerColorEnabled,
				editable: true,
			},
			{
				time: end,
				id: 'end',
				color: this.draggerColorEnabled,
				editable: true,
			},
		]);

		if (!this.peaks) {
			console.warn('peaks not defined');
			debugger;
			return;
		}
		if (start > end) {
			start = 0;
		}

		this.peaks!.on('player.ended', this.onEnd.bind(this));
		this.peaks!.on('player.seeked', this.onSeeked.bind(this));
		this.peaks!.on('player.timeupdate', this.onStopOnEnd.bind(this));
		this.peaks!.on('points.dragend', this.onPointDragged.bind(this));
		// this.peaks!.on('zoomview.dblclick', this.onPeakDblick.bind(this));
		this.peaks!.on('player.error', (err) => {
			console.log('peaks player.err', err);
		});

		return new Promise<void>((resolve) => {
			this.peaks!.on('peaks.ready', () => {
				this.peaks!.player.pause();
				console.log('peaks is ready');
				this.onTimeUpdate(start, end);
				if (!this.peaksZoomRef) {
					setTimeout(() => {
						this.focusSegmentOnStart();
						resolve();
					}, 500);
				} else {
					this.focusSegmentOnStart();
					resolve();
				}
			});
		});
	}

	@observable
	minZoomSeconds: number = 10;
	@observable
	maxZoomSeconds: number = 10;

	@action
	focusSegmentOnStart() {
		// @ts-ignore
		const sample_rate = this.peaks._waveformData.sample_rate;
		const minSpp = 256;
		const canvasWidth = this.peaksZoomRef!.getBoundingClientRect().width;
		this.minZoomSeconds = (canvasWidth / sample_rate) * minSpp;
		// this.maxZoomSeconds = Math.ceil(this.anim.audioLayer.data!.duration * 100) / 100;
		this.maxZoomSeconds = Math.ceil(this.anim.audioLayer.data!.duration);
		const zoomView = this.peaks!.views.getView('zoomview')!;
		const segment = this.peaks!.segments.getSegment('puredio')!;
		zoomView.setStartTime(segment.startTime);
		this.setZoom(this.maxZoomSeconds);
	}
	@action
	setZoom(seconds: number) {
		seconds = Math.ceil(seconds * 100) / 100;
		const zoomView = this.peaks!.views.getView('zoomview')!;
		this.setZoomSeconds(seconds);
		zoomView.setZoom({
			seconds: this.zoomSeconds,
		});
	}

	@action
	setZoomSeconds(s: number) {
		if (s <= this.minZoomSeconds) {
			this.zoomSeconds = this.minZoomSeconds;
			return;
		}
		if (s >= this.maxZoomSeconds) {
			this.zoomSeconds = this.maxZoomSeconds;
			return;
		}
		this.zoomSeconds = s;
	}

	@action
	zoomIn() {
		this.setZoom(this.zoomSeconds * 1.25);
	}
	@action
	zoomOut() {
		this.setZoom(this.zoomSeconds / 1.25);
	}

	@computed
	get isZoomedMax() {
		return this.zoomSeconds === this.maxZoomSeconds;
	}

	@computed
	get isZoomedMin() {
		return this.zoomSeconds === this.minZoomSeconds;
	}

	@action
	onPointDragged(point: Point) {
		if (!this.peaks) {
			return;
		}
		const segment = this.peaks!.segments.getSegment('puredio');
		const id = point.id;
		let start = segment!.startTime;
		let end = segment!.endTime;
		if (id === 'start') {
			start = point.time;
		}
		if (id === 'end') {
			end = point.time;
		}
		this.onTimeUpdate(start, end);
	}

	onTimeUpdate(start: number, end: number) {
		console.log('onTimeUpdate ', start, end);
		const segment = this.peaks!.segments.getSegment('puredio');
		start = Math.round(start * 10) / 10;
		end = Math.round(end * 10) / 10;

		if (end > this.anim.audioLayer.audioFileDuration!) {
			end = this.anim.audioLayer.audioFileDuration;
		}

		this.peaks!.points.getPoints().forEach((point) => {
			if (point.id === 'start') {
				point.update({ time: start });
			}
			if (point.id === 'end') {
				point.update({ time: end });
			}
		});

		if (start > end) {
			const s = start;
			start = end;
			end = s;
		}

		segment!.update({
			startTime: start,
			endTime: end,
		});

		const duration = end - start;
		this.anim.audioLayer.setStartDuration(start, duration);
	}

	@action
	onPeakDblick(time: number) {
		if (!this.peaks) {
			return;
		}

		let start, end;
		const segment = this.peaks!.segments.getSegment('puredio')!;
		if (time > segment.endTime) {
			end = time;
			start = segment.startTime;
		} else if (time < segment.startTime) {
			start = time;
			end = segment.endTime;
		} else {
			const ds = Math.abs(segment.startTime - time);
			const de = Math.abs(segment.endTime - time);
			if (ds > de) {
				start = segment.startTime;
				end = time;
			} else {
				start = time;
				end = segment.endTime;
			}
		}
		start = Math.round(start * 10) / 10;
		end = Math.round(end * 10) / 10;

		this.onTimeUpdate(start, end);
	}

	@observable
	seeked: number = 0;
	@action
	onSeeked(t: number) {
		this.seeked = t;
	}

	@action
	onStopOnEnd(time: number) {
		if (!this.anim) {
			console.log('no anim');
			return;
		}
		const end = this.anim.audioLayer.end;
		if (end === 0) {
			console.warn('cannot stop at end. because we dont have the end in the data');
			return;
		}
		if (time > 0 && end > 0 && time >= end) {
			console.log('stopOnEnd');
			this.onEnd();
		}
	}

	async play(t: number): Promise<number> {
		if (!this.peaks) {
			return 0;
		}
		if (!this.audioStore.canStartAudio) {
			console.log('too early for play');
			return t;
		}

		console.log('peaks animate @', t);
		const segmnt = this.peaks.segments.getSegment('puredio');
		if (t > 0) {
			this.peaks.player.seek(t);
			this.peaks.player.play();
		} else {
			console.log('start segment');
			this.peaks.player.playSegment(segmnt!);
		}
		return this.peaks.player.getCurrentTime();
	}

	stop() {
		if (!this.peaks) {
			return;
		}
		this.peaks.player.pause();
		const start = parseFloat(this.anim!.audioLayer.start.toString());
		this.peaks.player.seek(start);
		console.log('stop', start);
	}

	pause(): number {
		if (!this.peaks) {
			return 0;
		}
		const t = this.peaks.player.getCurrentTime();
		this.peaks.player.pause();
		return t;
	}
}
