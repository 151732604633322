import { Button } from 'app/components/common/Button';
import { useStore } from 'app/context';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ProjectModel } from 'shared-puredio';
import { ImageViewer } from '../common/ImageViewer';
import { SvgIcon, SvgIconType } from '../common/SvgIcon';
import t from 'app/common/translate';

import { observer } from 'mobx-react-lite';
import { PremiumTemplateModal } from './PremiumTemplateModal';
import _ from 'lodash';

interface ITemplatePreviewProps {
	template: ProjectModel;
	fmtId: string;
}

// @ts-ignore
export const EmptyTemplateProject = new ProjectModel({
	id: 'emptytemplate',
	title: 'Empty Template',
	// @ts-ignore
	_projectTemplate: {
		templateId: 'emptytemplate',
		templateTitle: 'Empty Template',
	},
});

export const TemplatePreview: React.FC<ITemplatePreviewProps> = (props: ITemplatePreviewProps) => {
	const [previewKey, setPreviewKey] = useState<string | undefined>(props.template.previewKey);
	useEffect(() => {
		if (props.template.id === EmptyTemplateProject.id) {
			setPreviewKey('public/templatePreview/EmptyTemplate.png');
		} else {
			setPreviewKey(props.template.previewKey);
		}
	}, [props.template, props.fmtId]);

	if (!props.template || !previewKey) {
		return <></>;
	}
	return (
		<>
			{props.template.isPremiumTemplate && <span className="tag is-round is-primary premium-tag">Premium</span>}

			<ImageViewer bucketKey={previewKey} />
		</>
	);
};
interface ITemplateListItemProps {
	template: ProjectModel;
	selected: boolean;
	fmtId: string;

	onSelect: (t: ProjectModel) => void;
}

export const EmptyTemplatePreview = observer(() => {
	return (
		<div className="empty-template">
			<div className="icon">
				<SvgIcon iconName={SvgIconType.templateEmpty} />
			</div>
			<div className="text">{t('general:project.emptyTemplate')}</div>
		</div>
	);
});

export const TemplateListItem = observer((props: ITemplateListItemProps) => {
	const { authStore, uiStore } = useStore();
	function onSelect(): void {
		if (props.template.isPremiumTemplate && uiStore.showPayments) {
			if (!authStore.current!.activeSubscription) {
				uiStore.showModal(uiStore.modalIds.premiumTemplate);
				return;
			}
		}
		props.onSelect(props.template);
	}

	const user = authStore.current;
	const noActivePlan = user && user.activeRank === 0;

	let classes = classNames({
		'blank-button': true,
		'template-list-item': true,
		'is-active': props.selected,
		'is-premium': props.template.isPremiumTemplate,
		'needs-upgrade': props.template.isPremiumTemplate && noActivePlan,
	});

	return (
		<>
			<Button title={props.template._projectTemplate.templateTitle} type="button" className={classes} onClick={onSelect}>
				{props.template._projectTemplate.templateId === 'emptytemplate' ? <EmptyTemplatePreview /> : <TemplatePreview template={props.template} fmtId={props.fmtId} />}
				{process.env.NODE_ENV !== 'production' && (
					<div className="debug">
						{props.template._projectTemplate.templateTitle} <br /> (id: {props.template._projectTemplate.templateId})
					</div>
				)}
			</Button>
		</>
	);
});

interface ITemplateListProps {
	currentTemplateId?: string;
	onSelect: (t: ProjectModel) => void;
	fmtId: string;
}
export const TemplateList = observer((props: ITemplateListProps) => {
	const { templateStore } = useStore();

	const [current, setCurrent] = useState(props.currentTemplateId);
	const [templates, setTemplates] = useState<ProjectModel[]>([]);
	useEffect(() => {
		const f = _.sortBy(
			templateStore.templateApi.list.filter((p) => p.fmtId === props.fmtId),
			'isPremiumTemplate',
		);
		console.log('found f.', f.length);
		setTemplates(f);
	}, [props.fmtId, templateStore.templateApi.isBusy, templateStore.templateApi.list]);

	const onSelect = (t: ProjectModel) => {
		props.onSelect(t);
		setCurrent(t.id);
	};

	return (
		<>
			<div className={`template-list is-${props.fmtId}`}>
				<TemplateListItem template={EmptyTemplateProject} key={EmptyTemplateProject.id} onSelect={onSelect} selected={EmptyTemplateProject.id === current} fmtId={props.fmtId} />
				{templates.map((template) => (
					<TemplateListItem template={template} key={template.id} onSelect={onSelect} selected={template.id === current} fmtId={props.fmtId} />
				))}
				<PremiumTemplateModal />
			</div>
		</>
	);
});
