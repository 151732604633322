import { observer } from 'mobx-react';
import React from 'react';
import { UserModel } from 'shared-puredio';
import { PlansList } from './PlansList';

export interface IPaymentUserProps {
	user: UserModel;
}

export const Payment = observer((props: IPaymentUserProps) => {
	const user = props.user;

	if (!user) return null;

	if (user.isTemplateManager || user.isAdmin) {
		return <>Admin</>;
	}

	return <PlansList user={user} />;
});
