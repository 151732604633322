import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { Modal } from '../common/Modal';
import { ProjectEditForm } from './Edit/ProjectEditForm';
import t from 'app/common/translate';
import { useStore } from 'app/context';

interface IProjectEditModal {
	project: ProjectVM;
	modalId: string;
	onRemove: () => void;
}

export const ProjectSettingsModal: React.FC<IProjectEditModal> = observer(({ project, modalId, onRemove }: IProjectEditModal) => {
	const store = useStore();

	project.model.beginSnapshot(project.navState);

	const handleClose = () => {
		project.model.cancelSnapshot();
		store.uiStore.hideModal(modalId);
	};

	const handleApply = () => {
		project.model.commitSnapshot();
		store.uiStore.hideModal(modalId);
	};

	const handleRemove = () => {
		onRemove();
		store.uiStore.hideModal(modalId);
	};

	const buttons = [
		{
			title: t('common:delete'),
			onClick: () => {
				handleRemove();
			},
			className: 'button is-red is-inverted',
		},
		{
			title: t('common:cancel'),
			onClick: () => {
				handleClose();
			},
			className: 'button is-secondary',
		},
		{
			title: t('common:apply'),
			onClick: () => {
				handleApply();
			},
			className: 'button is-primary',
		},
	];

	return (
		<Modal title={t('general:project.settings')} size="medium" modalId={modalId} buttons={buttons}>
			<ProjectEditForm project={project} />
		</Modal>
	);
});
