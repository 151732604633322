import { AuthStore } from './auth.store';
import CRUDSrvStore from './common/crud.service.store';
import { FileModel, GroupModel } from 'shared-puredio';
import { GroupApi } from 'app/api/group.api';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import t from 'app/common/translate';

export class GroupStore extends CRUDSrvStore<GroupModel> {
	authStore: AuthStore;
	groupApi: GroupApi;

	constructor(authStore: AuthStore, groupApi: GroupApi) {
		super(authStore, groupApi);
		makeObservable(this);
		this.authStore = authStore;
		this.groupApi = groupApi;
	}

	@computed
	get isBusy() {
		return this._isBusy || this.groupApi.isBusy;
	}

	@observable
	_isBusy: boolean = false;

	@action
	async saveNew(model: GroupModel): Promise<GroupModel> {
		this._isBusy = true;
		try {
			const g = await this.service.create(model);
			return g;
		} finally {
			runInAction(() => {
				this._isBusy = false;
			});
		}
	}

	@action
	async createDemoGroup() {
		const current = this.authStore.current;
		if (current && current.id) {
			const demoGroupModel: GroupModel = new GroupModel({
				title: t('general:groups.demo.title'),
				userId: current.id,
			});
			return await this.saveNew(demoGroupModel);
		}
	}

	isImageInUse(file: FileModel) {
		return this.groupApi.list.some((group) => group.titleImage && group.titleImage.md5Hash === file.md5Hash);
	}
}
