import { AuthStore } from './auth.store';
import CRUDSrvStore from './common/crud.service.store';
import { ProjectModel } from 'shared-puredio';
import { makeObservable } from 'mobx';
import { TemplateApi } from 'app/api/template.api';

export class TemplateStore extends CRUDSrvStore<ProjectModel> {
	authStore: AuthStore;
	templateApi: TemplateApi;

	constructor(authStore: AuthStore, templateApi: TemplateApi) {
		super(authStore, templateApi);
		makeObservable(this);
		this.authStore = authStore;
		this.templateApi = templateApi;
	}
}
