import * as React from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import colors from 'assets/scss/colors.module.scss';

interface ILayerPlayhead {
	project: ProjectVM;
}

// don't use a state variable: the dom event listeners don't understand it
class PressStateStore {
	pressed = false;
}
const pressState = new PressStateStore();

export const ProjectLayerPlayHead = observer((props: ILayerPlayhead) => {
	const { animatorStore, uiStore } = useStore();
	const project = props.project;
	const audioLayer = project.model.audioLayer;

	const [prog, setProg] = useState('0%');

	useEffect(() => {
		const p = (animatorStore.audioStore.currentRelativeTime / audioLayer.duration) * 100;
		setProg(p + '%');
	}, [animatorStore.audioStore.currentRelativeTime, audioLayer.duration]);

	const dndZoneRef = useRef<HTMLDivElement>(null);

	const onMouseDown = (event: any) => {
		pressState.pressed = true;
		event.stopPropagation();
	};

	useEffect(() => {
		const debouncedSetPlayerTime = _.debounce((xPercent: number) => {
			const s = audioLayer.start + (audioLayer.duration * xPercent) / 100;
			animatorStore.seek(s);
		}, 50);

		const onMouseUp = (event: any) => {
			pressState.pressed = false;
			event.stopPropagation();
		};
		const onMouseMove = (event: any) => {
			if (!pressState.pressed) {
				return event;
			}
			if (uiStore.modalIsVisible(uiStore.modalIds.projectCropAudio)) {
				// otherwise peaks does not know when to stop
				return event;
			}
			const mouseX = event.clientX;
			const rect = dndZoneRef.current!.getBoundingClientRect();
			const w = rect.width;

			let x = mouseX - rect.x;
			if (x < 0) {
				x = 0;
			}
			if (x > w) {
				x = w;
			}
			const p = (x / w) * 100;
			if (p > 100) {
				debugger;
			}
			debouncedSetPlayerTime(p);
			event.stopPropagation();
		};

		document.addEventListener('mouseup', onMouseUp);
		document.addEventListener('mousemove', onMouseMove);
		return () => {
			document.removeEventListener('mouseup', onMouseUp);
			document.removeEventListener('mousemove', onMouseMove);
		};
	}, [uiStore, animatorStore, audioLayer.duration, audioLayer.start]);

	return (
		<div className={'view-layer-playhead-dndzone'} ref={dndZoneRef}>
			<PlayheadBackground />
			<div className={'view-layer-playhead'} style={{ left: prog }} onMouseDown={onMouseDown}></div>
		</div>
	);
});

interface IPlayheadBackgroundProps {}

export const PlayheadBackground = observer((props: IPlayheadBackgroundProps) => {
	const backgroundRef = useRef<HTMLCanvasElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const W = 1;
		const H = 15;
		const MARKS = 20;

		if (!backgroundRef.current || !containerRef.current) return;

		const bounds = containerRef.current.getBoundingClientRect();

		const canvas = backgroundRef.current;
		canvas.width = bounds.width;
		canvas.height = 20;

		const ctx = canvas.getContext('2d');
		if (!ctx) return;

		ctx.fillStyle = colors.greyLight;

		for (let i = 0; i <= MARKS; i++) {
			const x = i * ((bounds.width - 1) / MARKS);
			ctx.fillRect(x, 0, W, H);
		}
	}, [backgroundRef]);

	return (
		<div className="playhead-background-container" ref={containerRef}>
			<canvas className="playhead-background" ref={backgroundRef} />
		</div>
	);
});
