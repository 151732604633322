import React, { useEffect, useState } from 'react';
import { useStore } from 'app/context';
import { formatDate, getInitials } from '../../utils';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Table } from '../Table/table';
import { TableModel, TableRow } from '../Table/table.model';
import { RenderStatus } from '../common/RenderStatus';
import { RenderFormat } from '../common/RenderFormat';
import t from './../../common/translate';
import { RenderItemVM } from 'app/stores/viewmodels/render.item.vm';
import { ImageViewer } from 'app/components/common/ImageViewer';
import { GroupModel } from 'shared-puredio';
import { runInAction } from 'mobx';
import PlanRemainingTime from '../payment/PlanRemainingTime';

interface IGroupTitleImage {
	group?: GroupModel;
}

export const GroupTitleImage = observer((props: IGroupTitleImage) => {
	const { fileStore } = useStore();
	const [key, setKey] = useState<string | undefined>(undefined);
	const [initials, setInitials] = useState('');

	useEffect(() => {
		setKey(undefined);
		if (!props.group) return;

		setInitials(getInitials(props.group.title));

		if (!props.group.titleImage) return;

		fileStore.setImageModel(props.group.titleImage).then((res) => {
			if (res) {
				setKey(res.key);
			}
		});
	}, [fileStore, props]);

	if (!key) {
		if (props.group?.title === t('general:groups.demo.title')) {
			return (
				<div className="demo-group-title-container">
					<div className="demo-group-title">{props.group.title}</div>
				</div>
			);
		}

		return <span className="initials">{initials}</span>;
	}

	return <ImageViewer bucketKey={key} />;
});

export const RenderList = observer(() => {
	const { rendersStore } = useStore();
	const history = useHistory();
	const tm = new TableModel<RenderItemVM, string>();

	const cols = [
		{
			label: '',
			path: 'id',
			render: (row: RenderItemVM) => {
				return (
					<div className="group-image">
						<GroupTitleImage group={row.group} />
					</div>
				);
			},
		},
		{
			label: t('general:renderdetail.group'),
			path: 'groupTitle',
		},
		{
			label: t('general:renderdetail.project'),
			path: 'projectTitle',
		},
		{
			label: t('general:renderdetail.format'),
			path: 'fmt',
			render: (row: RenderItemVM) => {
				if (!row.project) {
					return <> </>;
				}
				const fmt = row.project.fmt;
				return <RenderFormat fmt={fmt} hideDimensions={true} />;
			},
		},
		{
			label: t('general:renderdetail.status'),
			path: 'status',
			render: (row: RenderItemVM) => {
				return <RenderStatus mainTask={row.task} project={row.project} />;
			},
		},
		{
			label: t('general:renderdetail.created'),
			path: 'row.created',
			render: (row: RenderItemVM) => {
				return <>{formatDate(row.task.created, 'DD.MM.YYYY HH:MM')}</>;
			},
		},
	];
	tm.setCols(cols);
	tm.sortBy = 'name';
	tm.idProperty = 'id';
	tm.idType = 'string';

	tm.onRowClick = (row: TableRow<RenderItemVM>) => {
		history.push(routes.RENDERS.getPath(row.data.id));
	};

	useEffect(() => {
		runInAction(() => {
			tm.selectedId = rendersStore.selectId;
		});
	}, [rendersStore.selectId, tm.selectedId]);
	useEffect(() => {
		tm.setData(rendersStore.renderItems);
	}, [tm, rendersStore.renderItems]);
	return (
		<>
			<div className="section">
				<div className="pad-bottom-3rem">
					<PlanRemainingTime />
				</div>
				<Table className="render-list" tm={tm}></Table>
			</div>
		</>
	);
});
