import React from 'react';
import FileUploader, { FileUploaderProps } from './FileUploader';
import t from 'app/common/translate';

export type AudioUploadWidgetProps = Pick<FileUploaderProps, 'onUploadSuccess' | 'storageFolder'>;

const AudioUploadWidget: React.FC<AudioUploadWidgetProps> = (props) => {
	return (
		<FileUploader
			maxFiles={1}
			multiple={false}
			accept={'audio/mp3,audio/mpeg'}
			filetype="audio"
			dropZoneActiveText={t('components:audio.upload.dropZoneActiveText')}
			dropZoneText={t('components:audio.upload.dropZoneText')}
			uploadingText={t('components:audio.upload.uploadingText')}
			{...props}
		/>
	);
};

export default AudioUploadWidget;
