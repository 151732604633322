import { observer } from 'mobx-react';
import React from 'react';
import CRUDStore from '../../stores/common/crud.store';
import t from 'app/common/translate';

interface IProps {
	store: CRUDStore<any>;
}

const CrudEntityErrors: React.FC<IProps> = observer((props) => {
	props.store.resetEntity();
	return <CrudEntityErrorsList {...props}></CrudEntityErrorsList>;
});
const CrudEntityErrorsList: React.FC<IProps> = observer((props) => {
	let errors: string[] = [];
	if (props.store.hasEntityErrors) {
		errors = props.store.entityErrors;
	}
	if (errors.length === 0) {
		return <></>;
	}
	return (
		<div>
			{errors.map((err, i) => (
				<strong key={i}>{t('fb:' + err)}</strong>
			))}
		</div>
	);
});

export default CrudEntityErrors;
