import React from 'react';
import { ISnapshot, ISnapshotNavState, GroupModel, FileStatus } from 'shared-puredio';
import { action, comparer, computed, makeObservable, observable } from 'mobx';
import { ProjectModel } from 'shared-puredio';
import { ProjectMenuVM } from './project.menu.vm';

export class ProjectVM {
	constructor(project: ProjectModel) {
		makeObservable(this);
		this.model = project;
		this.menu = new ProjectMenuVM(this.model);
	}

	@observable
	menu: ProjectMenuVM;

	@observable
	currentSidePanel?: string;
	canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef<HTMLCanvasElement>();

	@observable
	group?: GroupModel;

	@observable
	model: ProjectModel;

	@observable
	selectedLayerKey?: string;

	@computed
	get id() {
		return this.model.id;
	}

	@observable
	scaleRatio: number = 1;

	@observable
	canvasCursor: string = '';

	@computed
	get canRender() {
		if (this.model.audioLayer.audioFile) {
			if (this.model.audioLayer.audioFile.fileStatus === FileStatus.completed) {
				return true;
			}
		}
		return false;
	}

	@action
	toggleSelectedAnimationLayer = (layerKey?: string) => {
		if (this.selectedLayerKey) {
			if (this.selectedLayerKey === layerKey) {
				this.selectedLayerKey = undefined;
				return;
			}
		}
		this.selectedLayerKey = layerKey;
	};

	@action
	setScaleRatio = (ratio: number) => {
		this.scaleRatio = ratio;
	};

	/// SNAPSHOT HANDLING
	@computed
	get navState(): ISnapshotNavState {
		return {
			projectId: this.model.id,
			panel: this.currentSidePanel,
		};
	}

	@computed
	get lastSnap(): ISnapshot | undefined {
		return this.history[this.history.length - 1];
	}

	@computed({ equals: comparer.structural })
	get history(): ISnapshot[] {
		let unsorted: ISnapshot[] = [];
		unsorted = unsorted.concat(this.model.history);
		if (this.model.layers) {
			this.model.layers.forEach((l) => {
				unsorted = unsorted.concat(l.history);
			});
		}
		if (this.model.audioLayer) {
			unsorted = unsorted.concat(this.model.audioLayer.history);
		}
		unsorted = unsorted.sort((h1, h2) => h1.time - h2.time);
		return unsorted;
	}

	@observable
	projectLevelSnapShotApplied?: Date;
	@action
	undo(): ISnapshot | undefined {
		let snap = this.lastSnap;
		if (!snap) {
			return;
		}
		if (snap.key === 'project') {
			this.projectLevelSnapShotApplied = new Date();
			return this.model.historyBack();
		} else if (snap.key === 'audiolayer') {
			this.projectLevelSnapShotApplied = new Date();
			return this.model.audioLayer!.historyBack();
		} else {
			const layer = this.model.layers.find((l) => l.key === snap!.key);
			if (!layer) {
				console.warn('layer ' + snap.key + ' to undo not found ');
				return;
			}
			layer.historyBack();
		}
	}
}
