import { useStore } from 'app/context';
import { formatTimeToMinutes } from 'app/utils';
import { observer } from 'mobx-react';
import React from 'react';
import t from 'app/common/translate';
import ProgressRing from '../common/ProgressRing';
import colors from 'assets/scss/colors.module.scss';
import { Button } from '../common/Button';
import { useHistory } from 'react-router-dom';

interface IPlanRemainingTimeProps {
	willExceed?: boolean;
	hideButton?: boolean;
}

const PlanRemainingTime: React.FC<IPlanRemainingTimeProps> = observer(({ ...props }) => {
	const history = useHistory();
	const { usageStore, paddleStore, uiStore } = useStore();
	const { willExceed, hideButton } = props;

	let percentage = (usageStore.currentTimeUsageSeconds / usageStore.userVideoSecondsQuota) * 100;
	const isDepleted = percentage >= 100 || willExceed;
	const color = !isDepleted ? colors.primary : colors.red;

	let title = t('payment:plan.remaining.title');
	let message = t('payment:plan.remaining.text', { time: formatTimeToMinutes(usageStore.remainingSeconds) });

	if (isDepleted) {
		title = t('payment:plan.depleted.title');
		message = t('payment:plan.depleted.text');
	}

	if (willExceed) {
		title = t('common:notice');
		message = t('general:render.quota.error');
	}

	const user = paddleStore.authStore.current;
	if (!user) return null;

	const handleUpgrade = () => {
		history.push('/account/payment');
	};

	if (!uiStore.showPayments) {
		return <></>;
	}

	return (
		<>
			<div className="plan-remaining-time">
				<div className="indicator">
					<ProgressRing modifier="is-static" color={color} percentage={percentage} radius={50} stroke={7} />
				</div>
				<div className="details">
					<h4 className="title is-5">{title}</h4>
					<p>{message}</p>
				</div>
				{(isDepleted || willExceed) && (
					<>
						{!hideButton && (
							<div className="upgrade">
								<Button className="button is-primary" onClick={handleUpgrade}>
									{t('payment:plan.upgrade')}
								</Button>
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
});

export default PlanRemainingTime;
