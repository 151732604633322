import { observer } from 'mobx-react';
import React from 'react';
import { ProjectModel } from 'shared-puredio';
import { ImageViewer } from '../common/ImageViewer';

interface IProjectItemProps {
	project: ProjectModel;
}

export const ProjectPreviewImage: React.FC<IProjectItemProps> = observer((props: IProjectItemProps) => {
	const model = props.project;

	return <>{model.previewKey && <ImageViewer bucketKey={model.previewKey} alt={model.title} />}</>;
});
