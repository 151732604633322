import { Point, Path } from 'paper';
import { degToRad } from '../utils/DrawingHelpers';
import BaseAudiogram from './BaseAudiogram';

export default class CircleWave extends BaseAudiogram {
    public initAnimation = () => {
        this.toAverage = 5;
    };

    public drawAudiogram(points) {
        // calculate the needed geo
        const color = this.color || '#FFFFFF';
        const centerX = this.anim.fmt.w / 2;
        const centerY = this.anim.fmt.h / 2;
        const amplitudeModifier = 0.15;
        const amplitude = this.calc({ v: this.amplitude * amplitudeModifier, d: 'h' });

        const interval = Math.floor(360 / this.toAverage);

        this.group.removeChildren();

        for (let x = 0; x < points.length; x++) {
            let path = new Path({
                strokeColor: color,
                strokeWidth: 3,
                opacity: 0.5,
            });

            for (let q = 0; q < points[x].length; q++) {
                let velocity = points[x][q] * amplitude;
                velocity += 150;
                const xVal = centerX + Math.cos(degToRad(q * interval)) * velocity;
                const yVal = centerY + Math.sin(degToRad(q * interval)) * velocity;
                path.add(new Point(xVal, yVal));
            }

            path.closePath();
            path.smooth();
            this.group.addChild(path);
        }
    }
}
