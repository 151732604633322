import * as React from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import ProjectRenderControls from 'app/components/Project/Edit/ProjectRenderControls';
import { ProjectLayerItem } from './ProjectLayers';
import { ProjectWaveform } from './ProjectWaveform';
import { ProjectLayerPlayHead } from './ProjectLayerPlayhead';
import { ProjectPlaybackControls } from './ProjectPlaybackControls';
import { ProjectLayerAdd } from './ProjectLayerAdd';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Button } from 'app/components/common/Button';
import t from 'app/common/translate';
import { SvgIcon, SvgIconType } from 'app/components/common/SvgIcon';

interface IProps {
	projectVM: ProjectVM;
}

const PreviewControls = observer((props: IProps) => {
	const { uiStore } = useStore();
	const history = useHistory();
	const projectVM = props.projectVM;
	const layers = projectVM.model.animLayers.sort((a, b) => b.sort - a.sort);
	const [cropModalOpen, setCropModalOpen] = React.useState<boolean>(false);
	const cropAudioModalId = uiStore.modalIds.projectCropAudio;

	useEffect(() => {
		if (uiStore.modalIsVisible(cropAudioModalId)) {
			setCropModalOpen(true);
		} else {
			setCropModalOpen(false);
		}
	}, [uiStore.visibleModals, uiStore.visibleModals.length, cropAudioModalId, uiStore]);

	const onSelectAudioLayer = () => {
		const p = routes.EDITPROJECT.getPath(projectVM.id, 'audio');
		history.push({
			pathname: p,
			search: '?panel=audio',
		});
	};

	return (
		<>
			<div className={`preview-controls`}>
				<div className="controls-container">
					<div className="add-layer">
						<ProjectLayerAdd project={projectVM} />
					</div>
					<div className="playback-controls">
						<ProjectPlaybackControls />
					</div>

					<div className="render-controls">
						<ProjectRenderControls project={projectVM} />
					</div>
				</div>

				<div className="project-playhead">
					<div className="project-layer-row">
						<div className="controls"></div>
						<div className="timeline">
							{!cropModalOpen && (
								// Playhead event listener has a conflict with the peaks cropper
								<ProjectLayerPlayHead project={projectVM} />
							)}
						</div>
					</div>
				</div>

				<div className="project-layers">
					{layers.map((layer) => (
						<ProjectLayerItem key={layer.key} project={projectVM} layer={layer} />
					))}
				</div>

				<div className="project-waveform-container">
					<div className="project-layer-row">
						<div className="controls">
							<Button className="button is-primary is-inverted is-smallish audio-settings-button" onClick={onSelectAudioLayer}>
								<SvgIcon iconName={SvgIconType.audioSettingsAlt} />
								<span>{t('general:project.audio.settings')}</span>
							</Button>
						</div>
						<div className="timeline">
							<ProjectWaveform project={projectVM} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
});

export default PreviewControls;
