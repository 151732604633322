import Icon from 'app/components/common/Icon';
import * as React from 'react';

interface IDetailPanelProps {
	onClose: () => void;
	title: any;
	subtitle?: any;
	width?: string;
}

const DetailPanel: React.FC<IDetailPanelProps> = ({ title, subtitle, onClose, children, width }) => {
	const style: any = {};
	if (width) {
		style['width'] = width;
	}
	return (
		<div className={`detail-panel`} style={style}>
			<div className="header">
				<button className="button is-icon close-icon" onClick={onClose}>
					<Icon iconName="long-arrow-left" faModifier="fal" />
				</button>
				<div className="header-content">
					<div className="title is-5">{title}</div>
					{subtitle && <div className="subtitle is-5">{subtitle}</div>}
				</div>
			</div>
			{children}
		</div>
	);
};

export default DetailPanel;
