import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { SideMenuAdmin } from 'app/components/SideMenuAdmin';
import { NotFound } from 'App';
import { runInAction } from 'mobx';
import { useParams } from 'react-router';
import { AdminFilesList } from 'app/components/admin/files/AdminFilesList';
import { AdminFileDetailPanel } from 'app/components/admin/files/AdminFileDetailPanel';

export interface AdminUsersParamTypes {
	id?: string;
}

const AdminFilesContainer: React.FC<IProps> = observer(() => {
	const { adminUserStore, adminFilesStore } = useStore();

	const { id } = useParams<AdminUsersParamTypes>();

	useEffect(() => {
		if (id) {
			adminFilesStore.loadById(id);
		} else {
			runInAction(() => {
				adminFilesStore.current = undefined;
			});
		}
	}, [id, adminFilesStore]);

	return (
		<>
			{adminUserStore.isAdmin && (
				<BaseContainer menu={<SideMenuAdmin />}>
					<AdminFilesList />
					{adminFilesStore.current && <AdminFileDetailPanel />}
				</BaseContainer>
			)}
			{!adminUserStore.isAdmin && <NotFound />}
		</>
	);
});

export default AdminFilesContainer;
