import React from 'react';
import { SvgIcon, SvgIconType } from '../common/SvgIcon';
import t from 'app/common/translate';

interface IPaddleInfo {
	modifier?: string;
}
export const PaddleInfo = (props: IPaddleInfo) => {
	const { modifier } = props;

	return (
		<div className={`paddle-info ${modifier || ''}`}>
			<SvgIcon iconName={SvgIconType.paddleLogo} />
			<span>{t('payment:message.weUsePaddle')}</span>
		</div>
	);
};
