import { AuthStore } from './auth.store';
import CRUDSrvStore from './common/crud.service.store';
import { ExportTaskModel, GroupModel, ProjectModel, TaskType } from 'shared-puredio';
import { action, computed, makeObservable, observable } from 'mobx';
import { ProjectApi } from 'app/api/project.api';
import { GroupApi } from 'app/api/group.api';
import { RenderItemVM } from './viewmodels/render.item.vm';
import { TasksMainApi } from 'app/api/tasks.main.api';

export class RendersStore extends CRUDSrvStore<ExportTaskModel> {
	authStore: AuthStore;
	tasksMainApi: TasksMainApi;
	groupApi: GroupApi;
	projectApi: ProjectApi;

	constructor(authStore: AuthStore, tasksMainApi: TasksMainApi, projectApi: ProjectApi, groupApi: GroupApi) {
		super(authStore, tasksMainApi);
		makeObservable(this);
		this.authStore = authStore;
		this.tasksMainApi = tasksMainApi;
		this.projectApi = projectApi;
		this.groupApi = groupApi;
	}

	@computed
	get selected(): RenderItemVM | undefined {
		return this.renderItems.find((i) => i.id === this.selectId);
	}
	@computed
	get selectedeOutputVideokey() {
		const s = this.selected;
		if (s) {
			return s.outputVideokey;
		}
		return undefined;
	}

	@computed
	get selectedProjectId(): string | undefined {
		const s = this.selected;
		if (s && s.project) {
			return s.project.id;
		}
		return undefined;
	}

	@observable selectId?: string;

	@action
	setSelectedById(id?: string) {
		this.selectId = id;
	}

	@computed
	get renderItems(): RenderItemVM[] {
		return this.mainTasks.map((t) => {
			let p: ProjectModel | undefined;
			let g: GroupModel | undefined;
			const po = t.opts.project;
			if (po) {
				p = new ProjectModel(po, []);
			} else {
				p = this.projectApi.list.find((p) => p.id === t.projectId);
			}

			if (p) {
				g = this.groupApi.list.find((g) => g.id === p!.groupId);
			}

			return new RenderItemVM(t, p, g);
		});
	}

	getLatestMainTaskForProject(projectId: string) {
		return this.mainTasks.find((t) => t.projectId === projectId);
	}

	@computed
	get mainTasks(): ExportTaskModel[] {
		return this.list
			.filter((t) => t.type === TaskType.export)
			.sort((b, a) => {
				return a.created.getTime() - b.created.getTime();
			});
	}
}
