import { AdminMailApi } from 'app/api/mail.api';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { MailModel, UserModel } from 'shared-puredio';
import { AdminUserStore, AdminUserVM } from './admin.user.store';

export class AdminMailVm {
	constructor(p: MailModel, u?: UserModel) {
		makeObservable(this);
		this.mail = p;
		this.user = u;
	}
	@observable
	mail: MailModel;
	user?: UserModel;
}

export class AdminMailsStore {
	adminMailApi: AdminMailApi;
	adminUserStore: AdminUserStore;

	constructor(adminMailApi: AdminMailApi, adminUserStore: AdminUserStore) {
		makeObservable(this);
		this.adminMailApi = adminMailApi;
		this.adminUserStore = adminUserStore;
	}

	@observable
	items: AdminMailVm[] = observable([]);

	@observable
	current?: AdminMailVm = undefined;

	@action
	loadById(mailId: string) {
		this.current = this.items.find((i) => i.mail.id === mailId);
	}

	async load() {
		if (this.items.length > 0) {
			return this.items;
		}
		const scope = {
			userId: 'userId',
		};
		const p: any = [];
		p.push(this.adminMailApi.listAll(scope));
		p.push(this.adminUserStore.load());

		const res = await Promise.all(p);

		const mails = res[0] as MailModel[];
		const users = res[1] as AdminUserVM[];

		const items = mails.map((m) => {
			const uVM = users.find((u) => u.user.fakeId === m.userId);
			const u = uVM ? uVM.user : undefined;
			return new AdminMailVm(m, u);
		});

		runInAction(() => {
			this.items = items;
		});
		return this.items;
	}
}
