import React from 'react';
import { observer } from 'mobx-react';
import { AggregrationType, TableAggregrateColumn, TableColumn, TableFilterItem, TableModel } from './table.model';
import _ from 'lodash';
import Icon from '../common/Icon';

interface ITableCell<T, P> {
	row: any;
	column: TableColumn<T, P>;
}

interface ITable<T, P> {
	tm: TableModel<T, P>;
	className?: string;
}

const TableCell = observer((props: ITableCell<any, any>) => {
	if (props.column.render) {
		return props.column.render(props.row);
	}
	const v = _.get(props.row, props.column.path);
	return <>{v}</>;
});

interface ITableHeader<T, P> {
	tm: TableModel<T, P>;
	column: TableColumn<T, P>;
}

const TableHeader = observer((props: ITableHeader<any, any>) => {
	const sort = () => {
		props.tm.setSortBy(props.column.sortProp);
		props.tm.sort();
	};
	const isSorting = props.column.sortProp === props.tm.sortBy;

	return (
		<th onClick={sort}>
			{Array.isArray(props.column.label) ? (
				<>
					{props.column.label.map((label, i) => (
						<div key={i}>{label}</div>
					))}
				</>
			) : (
				<span>{props.column.label}</span>
			)}
			{/* {isSorting && <>S-{props.tm.sortAsc ? <>ASC</> : <>DESC</>}</>} */}
			{isSorting && (
				<span className="sort-icon">
					<Icon iconName={props.tm.sortAsc ? 'caret-up' : 'caret-down'} />
				</span>
			)}
		</th>
	);
});

export const Table = observer((props: ITable<any, any>) => {
	const tm = props.tm;
	return (
		<>
			<TableFilter tm={tm} />

			{tm.data.length !== tm.rows.length && <>Filtered: {tm.rows.length}</>}

			<table className={`table ${props.className}`}>
				<thead>
					<tr>
						{tm.columns.map((col) => (
							<TableHeader key={col.path} column={col} tm={tm} />
						))}
					</tr>
				</thead>
				<tbody>
					{tm.rows.map((row, i) => (
						<tr className={row.selected ? 'is-selected' : ''} onClick={() => tm.onRowClick(row)} key={'row_' + i.toString()}>
							{tm.columns.map((col, j) => (
								<td key={'cell_' + i.toString() + '_' + j.toString()}>
									<TableCell row={row.data} column={col} />
								</td>
							))}
						</tr>
					))}
				</tbody>
				{tm.showFooter && (
					<tfoot>
						{tm.showSum && (
							<tr>
								{tm._showIndx && <th>index</th>}
								{tm.columns.map((col, i) => (
									<TableFooterCell key={'foot_' + i} column={col} tm={tm} />
								))}
							</tr>
						)}
					</tfoot>
				)}
			</table>
		</>
	);
});

const TableFooterCell = observer((props: ITableHeader<any, any>) => {
	if (!props.column.aggs) {
		return <td></td>;
	}
	return (
		<td>
			{props.column.aggs.map((agg, i) => (
				<TableFooterAgg key={i.toString()} agg={agg} />
			))}
		</td>
	);
});

interface ITableFooterAgg {
	agg: TableAggregrateColumn<any, any>;
}
const TableFooterAgg = observer((props: ITableFooterAgg) => {
	const agg = props.agg;
	if (agg.visible === false) {
		return <></>;
	}
	if (agg.render) {
		return agg.render(props.agg.tm);
	}

	let symbol = (
		<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.928828 12.6756L0.649994 13.1068L1.13238 14H9.69816C10.2158 14 10.7123 13.7935 11.0783 13.4259C11.4444 13.0584 11.65 12.5598 11.65 12.04V11.2H9.97699V12.04C9.97699 12.1143 9.94761 12.1855 9.89532 12.238C9.84303 12.2905 9.77211 12.32 9.69816 12.32H3.19575L6.82059 7L3.19575 1.68L9.69816 1.68C9.77211 1.68 9.84303 1.7095 9.89532 1.76201C9.94761 1.81452 9.97699 1.88574 9.97699 1.96V2.8H11.65V1.96C11.65 1.44018 11.4444 0.941642 11.0783 0.574071C10.7123 0.2065 10.2158 0 9.69816 0H1.63428H1.12401L0.649994 0.9044L4.78231 7L0.928828 12.6756Z"
				fill="black"
			/>
		</svg>
	);
	let aggTitle = 'Summe';

	if (agg.aggType === AggregrationType.avg) {
		symbol = (
			<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.4632 2.38537L11.2614 1.01403L10.1971 0.399994L9.4193 1.77134C8.64152 1.42339 7.78187 1.21871 6.88128 1.21871C3.48362 1.21871 0.699997 3.98187 0.699997 7.39999C0.699997 9.46725 1.72339 11.2889 3.27894 12.4146L2.4807 13.786L3.54503 14.4L4.3228 13.0287C5.10058 13.3766 5.96023 13.5813 6.86082 13.5813C10.2585 13.5813 13.0421 10.8181 13.0421 7.39999C13.0421 5.33274 12.0187 3.51111 10.4632 2.38537ZM1.9076 7.39999C1.9076 4.67777 4.11813 2.44678 6.86082 2.44678C7.53626 2.44678 8.19123 2.59005 8.78479 2.83567L3.87251 11.3298C2.68538 10.4292 1.9076 9.01695 1.9076 7.39999ZM6.86082 12.3532C6.18538 12.3532 5.53041 12.2099 4.93684 11.9643L9.84912 3.47017C11.0363 4.37075 11.814 5.8035 11.814 7.42046C11.814 10.1222 9.58304 12.3532 6.86082 12.3532Z"
					fill="black"
				/>
			</svg>
		);
		aggTitle = 'Durchschnitt';
	}
	if (agg.aggType === AggregrationType.count) {
		symbol = (
			<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M14.0302 0.0352478H0.372663C0.17626 0.0352478 0.0251808 0.186327 0.0251808 0.382729V2.01438C0.0251808 2.21078 0.17626 2.36186 0.372663 2.36186H14.0151C14.2115 2.36186 14.3626 2.21078 14.3626 2.01438V0.382729C14.3777 0.186327 14.2266 0.0352478 14.0302 0.0352478Z"
					fill="black"
				/>
				<path
					d="M14.0302 3.90288H0.372663C0.17626 3.90288 0.0251808 4.05396 0.0251808 4.25036V5.88201C0.0251808 6.07842 0.17626 6.2295 0.372663 6.2295H14.0151C14.2115 6.2295 14.3626 6.07842 14.3626 5.88201V4.25036C14.3777 4.05396 14.2266 3.90288 14.0302 3.90288Z"
					fill="black"
				/>
				<path
					d="M14.0302 7.77049H0.372663C0.17626 7.77049 0.0251808 7.92157 0.0251808 8.11797V9.74962C0.0251808 9.94602 0.17626 10.0971 0.372663 10.0971H14.0151C14.2115 10.0971 14.3626 9.94602 14.3626 9.74962V8.11797C14.3777 7.92157 14.2266 7.77049 14.0302 7.77049Z"
					fill="black"
				/>
				<path
					d="M14.0302 11.6381H0.372663C0.17626 11.6381 0.0251808 11.7892 0.0251808 11.9856V13.6173C0.0251808 13.8137 0.17626 13.9647 0.372663 13.9647H14.0151C14.2115 13.9647 14.3626 13.8137 14.3626 13.6173V11.9856C14.3777 11.7892 14.2266 11.6381 14.0302 11.6381Z"
					fill="black"
				/>
			</svg>
		);
		aggTitle = 'Anzahl';
	}

	if (agg.aggType === AggregrationType.empty) {
		symbol = <></>;
		aggTitle = '';
	}
	return (
		<div className="aggregation-value" title={aggTitle}>
			<span className="agg-icon">{symbol}</span>
			<span className="agg-value">{agg.doFormat(agg.aggValue)}</span>
		</div>
	);
});

interface ITableFilter<T, P> {
	tm: TableModel<T, P>;
}
export const TableFilter = observer((props: ITableFilter<any, any>) => {
	const tm = props.tm;
	if (tm.filters.length === 0) {
		return <></>;
	}

	return (
		<>
			{tm.filters.map((f) => (
				<TableFilterCell key={f.label} tm={tm} filter={f} />
			))}
		</>
	);
});

interface ITableFilterCell<T, P> {
	tm: TableModel<T, P>;
	filter: TableFilterItem;
}

export const TableFilterCell = observer((props: ITableFilterCell<any, any>) => {
	const f = props.filter;

	if (!f.component) {
		return <>No Filter Component</>;
	}

	const Component = f.component.type;

	const onFilterChanged = () => {
		props.tm.setData(props.tm.data);
	};

	return (
		<div>
			<Component filter={f} onFilterChanged={onFilterChanged} />
		</div>
	);
});
