import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/common/Modal';
import FormField from 'app/components/Form/FormField';
import { useStore } from 'app/context';
import { Formik, Form, Field } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import t from '../../common/translate';
import * as Yup from 'yup';
import { passwordRegex } from '../../utils';
import { notify } from 'app/common/notify';
import { useHistory } from 'react-router';
import routes from 'routes';

interface IPasswordUpdateModalProps {
	modalId: string;
}
export const UpdatePasswordModal: React.FC<IPasswordUpdateModalProps> = observer((props: IPasswordUpdateModalProps) => {
	const updatePasswordFormSchema = Yup.object()
		.shape({
			password1: Yup.string().matches(passwordRegex, t('validation:password.complexity')).required(t('validation:required.field')).default(''),
			password2: Yup.string()
				.oneOf([Yup.ref('password1')], t('validation:passwords.must.match'))
				.required(t('validation:required.field'))
				.default(''),
		})
		.required();

	type UpdatePassword = Yup.InferType<typeof updatePasswordFormSchema>;

	const { authStore } = useStore();
	const history = useHistory();

	const handleSubmit = async (values: UpdatePassword) => {
		const newPassword = values.password1;
		const res = await authStore.updatePassword(newPassword);

		// success seems to return undefined
		// console.log(res);
		if (res === undefined) {
			notify(t('auth:resetpassword.success'), 'success');
			notify(t('auth:please.login.again'));
			await authStore.signOut();
			history.push(routes.AUTH_LOGIN.getPath());
			return;
		}

		notify(t(res.message), 'error');
	};

	return (
		<>
			<Modal title={t('auth:resetpassword.submit')} size="medium" modalId={props.modalId}>
				<Formik validationSchema={updatePasswordFormSchema} initialValues={{ password1: '', password2: '' }} onSubmit={handleSubmit}>
					{({ errors, touched }) => (
						<Form>
							<FormField label={t('auth:password')} error={errors.password1} touched={touched.password1}>
								<Field type="password" name="password1" />
							</FormField>
							<FormField label={t('auth:password.repeat')} error={errors.password2} touched={touched.password2}>
								<Field type="password" name="password2" />
							</FormField>
							<FormField>
								<Button type="submit" className="button is-primary">
									{t('auth:resetpassword.submit')}
								</Button>
							</FormField>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
});
