import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Button } from '../common/Button';
import t from '../../common/translate';
import { SvgIcon, SvgIconType } from '../common/SvgIcon';

export const SignOutButton: React.FC = observer(() => {
	const store = useStore();
	const history = useHistory();
	if (!store.authStore.current) {
		return <></>;
	}

	const onSignOut = async () => {
		await store.authStore.signOut();
		store.uiStore.hideModal('logout_modal');
		history.push(routes.AUTH_LOGIN.getPath());
	};
	return (
		<div className="nav-item signout">
			<Button className="blank-button signout-button" onClick={onSignOut}>
				<SvgIcon iconName={SvgIconType.signout} />
				<span>{t('auth:signout')}</span>
			</Button>
		</div>
	);
});
