import { IProps, withStore } from 'app/context';
import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import t from '../../common/translate';
import { observer } from 'mobx-react';
import FormField from '../Form/FormField';
import { AuthStore } from '../../stores/auth.store';
import { Link, Redirect } from 'react-router-dom';
import routes from 'routes';
import CrudEntityErrors from '../Form/CrudEntityErrors';
import { Button } from '../common/Button';
import { Trans } from 'react-i18next';
import Icon from '../common/Icon';

const loginFormSchema = Yup.object()
	.shape({
		email: Yup.string().email(t('validation:invalid.email')).required(t('validation:required.field')).default(''),
		password: Yup.string().required(t('validation:required.field')).default(''),
	})
	.required();

type Login = Yup.InferType<typeof loginFormSchema>;

@withStore
@observer
export default class LoginForm extends React.Component<Partial<IProps>> {
	authStore: AuthStore;
	constructor(props: IProps) {
		super(props);
		this.authStore = this.props.store!.authStore;
	}

	async handleSubmit(values: Login) {
		await this.authStore.login({ ...values });
	}

	public render() {
		if (this.authStore.current) {
			return <Redirect to={routes.LISTGROUPS.getPath()} />;
		}

		return (
			<div className="auth-form is-login">
				<div className="form-container">
					<div className="form-container-bg">
						<div className="auth-form-header">
							<img src="/images/brand-with-signet.svg" alt="Puredio"></img>
							<h1 className="title is-1">{t('auth:login.welcomeback')}</h1>
						</div>
						<Formik validationSchema={loginFormSchema} initialValues={{ email: '', password: '' }} onSubmit={this.handleSubmit.bind(this)}>
							{({ errors, touched, isValidating }) => (
								<Form>
									<FormField error={errors.email} touched={touched.email}>
										<Field type="text" name="email" placeholder={t('auth:email')} autoComplete="username" />
									</FormField>
									<FormField error={errors.password} touched={touched.password}>
										<Field type="password" name="password" placeholder={t('auth:password')} autoComplete="current-password" />
									</FormField>
									<div className="form-control">
										<Button type="submit" className="button is-primary is-block" disabled={this.authStore.isEntityLoading}>
											<span>{t('auth:login.submit')}</span>
											<Icon iconName="arrow-right" faModifier="far" />
										</Button>
									</div>

									<CrudEntityErrors store={this.authStore} />
								</Form>
							)}
						</Formik>
						<div className="auth-links">
							<div className="auth-link">
								<Trans i18nKey={'auth:donthaveanaccount.linked'}>
									Bla<Link to={routes.AUTH_REGISTER.getPath()}>{t('auth:register')}</Link>
								</Trans>
							</div>
							<div className="auth-link">
								<Trans i18nKey={'auth:forgotpassword.linked'}>
									Bla<Link to={routes.AUTH_FORGOTPASSWORD.getPath()}>{t('auth:requestNewPassword')}</Link>
								</Trans>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
