import React, { useEffect, useRef } from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { runInAction } from 'mobx';
import { useStore } from 'app/context';
import * as _ from 'lodash';
import BucketPath from 'shared-puredio/src/models/bucket.path';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';

// async transition prevention
// taken from https://stackoverflow.com/a/66659233/198402
export const useBlock = (func: Function) => {
	const { block, push, location } = useHistory();
	const lastLocation = useRef<any>();

	const funcRef = useRef<any>();
	funcRef.current = func;

	useEffect(() => {
		if (location === lastLocation.current || !funcRef.current) return;
		lastLocation.current = location;

		const unblock = block((location, action) => {
			const doBlock = async () => {
				if (!(await funcRef.current(location, action))) {
					unblock();
					push(location);
				}
			};
			doBlock();
			return false;
		});
	}, [location, block, push]);
};

interface IProjectEdit {
	project: ProjectVM;
}

export const ProjectAutoSaveImageOnExit = observer((props: IProjectEdit) => {
	const { bucketStore, authStore, projectStore } = useStore();
	const currentUser = authStore.current;
	const vm = props.project;

	const saveImage = async (location: any) => {
		if (vm.canvasRef.current && !currentUser!.isTemplateManager) {
			const resizedCanvas = document.createElement('canvas');
			const resizedContext = resizedCanvas.getContext('2d');

			const fmt = vm.model.fmt;
			const w = fmt.w / 4;
			const h = fmt.h / 4;
			resizedCanvas.width = w;
			resizedCanvas.height = h;
			resizedContext!.drawImage(vm.canvasRef.current, 0, 0, w, h);
			const imageDataUrl = resizedCanvas.toDataURL();
			const previewKey = BucketPath.projectPreviewKey(vm.model);
			bucketStore.invalidateUrl(previewKey);
			const res = await bucketStore.uploadDataUrl(imageDataUrl, previewKey, false);
			if (res.key) {
				console.log('saving preview image to', res.key);
				runInAction(() => {
					vm.model.previewKey = res.key;
				});
				await projectStore.save(vm.model);
			}
		}
	};

	useBlock(async (location: any) => await saveImage(location));

	return <></>;
});
