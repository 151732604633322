import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import uuid from 'short-uuid';

export interface IAnimatedWord {
    s: number;
    e: number;
    w: string;
    st: number;
}

export interface IAnimatedPhrase {
    id?: string;
    s: number;
    e: number;
    w: string;
    text?: string;
    st: number;
}

const secToTimePicker = (time: number) => {
    // const h = Math.floor(time / 3600);
    const m = Math.floor((time % 3600) / 60).toString();
    const s = Math.floor((time % 3600) % 60).toString();
    const p = m.padStart(2, '0') + ':' + s.padStart(2, '0');
    return p;
};

export class AnimatedPhrase {
    constructor(opts: IAnimatedPhrase) {
        makeObservable(this);
        if (opts.id) {
            this.id = opts.id;
        } else {
            this.id = uuid.generate();
        }
        this.start = opts.s;
        this.end = opts.e;
        this.minStart = this.start;
        this.maxEnd = this.end;
        this.text = opts.w ? opts.w : opts.text;
        this.st = opts.st;
    }
    @observable id: string;

    @observable start: number;
    @observable end: number;
    @observable minStart: number;
    @observable maxEnd: number;
    @observable text: string;
    @observable st: number;

    @observable isPause?: boolean = false;
    @observable isLast: boolean = false;
    @observable isActive?: boolean = false;

    @action
    setStart(t: number) {
        if (t < this.minStart) {
            t = this.minStart;
        }
        if (t > this.maxEnd) {
            return;
        }
        if (this.end - t < 1) {
            t = this.end - 1;
        }
        this.start = t;
    }

    @action
    setEnd(t: number) {
        if (t > this.maxEnd && !this.isLast) {
            t = this.maxEnd;
        }
        if (t < this.minStart) {
            return;
        }
        if (t - this.start < 1) {
            t = this.start + 1;
        }
        this.end = t;
    }

    @computed
    get startTimePicker() {
        return secToTimePicker(this.start);
    }

    @computed
    get endTimePicker() {
        return secToTimePicker(this.end);
    }
    serialize() {
        return {
            id: this.id,
            s: this.start,
            e: this.end,
            w: this.text,
            st: this.st,
        };
    }
}

export class LayerAnimatedTextModel {
    constructor() {
        makeObservable(this);
    }
    subs: IAnimatedWord[] = [];

    @action
    setPhrases(items: IAnimatedPhrase[]) {
        if (!items || !items.map) {
            this.phrases = [];
            return;
        }
        let phrases = items.map((i) => new AnimatedPhrase(i));
        this.phrases = _.sortBy(phrases, 'start');
        this.setPausesAndBoundries();
    }

    @action
    setPhrasesFromOpts(items: AnimatedPhrase[]) {
        if (!items || !items.map) {
            this.phrases = [];
            return;
        }
        this.phrases = _.sortBy(items, 'start');
        this.setPausesAndBoundries();
    }

    @action
    clonePhrases(items: AnimatedPhrase[]) {
        if (!items || !items.map) {
            this.phrases = [];
            return;
        }
        return this.setPhrases(items.map((i) => i.serialize())); // clone
    }

    @action
    setPausesAndBoundries() {
        let phrases = this.phrases.filter((p) => !p.isPause);
        phrases = _.sortBy(phrases, 'start');

        // PAUSES: Removed for the time being
        // let lastEnd = 0;
        // // const pauses = [];

        // phrases.forEach((p) => {
        //     p.minStart = lastEnd;
        //     if (p.start - lastEnd >= 1) {
        //         // const { id, ...pp } = p;
        //         // const pause = new AnimatedPhrase(pp as any);
        //         // runInAction(() => {
        //         //     pause.start = lastEnd;
        //         //     pause.end = p.start;
        //         //     pause.text = '';
        //         //     pauses.push(pause);
        //         //     pause.isPause = true;
        //         // });
        //     }
        //     lastEnd = p.end;
        // });

        phrases = phrases.reverse();
        if (phrases.length > 0) {
            let lastStart = phrases[0].end;
            phrases.forEach((p, i) => {
                if (i === 0) {
                    p.isLast = true;
                }
                p.maxEnd = lastStart;
                lastStart = p.start;
            });
            this.phrases = _.sortBy(phrases, 'start');
        }

        // phrases = phrases.concat(pauses);
    }

    @computed
    get lastId() {
        return this.phrases[this.phrases.length - 1].id;
    }

    @action
    addPhraseAtEnd(defaultText: string) {
        let p = this.phrases.find((p) => p.id === this.lastId) as AnimatedPhrase;
        if (!p) {
            p = {
                s: 0,
                e: 2,
                w: defaultText,
                st: 1,
            } as any;
        }
        let start = p.end;
        const { id, ...x } = p;
        x.text = defaultText;
        const p2 = new AnimatedPhrase(x as any);
        if (this.phrases.length > 0) {
            p2.start = start;
            p2.end = start + 2;
        }
        console.log(p2);
        this.phrases.push(p2);
        this.phrases = _.sortBy(this.phrases, 's');
    }
    @action
    removePhrase(p: AnimatedPhrase) {
        this.phrases = this.phrases.filter((i) => i.id !== p.id);
    }

    @action
    setEndForPhrase(p: AnimatedPhrase, val: number) {
        const i = this.phrases.findIndex((x) => x.id === p.id);
        const nextPhrase = this.phrases[i + 1];
        let minEnd = p.start;
        let maxEnd = nextPhrase ? nextPhrase.start : 999999;
        if (val < minEnd) {
            p.setEnd(minEnd);
            return;
        }
        if (val > maxEnd) {
            p.setEnd(maxEnd);
            return;
        }
        p.setEnd(val);
    }

    @action
    setStartForPhrase(p: AnimatedPhrase, val: number) {
        const i = this.phrases.findIndex((x) => x.id === p.id);
        const prevPhrase = i > 0 ? this.phrases[i - 1] : undefined;
        let minStart = prevPhrase ? prevPhrase.end : 0;
        let maxStart = p.end;
        if (val < minStart) {
            p.setStart(minStart);
            return;
        }
        if (val > maxStart) {
            p.setStart(maxStart);
            return;
        }
        p.setStart(val);
    }

    @observable
    phrases: AnimatedPhrase[] = [];

    getPharseForTime(t: number) {
        return _.find(this.phrases, (p) => t >= p.start && t <= p.end);
    }

    toSecs(seconds: number, nano: number) {
        if (isNaN(Number(nano))) {
            nano = 0;
        }
        if (!seconds) {
            seconds = 0;
        }
        seconds += nano / 1000000000;

        // 4 000 000 00
        const t = seconds;
        if (t === 240.9) {
            // debugger;
        }
        return t;
    }
    // serializePhrases() {
    //     const noPause = this.phrases.filter((x) => !x.isPause);
    //     return noPause.map((p) => {
    //         return {
    //             id: p.id,
    //             s: p.start,
    //             e: p.end,
    //             w: p.text,
    //             st: p.st,
    //         };
    //     });
    // }

    // setSubs(data: any) {
    //     const result = data.results[data.results.length - 1];
    //     const allWords = result.alternatives[0].words;

    //     const subs: IAnimatedWord[] = [];
    //     allWords.forEach((word) => {
    //         subs.push({
    //             s: this.toSecs(word.start_offset.seconds, word.start_offset.nanos),
    //             e: this.toSecs(word.end_offset.seconds, word.end_offset.nanos),
    //             w: word.word,
    //             st: word.speaker_tag ? word.speaker_tag : 1,
    //         });
    //     });

    //     this.subs = subs;
    //     let currPhrase: IAnimatedPhrase = {
    //         s: 0,
    //         e: 0,
    //         w: '',
    //         st: -1,
    //     };
    //     let phrases: IAnimatedPhrase[] = [];

    //     let charCount = 0;

    //     subs.forEach((w) => {
    //         if (w.st !== currPhrase.st || charCount > 35) {
    //             console.log(currPhrase.st, currPhrase.s, currPhrase.e, currPhrase.w, charCount);
    //             charCount = 0;
    //             currPhrase = {
    //                 ...w,
    //             };
    //             phrases.push(currPhrase);
    //         }
    //         currPhrase.w += ' ' + w.w;
    //         currPhrase.e = w.e;
    //         currPhrase.w = currPhrase.w.replace(/(\b\S.+\b)(?=.*\1)/g, '').trim();

    //         charCount = currPhrase.w.replace(/ /g, '').length;
    //     });
    //     phrases = _.sortBy(phrases, 's');

    //     this.phrases = phrases.map((p, i) => new AnimatedPhrase(i, p));
    //     // console.log(JSON.stringify(this.phrases, null, 2));
    // }
}
// 0 800002000
// 800002000 200005000
// 166666.66666666666
