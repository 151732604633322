import moment from 'moment';
export const formatDate = (date?: Date, format?: string) => {
	if (!date) {
		return '';
	}
	const mom = moment(date);
	const f = format ? format : 'YYYY-MM-DD';
	return mom.format(f);
};

export const formatCurrency = (p: number, currency?: string) => {
	if (!currency) {
		currency = ' €';
	}

	return Math.round(p * 100) / 100 + currency;
};

export const getInitials = (name: string) => {
	let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
	if (!name) {
		return '';
	}

	// @ts-ignore
	let initials = [...name.matchAll(rgx)] || [];
	initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

	return initials.toString();
};

// from stackoverflow.com/questions/37096367/how-to-convert-seconds-to-minutes-and-hours-in-javascript
export const formatTime = (timeInSecs: number) => {
	const h = Math.floor(timeInSecs / 3600);
	const m = Math.floor((timeInSecs % 3600) / 60);
	const s = Math.floor((timeInSecs % 3600) % 60);

	var hDisplay = h > 0 ? h + 'h' : '';
	var mDisplay = m > 0 ? m + 'm' : '';
	var sDisplay = s > 0 ? s + 's' : '';

	// pad minutes and seconds
	mDisplay = h > 0 && mDisplay.length === 2 ? '0' + mDisplay : mDisplay;
	sDisplay = m > 0 && sDisplay.length === 2 ? '0' + sDisplay : sDisplay;

	return hDisplay + mDisplay + sDisplay;
};

export const formatTimeToMinutes = (timeInSecs: number) => {
	const m = Math.floor(timeInSecs / 60);
	const s = Math.floor((timeInSecs % 3600) % 60);

	if (m > 10) {
		return `${m}m`;
	}
	if (m < 1) {
		return `${s}s`;
	}

	return `${m}m${s}s`;
};

export const formatPlayerTime = (time: number, decimals?: number) => {
	const h = Math.floor(time / 3600);
	const m = Math.floor((time % 3600) / 60);
	const s = Math.floor((time % 3600) % 60);
	// const ms = Math.round((time - Math.floor(time)) * 100);

	var hDisplay = h > 0 ? h + ':' : '';
	var mDisplay = m > 0 ? m + ':' : '00:';
	var sDisplay = s > 0 ? s.toString() : '00';
	// var sDisplay = s > 0 ? s + '' : '00';
	// var msDisplay = ms > 0 ? ms : '00';

	// pad minutes and seconds
	mDisplay = m > 0 && mDisplay.length === 2 ? '0' + mDisplay : mDisplay;
	sDisplay = sDisplay.length === 1 ? '0' + sDisplay : sDisplay;

	return hDisplay + mDisplay + sDisplay; // + msDisplay;
};

export const passwordRegex = /(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;

export const stripIllegalChars = (v: string) => {
	// u000a-u000d -> LF -> CR
	//eslint-disable-next-line
	return v.replace(/[^\u0020-\u007e\u00a0-\u00ff\u000a-u000d]/g, '');
};

export const humanFileSize = (bytes: number, si = false, dp = 1) => {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}

	const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

	return bytes.toFixed(dp) + ' ' + units[u];
};
