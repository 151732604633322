import { AuthStore } from './auth.store';
import CRUDSrvStore from './common/crud.service.store';
import { ExportTaskModel, TaskStatus, TaskType } from 'shared-puredio';
import { action, computed } from 'mobx';
import { TasksProjectApi } from 'app/api/tasks.project.api';

export class ProjectRenderStore extends CRUDSrvStore<ExportTaskModel> {
	authStore: AuthStore;
	tasksProjectApi: TasksProjectApi;

	constructor(authStore: AuthStore, tasksProjectApi: TasksProjectApi) {
		super(authStore, tasksProjectApi);
		this.authStore = authStore;
		this.tasksProjectApi = tasksProjectApi;
	}

	@action
	async listenToProjectId(id?: string) {
		return this.tasksProjectApi.listenToList({ projectId: id });
	}

	@action
	stopListeningToList() {
		this.tasksProjectApi.stopListeningToList();
	}

	@computed
	get currentProgress(): number {
		const currentTasks = this.currentItems;
		let taskCount = currentTasks.length - 1;
		if (!currentTasks.find((t) => t.type === TaskType.mergeSplits)) {
			taskCount += 1;
		}
		if (taskCount <= 0) {
			return 0;
		}
		let progressSum = 0;
		currentTasks.forEach((t) => {
			if (t.status === TaskStatus.completed) {
				progressSum += 1;
			} else {
				progressSum += t.progress;
			}
		});

		const progress = (progressSum / taskCount) * 100;
		if (progress === 0) {
			return 1;
		}
		return Math.round(progress);
	}

	@computed
	get currentItems(): ExportTaskModel[] {
		if (this.mainTask) {
			return this.list.filter((t) => t.taskGroupId === this.mainTask.taskGroupId);
		}
		return [];
	}

	@computed
	get mainTask(): ExportTaskModel {
		return this.mainTasks[0];
	}

	@computed
	get mainTasks(): ExportTaskModel[] {
		return this.list
			.filter((t) => t.type === TaskType.export)
			.sort((b, a) => {
				return a.created.getTime() - b.created.getTime();
			});
	}
}
