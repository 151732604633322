import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { ExportTaskModel, GroupModel } from 'shared-puredio';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { useStore } from 'app/context';
import { Button } from 'app/components/common/Button';
import t from 'app/common/translate';
import { notify } from 'app/common/notify';
import { formatTime } from 'app/utils';
import { Image } from 'app/components/Image';
import { RenderFormatLogos } from 'app/components/common/RenderFormatLogos';
import { CopyLinkToClipboard } from 'app/components/common/CopyLinkToClipboard';
import { DownloadVideo } from 'app/components/common/DownloadVideo';
import { VideoPreview } from 'app/components/common/VideoPreview';
import { useHistory } from 'react-router';
import { AudioFileProgress } from 'app/components/Audio/AudioFileProgress';
import PlanRemainingTime from 'app/components/payment/PlanRemainingTime';
import { VerfiyEmailReminderNotice } from 'app/components/auth/VerifyEmailReminder';

interface IProjectRenderProps {
	projectVM: ProjectVM;
	onDone: () => void;
}

export enum RenderState {
	initForm = 'initForm',
	waitingForAudio = 'waitingForAudio',
	canStart = 'canStart',
	started = 'started',
	aborted = 'aborted',
	error = 'error',
	success = 'success',
}

/// loadedr
export const ProjectRender = observer((props: IProjectRenderProps) => {
	const { projectRenderStore } = useStore();
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		const model = props.projectVM.model;
		projectRenderStore.listenToProjectId(model.id).then(() => {
			setLoaded(true);
		});
		return () => {
			projectRenderStore.stopListeningToList();
		};
	}, [projectRenderStore, props.projectVM.model, props.projectVM.model.id]);
	if (!loaded) {
		return <></>;
	}
	return <XProjectRender {...props} />;
});

interface IRenderButton {
	renderState: RenderState;
	projectVM: ProjectVM;
	onDone: () => void;
}

const RenderButton = observer((props: IRenderButton) => {
	const { projectStore, usageStore, authStore, uiStore } = useStore();
	const history = useHistory();

	// Remove for the time being
	// if (!authStore.current!.emailVerified) {
	// 	return <VerfiyEmailReminderNotice onDone={props.onDone} />;
	// }

	const onRender = async () => {
		const res = await projectStore.triggerRender(props.projectVM.model);
		if (res && res.result && res.result.errorCode) {
			let msg = t('api:' + res.result.errorCode);
			console.log(toJS(res.result));
			notify(msg, 'error');
		} else {
			history.push(`/renders/${res.id}`);
			props.onDone();
		}
	};
	usageStore.setCurrentProject(props.projectVM);

	if (uiStore.showPayments) {
		if (usageStore.currentRenderQuotaExceeded || usageStore.wouldExeceedQuota) {
			return (
				<>
					<div className="pad-1rem">
						<PlanRemainingTime willExceed={true} />
					</div>

					<Button className="button is-primary" disabled={true}>
						{t('general:render.renderStart')}
					</Button>
				</>
			);
		}
	}

	switch (props.renderState) {
		case RenderState.canStart:
			return (
				<>
					<div className="pad-1rem">
						<PlanRemainingTime />
					</div>
					<Button className="button is-primary" onClick={onRender}>
						{t('general:render.renderStart')}
					</Button>
				</>
			);

		case RenderState.success:
			return (
				<>
					<div className="pad-1rem">
						<PlanRemainingTime />
					</div>
					<Button className="button is-primary" onClick={onRender}>
						{t('general:render.renderagain')}
					</Button>

					<p className="pad-1rem">
						<small>{t('general:render.renderagain.info')}</small>
					</p>
				</>
			);
		case RenderState.error:
		case RenderState.aborted:
			return (
				<>
					<div className="pad-1rem">
						<PlanRemainingTime />
					</div>
					<Button className="button is-primary" onClick={onRender}>
						{t('general:render.renderagain')}
					</Button>
				</>
			);
		default:
			return <></>;
	}
});

const XProjectRender = observer((props: IProjectRenderProps) => {
	const store = useStore();

	const [renderState, setRenderState] = useState<RenderState>(RenderState.initForm);
	const [group, setGroup] = useState<GroupModel>();
	const [mainTask, setMainTask] = useState<ExportTaskModel>();
	const [errorMsg, setError] = useState<string>('');

	const model = props.projectVM.model;

	useEffect(() => {
		store.groupStore.getById(model.groupId).then((g) => {
			setGroup(g);
		});
	}, [model.groupId, store.groupStore]);

	useEffect(() => {
		setMainTask(store.projectRenderStore.mainTask);
		store.projectStore.getRenderStatus(model, store.projectRenderStore.mainTask).then((state) => {
			setRenderState(state);
			if (state === RenderState.error) {
				if (mainTask) {
					if (mainTask.result) {
						setError(t('api:' + mainTask.result.errorCode));
					}
					console.log('render error', toJS(mainTask.result));
				}
			}
		});
	}, [mainTask, model, props, store, store.projectRenderStore.mainTask]);

	switch (renderState) {
		case RenderState.initForm:
			return <></>;
		case RenderState.waitingForAudio:
			return (
				<div className="render-modal has-text-centered">
					<div className="heading">
						<h2 className="title is-4">{t('general:render.title.waitingForAudio')}</h2>
					</div>
					<AudioFileProgress file={model.audioLayer.audioFile!} />

					<div className="content pad-top-1rem">
						<p>{t('general:render.waitingForAudio')}</p>
					</div>
					<div className="pad-top-1rem">
						<Button onClick={props.onDone} className="button is-primary">
							{t('common:close')}
						</Button>
					</div>
				</div>
			);

		case RenderState.canStart:
			return (
				<div className="render-modal has-text-centered">
					{/* <TaskDebug task={mainTask} /> */}
					<div className="heading">
						<h2 className="title is-4">
							{t('general:render.title.canStart')}{' '}
							<span role="img" aria-label="thumbsup">
								{t('general:render.title.canStartEmoji')}
							</span>
						</h2>
					</div>

					<div className="group-title">
						{group && <h3 className="title is-5">{group.title}</h3>}
						<h4 className="subtitle is-6">{model.title}</h4>
					</div>

					<Image src="render-modal-image.svg" />

					<div className="render-details">
						<p>
							{t('common:duration')}: {formatTime(model.audioLayer.duration)}
							{' | '}
							{t('general:render.videoFormat')}: {model.fmt.id} ( {model.fmt.w} x {model.fmt.h} px)
							{' | '}
							{t('general:render.formatFor')}: <RenderFormatLogos fmt={model.fmt} />
						</p>
					</div>
					<RenderButton {...props} renderState={renderState} />
				</div>
			);

		case RenderState.started:
			return (
				<div className="render-modal has-text-centered">
					{/* Moved to RenderDetail.tsx */}

					{/* <TaskDebug task={mainTask} /> */}
					{/* <div className="heading">
						<h2 className="title is-4">
							{t('general:render.title.started')}{' '}
							<span role="img" aria-label="thumbsup">
								{t('general:render.title.startedEmoji')}
							</span>
						</h2>
					</div>

					<Image src="render-modal-image.svg" />

					<div>
						<p>
							<strong>{t('general:render.youcanclose')}</strong> <br />
							<span>{t('general:render.wewillinformyou')}</span>
						</p>
					</div>
					<div className="progress">
						<div className="value" style={{ width: `${store.projectRenderStore.currentProgress}%` }}></div>
					</div>
					<div className="render-details">
						<p>{t('general:render.estimatedDuration')}</p>
					</div>
					<RenderAbort project={model} /> */}
				</div>
			);

		case RenderState.success:
			const videoKey = mainTask && mainTask.result ? mainTask.result.outputKey : undefined;
			return (
				<div className="render-modal has-text-centered">
					{/* <TaskDebug task={mainTask} /> */}
					<div className="heading">
						<h2 className="title is-4">
							{t('general:render.title.success')}{' '}
							<span role="img" aria-label="thumbsup">
								{t('general:render.title.successEmoji')}
							</span>
						</h2>
					</div>

					{mainTask && <VideoPreview mainTask={mainTask} />}
					<div className="buttons pad-1rem">
						<CopyLinkToClipboard storageKey={videoKey} />
						<DownloadVideo mainTask={mainTask} />
					</div>

					<p>{t('general:render.renderlist.info')}</p>

					<div className="render-details">
						<p>&nbsp;</p>
						<RenderButton {...props} renderState={renderState} />
					</div>
				</div>
			);
		case RenderState.error:
		case RenderState.aborted:
			return (
				<div className="render-modal has-text-centered">
					<div className="heading">
						<h2 className="title is-4">{t('general:render.title.error')} </h2>
					</div>

					{errorMsg && <span className="tag is-medium is-red">{errorMsg}</span>}
					<div>
						<p>{t('general:render.error.support')}</p>
					</div>
					<div className="render-details">
						<p>&nbsp;</p>
						<RenderButton {...props} renderState={renderState} />
					</div>
				</div>
			);
		default:
			throw new Error('Invalid RenderState in ProjectRender.tsx');
	}
});
