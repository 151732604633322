import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import Icon from 'app/components/common/Icon';

interface ITextAlignProps {
	value: string;
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
}

export const TextAlignControl: React.FC<ITextAlignProps> = observer((props: ITextAlignProps) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		props.onChange(props.name, e.target.value);
	};

	const options = ['left', 'center', 'right'];

	return (
		<>
			<div className="form-control">
				{props.label && <label>{props.label}</label>}
				<div className="radio-button-group">
					{options.map((o) => (
						<label key={o} htmlFor={`radio-${o}`}>
							<input type="radio" value={o} id={`radio-${o}`} name={props.name} onChange={(e) => handleChange(e)} defaultChecked={props.value === o} />
							<Icon iconName={`align-${o}`} faModifier="far" />
						</label>
					))}
				</div>
			</div>
		</>
	);
});
