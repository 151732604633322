import { computed, makeObservable, observable } from 'mobx';
import { IDbExportTask, TaskStatus, TaskType } from '..';
import { BaseModel } from './base.model';
import { ConvertResult } from './convert-result';

interface IExportUsage {
    id: string;
    userId: string;
    created: Date;
    modified: Date;
    taskGroupId: string;
    projectId: string;
    groupId: string;
    key: string;
    duration: number;
    opts: any;
    status: TaskStatus;
    result: ConvertResult;
}

export class ExportUsage extends BaseModel {
    constructor(opts: IExportUsage) {
        super();
        makeObservable(this);
        this.id = opts.id;
        this.userId = opts.userId;
        this.created = opts.created;
        this.modified = opts.modified;
        this.taskGroupId = opts.taskGroupId;
        this.projectId = opts.projectId;
        this.groupId = opts.groupId;
        this.key = opts.key;
        this.duration = opts.duration;
        this.opts = opts.opts;
        this.status = opts.status;
        this.result = opts.result;
    }
    @observable id: string;
    @observable userId: string;
    @observable created: Date;
    @observable modified: Date;
    @observable taskGroupId: string;
    @observable projectId: string;
    @observable groupId: string;
    @observable key: string;
    @observable duration: number;
    @observable opts: any;
    @observable status: TaskStatus;
    @observable result: ConvertResult;
}

export class ExportTaskModel extends BaseModel {
    constructor(data: IDbExportTask) {
        super();
        makeObservable(this);
        this.id = data.id;
        this.userId = data.userId;
        this.taskGroupId = data.taskGroupId;
        this.projectId = data.projectId;
        this.type = data.type;
        this.key = data.key;
        this.start = data.start;
        this.splitSize = data.splitSize;
        this.duration = data.duration;
        this.opts = data.opts;
        this.status = data.status;
        this.created = data.created;
        this.completed = data.completed;
        this.fixDate('created');
        this.fixDate('modified');
        this.fixDate('completed');
        this.result = data.result;
        if (data.progress) {
            this.progress = data.progress;
        }
    }
    @observable id?: string;
    @observable userId: string;
    @observable taskGroupId: string;
    @observable projectId: string;
    @observable type: TaskType;
    @observable key: string;
    @observable start?: number;
    @observable duration?: number;
    @observable splitSize: number;
    @observable opts?: any;
    @observable status: TaskStatus;
    @observable created: Date;
    @observable completed?: Date;
    @observable result?: ConvertResult;
    @observable progress: number = 0;

    @computed
    get isAborted(): boolean {
        if (this.result && this.result.errorCode === 'aborted') {
            return true;
        }
        return false;
    }

    @computed
    get isCompleted(): boolean {
        if (this.status === TaskStatus.completed) {
            return true;
        }
        return false;
    }

    @computed
    get hasError(): boolean {
        if (this.result && this.result.errorCode && this.result.errorCode !== 'aborted') {
            return true;
        }
        return false;
    }

    @computed
    get renderDuration() {
        const created = this.created;
        const completed = this.completed;
        if (!created || !completed) {
            return 0;
        }
        return (completed.getTime() - created.getTime()) / 1000;
    }

    serialize() {
        return {
            id: this.id,
            userId: this.userId,
            taskGroupId: this.taskGroupId,
            projectId: this.projectId,
            type: this.type,
            key: this.key,
            start: this.start,
            duration: this.duration,
            splitSize: this.splitSize,
            opts: this.opts,
            status: this.status,
            created: this.created,
            completed: this.completed,
            result: this.result ? this.result.serialize() : undefined,
            progress: this.progress,
        };
    }
}
