import { computed, observable } from 'mobx';
import * as path from 'path';
import { FileModel } from './file.model';

export interface IImageModel {
    userId?: string;
}
export class ImageModel extends FileModel {
    constructor(opts?: IImageModel) {
        super();
        if (opts) {
            this.userId = opts.userId;
        }
    }
    @observable cropKey?: string;
    @observable cropData: any;

    @computed
    get key() {
        if (this.cropKey) {
            return this.cropKey;
        }
        return this.normalizedKey;
    }

    @computed
    get hasActiveFile() {
        return !!this.md5Hash && !!this.originalFileName;
    }

    static fromFile;

    serialize() {
        return {
            id: this.id,
            md5Hash: this.md5Hash,
            metadata: this.metadata,
            originalFileName: this.originalFileName,
            userId: this.userId,
            fileStatus: this.fileStatus,
            audioData: this.audioData,
        };
    }

    static fromStringOrObject(value: any): ImageModel {
        const image = new ImageModel({ userId: 'fuckit' });
        if (typeof value === 'string') {
            const s = value.split('/');
            if (s[2] === 'prep') {
                image.md5Hash = s[3];
            } else {
                // throw new Error('Hopefully not needed anymore:' + value);
            }
            // fix until: all templates use the image model
            //image.originalKey = value;
        } else {
            Object.assign(image, value);
        }
        return image;
    }
}

// saved inside Project/Export/Group etc.
export class ImageModelOld {
    @computed
    get key() {
        if (this.cropKey) {
            return this.cropKey;
        }
        return this.normKey;
    }
    @observable originalKey?: string;

    @computed get _basePath(): string | undefined {
        if (!this.originalKey) {
            return undefined;
        }
        const originalKey = this.originalKey;
        let f = path.basename(originalKey);
        f = f.replace(/[^a-zA-Z0-9_]/g, '');
        const folder = path.dirname(originalKey);
        return path.join(folder, f);
    }

    @computed get normKey(): string | undefined {
        if (!this._basePath) {
            return undefined;
        }
        if (this._basePath.indexOf('public/templat') >= 0) {
            return this.originalKey;
        }
        return this._basePath + '.norm.png';
    }

    @computed get thumbKey(): string | undefined {
        if (!this._basePath) {
            return undefined;
        }
        if (this._basePath.indexOf('public/templat') >= 0) {
            return this.originalKey;
        }
        return this._basePath + '.thumb.jpg';
    }

    @observable cropKey?: string;
    @observable cropData: any;
    serialize() {
        return {
            originalKey: this.originalKey,
            cropKey: this.cropKey,
            cropData: this.cropData,
        };
    }

    static fromStringOrObject(value: any): ImageModel {
        const image = new ImageModel();
        if (typeof value === 'string') {
            // fix until: all templates use the image model
            // image.originalKey = value;
        } else {
            Object.assign(image, value);
        }
        return image;
    }
}
