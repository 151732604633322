import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import { useStore } from 'app/context';
import t from 'app/common/translate';
import { ProjectDuplicateForm } from './ProjectDuplicateForm';
import { ProjectModel } from 'shared-puredio';

interface IGroupDeleteModalProps {
	modalId: string;
	project: ProjectModel;
	onDone: () => void;
}

export const ProjectDuplicateModal: React.FC<IGroupDeleteModalProps> = observer((props: IGroupDeleteModalProps) => {
	const store = useStore();
	const onDone = () => {
		store.uiStore.hideModal(props.modalId);
		props.onDone();
	};
	return (
		<Modal title={t('general:project.duplicate')} size="medium" modalId={props.modalId}>
			<ProjectDuplicateForm project={props.project} onDone={onDone} />
		</Modal>
	);
});
