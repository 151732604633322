import Icon from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import ReactJson from 'react-json-view';

export const AdminTaskDetail = observer(() => {
	const { adminTasksStore } = useStore();
	if (!adminTasksStore.current) {
		return <></>;
	}
	const task = adminTasksStore.current;

	return (
		<>
			<strong>{task.task.type}</strong>
			<br />
			TaskId: {task.task.id} <GcsLink textToSearch={task.task.taskGroupId} />
			<br />
			TaskGroupId: {task.task.taskGroupId} <GcsLink textToSearch={task.task.taskGroupId} />
			<br />
			<br />
			<ReactJson src={task.task} collapsed={1} />
		</>
	);
});

interface IGcsLink {
	textToSearch: string;
}

const GcsLink = (props: IGcsLink) => {
	let tmp =
		'https://console.cloud.google.com/logs/query;query=-protoPayload.serviceName%3D%22bigquery.googleapis.com%22%0A-resource.type%3D%22build%22%20%0A-%22ext-bigquery%22%0A%22uTkLLE16knLkREyuBGrZHT%22%20%0A%0A;timeRange=P1D;cursorTimestamp=2022-01-18T09:43:23.832Z?authuser=2&project=puredio-development&query=%0A';
	const urlDev = tmp.replace('uTkLLE16knLkREyuBGrZHT', props.textToSearch);
	const urlProd = urlDev.replace('puredio-development', 'puredio-production');

	return (
		<>
			<i>GCLogs:</i>
			<a href={urlDev} target={'_blank'}>
				<Icon iconName="external-link-alt" /> DEV
			</a>{' '}
			<a href={urlProd} target={'_blank'}>
				<Icon iconName="external-link-alt" />
				PROD
			</a>
		</>
	);
};
