import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/styles.scss';
import { i18Init } from 'app/common/i18n';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import { StoreContext, globalStoreInstance } from 'app/context';
import Config from './config';
import moment from 'moment';
import 'moment/locale/de';
import { ErrorBoundary } from 'app/components/ErrorBoundary';

console.log(`Starting Puredio v${process.env.REACT_APP_VERSION} ${Config.ENV}`);

i18Init.then(() => {
	moment.locale('de');
	ReactDOM.render(
		<React.StrictMode>
			<ErrorBoundary>
				<StoreContext.Provider value={globalStoreInstance}>
					<App />
				</StoreContext.Provider>
			</ErrorBoundary>
		</React.StrictMode>,
		document.getElementById('root'),
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
