import React from 'react';
import { IVideoFormat } from 'shared-puredio';
import Icon from './Icon';

interface IRenderFormatProps {
	fmt?: IVideoFormat;
}
export const RenderFormatLogos = (props: IRenderFormatProps) => {
	const format = props.fmt;
	if (!format) {
		return <> </>;
	}
	switch (format.orientation) {
		case 'square':
			return (
				<>
					<Icon iconName="instagram" /> <Icon iconName="facebook" />
				</>
			);
		case 'landscape':
			return (
				<>
					<Icon iconName="facebook" /> <Icon iconName="twitter" /> <Icon iconName="youtube" /> <Icon iconName="linkedin" />
				</>
			);
		case 'portrait':
			return (
				<>
					<Icon iconName="instagram" /> <Icon iconName="facebook" />
				</>
			);
	}

	return <>{format.id}</>;
};
