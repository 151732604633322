import React, { useEffect, useState } from 'react';
import BaseContainer from '../BaseContainer';
import { RouteComponentProps } from 'react-router';
import { useStore } from 'app/context';
import t from 'app/common/translate';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { Trans } from 'react-i18next';

export const VerifyEmailContainer: React.FC<RouteComponentProps> = (props) => {
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState(false);

	const id = (props.match.params as any).id;
	const { computeApi } = useStore();
	useEffect(() => {
		computeApi
			.verifyEmail(id)
			.then(() => {
				setLoading(false);
				setSuccess(true);
			})
			.catch((err) => {
				setError(true);
				setLoading(false);
			});
	}, [id, computeApi]);

	return (
		<BaseContainer hideBrand={true} classModifier="is-auth">
			<div className="auth-form is-success">
				<section className="section">
					<div className="auth-form-header">
						<img src="/images/brand-with-signet.svg" alt="Puredio"></img>
					</div>
					<div className="align-center">
						{loading && <></>}
						{success && (
							<>
								<h1 className="title is-3">
									<strong>{t('auth:verifyemail.success')}</strong>
								</h1>
								<div className="pad-1rem">
									<p>{t('auth:verifyemail.success.calltoaction')}</p>
								</div>
								<Link className="button is-green" to={routes.AUTH_LOGIN.getPath()}>
									{t('auth:login')}
								</Link>
							</>
						)}
						{error && (
							<>
								<h2>
									<strong>{t('auth:verifyemail.error')}</strong>
								</h2>
								<Trans i18nKey={'auth:needhelp.linked'}>
									Bla<Link to={routes.CONTACT.getPath()}>Blaa</Link>
								</Trans>
							</>
						)}
					</div>
				</section>
			</div>
		</BaseContainer>
	);
};
