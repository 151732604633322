import gsap from 'gsap';
import { PointText, Color, Point, Shape } from 'paper';
import { LayerType } from '../..';
import { BaseTextLayer } from './BaseTextLayer';
import TextArea from './TextArea';

export default class MultilineText extends TextArea {
    public layerType: LayerType = LayerType.text;

    /*
    public drawTextNope = (currentframe?: number) => {
        this.group.removeChildren();

        // prepare props
        const content = this.getTextForFrame(currentframe || Math.floor((gsap.globalTimeline.time() - this.anim.audioLayer.start) * 30));
        const x = this.calc(this.x);
        const y = this.calc(this.y);
        const scale = this.scale || 1;
        const textAlign = this.textAlign || 'center';
        const color = this.color || new Color(0, 0, 0, 0.7);
        const fontFamily = this.googleFont.family;
        const fontWeight = this.googleFont.currentVariant.fontWeight;
        let fontStyle = this.googleFont.currentVariant.fontStyle;
        if (fontStyle === 'regular') {
            fontStyle = '';
        }

        // Add all needed items to the stage
        const text = new PointText({
            point: [x, y],
            content: content,
            fillColor: color,
            fontFamily: fontFamily,
            fontWeight: fontWeight + ' ' + fontStyle,
            justification: textAlign,
            fontSize: 100,
        });

        this.text = text;
        this.drawBackgroundRect();

        // add items to group
        this.group.applyMatrix = false;
        this.group.addChild(this.backgroundRect);
        this.group.addChild(text);

        this.group.position.x = x;
        this.group.position.y = y;

        this.group.rotation = this.rotation;
        this.group.scaling = new Point(scale, scale);
        this.controlGroup.bind(this);
    };
    */
}
