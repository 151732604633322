import { BaseAnimation } from './BaseAnimation';
import SimpleProgressAnimation from './elements/SimpleProgressAnimation';

// backgrounds
import ImageBackground from './background/ImageBackground';
import SolidBackground from './background/SolidBackground';
import GradientBackground from './background/GradientBackground';

// elements
import ImageLayer from './elements/ImageLayer';
import IconLayer from './elements/IconLayer';
import MultilineText from './text/MultilineText';
import AnimatedText from './text/AnimatedText';
import TextArea from './text/TextArea';

// audiograms
import WaveAnimation from './audiograms/WaveAnimation';
import RoundBars from './audiograms/RoundBars';
import Skyscrapers from './audiograms/Skyscrapers';
import RoundedSquareWave from './audiograms/RoundedSquareWave';
import CircleWave from './audiograms/CircleWave';

import { BaseAnimationOptions } from './LayerAnimationOptions';
import { LayerType } from '../models/iexport';
import WatermarkLayer from './elements/WatermarkLayer';

class _AnimationTypes {
    BaseAnimation: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = BaseAnimation;
    SimpleProgressAnimation: {
        new (timeOpts: BaseAnimationOptions, opts?: any): SimpleProgressAnimation;
    } = SimpleProgressAnimation;

    // Backgrounds
    ImageBackground: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = ImageBackground;
    SolidBackground: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = SolidBackground;
    GradientBackground: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = GradientBackground;
    // Images
    ImageLayer: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = ImageLayer;
    IconLayer: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = IconLayer;
    WatermarkLayer: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = WatermarkLayer;
    // Text
    MultilineText: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = MultilineText;
    AnimatedText: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = AnimatedText;
    TextArea: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = TextArea;

    // DoubleHorizontalText: { new (timeOpts: LayerAnimationOptions, opts?: any): BaseAnimation } = DoubleHorizontalText;

    // Audiograms
    WaveAnimation: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = WaveAnimation;
    RoundBars: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = RoundBars;
    RoundedSquareWave: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = RoundedSquareWave;
    CircleWave: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = CircleWave;
    Skyscrapers: { new (timeOpts: BaseAnimationOptions, opts?: any): BaseAnimation } = Skyscrapers;

    // Others

    getByBaseAnimationByName(name): BaseAnimation {
        let c = this[name] as any;
        if (!c) {
            // fallback when class goes missing.
            console.error(`could not find base animation class ${name}. Using Baseanimation as Fallback. Expect weird behaviour in the UI.`);
            c = BaseAnimation as any;
        }
        const i = new c({}, {});
        return i;
    }

    classNamesByLayerType(layerType: LayerType): string[] {
        const keys = Object.keys(this);
        const res: string[] = [];

        keys.forEach((key) => {
            const i = this.getByBaseAnimationByName(key);
            if (i instanceof BaseAnimation) {
                if (i.layerType === layerType) {
                    res.push(key);
                }
            }
        });
        return res;
    }
}
export const AnimationTypes = new _AnimationTypes();
