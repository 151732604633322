import React, { ForwardRefRenderFunction, useEffect, useState } from 'react';
import { useStore } from 'app/context';
import { ImageModel } from 'shared-puredio';
import { observer } from 'mobx-react';

interface IImageViewerProps {
	isBackground?: boolean;
	bucketKey: string;
	alt?: string;
}

interface IPublicImageViewer {
	bucketKey: string;
	alt?: string;
}

export const PublicImageViewer = (props: IPublicImageViewer) => {
	const bucketKey = props.bucketKey;
	const url = '/' + bucketKey.replace('public/', '');
	return <img key={url} src={url} alt={props.alt || ''} />;
};

// Petr Grinevich - now we forward ref only if component renders image.
const Viewer: ForwardRefRenderFunction<HTMLImageElement, IImageViewerProps> = (props, ref) => {
	const isBackground = props.isBackground;
	const bucketKey = props.bucketKey;

	const { bucketStore } = useStore();
	const [url, setUrl] = useState<string | undefined>('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=');

	useEffect(() => {
		let isMounted = true; // note this flag denote mount status
		const loadImage = async () => {
			if (bucketKey) {
				const u = await bucketStore.getDownloadUrl(bucketKey);
				if (isMounted) {
					setUrl(u);
				}
			}
		};
		if (bucketKey) {
			if (bucketKey.startsWith('http')) {
				if (bucketKey.indexOf('dummyimage.com') === -1) {
					console.warn('bucket key should not start with http: ', bucketKey);
				}
				if (isMounted) {
					setUrl(bucketKey);
				}
			} else {
				loadImage();
			}
		}
		return () => {
			isMounted = false;
		};
	}, [bucketKey, bucketStore]);

	return isBackground ? <div className="image is-background" style={{ backgroundImage: `url(${url})` }}></div> : <img ref={ref} key={url} src={url} alt={props.alt || ''} />;
};

export const ImageViewer = React.forwardRef(Viewer);

interface IImageModelViewer {
	im?: ImageModel;
}
export const ImageModelViewer = observer((props: IImageModelViewer) => {
	const { fileStore } = useStore();
	const [key, setKey] = useState<string | undefined>(undefined);
	useEffect(() => {
		setKey(undefined);
		if (!props.im) {
			return;
		}
		fileStore.setImageModel(props.im).then((res) => {
			if (res) {
				setKey(res.key);
			}
		});
	}, [fileStore, props]);
	return <>{key && <ImageViewer bucketKey={key} />}</>;
});
