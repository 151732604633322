import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import t from '../../common/translate';
import { useStore } from 'app/context';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../Form/FormField';
import { Button } from '../common/Button';
import { SupoortMailModel } from 'shared-puredio';
import { Trans } from 'react-i18next';

const supportFormSchema = Yup.object()
	.shape({
		body: Yup.string().required(t('validation:required.field')).default(''),
	})
	.required();

type Feedback = Yup.InferType<typeof supportFormSchema>;

interface ICancelSubscriptionModal {
	onDone: () => void;
}

export const PaymentCancelSubscriptioFeedbackModal: React.FC<ICancelSubscriptionModal> = observer((props: ICancelSubscriptionModal) => {
	const { uiStore, authStore, computeApi } = useStore();
	const modalId = uiStore.modalIds.cancelSubscriptionFeedback;

	const user = authStore.current;
	const defaultValues = {
		body: '',
	};

	const handleSubmit = useCallback(
		async (values: Feedback) => {
			let { body } = values;

			const mail = new SupoortMailModel();
			mail.from = user!.email;
			mail.subject = 'Cancel Subscription Feedback';
			if (user) {
				body += `\n\n----------------------------------------------`;
				body += `\n\nAbsender: ${user.email} ${user.fullName} `;
				body += `\n\nArt: Cancel Subscription} `;
				mail.userId = user.id!;
			}
			mail.body = body;

			const res = await computeApi.sendSupportEmail(mail);
			if (res) {
				props.onDone();
			}
		},
		[user, computeApi, props],
	);

	return (
		<Modal title={''} size="large" modalId={modalId} onClose={props.onDone}>
			<div className="welcome-modal content">
				<h2 className="title is-3 align-center">Deine Meinung ist uns wichtig!</h2>
				<div className="message pad-top-3rem pad-bottom-1rem align-center">
					<Trans i18nKey="payment:message.cancel.feedback">messaege here</Trans>
				</div>
				<Formik validationSchema={supportFormSchema} initialValues={defaultValues} onSubmit={handleSubmit}>
					{({ errors, touched }) => (
						<Form className="form is-support-form">
							<FormField label={t('payment:message.cancel.feedback.label')} error={errors.body} touched={touched.body}>
								<Field as="textarea" name="body" className="input text-area" />
							</FormField>
							<div className="pad-top-3rem align-center">
								<Button type="submit" className="button is-primary" disabled={authStore.isEntityLoading}>
									{t('common:send')}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
});
