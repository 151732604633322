import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { useStore } from 'app/context';
import BucketPath from 'shared-puredio/src/models/bucket.path';
import { notify } from 'app/common/notify';
import { Button } from 'app/components/common/Button';
import { ImageViewer } from 'app/components/common/ImageViewer';

interface IProjectManageTemplate {
	project: ProjectVM;
}

export const ProjectCreateScreenshotButton = observer((props: IProjectManageTemplate) => {
	const { authStore, projectStore, bucketStore } = useStore();

	const currentUser = authStore.current;
	if (!currentUser || !currentUser.isTemplateManager) {
		return <></>;
	}
	const vm = props.project;

	const createScreenShot = async () => {
		const resizedCanvas = document.createElement('canvas');
		const resizedContext = resizedCanvas.getContext('2d');

		const fmt = vm.model.fmt;
		const w = fmt.w / 4;
		const h = fmt.h / 4;
		resizedCanvas.width = w;
		resizedCanvas.height = h;

		if (vm.canvasRef.current && currentUser!.isTemplateManager) {
			// const imageDataUrl = vm.canvasRef.current.toDataURL();
			resizedContext!.drawImage(vm.canvasRef.current, 0, 0, w, h);
			const imageDataUrl = resizedCanvas.toDataURL();

			const previewKey = BucketPath.projectPreviewKey(vm.model);
			bucketStore.invalidateUrl(previewKey);
			const res = await bucketStore.uploadDataUrl(imageDataUrl, previewKey, false);
			if (res.key) {
				console.log('saved preview image to', res.key);
				vm.model.previewKey = res.key;
			}
		}
		await projectStore.save(vm.model);
		notify('screenshot wurde gespeichert. evtl. modal neu öffnen');
	};

	return (
		<>
			<div className="align-center">
				{vm.model.previewKey && <ImageViewer bucketKey={vm.model.previewKey} alt={vm.model.title} />}
				<div className="pad-top-1rem">
					<Button className="button is-smallish is-primary is-inverted" onClick={createScreenShot}>
						Screenshot erstellen
					</Button>
				</div>
			</div>
		</>
	);
});
