import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../common/Modal';
import t from 'app/common/translate';
import { Button } from '../common/Button';
import { useStore } from 'app/context';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Image } from '../Image';
import { PlanDefinitions, PlanType } from 'shared-puredio';
import { SvgIcon, SvgIconType } from '../common/SvgIcon';

export const PremiumTemplateModal = observer(() => {
	const { uiStore } = useStore();
	const history = useHistory();
	const modalId = uiStore.modalIds.premiumTemplate;
	const onBuy = () => {
		uiStore.hideModal(modalId);
		history.push(routes.ACCOUNTSETTINGS.getPath('payment'));
	};

	const podcasterPlan = PlanDefinitions.find((p) => p.planType === PlanType.pocaster)!;

	return (
		<Modal title={''} modifier="premium-templates-modal" size="large" modalId={modalId}>
			<h3 className="title is-3 align-center pad-bottom-1rem">{t('payment:templatesModal.title')}</h3>
			<h4 className="subtitle is-5 align-center pad-bottom-1rem">{t('payment:templatesModal.subTitle')}</h4>

			<div className="grid is-col-half pad-3rem">
				<div className="left">
					<div className="perks">
						<div className="perks-list">
							<div className="perks">
								<div className={`perk`}>
									<SvgIcon iconName={SvgIconType.check} />
									<span>{t('payment:premium.perk1')}</span>
								</div>
								<div className={`perk`}>
									<SvgIcon iconName={SvgIconType.check} />
									<span>{t('payment:premium.perk2')}</span>
								</div>
								<div className={`perk`}>
									<SvgIcon iconName={SvgIconType.check} />
									<span>{t('payment:premium.perk3')}</span>
								</div>
								<div className={`perk`}>
									<SvgIcon iconName={SvgIconType.check} />
									<span>{t('payment:premium.perk4')}</span>
								</div>
							</div>
							<strong className="title is-4">{t('payment:price.from', { price: podcasterPlan.price })}</strong>
						</div>
					</div>
				</div>
				<div className="right">
					<Image src="premium-templates.png" />
				</div>
			</div>

			<div className="align-center">
				<Button className="button is-primary" onClick={onBuy}>
					{t('payment:goto.upgrade')}
				</Button>
			</div>
		</Modal>
	);
});
