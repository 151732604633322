import { BaseAnimation } from '../BaseAnimation';
import { Shape, Point, Color, Group } from 'paper';

import { LayerType } from '../..';
import { computed, reaction } from 'mobx';
import { IPoint } from '../utils/ControlGroup';

export default class SimpleProgressAnimation extends BaseAnimation {
    public layerType: LayerType = LayerType.progress;
    private bar: paper.Shape;
    private backgroundRect: paper.Shape;

    private currentframe: number = 0;

    @computed
    get color() {
        return this.anim.animationLayer.opts.color;
    }
    @computed
    get background() {
        return this.anim.animationLayer.opts.background;
    }

    public getDefaultOpts = (ftmId: string) => {
        return {
            x: { v: 0.5, d: 'w' } as IPoint,
            y: { v: 0.015, d: 'h' } as IPoint,
            height: { v: 0.03, d: 'h' } as IPoint,
            color: '#ffffff',
            rotation: 0,
            scale: 1,
        };
    };

    public create = async () => {
        this.group = new Group();

        this.initAnimation();

        const disp = reaction(
            () => [this.x, this.y, this.rotation, this.scale, this.color, this.background],
            () => this.drawBar(this.currentframe),
            { fireImmediately: true },
        );
        this.disposers.push(disp);

        return this.group;
    };

    private drawBar = (frame: number) => {
        if (this.group) {
            this.group.removeChildren();
        }

        if (this.anim.animationLayer.isDisabled) {
            // for some reason the disabled progress bar still ends up here.
            return;
        }

        const defaultOpts = this.getDefaultOpts(this.anim.fmt.id);

        const totalFrames = this.anim.totalDuration * 30;
        const color = this.color || '#333333';
        const background = this.background || 'transparent';
        const width = this.calc({ v: frame / totalFrames, d: 'w' });
        const height = this.calc(this.height || defaultOpts.height);
        const x = this.x || defaultOpts.x;
        const y = this.y || defaultOpts.y;
        const scale = this.scale || defaultOpts.scale;
        const rotation = this.rotation || defaultOpts.rotation;

        this.backgroundRect = new Shape.Rectangle({
            from: [0, 0],
            to: [this.anim.fmt.w, height],
            fillColor: new Color(background),
        });

        this.bar = new Shape.Rectangle({
            from: [0, 0],
            to: [width, height],
            fillColor: new Color(color),
        });

        this.group.addChildren([this.backgroundRect, this.bar]);
        this.group.applyMatrix = false;
        this.group.position.x = this.calc(x);
        this.group.position.y = this.calc(y);
        this.group.rotation = rotation;
        this.group.scaling = new Point(scale, scale);
        this.controlGroup.bind(this);
    };

    public tick(currentframe) {
        if (!this.assetResolver) {
            debugger;
        }

        this.currentframe = currentframe;
        this.drawBar(currentframe);
        return true;
    }

    private initAnimation = () => {
        this.timeline.to({}, { duration: this.anim.totalDuration }, 0);
        // const w = this.anim.fmt.w;
        // this.timeline.to(this.bar.position, { duration: this.anim.totalDuration, x: 0, ease: 'linear' }, 0);
        // this.timeline.to(this.bar.size, { duration: this.anim.totalDuration, width: w, ease: 'linear' }, 0);
    };
}
