import { ExportTaskModel } from 'shared-puredio';
import { IDbExportTask } from 'shared-puredio';
import { BaseApi, IStoreScope } from './base.api';
import { firestore } from './firebase-init';

// import { notify } from 'app/common/notify';
// import t from 'app/common/translate';

// notify(t('components:audio.upload.processingcompleted'));

export class TasksAudioPrepApi extends BaseApi<ExportTaskModel> {
	constructor() {
		super();
		this.collection = 'tasks';
		this.needsUserId = true;
	}

	createNewInstance(data: IDbExportTask): ExportTaskModel {
		const m = new ExportTaskModel(data);
		return m;
	}

	getScopedListQuery(scope: IStoreScope): firebase.firestore.Query {
		const ref = firestore.collection(this.collection);
		return ref.where('userId', '==', scope.userId); //.where('projectId', '==', 'audio_preperation');
	}
}
