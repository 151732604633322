import React, { useState } from 'react';
import { FieldConfig, useField, useFormikContext } from 'formik';
import AudioUploadWidget from '../common/AudioUploadWidget';
import { FileModel } from 'shared-puredio';
import { SelectUploadedFileControlButton } from '../common/SelectUploadedFileControl';
import { useStore } from 'app/context';
import t from 'app/common/translate';
import { SvgIcon, SvgIconType } from '../common/SvgIcon';
import { UploadResponse } from 'app/api/bucket.api';

interface IAudioUploadField extends FieldConfig {
	uploadFolder: string;
}

export const AudioUploadField: React.FC<IAudioUploadField> = (props) => {
	const [field] = useField(props);
	const { fileStore } = useStore();
	const [fileModel, setFileModel] = useState<FileModel | undefined>();
	const { setFieldValue } = useFormikContext();

	const onChange = async (res: UploadResponse) => {
		const f = await fileStore.findByMd5Hash(res.md5Hash!);
		if (f) {
			setFieldValue(field.name, f.md5Hash);
			setFileModel(f);
		}
	};
	const onSelectPreviousUpload = (audioMd5: string) => {
		fileStore.findByMd5Hash(audioMd5).then((f) => {
			if (f) {
				setFieldValue(field.name, f.md5Hash);
				setFileModel(f);
			}
		});
	};
	const onDelete = () => {
		setFieldValue(field.name, undefined);
		setFileModel(undefined);
	};

	return (
		<div className="uploader is-audio">
			{!fileModel && (
				<>
					<div className="align-right">
						<SelectUploadedFileControlButton fileType={'audio'} onSelect={onSelectPreviousUpload} />
					</div>
					<AudioUploadWidget storageFolder={props.uploadFolder} onUploadSuccess={onChange} />
				</>
			)}
			{fileModel && (
				<>
					<div className="drop-area audio-upload-success">
						<div className="message">
							<div className="body">
								<div className="icon">
									<SvgIcon iconName={SvgIconType.success} />
								</div>
								<div className="text">
									<span className="text-primary text-bold">{fileModel.originalFileName}</span> <span>{t('components:upload.success.long')}</span>
								</div>
							</div>
							<div className="pad-top-1rem">
								<button className="button is-text is-grey" onClick={onDelete}>
									<SvgIcon iconName={SvgIconType.audioSettings} />
									<span>{t('components:upload.replace')}</span>
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
