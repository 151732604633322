import { action, computed, observable } from 'mobx';
import { AllFonts } from '../all.fonts';

export class FontVariant {
    constructor(v: string) {
        this.id = v;
        if (v.indexOf('regular') >= 0) {
            this.fontStyle = 'regular';
            v = v.replace('regular', '');
        }
        if (v.indexOf('italic') >= 0) {
            this.fontStyle = 'italic';
            v = v.replace('italic', '');
        }
        this.fontWeight = v;
    }
    id: string;
    fontStyle: string = 'regular';
    fontWeight: string = '100';
    @computed
    get showFontStyleInSelect() {
        if (this.fontWeight.length > 0 && this.fontStyle === 'regular') {
            return false;
        }
        return true;
    }
}

export class Font {
    constructor(opts: any) {
        this.id = opts.id;
        this.family = opts.family;
        this.variants = opts.variants.map((v: string) => new FontVariant(v));
        this.subsets = opts.subsets;
        this.category = opts.category;
        this.version = opts.version;
        this.lastModified = new Date(opts.lastModified);
        this.popularity = opts.popularity;
        this.defSubset = opts.defSubset;
        this.defVariant = opts.defVariant;
        this.currentVariant = this.variants.find((v) => v.id === this.defVariant);
    }
    id: string;
    family: string;
    @computed
    get familyLower() {
        return this.family.toLowerCase();
    }

    @observable
    variants: FontVariant[];
    subsets: string[];
    category: string;
    version: string;
    lastModified: Date;
    popularity: number;
    defSubset: string;
    defVariant: string;

    @action
    setVariant(v: string) {
        this.currentVariant = this.variants.find((i) => i.id == v);
        if (!this.currentVariant) {
            console.warn('variant', this.currentVariant, ' does not exist for font', this.id);
            this.currentVariant = this.defaultVariant;
        }
    }

    @computed
    get defaultVariant() {
        return this.variants.find((v) => v.id === this.defVariant);
    }

    @observable
    currentVariant: FontVariant;

    @computed
    get ttfFontPath() {
        if (!this.variants.includes(this.currentVariant)) {
            console.warn('variant', this.currentVariant, ' does not exist for font', this.id);
            this.currentVariant = this.defaultVariant;
        }
        return `fonts/${this.id}/fonts/${this.id}-${this.version}-latin-${this.currentVariant.id}.ttf`;
    }

    @computed
    get localTmpPath() {
        return '/tmp/fonts' + this.ttfFontPath;
    }

    @computed
    get registerFontOpts() {
        return {
            family: this.family,
            weight: this.currentVariant.fontWeight,
            style: this.currentVariant.fontStyle,
        };
    }
}

export const Fonts = AllFonts.map((f) => new Font(f));
