import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { ImageModel, LayerModel } from 'shared-puredio';
import { AnimClassSelectControl } from './AnimClassSelectControl';
import { ColorPickerControl } from 'app/components/common/ColorPickerControl';
import t from 'app/common/translate';
import { ImageUploadControl } from './ImageUploadControl';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';

interface IAnimLayerFormProps {
	title: string;
	projectVM: ProjectVM;
	animLayer: LayerModel;
}

export const LayerFormBackground = observer((props: IAnimLayerFormProps) => {
	const { animLayer, projectVM } = props;
	const { authStore } = useStore();
	const uploadFolder = authStore.uploadFolder!;

	const onAnimClassChange = (val: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		animLayer.setAnimClass(val);
		animLayer.commitSnapshot();
	};

	const onColorChange = (name: string, value: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			if (name === 'startColor') {
				animLayer.opts.startColor = value;
			}
			if (name === 'endColor') {
				animLayer.opts.endColor = value;
			}
			if (name === 'color') {
				animLayer.opts.color = value;
			}
		});
		animLayer.commitSnapshot();
	};

	const onImageChange = (val: ImageModel) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			animLayer.opts.image = val;
		});
		animLayer.commitSnapshot();
	};

	return (
		<>
			<div className="ui-control-group">
				<AnimClassSelectControl layerType={animLayer.layerType} animClass={animLayer.animClass} onChange={onAnimClassChange} />
				{animLayer.animClass === 'SolidBackground' && (
					<>
						<ColorPickerControl name={'color'} label={t('components:uie.color')} value={animLayer.opts.color} onChange={onColorChange} />
					</>
				)}
				{animLayer.animClass === 'GradientBackground' && (
					<>
						<ColorPickerControl name={'startColor'} label={t('components:uie.startColor')} value={animLayer.opts.startColor} onChange={onColorChange} />
						<ColorPickerControl name={'endColor'} label={t('components:uie.endColor')} value={animLayer.opts.endColor} onChange={onColorChange} />
					</>
				)}
				{animLayer.animClass === 'ImageBackground' && (
					<>
						<ImageUploadControl label={t('components:uie.image')} value={animLayer.opts.image} onChange={onImageChange} uploadFolder={uploadFolder} />
					</>
				)}
			</div>
		</>
	);
});
