import React from 'react';
import { observer } from 'mobx-react';
import colors from 'assets/scss/colors.module.scss';

interface IProgressRing {
	percentage: number;
	label?: string;
	modifier?: string;
	radius?: number;
	stroke?: number;
	color?: string;
}

const ProgressRing: React.FC<IProgressRing> = observer((props: IProgressRing) => {
	const radius = props.radius || 100;
	const stroke = props.stroke || 10;
	const normalizedRadius = radius - stroke * 2;
	const circumference = (radius - stroke * 2) * 2 * Math.PI;
	const strokeDashoffset = circumference - (props.percentage / 100) * circumference;
	const fontSize = Math.max(20, radius / 3);
	const color = props.color || colors.primary;

	return (
		<div className={`progress-ring ${props.modifier ? props.modifier : ''}`}>
			<svg height={radius * 2} width={radius * 2}>
				<circle className="background" fill="transparent" stroke={colors.greyLight} strokeWidth={stroke} r={normalizedRadius} cx={radius} cy={radius} />
				<circle className="progress" fill="transparent" stroke={color} strokeLinecap="round" strokeWidth={stroke} strokeDasharray={circumference + ' ' + circumference} style={{ strokeDashoffset }} r={normalizedRadius} cx={radius} cy={radius} />
				<text fontSize={fontSize} fontWeight={'bold'} className="percentage-text" textAnchor="middle" dominantBaseline="central" x={radius} y={radius}>
					{Math.round(props.percentage)}%
				</text>
				{props.label && (
					<text className="label" x={radius} y={radius} textAnchor="middle" dominantBaseline="central">
						{props.label}
					</text>
				)}
			</svg>
		</div>
	);
});

export default ProgressRing;
