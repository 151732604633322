import { YesNo } from 'app/components/common/YesNo';
import { useStore } from 'app/context';
import { formatDate } from 'app/utils';
import { observer } from 'mobx-react';
import React from 'react';

export const AdminUserDetail = observer(() => {
	const { adminUserStore } = useStore();
	if (!adminUserStore.current) {
		return <></>;
	}
	const user = adminUserStore.current.user;
	return (
		<>
			<table>
				<tbody>
					<tr>
						<td>PaymentType</td>
						<td>{user.paymentType}</td>
					</tr>
					<tr>
						<td>user.activePlan</td>
						<td>
							{user.activePlan} ({user.activePlanId}) {user.videoSecondsQuota / 60} min
						</td>
					</tr>
					<tr>
						<td>paddleUserId</td>
						<td>{user.paddleUserId}</td>
					</tr>
					{user.hasActiveSubscription && (
						<>
							<tr>
								<td>user.subscriptionStartDate </td>
								<td>
									{formatDate(user.subscriptionStartDate, 'YYYY-MM-DD HH:mm')}
									(#Months: {user.subscriptionMonths})
								</td>
							</tr>
							<tr>
								<td>user.currentBillingStartDate </td>
								<td>
									{formatDate(user.currentBillingStartDate, 'YYYY-MM-DD HH:mm')} Days used:
									{user.daysUsedInCurrentSubscriptionMonth}
								</td>
							</tr>
						</>
					)}
					<tr>
						<td>user.canAccessPremiumFeatures</td>
						<td>
							<YesNo value={user.canAccessPremiumFeatures} />
						</td>
					</tr>
					<tr>
						<td>user.activeSubscriptionIsEnding</td>
						<td>
							<YesNo value={user.activeSubscriptionIsEnding} />
						</td>
					</tr>
					<tr>
						<td>user.hasCancelableSubscription</td>
						<td>
							<YesNo value={user.hasCancelableSubscription} />
						</td>
					</tr>
					<tr>
						<td>user.isFreeUser</td>
						<td>
							<YesNo value={user.isFreeUser} />
						</td>
					</tr>
					<tr>
						<td>user.daysUsedInCurrentSubscriptionMonth</td>
						<td>{user.daysUsedInCurrentSubscriptionMonth} days</td>
					</tr>
				</tbody>
			</table>

			<br />

			<br />
		</>
	);
});
