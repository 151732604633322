import Icon from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { formatPlayerTime } from 'app/utils';
import { observer } from 'mobx-react';
import React from 'react';

export const ProjectPlaybackControls: React.FC = observer(() => {
	const { animatorStore } = useStore();
	return (
		<div className="controls">
			<div className="control-button-group">
				<button className="control-button is-stop" onClick={() => animatorStore.stop()} title="Stop">
					<Icon iconName="stop" />
				</button>
				{animatorStore.isRunning ? (
					<button className="control-button is-pause" onClick={() => animatorStore.pause()} title="Pause">
						<Icon iconName="pause" />
					</button>
				) : (
					<button className="control-button is-play" onClick={() => animatorStore.playViaUserInteraction()} title="Play">
						<Icon iconName="play" />
					</button>
				)}
			</div>
			<div className="current-time">
				{formatPlayerTime(animatorStore.audioStore.currentRelativeTime)}
				{animatorStore.audioStore.currentDuration > 0 && <> / {formatPlayerTime(animatorStore.audioStore.currentDuration)}</>}{' '}
			</div>
		</div>
	);
});
