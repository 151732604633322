import React from 'react';
import { withStore, IProps, useStore } from './../context';
import { observer } from 'mobx-react';
import { ProjectModel } from 'shared-puredio';

interface ITemplateItem {
	project: ProjectModel;
}

const TemplateItem = observer((props: ITemplateItem) => {
	const p = props.project;

	return <div>{p.title}</div>;
});

const Templates = observer(() => {
	const { templateStore } = useStore();

	return (
		<>
			{templateStore.templateApi.list.map((p) => {
				return <TemplateItem key={p.id} project={p} />;
			})}
		</>
	);
});

@withStore
@observer
export default class TemplatesContainer extends React.Component<IProps> {
	public render() {
		return (
			<div>
				Templates
				<hr />
				<Templates />
				{/* <TemplateList onSelect={(t: TemplateBase) => alert('reimplement')} fmtId={VideoFormats.square.id} /> */}
			</div>
		);
	}
}
