import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { LayerModel } from 'shared-puredio';
import { AnimClassSelectControl } from './AnimClassSelectControl';
import { ColorPickerControl } from 'app/components/common/ColorPickerControl';
import t from 'app/common/translate';
import RangeControl from 'app/components/common/RangeControl';
import { runInAction } from 'mobx';

interface IAnimLayerFormProps {
	title: string;
	projectVM: ProjectVM;
	animLayer: LayerModel;
}

export const LayerFormAudiogram = observer((props: IAnimLayerFormProps) => {
	const { animLayer, projectVM } = props;

	const onAnimClassChange = (val: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		animLayer.setAnimClass(val);
		animLayer.commitSnapshot();
	};

	const onColorChange = (name: string, value: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		if (name === 'color') {
			runInAction(() => {
				animLayer.opts.color = value;
			});
		}
		animLayer.commitSnapshot();
	};

	const onAmplitudeChange = (name: string, val: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			animLayer.opts.amplitude = val;
		});
		animLayer.commitSnapshot();
	};

	return (
		<>
			<div className="ui-control-group">
				<AnimClassSelectControl layerType={animLayer.layerType} animClass={animLayer.animClass} onChange={onAnimClassChange} />
				<ColorPickerControl name={'color'} label={t('components:uie.color')} value={animLayer.opts.color} onChange={onColorChange} />
				<RangeControl name={'amplitude'} label={t('components:uie.amplitude')} value={animLayer.opts.amplitude} min={0} max={1} step={0.05} onChange={onAmplitudeChange} />
			</div>
		</>
	);
});
