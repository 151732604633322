import { storage } from './firebase-init';
import * as path from 'path';
import { uuid } from 'short-uuid';
import { computed } from 'mobx';

export class BucketFile {
	constructor(fileRef: firebase.storage.Reference) {
		this.fileRef = fileRef;
		this.name = fileRef.name;
		this.key = fileRef.fullPath;
	}
	fileRef: firebase.storage.Reference;
	name: string;
	key: string;

	@computed
	get isImage() {
		const imageExts = ['.png', '.gif', '.jpg', '.jpeg'];
		const ext = path.extname(this.name).toLowerCase();
		return imageExts.includes(ext);
	}

	@computed
	get isOriginalImage() {
		const f = path.basename(this.name).toLowerCase();
		return !f.endsWith('.norm.png') && !f.endsWith('.thumb.jpg');
	}

	@computed
	get isAudio() {
		const imageExts = ['.mp3'];
		const ext = path.extname(this.name).toLowerCase();
		return imageExts.includes(ext);
	}
}

export interface UploadResponse {
	key: string;
	url?: string;
	md5Hash?: string;
}

class DownloadUrlCache {
	constructor(key: string, url: string) {
		this.key = key;
		this.url = url;
	}
	key: string;
	url: string;
}

export class BucketApi {
	private urlCache: DownloadUrlCache[];
	constructor() {
		this.urlCache = [];
	}

	async fileExists(key: string): Promise<boolean> {
		return new Promise((resolve) => {
			const ref = storage.ref(key);
			ref.getMetadata()
				.then(() => {
					return resolve(true);
				})
				.catch(() => resolve(false));
		});
	}

	async getUniqueKey(key: string) {
		let increment = 0;
		let exists = await this.fileExists(key);
		const fileNmae = path.basename(key);
		const folder = path.dirname(key);

		while (exists) {
			increment++;
			const name = `${path.basename(fileNmae, path.extname(fileNmae))}(${increment || ''})${path.extname(fileNmae)}`;
			key = `${folder}/${name}`;
			exists = await this.fileExists(key);
		}

		return key;
	}

	async uploadFile(file: any, key: string, onProgress?: (progress: number) => void): Promise<UploadResponse> {
		key = await this.getUniqueKey(key);
		const response = {
			key: key,
			url: undefined,
			md5Hash: undefined,
		};

		const task = storage.ref(key).put(file, {});
		task.on('state_changed', (snapShot) => {
			if (onProgress) {
				const progress = (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
				onProgress(progress);
			}
		});
		return new Promise((resolve, reject) => {
			task.then((res) => {
				res.ref.getDownloadURL().then((url) => {
					response.url = url;
					res.ref.getMetadata().then((metadata) => {
						response.md5Hash = metadata.md5Hash.replace(/\//g, '_');
						resolve(response);
					});
				});
			}).catch((err) => {
				console.error(err);
				reject(err);
			});
		});
	}
	async uploadDataUrl(dataUrl: string, key: string, randFileName: boolean = true): Promise<UploadResponse> {
		let cacheControl = '';
		if (randFileName) {
			const ext = path.extname(key);
			const p = path.dirname(key);
			key = path.join(p, uuid() + ext);
			cacheControl = 'private, max-age=3600';
		}
		const response = {
			key: key,
			url: undefined,
		};
		return new Promise((resolve, reject) => {
			storage
				.ref(key)
				.putString(dataUrl, 'data_url', { cacheControl })
				.then((res) => {
					res.ref.getDownloadURL().then((url) => {
						response.url = url;
						resolve(response);
					});
				})
				.catch((err) => {
					console.error(err);
					reject(err);
				});
		});
	}

	async getDownloadUrl(key: string) {
		if (!key) {
			return;
		}
		const url = await storage
			.ref(key)
			.getDownloadURL()
			.catch((e) => console.warn('getDownloadUrl error', e));

		return url;
	}

	async listFolder(folder: string): Promise<BucketFile[]> {
		if (!folder) {
			return [];
		}
		const storageRef = storage.ref(folder);
		const list = await storageRef.listAll();
		return list.items.map((item) => new BucketFile(item));
	}

	async removeFile(key: string): Promise<boolean> {
		let success = true;

		try {
			await storage.ref(key).delete();
		} catch (err) {
			success = false;
		}

		return success;
	}
}
