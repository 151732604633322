import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from './common/Modal';
import { Image } from 'app/components/Image';
import t from '../common/translate';

interface IWelcomeModalProps {
	modalId: string;
	onDone: () => void;
}

export const BrowserNotSupportedModal: React.FC<IWelcomeModalProps> = observer((props: IWelcomeModalProps) => {
	return (
		<Modal title={''} size="large" modalId={props.modalId} onClose={props.onDone}>
			<div className="welcome-modal content has-text-centered">
				<h2 className="title is-2">
					{t('general:modal.badBrowser.title')}{' '}
					<span role="img" aria-label="celebration">
						{t('general:modal.badBrowser.emoji')}
					</span>
				</h2>
				<Image src="bad-browser.svg" />
				<div className="message">
					<p>{t('general:modal.badBrowser.text1')}</p>
					<p>{t('general:modal.badBrowser.text2')}</p>
				</div>
				<button onClick={props.onDone} className="button is-primary is-medium">
					{t('common:close')}
				</button>
			</div>
		</Modal>
	);
});
