import React from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import t from '../../../common/translate';

import { AnimationTypes, LayerType } from 'shared-puredio';
import Icon from 'app/components/common/Icon';
import { getLayerTypeIcon } from 'app/stores/viewmodels/project.menu.vm';
import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/common/Modal';

interface IProjectEdit {
	project: ProjectVM;
}

export const AddLayerModal = observer(() => {
	const lts = [LayerType.audiogram, LayerType.image, LayerType.progress, LayerType.text, LayerType.animatedText];
	const { animatorStore, uiStore } = useStore();
	const modalId = uiStore.modalIds.newLayer;

	const addLayer = async (layerType: LayerType) => {
		if (animatorStore.paperVm) {
			const animTypes = AnimationTypes.classNamesByLayerType(layerType);
			uiStore.hideModal(modalId);
			await animatorStore.paperVm!.addLayer(layerType, animTypes[0]);
		}
	};

	return (
		<Modal title={t('components:layer.add')} size="medium" modalId={modalId}>
			<div className="layer-list">
				{lts.map((lt, i) => (
					<Button className="button is-primary is-inverted is-layer-add-button" onClick={() => addLayer(lt)} key={i}>
						<Icon iconName={getLayerTypeIcon(lt)} faModifier="fal" />
						<span>{t('components:layertype.' + lt)}</span>
					</Button>
				))}
			</div>
		</Modal>
	);
});

export const ProjectLayerAdd = observer((props: IProjectEdit) => {
	const { uiStore } = useStore();
	const toggleOpen = () => {
		uiStore.showModal(uiStore.modalIds.newLayer);
	};

	return (
		<>
			<Button className="button is-primary is-inverted is-smallish" data-tooltip={t('tooltips:layer.add')} onClick={toggleOpen}>
				<Icon iconName="plus" />
				<span>{t('components:layer.add')}</span>
			</Button>
			<AddLayerModal />
		</>
	);
});
