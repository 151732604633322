import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';

export enum NoticeType {
	normal = 'normal',
	warning = 'warning',
	danger = 'danger',
	info = 'info',
	success = 'success',
}

interface INoticeProps {
	title?: string;
	icon?: ReactNode;
	type?: NoticeType;
}

const Notice: React.FC<INoticeProps> = observer(({ children, ...props }) => {
	const { title, icon, type } = props;
	return (
		<>
			<div className={`notice is-${type || 'normal'} ${icon ? 'has-icon' : ''}`}>
				{icon && <div className="notice-icon">{icon}</div>}
				<div className="details">
					{title && <h4 className="title is-6">{title}</h4>}
					<div className="children">{children}</div>
				</div>
			</div>
		</>
	);
});

export default Notice;
