import { LayerType, ProjectModel } from 'shared-puredio';
import { ISidePanelItem } from './ISidePanelItem';
import t from 'app/common/translate';
import { action, makeObservable, observable } from 'mobx';

export class SidePanelItem {
	constructor(opts: ISidePanelItem) {
		makeObservable(this);
		this.key = opts.key;
		this.icon = opts.icon;
		this.title = opts.title;
		this.isOpen = opts.isOpen;
		this.sort = opts.sort;
		this.isWide = opts.isWide || false;
	}

	@observable
	key: string;
	@observable
	icon: string;
	@observable
	title: string;
	@observable
	isOpen: boolean;
	@observable
	sort: number;
	@observable
	isWide: boolean;
}

export const getLayerTypeIcon = (layerType: LayerType): string => {
	switch (layerType) {
		case LayerType.audio:
			return 'volume';
		case LayerType.audiogram:
			return 'waveform';
		case LayerType.background:
			return 'fill-drip';
		case LayerType.badge:
			return 'comet';
		case LayerType.image:
			return 'image-polaroid';
		case LayerType.progress:
			return 'tasks-alt';
		case LayerType.text:
			return 'font-case';
		case LayerType.util:
			return 'tools';
		case LayerType.watermark:
			return 'stamp';
		case LayerType.animatedText:
			return 'film';
		case LayerType.textArea:
			return 'font-case';
		default:
			return 'question';
	}
};

export class ProjectMenuVM {
	project: ProjectModel;
	constructor(project: ProjectModel) {
		this.project = project;
	}

	@action
	getSidePanelItem(sidePanelName?: string) {
		if (sidePanelName === 'renderlist') {
			return new SidePanelItem(this.renderListItem());
		}
		if (sidePanelName === 'audio') {
			return new SidePanelItem(this.audioItem());
		}
		const items = this.menuItems(sidePanelName);
		const item = items.find((i) => i.isOpen === true);
		if (item) {
			return new SidePanelItem(item);
		}
		return new SidePanelItem(items[0]);
	}

	menuItems(currentSidePanelName?: string) {
		const items: ISidePanelItem[] = [];

		this.project.animLayers.forEach((l) => {
			let title = t('components:layertype.' + l.layerType);

			if (l.layerType === LayerType.text) {
				const text = l.opts.content;
				if (text) {
					title = text;
					if (title.toString().length > 15) {
						title = title.substring(0, 15) + '..';
					}
				}
			}

			items.push({
				key: l.key,
				title,
				isOpen: false,
				sort: l.sort,
				icon: getLayerTypeIcon(l.layerType),
				isWide: l.layerType === LayerType.animatedText,
			});
		});

		if (currentSidePanelName) {
			const i = items.find((i) => i.key === currentSidePanelName);
			if (i) {
				i.isOpen = true;
			}
		} else {
			if (items.length > 0) {
				items[0].isOpen = true;
			}
		}
		return items;
	}

	audioItem() {
		const renderListSidePanelitem: ISidePanelItem = {
			key: 'audio',
			icon: 'phone-laptop',
			title: 'Audio',
			sort: 1,
			isOpen: false,
			isWide: false,
		};
		return renderListSidePanelitem;
	}
	renderListItem() {
		const renderListSidePanelitem: ISidePanelItem = {
			key: 'renderlist',
			icon: 'phone-laptop',
			title: 'Renderliste',
			sort: 99,
			isOpen: false,
			isWide: false,
		};
		return renderListSidePanelitem;
	}
}
