import { Button } from 'app/components/common/Button';
import DetailPanel from 'app/components/common/DetailPanel';
import { ProjectPreviewImage } from 'app/components/Project/ProjectPreviewImage';
import { ProjectTemplateEditForm } from 'app/components/Project/Template/ProjectTemplateEditForm';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export const AdminTemplateDetailPanel = observer(() => {
	const { adminProjectStore } = useStore();
	const vm = adminProjectStore.current;

	const history = useHistory();
	if (!vm) {
		return <></>;
	}
	const onClose = () => {
		history.push(routes.ADMINTEMPLATES.getPath());
	};

	const openEpisode = () => {
		const url = '/#' + routes.EDITPROJECT.getPath(vm.project.id);
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<DetailPanel onClose={onClose} title={vm.project.title + ' ' + vm.project.fmtId} width={'70%'}>
			<div className="body ">
				<Button onClick={openEpisode} className="button is-primary">
					Episode in neuem Fenster öffnen
				</Button>
				<div>
					Preview:
					<br />
					<ProjectPreviewImage project={vm.project} />
				</div>
				<ProjectTemplateEditForm project={vm.project} />
			</div>
		</DetailPanel>
	);
});
