// ExportUsageApi
import { action, computed, makeObservable, observable } from 'mobx';
import { ExportUsageModel, TaskStatus } from 'shared-puredio';
import { AuthStore } from './auth.store';
import CRUDSrvStore from './common/crud.service.store';
import { ProjectVM } from './viewmodels/project.vm';
import moment from 'moment';
import { RendersStore } from './renders.store';
import { ExportUsageApi } from 'app/api/export.usage.api';

export class UsageStore extends CRUDSrvStore<ExportUsageModel> {
	authStore: AuthStore;
	exportUsageApi: ExportUsageApi;
	rendersStore: RendersStore;

	constructor(authStore: AuthStore, exportUsageApi: ExportUsageApi, renderStore: RendersStore) {
		super(authStore, exportUsageApi);
		makeObservable(this);
		this.authStore = authStore;
		this.exportUsageApi = exportUsageApi;
		this.rendersStore = renderStore;
	}

	@computed
	get currentMonthItems() {
		const currBill = moment(this.authStore.current!.currentBillingStartDate);
		return this.exportUsageApi.list.filter((x) => moment(x.created).isSameOrAfter(currBill) && x.success === true);
	}

	@computed
	get currVideoCount() {
		return this.currentMonthItems.length;
	}

	@computed
	get currentTimeUsageSeconds() {
		let t = 0;
		this.currentMonthItems.forEach((x) => (t += Math.floor(x.duration)));
		return Math.round(t);
	}

	@computed
	get userVideoSecondsQuota() {
		const user = this.authStore.current!;
		return user.videoSecondsQuota;
	}

	@computed
	get userRenderQuta() {
		const user = this.authStore.current!;
		return user.renderCountQuota;
	}

	@computed
	get remainingSeconds() {
		const remaining = this.userVideoSecondsQuota - this.currentTimeUsageSeconds;
		if (remaining > 0) {
			return remaining;
		}
		return 0;
	}

	@computed
	get timeQuotaExceeded(): boolean {
		return false; // disable usage check
		// return this.currentTimeUsageSeconds > this.userVideoSecondsQuota;
	}

	@observable
	currentProject?: ProjectVM;

	@action
	setCurrentProject(p?: ProjectVM) {
		this.currentProject = p;
	}

	@computed
	get wouldExeceedQuota() {
		return false; // disable usage check
		// if (!this.currentProject) {
		// 	return false;
		// }
		// const remaining = this.remainingSeconds;
		// const duration = Math.floor(this.currentProject.model.audioLayer.duration);
		// const delta = remaining - duration;
		// if (delta < 0) {
		// 	return true;
		// }
		// return false;
	}
	@computed
	get wouldExeceedQuotaBy() {
		return false; // disable usage check
		// if (!this.wouldExeceedQuota || !this.currentProject) {
		// 	return 0;
		// }
		// const remaining = this.remainingSeconds;
		// const duration = Math.floor(this.currentProject.model.audioLayer.duration);
		// return duration - remaining;
	}

	@computed
	get currentRenderQuotaExceeded() {
		return false; // disable usage check
		// const quota = this.authStore.current!.renderCountQuota;
		// const currentActiveRenders = this.rendersStore.mainTasks.filter((t) => t.status === TaskStatus.processing).length;
		// if (currentActiveRenders >= quota) {
		// 	return true;
		// }
		// return false;
	}

	// @computed
	// get videoQuotaExceeded(): boolean {
	// 	const user = this.authStore.current!;
	// 	return this.currVideoCount > user.videoSecondsQuota;
	// }
}
