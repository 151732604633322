import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { useStore } from 'app/context';
import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/common/Modal';
import { ProjectCreateScreenshotButton } from './ProjectCreateScreenshotButton';
import { ProjectTemplateEditForm } from './ProjectTemplateEditForm';

interface IProjectManageTemplate {
	project: ProjectVM;
}

export const ProjectManageTemplateModal = observer((props: IProjectManageTemplate) => {
	const { authStore, uiStore } = useStore();
	const modalId = uiStore.modalIds.manageTemplate;

	const currentUser = authStore.current;
	if (!currentUser || !currentUser.isTemplateManager) {
		return <></>;
	}
	const onClose = () => {
		uiStore.hideModal(modalId);
	};

	const onOpen = () => {
		uiStore.showModal(modalId);
	};

	return (
		<>
			<div className="pad-1rem">
				<Button className="button is-smallish is-primary is-inverted" onClick={onOpen}>
					Template Managen
				</Button>
			</div>
			<Modal title={'Template Einstellungen'} size="large" modalId={modalId} onClose={onClose}>
				<div className="grid is-col-auto">
					<ProjectCreateScreenshotButton {...props} />
					<ProjectTemplateEditForm project={props.project.model} />
				</div>
			</Modal>
		</>
	);
});
