import { IDbProject, ExportTaskModel, LayerModel, FileModel, IVideoFormat, VideoFormats, TemplateManagers } from '..';
import { observable, computed, IObservableArray, action, override, makeObservable } from 'mobx';
import { ILayerBase, isAnimationLayer } from './iexport';
import { SnapshotBase } from './snapshot.base';
import _ from 'lodash';

export class ProjectTemplate {
    constructor(opts: any) {
        makeObservable(this);
        if (!opts) {
            return;
        }
        if (opts.templateId !== undefined) {
            this.templateId = opts.templateId;
        }
        if (opts.templateTitle !== undefined) {
            this.templateTitle = opts.templateTitle;
        }
        if (opts.published !== undefined) {
            this.published = opts.published;
        }

        if (opts.isPremium !== undefined) {
            this.isPremium = opts.isPremium;
        }
    }

    @observable
    templateId: string = '';
    @observable
    templateTitle: string = '';
    @observable
    published: boolean = false;

    @observable
    isPremium: boolean = false;

    serialize() {
        return {
            templateId: this.templateId,
            templateTitle: this.templateTitle,
            published: this.published,
            isPremium: this.isPremium,
        };
    }
}

export class ProjectModel extends SnapshotBase {
    constructor(parms: IDbProject, tasks?: ExportTaskModel[]) {
        super();
        makeObservable(this);
        // this.tasks = tasks;
        this.id = parms.id;
        this.userId = parms.userId;
        this.fmtId = parms.fmtId;
        this.snapshotKey = 'project';
        this.title = parms.title;
        this.groupId = parms.groupId;
        this.created = parms.created;
        this.modified = parms.modified;
        this.fixDate('created');
        this.fixDate('modified');
        this.previewKey = parms.previewKey;
        this.layers = observable([]);
        this.templateId = parms.templateId;
        if (parms.history) {
            this.history.replace(parms.history);
        }
        this._projectTemplate = new ProjectTemplate(parms._projectTemplate);
        if (parms.audioLayer) {
            this.audioLayer = new LayerModel(parms.audioLayer);
            // this.audioLayer.audioFile.userId = parms.userId;
        } else {
            this.audioLayer = new LayerModel({ key: 'audio' });
            this.audioLayer.audioFile = new FileModel();
        }
        if (parms.layers) {
            if (Array.isArray(parms.layers)) {
                const ls = (parms.layers as any[]).map((l) => new LayerModel(l));
                this.layers.replace(ls);
            } else {
                let layerMap = parms.layers;
                let layerKeys = Object.keys(layerMap);
                // clear out audio layers from older models
                layerKeys = layerKeys.filter((k) => k !== 'audio');
                const ls = layerKeys.map((key) => {
                    const thing = layerMap![key];
                    return new LayerModel(thing as ILayerBase);
                });
                this.layers.replace(ls);
            }
        }
    }

    @observable id: string;
    @observable groupId: string;
    @observable userId: string;
    @observable title: string;
    @observable previewKey?: string;
    @observable templateId?: string;
    @observable created?: Date;
    @observable modified?: Date;
    @observable renderOutput?: any;

    @observable layers: IObservableArray<LayerModel>;
    @observable audioLayer: LayerModel;
    @observable fmtId: string;

    @observable _projectTemplate: ProjectTemplate;

    @computed
    get isTemplate() {
        return TemplateManagers.includes(this.userId);
    }

    @computed
    get isPremiumTemplate() {
        if (this._projectTemplate) {
            return this._projectTemplate.isPremium;
        }
        return false;
    }

    @computed
    get fmt(): IVideoFormat {
        let fmtId = this.fmtId;
        if (!this.fmtId) {
            console.warn('missing format id. defaulting to square');
            fmtId = VideoFormats.square.id;
        }
        return VideoFormats.findById(fmtId);
    }

    fixDate(prop: string) {
        if (this[prop] && this[prop].toDate) {
            this[prop] = this[prop].toDate();
        }
    }

    @computed
    get createdTime() {
        if (typeof this.created === 'string') {
            // protection against tests writing stupid dates.
            return new Date(this.created).getTime();
        }
        return this.created ? this.created.getTime() : 0;
    }

    @computed
    get animLayers(): LayerModel[] {
        let al = this.layers.filter((l) => isAnimationLayer(l));
        al = al.sort((a, b) => a.sort - b.sort);
        return al as LayerModel[];
    }

    @action
    async setAudioData(audioFile: FileModel) {
        const al = this.audioLayer;
        if (al) {
            al.audioFile = audioFile;
            al.setStartDuration(0, audioFile.audioData.duration);
        }
    }

    @action
    async setStartDuration(start: number, duration: number) {
        const al = this.audioLayer;
        if (al) {
            al.setStartDuration(start, duration);
        }
    }

    @computed
    get firstLayer(): LayerModel | undefined {
        if (this.layers) {
            const ls = this.layers.filter((l) => isAnimationLayer(l)).sort((a, b) => a.sort - b.sort);
            return ls ? ls[0] : undefined;
        }
    }
    @computed
    get ainmationLayers(): LayerModel[] {
        if (this.layers) {
            const ls = this.layers.filter((l) => isAnimationLayer(l));
            if (ls) {
                const als = ls as LayerModel[];
                return als.map((l) => new LayerModel(l));
            }
        }
    }

    getSnapshotData(): any {
        return {
            title: this.title,
            layers: this.layers.map((l) => l.getSnapshotData()),
        };
    }

    @override
    applySnapshot(s: any) {
        this.title = s.title;
        const undodedLayers = s.layers.map((l: any) => new LayerModel(l)).sort((l: LayerModel) => l.sort);
        this.layers.replace(undodedLayers);
    }

    serialize() {
        const p: any = {
            id: this.id,
            title: this.title,
            userId: this.userId,
            fmtId: this.fmtId,
            groupId: this.groupId,
            templateId: this.templateId,
            _projectTemplate: this._projectTemplate.serialize(),
            previewKey: this.previewKey,
            created: this.created,
            modified: this.modified,
            history: _.map(this.history, (h) => h),
            exports: [],
            audioLayer: this.audioLayer.serialize(),
        };
        p.layers = this.layers.map((l) => l.serialize());
        return p;
    }
}
