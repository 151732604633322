import firebase from 'firebase/app';
import { computed, observable } from 'mobx';
import moment from 'moment';
import { IPaddlePlan, PaddleSubscription, TemplateManagers } from '..';
import { IPaddleSubscription } from './paddle';
import { PaddlePlan, PaymentType, PlanType } from './payment.plans';

export class UserModel {
    constructor(opts: any) {
        this.id = opts.id;
        this.firstName = opts.firstName;
        this.lastName = opts.lastName;
        this.email = opts.email;
        this.emailVerified = opts.emailVerified;
        this.created = opts.created;
        this.modified = opts.modified;
        this.deleted = opts.deleted;
        this.deletedDate = opts.deletedDate;
        this.lastLogin = opts.lastLogin;
        this.betaMessageSeen = opts.betaMessageSeen;
        this.fixDates();
        this._subscriptions = opts._subscriptions;
        this._plans = opts._plans;
        this.paddleUserId = opts.paddleUserId;
        this.__activePlan = opts.__activePlan;
        this.freeUntil = opts.freeUntil;

        if (opts.paymentType) {
            this.paymentType = opts.paymentType;
        }
    }

    @observable paddleUserId?: number;
    @observable _subscriptions: IPaddleSubscription[];
    @observable _plans: IPaddlePlan[];
    @observable __activePlan: PlanType;
    @observable freeUntil?: string;
    @observable paymentType: PaymentType = PaymentType.free;

    @observable id?: string;
    @observable firstName: string = '';
    @observable lastName: string = '';
    @observable email: string = '';
    @observable emailVerified: boolean = false;
    @observable created: Date = new Date();
    @observable modified: Date = new Date();
    @observable lastLogin: Date = new Date();
    @observable betaMessageSeen: boolean = false;
    @observable isAdmin: boolean = false;

    @computed
    get nameAndEmailAndId() {
        return this.firstName + ' ' + this.lastName + ' ' + this.fullName + ' ' + this.fakeId;
    }

    @computed
    get isTemplateManager() {
        return TemplateManagers.includes(this.id);
    }

    @computed
    get plans() {
        if (!this._plans) {
            return [];
        }
        return this._plans.map((p) => new PaddlePlan(p));
    }

    @computed
    get subscriptions() {
        if (!this._subscriptions) {
            return [];
        }
        return this._subscriptions.map((p) => new PaddleSubscription(p, this._plans));
    }

    @computed
    get activePlan() {
        if (this.activeSubscription) {
            return this.activeSubscription.plan.planType;
        }
        if (this.isFreeUser) {
            return this.__activePlan;
        }
        return PlanType.starter;
    }

    @computed
    get canAccessPremiumFeatures() {
        if (this.isAdmin && this.isTemplateManager) {
            return true;
        }
        if (this.hasActiveSubscription) {
            return true;
        }
        if (this.isFreeUser) {
            return true;
        }
        return false;
    }

    @computed
    get hasCancelableSubscription() {
        if (this.hasActiveSubscription && !this.activeSubscriptionIsEnding) {
            return true;
        }
        return false;
    }

    @computed
    get canUpgrade() {
        if (this.isFreeUser) {
            return false;
        }
        return true;
    }

    @computed
    get isFreeUser() {
        const freeUntil = this.freeUntil;
        if (freeUntil && this.__activePlan !== PlanType.starter) {
            const m = moment(freeUntil);
            const today = moment();
            if (m.isSameOrAfter(today)) {
                return true;
            }
        }
        return false;
    }

    @computed
    get hasActiveSubscription() {
        return !!this.activeSubscription;
    }

    @computed
    get activeSubscriptionIsEnding() {
        return this.activeSubscription && this.activeSubscription.subscriptionIsEnding;
    }
    @computed
    get activeSubscriptionEndDate() {
        return this.activeSubscription && this.activeSubscription.subscriptionEndDate;
    }

    @computed
    get activeSubscription() {
        return this.subscriptions.find((s) => s.subscriptionIsActive);
    }

    @computed
    get activeRank() {
        if (this.activeSubscription) {
            return this.activeSubscription.plan.planRank;
        }
        return 0;
    }

    @computed
    get activePlanId() {
        if (this.activeSubscription) {
            return this.activeSubscription.plan.id;
        }
        return 0;
    }

    /// subscription end;

    @computed
    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    @computed
    get fakeId() {
        return this.id!.toString();
    }

    fixDates() {
        this.fixDate('lastLogin');
        this.fixDate('created');
        this.fixDate('modified');
    }

    fixDate(prop: string) {
        if (this[prop] && this[prop].toDate) {
            this[prop] = this[prop].toDate();
        }
    }

    deleted?: boolean;
    deletedDate?: Date;

    @computed
    get subscriptionStartDate() {
        if (!this.activeSubscription) {
            return this.created;
        }
        return moment(this.activeSubscription.signup_date).toDate();
    }

    @computed
    get subscriptionMonths() {
        return moment().diff(moment(this.subscriptionStartDate), 'months');
    }

    @computed
    get currentBillingStartDate() {
        return moment(this.subscriptionStartDate).add(this.subscriptionMonths, 'months').toDate();
    }

    @computed
    get daysUsedInCurrentSubscriptionMonth() {
        return moment().diff(moment(this.currentBillingStartDate), 'days');
    }

    @computed
    get videoSecondsQuota() {
        if (this.activeSubscription) {
            return this.activeSubscription.plan.videoSecondsQuota;
        }
        return PaddlePlan.getVideoMinuteQuota(this.activePlan) * 60;
    }

    @computed
    get renderCountQuota() {
        if (this.activeSubscription) {
            if (this.activeSubscription.plan.planType === PlanType.studio) {
                return 5;
            }
            if (this.activeSubscription.plan.planType === PlanType.pocaster) {
                return 2;
            }
        }
        return 1;
    }

    getPropsFromFirebaseObject(user: firebase.User) {
        if (!user) {
            console.warn('not firebase user given');
            return;
        }
        this.id = user.uid;
        this.email = user.email ? user.email : '';
        this.emailVerified = user.emailVerified ? user.emailVerified : false;
        if (user.metadata) {
            if (user.metadata.creationTime) {
                this.created = new Date(user.metadata.creationTime);
            }
            if (user.metadata.lastSignInTime) {
                this.lastLogin = new Date(user.metadata.lastSignInTime);
            }
        }
    }

    getPropsFromClaim(claims: any) {
        if (!claims) {
            return;
        }
        this.isAdmin = !!claims.admin;
        if (claims.plan && claims.plan !== PlanType.starter) {
            this.freeUntil = claims.freeUntil;
            this.__activePlan = claims.plan;
            this.paymentType = PaymentType.free;
        }
        if (claims.paymentType) {
            this.paymentType = claims.paymentType;
        }
    }

    serialize() {
        return {
            id: this.id ? this.id : this.fakeId,
            paddleUserId: this.paddleUserId,
            _subscriptions: this._subscriptions,
            _plans: this._plans,
            __activePlan: this.__activePlan,
            freeUntil: this.freeUntil,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            emailVerified: this.emailVerified,
            paymentType: this.paymentType,
            created: this.created,
            modified: this.modified,
            deleted: this.deleted,
            deletedDate: this.deletedDate,
            lastLogin: this.lastLogin,
            betaMessageSeen: this.betaMessageSeen,
        } as any;
    }
}
