import * as React from 'react';
import { observer } from 'mobx-react';
import routes from 'routes';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import Breadcrumb from 'app/components/Breadcrumb';
import { useEffect, useState } from 'react';
import { ProjectManageTemplateModal } from '../Template/ProjectManageTemplate';

interface IProjectEditNavbarProps {
	project: ProjectVM;
}

const ProjectEditNavbar = observer((props: IProjectEditNavbarProps) => {
	const project = props.project;
	const [bread, setBread] = useState<any[]>();
	useEffect(() => {
		if (!project || !project.group) {
			return;
		}
		const _bread = [
			{
				title: 'Podcasts',
				url: routes.LISTGROUPS.getPath(),
			},
			{
				title: project.group!.title,
				url: routes.LISTPROJECTS.getPath(project.group.id),
			},
			{
				title: project.model.title,
			},
		];
		setBread(_bread);
	}, [project, project.group, project.model.title]);

	return (
		<>
			<div className="navbar project-edit-navbar">{bread && <Breadcrumb crumbs={bread} />}</div>
			<ProjectManageTemplateModal project={project} />
		</>
	);
});

export default ProjectEditNavbar;
