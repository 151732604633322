import { AuthStore } from './auth.store';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import Config from 'config';
import _ from 'lodash';

const paymentTestUsers = [
	'templates@puredio.com',
	'holger@puredio.com',
	'luki92@googlemail.com',
	'lukas@puredio.com',
	'karsten@grombach.com',
	'karsten@puredio.com',
	'karsten.grombach@isarbits.com',
	'holgerrausch@gmail.com',
	'holger.rausch@isarbits.com',
	'lukas@lukasvolz.de',
	'karsten2@grombach.com',
	'luckymovies@gmx.de',
	'demo@puredio.com',
];

export const isPaymentTestUser = (email: string) => {
	return _.includes(paymentTestUsers, email.toLowerCase());
};

export class UiStore {
	authStore: AuthStore;
	constructor(authStore: AuthStore) {
		makeObservable(this);
		this.authStore = authStore;
		this.visibleModals = observable([]);
	}

	get showPayments() {
		return true;
		// const currentUser = this.authStore.current;
		// if (!currentUser) {
		// 	return false;
		// }
		// if (Config.FEATURE_PAYMENT) {
		// 	return isPaymentTestUser(currentUser.email);
		// }
		// return false;
	}

	@computed
	get isLoggedIn() {
		return !!this.authStore.current;
	}

	@observable
	isUploading: boolean = false;

	/// MODAL HANDLING
	@action
	hideModal(id: string) {
		this.visibleModals.replace(this.visibleModals.filter((x) => x !== id));
	}
	@action
	showModal(id: string) {
		if (!this.visibleModals.includes(id)) {
			this.visibleModals.push(id);
		} else {
			console.warn('Modal allready open / invalid Modal ID');
		}
	}

	@action
	setUploading(state: boolean) {
		this.isUploading = state;
	}

	@observable
	visibleModals: IObservableArray<string>;

	modalIsVisible(modalId: string) {
		return this.visibleModals.includes(modalId);
	}

	modalIds = modalIdsMap;

	@observable
	showVerifyEmailBanner: boolean = true;

	@action
	hideVerifyEmailBanner() {
		this.showVerifyEmailBanner = false;
	}
}

const modalIdsMap = Object.freeze({
	groupNew: 'group_new',
	selectUploadedFile: 'select_uploadedfile',
	cropImage: 'cropImage',
	manageExports: (projectId: string) => `manage_exports_${projectId}`,
	newLayer: 'new_layer',
	contact: 'contact',
	editGroup: (groupId: string) => `edit_group_${groupId}`,
	deleteGroup: (groupId: string) => `delete_group_${groupId}`,
	projectDelete: (projectId: string) => `project_delete_${projectId}`,
	projectDuplicate: (projectId: string) => `project_duplicate_${projectId}`,
	deleteFile: 'delete_file',
	audioSetting: 'audioSettings',
	audioSwap: 'audioSwap',
	welcomeMessage: 'welcomeMessage',
	browserNotSupported: 'browserNotSupported',
	projectSettings: 'projectSettings',
	projectCropAudio: 'projectCropAudio',
	changeSubscription: 'changeSubscription',
	cancelSubscription: 'cancelSubscription',
	cancelSubscriptionFeedback: 'cancelSubscriptionFeedback',
	manageTemplate: 'manageTemplate',
	premiumTemplate: 'premiumTemplate',
	verfiyEmailReminder: 'verfiyEmailReminder',
	update_password: 'update_password',
	updateEmail: 'updateEmail',
	delete_account: 'delete_account',
});
