import React from 'react';
import { observer } from 'mobx-react';
import { GroupModel } from 'shared-puredio';
import { useStore } from 'app/context';
import { Button } from 'app/components/common/Button';
import t from 'app/common/translate';

interface IGroupDeleteModalProps {
	group: GroupModel;
	onDone: () => void;
}

export const GroupDeleteForm: React.FC<IGroupDeleteModalProps> = observer((props: IGroupDeleteModalProps) => {
	const store = useStore();
	const group = props.group;
	const projects = store.projectStore.list.filter((p) => p.groupId === group.id);
	const onDelete = async () => {
		for (const p of projects) {
			await store.projectStore.deleteById(p.id);
		}
		await store.groupStore.deleteById(group.id);
		props.onDone();
	};

	return (
		<div className="content">
			<p>{t('general:group.delete.count', { count: projects.length })}</p>
			<p>
				{t('general:group.delete.warn1')}
				<br />
				<strong>{t('general:group.delete.warn2')}</strong>
			</p>
			<Button type="button" className="button is-red is-inverted" onClick={onDelete}>
				{t('common:delete')}
			</Button>
			<Button type="submit" className="button is-secondary" onClick={props.onDone}>
				{t('common:cancel')}
			</Button>
		</div>
	);
});
