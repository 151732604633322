import { BaseAnimation } from '../BaseAnimation';
import { Group, Raster, Size, Shape } from 'paper/dist/paper-core';
import { LayerType } from '../..';
import { reaction } from 'mobx';
import { Point } from 'paper';

export default class ImageBackground extends BaseAnimation {
    public layerType: LayerType = LayerType.background;
    public supportsDisabling = false;
    isLayerTransformable = false;
    animationEnabled: boolean = false;

    public getDefaultOpts = (ftmId: string) => {
        return {
            image: `public/templatePlaceholders/microphone-bg.png`,
        };
    };

    public create = async (project: paper.Project) => {
        this.ready = false;
        const raster = new Raster({
            position: project.view.center,
            project,
            crossOrigin: 'anonymous',
        });
        raster.on('error', (err) => {
            console.error('Error in ImageBackground', err);
        });

        let initialLoad = true;
        await new Promise((resolve) => {
            raster.on('load', () => {
                const scaleX = this.anim.fmt.w / raster.width;
                const scaleY = this.anim.fmt.h / raster.height;
                raster.scaling = new Point(1, 1); // <-- reset scalling, so that future calls of raster.scale will have the same effect
                raster.scale(scaleX >= scaleY ? scaleX : scaleY);
                this.ready = true;
                this.assetResolver.onLoad();

                let bg = new Shape.Rectangle(new Point(0, 0), new Size(this.anim.fmt.w, this.anim.fmt.h));
                this.group = new Group([bg]);

                if (initialLoad) {
                    initialLoad = false;
                    resolve(undefined);
                }
            });
            raster.on('error', (err) => {
                console.error('Could not load image. using fallback ', err);
                this.getImageUrl('public/templatePlaceholders/default.img.svg').then((url) => {
                    raster.source = url;
                    initialLoad = false;
                    resolve(undefined);
                });
            });

            this.loadImage(raster);
        });

        const disp = reaction(
            () => this.image,
            async () => {
                this.loadImage(raster);
            },
            { fireImmediately: false },
        );
        this.disposers.push(disp);
        return raster;
    };

    public tick(currentframe) {
        return;
    }
}
