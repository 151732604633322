import React from 'react';
import { IVideoFormat } from 'shared-puredio';
import t from './../../common/translate';

interface IRenderFormatProps {
	fmt?: IVideoFormat;
	hideDimensions?: boolean;
}
export const RenderFormat = (props: IRenderFormatProps) => {
	const { fmt } = props;
	if (!fmt) {
		return <div></div>;
	}
	return (
		<>
			{t('components:fmt.' + fmt.id)}{' '}
			{props.hideDimensions !== true && (
				<span>
					({fmt.w} x {fmt.h} px)
				</span>
			)}
		</>
	);
};
