import { Button } from 'app/components/common/Button';
import Icon from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { observer } from 'mobx-react';
import React from 'react';
import t from 'app/common/translate';
import { ProjectSettingsModal } from '../ProjectSettingsModal';
import { ProjectCutAudioModal } from '../Edit/ProjectCutAudio';
import { ProjectAudioSettingsModal } from '../ProjectAudioSettingsModal';

interface IAudioLayerEdit {
	project: ProjectVM;
}
export const AudioLayerEdit = observer((props: IAudioLayerEdit) => {
	const { uiStore, animatorStore } = useStore();
	const projectVM = props.project;
	const audioLayer = projectVM.model.audioLayer!;
	const project = projectVM;
	const cropAudioModalId = uiStore.modalIds.projectCropAudio;
	const projectSettingsModalId = uiStore.modalIds.projectSettings;
	const deleteProjectModalId = uiStore.modalIds.projectDelete(project.id);

	const onCropAudio = () => {
		uiStore.showModal(cropAudioModalId);
		audioLayer.beginSnapshot(projectVM.navState, 'audiolayer');
	};
	const onAudioSettings = () => {
		uiStore.showModal(uiStore.modalIds.audioSetting);
	};
	const onCropApply = () => {
		audioLayer.commitSnapshot();
		animatorStore.stop();
	};
	const onCropCancel = () => {
		audioLayer.cancelSnapshot();
		animatorStore.stop();
	};
	const showEpisodeSettingsModal = () => {
		uiStore.showModal(projectSettingsModalId);
	};

	const openProjectDeleteModal = () => {
		uiStore.showModal(deleteProjectModalId);
	};

	return (
		<>
			<div className="ui-control is-audio-settings">
				<div className="main-controls">
					<h2 className="title">{t('general:project.audio.settings')}</h2>
					<div className="ui-control-group grid">
						<div>
							<Button className="button is-block is-inverted" onClick={onAudioSettings} title={t('general:project.audiofile')}>
								<Icon iconName="file-audio" faModifier="fal" /> {t('tooltips:audio.swap')}
							</Button>
						</div>
						<div>
							<Button className="button is-block is-inverted" onClick={onCropAudio} title={t('general:project.crop')}>
								<Icon iconName="cut" faModifier="fal" /> {t('general:project.crop')}
							</Button>
						</div>
						<div>
							<Button className="button is-block is-inverted" onClick={showEpisodeSettingsModal} title={t('general:project.settings')}>
								<Icon iconName="cog" faModifier="fal" /> {t('tooltips:episode.settings')}
							</Button>
						</div>
					</div>
				</div>
			</div>
			<ProjectAudioSettingsModal project={projectVM} modalId={uiStore.modalIds.audioSetting} />
			<ProjectSettingsModal project={projectVM} modalId={projectSettingsModalId} onRemove={openProjectDeleteModal} />
			<ProjectCutAudioModal project={projectVM} onApply={onCropApply} onCancel={onCropCancel} />
		</>
	);
});
