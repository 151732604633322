import { useStore } from 'app/context';
import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import t from '../../common/translate';
import { observer } from 'mobx-react';
import FormField from '../Form/FormField';
import { useHistory, useParams } from 'react-router-dom';
import routes from 'routes';
import CrudEntityErrors from '../Form/CrudEntityErrors';
import { Button } from '../common/Button';
import { Trans } from 'react-i18next';
import ContactButtonWithModal from '../common/ContactButtonWithModal';
import { passwordRegex } from '../../utils';
import { notify } from 'app/common/notify';

const loginFormSchema = Yup.object()
	.shape({
		password1: Yup.string().matches(passwordRegex, t('validation:password.complexity')).required(t('validation:required.field')).default(''),
		password2: Yup.string()
			.oneOf([Yup.ref('password1')], t('validation:passwords.must.match'))
			.required(t('validation:required.field'))
			.default(''),
	})
	.required();
const block = 'register2';

type ResetPassword = Yup.InferType<typeof loginFormSchema>;

export interface IResetPassword {
	id: string;
}

export const ResetPasswordForm = observer(() => {
	const { authStore } = useStore();
	const history = useHistory();
	const parms = useParams<IResetPassword>();
	const verifyId = parms.id;

	const [verifiyIdInvalid, setVerifiyIdInvalid] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>();

	useEffect(() => {
		authStore.checkResetPassworId(parms.id).then((res) => {
			if (res && (res.status === 201 || res.status === 200)) {
				return;
			}
			setVerifiyIdInvalid(true);
		});
	}, [parms.id]);

	const handleSubmit = async (values: ResetPassword) => {
		const newPassword = values.password1;
		const res = await authStore.resetPassword(verifyId, newPassword);
		if (res && (res.status === 201 || res.status === 200)) {
			notify(t('auth:resetpassword.change.pleaselogin.again'), 'success');
			history.push(routes.AUTH_LOGIN.getPath());
			return;
		}
		setError(true);
		setErrorMsg(t(res.message));
	};

	if (verifiyIdInvalid) {
		return (
			<>
				<div className="auth-form">
					<div className="form-container">
						<div className="form-container-bg">
							<div className="auth-form-header">
								<img src="/images/brand-with-signet.svg" alt="Puredio"></img>
								<h1 className="title is-4">{t('auth:resetpassword.verifiyIdInvalid')}</h1>
							</div>
							<div className="auth-links">
								<div className="auth-link">
									<Trans i18nKey={'auth:needhelp.linked'}>
										Bla<ContactButtonWithModal className="button is-text">Bla</ContactButtonWithModal>
									</Trans>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="auth-form is-login">
				<div className="form-container">
					<div className="form-container-bg">
						<div className="auth-form-header">
							<img src="/images/brand-with-signet.svg" alt="Puredio"></img>
							<h1 className="title is-4">{t('auth:resetpassword.calltoaction')}</h1>
						</div>

						<Formik validationSchema={loginFormSchema} initialValues={{ password1: '', password2: '' }} onSubmit={handleSubmit.bind(this)}>
							{({ errors, touched, isValidating }) => (
								<Form className={block}>
									<div className="form-control">
										<FormField label={t('auth:password')} error={errors.password1} touched={touched.password1}>
											<Field type="password" name="password1" />
										</FormField>
									</div>
									<div className="form-control">
										<FormField label={t('auth:password.repeat')} error={errors.password2} touched={touched.password2}>
											<Field type="password" name="password2" />
										</FormField>
									</div>
									<div className="form-control">
										<Button type="submit" className="button is-primary" disabled={authStore.isEntityLoading}>
											{t('auth:resetpassword.submit')}
										</Button>
									</div>
									<CrudEntityErrors store={authStore} />
								</Form>
							)}
						</Formik>
						<div className="auth-links">
							<div className="auth-link">
								<Trans i18nKey={'auth:needhelp.linked'}>
									Bla<ContactButtonWithModal className="button is-text">Bla</ContactButtonWithModal>
								</Trans>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
});
