import { useStore } from 'app/context';
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import routes from './routes';

export const AuthRoute: React.FC<RouteProps> = ({ children, ...rest }: RouteProps) => {
	const auth = useStore().authStore;
	if (!auth.current) {
		return <Redirect
			to={{
				pathname: routes.AUTH_LOGIN.getPath(),
				//state: { from: location }
			}}
		/>
	}
	return (
		<Route
            {...rest}
		/>
	);
}
