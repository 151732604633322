import { computed, makeObservable, observable } from 'mobx';
import * as path from 'path';
import { IAudioData } from './iaudio.data';

export interface IMetadata {
    name: string;
    selfLink: string;
    id: string;
    size: number;
    etag: string;
    crc32c: string;
    md5Hash: string;
    cacheControl: string;
    contentType: string;
    contentDisposition: string;
    contentEncoding: string;
    contentLanguage: string;
    mediaLink: string;
}

export enum FileType {
    image = 'image',
    audio = 'audio',
    videoexport = 'videoexport',
}
export enum FileStatus {
    created = 'created',
    inprogress = 'inprogress',
    completed = 'completed',
}

export interface IFileModelOpts {
    originalFileName: string;
    md5Hash: string;
    metadata: IMetadata;
    userId: string;
}

export class FileModel {
    constructor() {
        makeObservable(this);
    }

    @observable
    id: string;

    @observable
    md5Hash?: string;

    metadata: IMetadata;

    @observable
    originalFileName: string;

    created?: Date;
    fixDate(prop: string) {
        if (this[prop] && this[prop].toDate) {
            this[prop] = this[prop].toDate();
        }
    }

    userId: string;
    @observable
    fileStatus: FileStatus = FileStatus.created;

    @observable
    audioData?: IAudioData;

    serialize() {
        return {
            id: this.id,
            md5Hash: this.md5Hash,
            metadata: this.metadata,
            originalFileName: this.originalFileName,
            userId: this.userId,
            fileStatus: this.fileStatus,
            audioData: this.audioData,
        };
    }

    @computed
    get ext() {
        const uploadKey = this.originalFileName;
        const ext = path.extname(uploadKey);
        if (ext) {
            return ext.toLowerCase();
        }
    }

    @computed
    get fileType() {
        const images = ['.jpg', '.jpeg', '.png'];
        const audios = ['.mp3'];
        if (images.includes(this.ext)) {
            return FileType.image;
        }
        if (audios.includes(this.ext)) {
            return FileType.audio;
        }
        throw new Error('unsupported ext: ' + this.ext);
    }

    @computed
    get audioDuration() {
        if (this.fileType !== FileType.audio) {
            return -1;
        }
        if (this.audioData) {
            console.log(this.originalFileName, this.audioData.duration);
            return this.audioData.duration;
        }
        return 0;
    }

    @computed
    get baseKey() {
        const userId = this.userId;
        return path.join('users', userId, 'prep', this.md5Hash);
    }

    @computed
    get originalKey() {
        const base = this.baseKey;
        return path.join(base, 'org' + this.ext);
    }

    @computed
    get normalizedKey() {
        const base = this.baseKey;
        let ext = '';
        switch (this.fileType) {
            case FileType.audio:
                ext = '.m4a';
                break;
            case FileType.image:
                ext = '.png';
                break;
            default:
                throw new Error('no normalize key for:' + this.fileType);
        }
        return path.join(base, 'norm' + ext);
    }

    @computed
    get thumbKey() {
        const base = this.baseKey;
        let ext = '';
        switch (this.fileType) {
            case FileType.image:
                ext = '.jpg';
                break;
            default:
                throw new Error('no thumb key for:' + this.fileType);
        }
        return path.join(base, 'thumb' + ext);
    }

    @computed
    get wavedataKey() {
        const base = this.baseKey;
        switch (this.fileType) {
            case FileType.audio:
                break;
            default:
                throw new Error('no wavedataKey for:' + this.fileType);
        }
        return path.join(base, 'wavedata.dat');
    }

    @computed
    get audiosplitPath() {
        const base = this.baseKey;
        switch (this.fileType) {
            case FileType.audio:
                break;
            default:
                throw new Error('no split path for:' + this.fileType);
        }
        return path.join(base, 'split');
    }

    getAudioKeyForSplit(num: string) {
        return path.join(this.audiosplitPath, 'split' + num + '.wav');
    }
}

// Original: [userId]/upload/[filename]
// org: [userId]/prep/[md5]/original.[ext]
// Dat: [userId]/prep/[md5]/wavedata.dat
// normalized: [userId]/prep/[md5]/audio.m4a
// m4a: [userId]/prep/[md5]/splits/split0000000.wav

// Original: [userId]/upload/[filename]
// Org: [userId]/prep/[md5]/org.[ext]
// Norm: [userId]/prep/[md5]/norm.png
// Thumb: [userId]/prep/[md5]/thumb.png
