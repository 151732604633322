import React from 'react';
import BaseContainer from './BaseContainer';
import SideMenu from 'app/components/SideMenu';
import t from '../common/translate';
import Breadcrumb, { IBreadcrumbItem } from 'app/components/Breadcrumb';
import { SvgIcon, SvgIconType } from 'app/components/common/SvgIcon';

export const ContactContainer: React.FC = () => {
	const crumbs: IBreadcrumbItem[] = [
		{
			title: t('nav:support'),
		},
	];

	const ContactNavbar = () => {
		return (
			<div className="app-navbar">
				<Breadcrumb crumbs={crumbs} />
			</div>
		);
	};

	return (
		<BaseContainer menu={<SideMenu />} navbar={<ContactNavbar />}>
			<div className="section">
				<div className="contact-list">
					<a className="contact-list-item" href="mailto:info@puredio.com">
						<div className="contact-icon">
							<SvgIcon iconName={SvgIconType.mail} />
						</div>
						<div className="text">
							<div className="title is-5">{t('contact:feedback.title')}</div>
							<span>{t('contact:feedback.text')}</span>
						</div>
					</a>

					{/* <div className="contact-list-item" onClick={openLearningLink}>
						<div className="contact-icon">
							<SvgIcon iconName={SvgIconType.learning} />
						</div>
						<div className="text">
							<div className="title is-5">{t('contact:learning.title')}</div> <span>{t('contact:learning.text')}</span>
						</div>
					</div> */}

					<a className="contact-list-item" href={t('contact:tawk.link')} target="_blank" rel="noreferrer noopener">
						<div className="contact-icon">
							<SvgIcon iconName={SvgIconType.bug} />
						</div>
						<div className="text">
							<div className="title is-5">{t('contact:bug.title')}</div>
							<span>{t('contact:bug.text')}</span>
						</div>
					</a>

					<a className="contact-list-item" href={t('contact:tawk.link')} target="_blank" rel="noreferrer noopener">
						<div className="contact-icon">
							<SvgIcon iconName={SvgIconType.chat} />
						</div>
						<div className="text">
							<div className="title is-5">{t('contact:support.title')}</div>
							<span>{t('contact:support.text')}</span>
						</div>
					</a>
				</div>
			</div>
		</BaseContainer>
	);
};
