import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { PaddlePlan, PlanType } from 'shared-puredio';

// firebase api auth errors
// https://firebase.google.com/docs/auth/admin/errors
const validation = {
	'required.field': 'Dieses Feld ist erforderlich',
	'required.format': 'Bitte eine Videoformat auswählen',

	'required.audiofile': 'Bitte eine Audiodatei auswählen',
	'required.template': 'Bitte eine Vorlage auswählen',
	'required.email': 'E-Mail ist erforderlich',
	'invalid.email': 'E-Mail-Addresse ist ungültig',
	'passwords.must.match': 'Passwörter müssen übereinstimmen',
	'password.complexity': 'Das Passwort muss mindestens 8 Buchstaben mit Groß- und Kleinschreibung, eine Zahl und ein Sonderzeichen enthalten.',
};

const auth = {
	email: 'E-Mail-Adresse',
	yourEmail: 'Deine E-Mail-Adresse',
	password: 'Passwort',
	register: 'Registrieren',
	firstName: 'Vorname',
	lastName: 'Nachname',
	support: 'Support',
	login: 'Anmelden',
	signout: 'Abmelden',
	personalData: 'Persönliche Daten',
	account: 'Konto',
	settings: 'Einstellungen',
	payment: 'Subscription',
	invoices: 'Rechnungen',
	accountsettings: 'Konto Einstellungen',
	'update.success': 'Änderungen wurden gespeichert',
	'email.update.success': 'E-Mail-Adresse wurde aktualisiert. Bitte den Bestätigungs-Link in Deinem E-Mail-Postfach klicken.',
	'delete.account': 'Konto löschen',
	'delete.account.info': 'Wenn Du Deinen Account löscht, werden alle Deine Daten unwiderruflich entfernt.',
	'delete.account.areyousure': 'Wenn Du sicher bist, dass Du Dein Puredio Konto ({{email}}) unwiderruflich löschen willst, dann klicke bitte auf "Konto Löschen".',
	'register.welcome': 'Registrierung',
	'register.welcome.calltoaction': 'Gemeinsam mit Deiner Unterstützung wollen wir puredio noch besser machen. Teste jetzt unverbindlich!',
	'register.success': 'Danke für Deine Anmeldung!',

	'register.success.calltoaction': 'Um Deine Anmeldung abzuschließen, haben wir Dir eine Email zur Bestätigung Deiner E-Mail-Adresse geschickt. <1/><1/> Bitte klicke auf den darin enthalten Link. <1/>',
	'register.success.calltoaction.2': 'Videos erstellen',
	'register.tosmustbechecked': 'Bitte bestätige die AGB und Datenschutzebstimmugen gelesen zu haben',

	'verifyemail.success': 'Vielen Dank, dass Du Deine E-Mail-Adresse bestätigt hast!',
	'verifyemail.success.calltoaction': 'Du kannst Dich jetzt anmelden.',
	'verifyemail.error': 'Da ist leider etwas schief gelaufen!',

	'verifyemail.reminderbutton': 'Bitte Email verifizieren',
	'verifyemail.sendagain': 'Verfizierungslink erneut senden',
	'verifyemail.reminder.calltoaction': 'Deine Emailadresse ist noch nicht verifiziert. Bitte klicke auf den Link den wir Dir bei der Registierung geschickt haben.<br/><br/> Falls du diesen nicht mehr zur Hand hast, kannst du dir auch einen neuen Link zukommen lassen.',
	'verifyemail.sentagain': 'Verfizierungslink wurde erneut verschickt',
	'verifyemail.banner.message': 'Bitte bestätige Deine E-Mail-Adresse um puredio nutzen zu können!',
	'verifyemail.banner.cta': 'Link erneut zusenden',
	'verifyemail.notice.title': 'E-Mail-Adresse bestätigen',
	'verifyemail.notice.message': 'Bitte bestätige aus Sicherheitsgründen Deine E-Mail-Adresse. Klicke hierfür auf den Bestätigungs-Link, den Du in Deiner Registrierungs-Mail erhalten hast. Du kannst Dir auch <1>einen neuen Link zusenden</1> lassen.',

	'resetpassword.success': 'Passwort erfolgreich geändert',
	'please.login.again': 'Bitte Melde Dich jetzt wieder an!',
	'resetpassword.error': 'Da ist leider etwas schief gelaufen!',
	'resetpassword.verifiyIdInvalid': 'Die Passwortanforderung ist unbekannt, ungültig oder abgelaufen.',

	'login.link': 'Zum Login',
	'forgotpassword.email': 'E-Mail, mit der Du bei Puredio registriert bist',
	'forgotpassword.instructions': 'Mithilfe Deiner E-Mail-Adresse kannst Du Dein Passwort zurücksetzen.',
	'forgotpassword.request.sent': 'Deine Passwortanfrage wurde versendet. Bitte überprüfe Dein Postfach und folge den Anweisungen in der E-Mail. ',
	'forgotpassword.request.sent.note': 'Wir versenden eine Anforderung nur, wenn wir die E-Mail auch in unserem System finden können. Dies ist eine Sicherheitsmaßnahme.',

	//aggreeToTos: 'Mit einem Klick auf den Button "{{button}}" bestätigst Du, dass Du unsere AGBs und Datenschutzbestimmungen gelesen hast und akzeptierst.',
	aggreeToTos:
		'Hiermit bestätige ich, dass ich die <1>AGB</1> und den <2>Datenschutz</2> von Puredio gelesen habe und akzeptiere. Wir verwenden Deine E-Mail-Adresse, um Dir Werbung für Produkte und Dienstleistungen von Puredio zuzusenden. Du kannst der Verwendung Deiner E-Mail-Adresse zu Werbezwecken jederzeit widersprechen, indem Du eine E-Mail an unsubscribe@puredio.com sendest.',
	requestNewPassword: 'Neues Passwort anfordern',
	'forgotpassword.linked': '<1>Passwort vergessen?</1>',
	'needhelp.linked': 'Fragen oder Probleme? Dann nutze doch unser <1>Support Formular<2>',
	'donthaveanaccount.linked': 'Du hast noch keinen Account? <1>Zur Registrierung<2>',
	'allreadyHaveAnAccount.linked': 'Du hast bereits einen Account? <1>Zum Login<2>',
	'login.welcomeback': 'Willkommen zurück',
	'login.submit': 'Login',

	'password.new': 'Neues Passwort',
	'password.repeat': 'Passwort wiederholen',
	'resetpassword.calltoaction': 'Bitte gib hier Dein neues Passwort ein',
	'resetpassword.submit': 'Passwort ändern',
	'resetpassword.change.pleaselogin.again': 'Passwort wurde erfolgreich geändert. Bitte neu anmelden.',

	'changeemail.submit': 'Email ändern',
};
const common = {
	ready: 'Bereit!',
	save: 'Speichern',
	cancel: 'Abbrechen',
	goback: 'Zurück',
	next: 'Weiter',
	send: 'Abschicken',
	created: 'Erstellt',
	edit: 'Bearbeiten',
	settings: 'Einstellungen',
	delete: 'Löschen',
	undo: 'Rückgängig',
	ok: 'OK',
	close: 'Schließen',
	apply: 'Übernehmen',
	crop: 'Zuschneiden',
	start: 'Start',
	end: 'Ende',
	duration: 'Länge',
	startnow: 'Loslegen',
	madeInGermany: 'Entwickelt \nin Deutschland',
	realSupport: 'Echter Support.\nImmer für Dich da.',
	fairPricing: 'Transparente Preise.\nJederzeit kündbar.',
	impressum: 'Impressum',
	'impressum.link': 'https://puredio.com/impressum',
	datenschutz: 'Datenschutz',
	'datenschutz.link': 'https://puredio.com/datenschutz',
	agb: 'AGB',
	'agb.link': 'https://puredio.com/agb',
	notice: 'Hinweis',
	makeAppointment: 'Termin vereinbaren',
};

const fonts = {
	'100': '100',
	'200': '200',
	'300': '300',
	'400': '400',
	'500': '500',
	'600': '600',
	'700': '700',
	'800': '800',
	'900': '900',
	'100italic': '100 Kursiv',
	'200italic': '200 Kursiv',
	'300italic': '300 Kursiv',
	'400italic': '400 Kursiv',
	'500italic': '500 Kursiv',
	'600italic': '600 Kursiv',
	'700italic': '700 Kursiv',
	'800italic': '800 Kursiv',
	'900italic': '900 Kursiv',
	regular: 'Normal',
	italic: 'Kursiv',
};

const nav = {
	home: 'Home',
	podcasts: 'Podcasts',
	renders: 'Videos',
	audioFiles: 'Audiodateien',
	imageFiles: 'Bilder',
	support: 'Support',
	account: 'Konto',
};

const tooltips = {
	'nav.home': 'Verwaltungsoberfläche',
	'audio.crop': 'Audio zuschneiden',
	'audio.upload': 'Audiodatei hochladen',
	'audio.swap': 'Audiodatei austauschen',
	'episode.settings': 'Episodeneinstellungen',
	'layer.add': 'Füge Texte, Bilder, Audiogramme oder andere Elemente hinzu.',
	'layer.settings': 'Ebeneneinstellungen',
};

const general = {
	'home.welcome': 'Willkommen bei puredio!',
	'home.intro': 'Danke, dass Du uns hilfst, mit puredio die beste Podcast-Visualisierung am Markt zu gestalten. Damit das gelingt, benötigen wir Dein Feedback. Daher bitten wir Dich um 5 Minuten Deiner Zeit, um das Feedback-Formular im Anschluss Deines Tests auszufüllen.',
	'home.sendfeedback': 'Feedback senden',
	'home.quickstart': 'Quick Start',
	'home.firstpodcast': 'Deinen ersten Podcast anlegen',
	'home.newpodcast': 'Neuen Podcast anlegen',

	'groups.empty.title': 'Willkommen bei puredio',
	'groups.empty.emoji': '👋',
	'groups.empty.text': 'Hinterlege Deinen Podcast mit einem Klick auf den Button “Podcast hinzufügen”. \nDadurch erstellst Du einen Ordner, worin Du Deine einzelnen Episoden verwaltest. \nProbiere es gleich aus!',

	'groups.demo.title': 'Demo Podcast',
	'groups.title': 'Podcasts',
	'groups.gotoprojects': 'Zu den Episoden',
	'groups.add': 'Podcast hinzufügen',
	'group.edit.title': 'Podcast Einstellungen',
	'group.add.submit': 'Podcast erstellen',
	'group.not.found': 'Podcast nicht gefunden',
	'group.new': 'Neuer Podcast',
	'group.title': 'Titel',
	'group.titleImage': 'Coverbild',
	'group.delete': 'Podcast löschen',
	'group.delete.are.you.sure': 'Bist Du sicher, dass Du diese Episode löschen willst? \nDeine exportierten Videos gehen dabei nicht verloren.',
	'project.delete.are.you.really.sure': 'Dieser Vorgang kann nicht rückgängig gemacht werden.',
	'group.delete.count': 'Dieser Podcast hat {{count}} Episode(n).',
	'group.delete.warn1': 'Bist Du Dir sicher, das Du den Podcast und alle Episoden löschen möchtest?',
	'group.delete.warn2': 'Dieser Vorgang kann nicht rückgängig gemacht werden.',
	'projects.add': 'Episode hinzufügen',
	'projects.title': 'Episoden',
	'project.selectTemplate': 'Wähle Deine Vorlage',
	'project.uploadAudio': 'Audiodatei hochladen',
	'project.selectAudio': 'Audio wählen',
	'project.selectCrop': 'Ausschnitt wählen',
	'project.delete': 'Episode löschen',
	'project.title': 'Titel',
	'project.audio.settings': 'Audioeinstellungen',
	'project.audiofile': 'Audiodatei',
	'project.crop': 'Audiodatei zuschneiden',
	'project.template': 'Vorlage',
	'project.emptyTemplate': 'Leeres Video',
	'project.notfound': 'Episode nicht gefunden',
	'project.format': 'Format',
	'project.status': 'Status',
	'project.render': 'Video exportieren',
	'project.renderlist': 'Renderliste',
	'project.create': 'Episode erstellen',
	'project.new.title': 'Neue Episode',
	'project.settings': 'Episodeneinstellungen',
	'project.duplicate': 'Duplizieren',
	'project.copyof': 'Kopie von',
	'project.isbeingcreated': 'Episode wird erstellt... ',
	'project.create.error': 'Beim Erstellen der Episode ist ein Fehler aufgetreten',
	'project.duplicate.error': 'Beim duplizieren der Episode ist ein Fehler aufgetreten',
	'project.duplicate.success': 'Episode wurde erfolgreich dupliziert',
	'project.empty.title': 'Episoden',
	'project.empty.text': 'Erstelle Deine erste Episode und gestalte Podcast Videos, indem Du auf den Button “Episode hinzufügen” klickst.',
	'render.title.waitingForAudio': 'Audioverarbeitung - Bitte noch einen Moment Geduld',
	'render.waitingForAudio': 'Deine Audiodatei wird aktuell im Hintergrund verarbeitet. \nEine Datei mit einer Länge von 1 Stunde benötigt ca. 3 Minuten. \n\nSobald die Verarbeitung abgeschlossen ist, kann Dein Video exportiert werden.',
	'render.title.canStart': 'Dein Video ist bereit zum rendern',
	'render.title.canStartEmoji': '👍',
	'render.videoFormat': 'Format',
	'render.formatFor': 'Perfekt für',
	'render.title.started': 'Dein Video wird gerendert',
	'render.title.startedEmoji': '🚀',
	'render.youcanclose': 'Du kannst dieses Fenster schließen.',
	'render.wewillinformyou': 'Sobald Dein Video fertig ist, senden wir Dir eine E-Mail.',
	'render.estimatedDuration': 'Geschätzte Renderzeit: ca. 3 bis 7 Minuten',
	'render.title.success': 'Dein Video ist fertig',
	'render.title.successEmoji': '🎉',
	'render.render': 'Rendern',
	'render.renderStart': 'Rendern starten',
	'render.abort': 'Rendern abbrechen',
	'render.renderlist.info': 'Du findest dieses Video und auch Deine vorherigen Videos auch in der Renderliste.',
	'render.renderagain': 'Nochmal rendern!',
	'render.renderagain.info': 'Dein vorheriges Video geht hierbei nicht verloren',
	'render.title.error': 'Beim Rendern ist ein Fehler aufgetreten',
	'render.quota.error': 'Deine verfügbare Renderzeit ist für den Export Deines Videos nicht ausreichend.',
	'render.quota.pleaseUpgrade': '<1>Führe jetzt ein Upgrade durch</1> und habe sofort mehr Videominuten zur Verfügung.',
	'render.error.support': 'Falls Du Unterstützung benötigst, kannst Du uns über den Support Button erreichen. Wir werden unser bestes geben, Dein Anliegen schnellstmöglichst zu lösen.',
	'renderdetail.group': 'Podcast',
	'renderdetail.project': 'Episode',
	'renderdetail.status': 'Status',
	'renderdetail.duration': 'Länge',
	'renderdetail.created': 'Gestartet',
	'renderdetail.format': 'Format',
	'renderdetail.link': 'Link',
	'renderdetail.preview': 'Vorschau',

	'modal.welcome.title': 'Willkommen bei puredio!',
	'modal.welcome.emoji': '🎉',
	'modal.welcome.text1': 'Es freut uns, dass Du puredio bereits in der Beta-Testphase ausprobierst. Bitte bedenke, dass es an manchen Stellen noch zu Fehlern kommen kann. ',
	'modal.welcome.text2': 'Solltest Du einen Bug entdeckt haben, freuen wir uns über Deine Nachricht.',
	'modal.badBrowser.title': 'Dein Browser wird nicht unterstützt',
	'modal.badBrowser.emoji': '😔',
	'modal.badBrowser.text1': 'Leider wird Dein derzeit genutzter Browser momentan noch nicht unterstützt. Wir empfehlen Dir, die aktuelle Version von Chrome oder Firefox zu verwenden.',
	'modal.badBrowser.text2': 'Wir entschuldigen uns für die Unannehmlichkeiten!',

	'support.name': 'Name',
	'support.email': 'E-Mail',
	'support.type': 'Grund',
	'support.type.any': 'Frage',
	'support.type.bug': 'Bug',
	'support.type.improvement': 'Verbesserungsvorschlag',
	'support.type.enterpriserequest': 'Anfrage Plan Enterprise',
	'support.subject': 'Betreff',
	'support.body': 'Text',
	'support.message.sent': 'Vielen Dank für Deine Anfrage. Wir werden uns schnellstmöglich um Dein Anliegen kümmern!',
	'contact.feedback.welcome': 'Mit Deinem Feedback wird puredio noch besser!',
	'contact.feedback.calltoaction1': 'Gemeinsam mit Dir wollen wir puredio zur besten Software für Podcast Video entwickeln.',
	'contact.feedback.calltoaction2': 'Damit das gelingt, benötigen wir Dein Feedback in Form einer kurzen Umfrage.',
	'contact.sendfeedback': 'Feedback senden',
	'contact.support.welcome': 'Du benötigst Unterstützung oder hast einen Bug entdeckt?',
	'contact.support.calltoaction': 'puredio befindet sich aktuell noch in der Entwicklung. Daher kann es vereinzelt zu kleineren Ausfällen oder unklaren Prozessen kommen. Nutze bei Anliegen daher bitte unser Support Formular.',
	'contact.support.get': 'Support anfragen',

	'settings.payment.invoiceDate': 'Rechnungsdatum',
	'settings.payment.amount': 'Betrag',
	'settings.payment.isPaid': 'Bezahlt',
	'settings.payment.downloadInvoice': 'Rechnung öffnen',
	'settings.payment.noinvoices': 'Sobald Du Dich für einen kostenpflichtigen Puredio-Plan entschieden hast, findest Du hier Deine Rechnungsübersicht inkl. Download-Option.',

	'error.general': 'Es ist ein Fehler aufgetreten. Bitte setze dich mit dem Support in Verbindung',
};

const level1Min = PaddlePlan.getVideoMinuteQuota(PlanType.starter);
const level15Min = PaddlePlan.getVideoMinuteQuota(PlanType.pocaster);
const level50Min = PaddlePlan.getVideoMinuteQuota(PlanType.studio);

const plans = {
	'starter.title': 'Starter',
	'starter.message': 'Perfekt, wenn Du hin und wieder ein Video erstellen möchtest.',

	'podcaster.title': 'Podcaster',
	'podcaster.message': 'Für Podcaster*innen, die regelmäßig neue Folgen veröffentlichen.',

	'studio.title': 'Studio',
	'studio.message': 'Für Agenturen, Studios & Podcaster*innen mit einer Vielzahl an Podcasts.',

	'enterprise.title': 'Custom Solutions',
	'enterprise.message': 'Du bist Corporate, Brand, Podcast Hoster, Verlag oder Medienhaus und hast ganz spezielle Anforderungen? <br> Kontaktiere uns, um mehr über unsere individuellen Lösungen zu erfahren.',
	'enterprise.calendly': 'https://calendly.com/puredio/puredio-custom-solutions?month=2022-02',
};

const payment = {
	'message.noplan': 'Du hast derzeit noch keinen aktiven puredio Plan. Wähle das für Dich passende Modell und entdecke viele weitere Funktionen und Möglichkeiten.',
	'message.hasplan': 'Du kannst jederzeit zum Monatsende kündigen oder auf ein anderes Modell wechseln.',
	perMonth: 'pro Monat',
	recommended: 'Beliebt',
	'perk.level1': level1Min + ' Minuten Video pro Monat',
	'perk.level15': level15Min + ' Minuten Video pro Monat',
	'perk.level50': level50Min + ' Minuten Video pro Monat',
	'perk.level999': 'Soviel Renderzeit wie du brauchst',
	'perk.nowatermark': 'kein Wasserzeichen',
	'perk.watermark': 'Puredio Wasserzeichen',
	'perk.template25': 'Basic + Premium Vorlagen',
	'perk.saveInfinite': 'Exportierte Videos werden dauerhaft gespeichert',
	'perk.customFonts': 'Eigene Schriftarten',
	'perk.terminateMonthly': 'monatlich kündbar',
	'perk.hd': 'HD Export',
	'message.weUsePaddle': 'Für sichere Transaktionen nutzen wir den Zahlungsdienstleister paddle.',
	'price.onRequest': 'Preis auf Anfrage',
	'plan.upgrade': 'Upgrade',
	'plan.downgrade': 'Downgrade',
	'plan.current': 'Aktueller Plan',
	'plan.freeuntil': 'Kostenlos bis',
	'plan.cancel': 'Plan kündigen',
	'plan.emoji': '😔',
	'message.cancel.areyousure': 'Bist Du sicher, dass Du deinen Plan kündigen möchtest? Nach Deiner Kündigung werden einige Features nicht mehr zur Verfügung stehen.',
	'message.cancel.wedowngradeyou': 'Du wirst automatisch auf die kostenfreie Version zurückgestuft und kannst zu einem späteren Zeitpunkt jederzeit erneut ein Upgrade durchführen.',
	'message.cancel.letschat': 'Solltest Du Fragen oder Probleme bei der Verwendung von puredio haben, <1>kontaktiere uns,</1> um gemeinsam an einer Lösung arbeiten.',
	'message.cancel.feedback': '<p>Wir bedauern, dass Du Deinen puredio Plan gekündigt hast.</p><p> Um unsere Services zu verbessern freuen wir uns auf Deine ehrliche Meinung.</p>',
	'message.cancel.feedback.label': 'Was können wir besser machen?',
	'message.downgrade.fyi':
		'<p>Bitte bestätige Dein Downgrade auf <1>{{plan}}</1>. Nach Deiner Bestätigung ist das Downgrade ab dem nächsten Abrechnungszzeitraum aktiv. Dein aktueller Plan bleibt bis dahin bestehen.</p><p>Bitte beachte, dass Dir nach dem Downgrade manche Funktionen nicht mehr oder nur eingeschränkt zur Verfügung stehen werden.</p>',
	'message.upgrade.fyi': '<p>Bitte bestätige Dein Upgrade auf <1>{{plan}}</1>. Nach Deiner Bestätigung ist das Upgrade sofort aktiv und Du kannst alle neuen Funktionen vollumfänglich nutzen. Bereits betätigte monatliche Zahlungen werden beim Upgrade verrechnet.</p>',
	'plan.yourBenefits': 'Dein Vorteil:',
	'templatesModal.title': 'Premium Vorlage',
	'templatesModal.subTitle': 'Mit einem Upgrade hast Du Zugriff auf zahlreiche Features & Vorlagen',
	'premium.perk1': 'bis zu 500 Videominuten pro Monat',
	'premium.perk2': 'kein Wasserzeichen',
	'premium.perk3': 'Basic + Premium Vorlagen',
	'premium.perk4': 'Exportierte Videos werden dauerhaft gespeichert',
	'price.from': 'Ab {{price}} € pro Monat',
	'goto.upgrade': 'Zum Upgrade',
	'plan.remaining.title': 'Deine Nutzung',
	'plan.remaining.text': 'Du hast diesen Monat noch {{time}} zur Verfügung.',
	'plan.depleted.title': 'Videominuten aufgebraucht',
	'plan.depleted.text': 'Führe jetzt ein Upgrade durch und habe sofort mehr Videominuten zur Verfügung.',
	'plan.active.until': 'Aktiv bis',
	'watermark.upgrade.text': 'Führe jetzt ein Upgrade durch um das Wasserzeichen zu entfernen und zahlreiche weitere Funktionen freizuschalten.',
	'watermark.upgrade.cta': 'Wasserzeichen entfernen',
};

const contact = {
	title: 'Support',
	'feedback.title': 'Feedback',
	'feedback.text': 'Du möchtest uns etwas mitteilen? Wir freuen uns auf Deine Nachricht.',
	'learning.title': 'Learning Center',
	'learning.text': 'Wirf einen Blick in unser Learning Center mit zahlreichen Tutorials, Video Guides und mehr.',
	'bug.title': 'Fehler melden',
	'bug.text': 'Du bist auf einen Fehler gestoßen? Lass es uns wissen.',
	'support.title': 'Support',
	'support.text': 'Du benötigst Unterstützung oder weitere Beratung? Kontaktiere uns!',
	'tawk.link': 'https://tawk.to/chat/603735401c1c2a130d62303a/1evbqg1fr',
};

const components = {
	'fmt.square': 'Quadrat',
	'fmt.landscape': 'Landscape',
	'fmt.portrait': 'Story',
	'fmt.background': 'Hintergrund',
	'renderStatus.success': 'Erfolgreich',
	'renderStatus.processing': 'In Bearbeitung',
	'renderStatus.error': 'Fehler',
	'renderStatus.undefined': 'Noch nicht gestartet',
	'renderStatus.aborted': 'Abgebrochen',
	layerSettings: 'Ebeneneinstellungen',
	'layer.add': 'Element hinzufügen',
	'layertype.audio': 'Audio',
	'layertype.audiogram': 'Audiogramm',
	'layertype.background': 'Hintergrund',
	'layertype.badge': 'Badge',
	'layertype.image': 'Bild',
	'layertype.progress': 'Fortschritt',
	'layertype.text': 'Text',
	'layertype.animatedText': 'Animierter Text',
	'layertype.util': 'Tools',
	'layertype.watermark': 'Wasserzeichen',
	'link.copied': 'Link wurde in die Zwischenablage kopiert',
	'link.copy': 'Link kopieren',
	'download.video': 'Download',
	'animatedText.defaultText': 'Text eingeben...',
	'animclass.ImageBackground': 'Bild',
	'animclass.GradientBackground': 'Farbverlauf',
	'animclass.SolidBackground': 'Farbe',
	'animclass.ImageLayer': 'Bild',
	'animclass.IconLayer': 'Icon',
	'animclass.TextBlock': 'Textblock',
	'animclass.DoubleHorizontalText': 'Zwei Zeilen Text',
	'animclass.WaveAnimation': 'Welle',
	'animclass.RoundBars': 'Gerundete Säulen',
	'animclass.RoundedSquareWave': 'Miniwellen',
	'animclass.CircleWave': 'Kreis',
	'animclass.Skyscrapers': 'Wolkenkratzer',
	'animclass.Badge': 'Badge',
	'animclass.SimpleProgressAnimation': 'Fortschritt (Einfach)',
	'animclass.DebugTimeAnimation': 'DebugTimeAnimation',
	'animclass.MultilineText': 'Text',
	'animclass.watermark': 'Wasserzeichen',
	'animtext.addText': 'Text hinzufügen',
	'crop.image': 'Bild zuschneiden',
	'upload.success': 'Erfolgreich hochgeladen',
	'upload.success.long': 'wurde erfolgreich hochgeladen, wähle im nächsten Schritt den passenden Ausschnitt.',
	'upload.replace': 'Datei ändern',
	'upload.select.previous.image': 'Vorherige Uploads',
	'upload.select.previous.audio': 'Vorherige Uploads',
	'upload.select.image': 'Bild auswählen',
	'upload.select.audio': 'Audiodatei auswählen',
	'upload.cannot.delete': 'Diese Datei wird noch an anderer Stelle verwendet und kann nicht gelöscht werden.',
	'upload.delete.title': 'Datei löschen',
	'upload.delete.confirmation': 'Bist Du sicher, dass Du diese Datei löschen möchtest?',
	'image.parsing': 'Verarbeite Bild..',
	'audio.crop.title': 'Audio zuschneiden',
	'audio.start': 'Start',
	'audio.end': 'Ende',
	'audio.duration.sec': 'Länge (Sek)',
	'audio.duration': 'Länge',
	'audio.uploaded': 'Hochgeladen',
	'audio.reading': 'Wird gelesen',
	'audio.upload.dropZoneActiveText': 'Datei hier ablegen',
	'audio.upload.dropZoneText': '.MP3-Datei hier ablegen oder manuell hochladen.',
	'audio.upload.uploadingText': 'Datei wird hochgeladen',
	'audio.upload.processingstarted': 'Audioverarbeitung wird gestartet...',
	'audio.upload.processingcompleted': 'Audioverarbeitung abgeschlossen',
	'image.upload.dropZoneActiveText': 'Datei hier ablegen',
	'image.upload.dropZoneText': 'Bild per Drag & Drop hier ablegen',
	'image.upload.uploadingText': 'Datei wird hochgeladen',
	'uie.disabled': 'Deaktivieren',
	'uie.isRound': 'Rund',
	'uie.icon': 'Icon',
	'uie.border': 'Bildrand',
	'uie.borderColor': 'Randfarbe',
	'uie.borderWidth': 'Breite',
	'uie.startColor': 'Start',
	'uie.endColor': 'Ende',
	'uie.color': 'Farbe',
	'uie.height': 'Höhe',
	'uie.font': 'Schriftart',
	'uie.fontVariant': 'Schriftstil',
	'uie.fontWeight': 'Schriftbreite',
	'uie.fontSize': 'Schriftgröße',
	'uie.textAlign': 'Ausrichtung',
	'uie.image': '',
	'uie.contentTop': 'Zeile 1',
	'uie.contentBottom': 'Zeile 2',
	'uie.fillColor': 'Farbe',
	'uie.hasBackground': 'Hintergrund',
	'uie.backgroundColor': 'Hintergrundfarbe',
	'uie.background': 'Hintergrundfarbe',
	'uie.content': 'Text',
	'uie.badge': 'Badge',
	'uie.y': 'Y-Position',
	'uie.amplitude': 'Verstärkung',
	'uie.settings': 'Einstellungen',
	'dropzone.file-too-large': 'Datei zu groß',
	'dropzone.file-too-small': 'Datei zu klein',
	'dropzone.too-many-files': 'Nur eine Datei auf einmal bitte',
	'dropzone.file-invalid-type': 'Ungültiger Dateityp',
};

const fb = {
	'auth/email-already-in-use': 'Diese E-Mail wird bereits verwendet',
	'auth/user-not-found': 'Diese E-Mail ist uns unbekannt',
	'auth/user-disabled': 'Diese Konto ist deaktiviert',
	'auth/weak-password': 'Bitte wähle ein stärkeres Passwort.',
	'auth/wrong-password': 'Falsches Passwort',
	'auth/invalid-password': 'Ungültiges Passwort',
	'auth/error': 'Ein Fehler ist aufgetreten',
	'auth/requires-recent-login': 'Login ist zu alt, bitte wieder einloggen.',
};
const api = {
	'audio-prep-not-ready': 'Audio Vorbereitung noch nicht abgeschlossen.',
	'exec-render-task-error': 'Fehler beim Exportieren.',
	aborted: 'Abgebrochen.',
	change_password_invalid_verification_id: 'Ungültige Anforderung',
	change_password_allready_used: 'Diese Anforderung kann nur einmal verwendet werden',
};

export const i18Init = i18next.use(initReactI18next).init(
	{
		lng: 'de',
		debug: true,
		keySeparator: false,
		defaultNS: 'common',
		resources: {
			de: {
				validation,
				api,
				auth,
				common,
				nav,
				general,
				components,
				fb,
				tooltips,
				fonts,
				contact,
				payment,
				plans,
			},
		},
	},
	function (err, t) {
		console.log('i18n', t('ready'));
	},
);
