import { AuthStore } from './auth.store';
import CRUDSrvStore from './common/crud.service.store';
import { FileModel, FileType, ImageModel } from 'shared-puredio';
import { notify } from 'app/common/notify';
import t from 'app/common/translate';

import { FileApi } from 'app/api/file.api';
import { action, computed, makeObservable, observable } from 'mobx';
import ComputeApi from 'app/api/compute.api';
import _ from 'lodash';

export class FileStore extends CRUDSrvStore<FileModel> {
	authStore: AuthStore;
	fileApi: FileApi;
	computeApi: ComputeApi;

	constructor(authStore: AuthStore, fileApi: FileApi, computeApi: ComputeApi) {
		super(authStore, fileApi);
		makeObservable(this);
		this.authStore = authStore;
		this.fileApi = fileApi;
		this.computeApi = computeApi;
	}

	@observable
	isBusy: boolean = false;

	@action
	setIsBusy(v: boolean) {
		this.isBusy = v;
	}

	@observable selectId?: string;

	@action
	setSelectedById(id?: string) {
		this.selectId = id;
	}
	@computed
	get selected(): FileModel | undefined {
		return this.list.find((i) => i.id === this.selectId);
	}

	async findByMd5Hash(md5: string): Promise<FileModel | undefined> {
		try {
			const userId = this.authStore.current!.id!;
			const snapShot = await this.service.getCollection().where('userId', '==', userId).where('md5Hash', '==', md5).get();
			if (!snapShot.empty) {
				return this.service._map(snapShot.docs[0]);
			}
		} catch (ex) {
			console.log('findById', ex);
			throw ex;
		}
	}

	listImages(): FileModel[] {
		let res = this.list.filter((p) => p.fileType === FileType.image);
		if (!res) {
			return [];
		}
		res = _.sortBy(res, 'created').reverse();
		return res;
	}

	listAudio(): FileModel[] {
		let res = this.list.filter((p) => p.fileType === FileType.audio);
		if (!res) {
			return [];
		}
		res = _.sortBy(res, 'created').reverse();
		return res;
	}

	@computed
	get audioFiles() {
		return this.list.filter((p) => p.fileType === FileType.audio);
	}

	@computed
	get imageFiles() {
		return this.list.filter((p) => p.fileType === FileType.image).map((f) => ImageModel.fromStringOrObject(f));
	}

	@action
	async parseAudioByKey(key: string, force: boolean = false): Promise<FileModel | undefined> {
		this.setIsBusy(true);
		const res = await this.computeApi.parseAudio(key, force);
		if (res && res.data) {
			const f = await this.findByMd5Hash(res.data.md5Hash);
			if (f && f.md5Hash) {
				const readyCheck = await this.computeApi.checkAudioIsReady(f.md5Hash!);
				if (readyCheck && readyCheck.audioIsReady) {
					notify(t('components:audio.upload.processingcompleted'));
				} else {
					notify(t('components:audio.upload.processingstarted'));
				}
			}
			this.setIsBusy(false);
			return f;
		}
	}

	@action
	async prepareImage(key: string) {
		const userId = this.authStore.current!.id!;
		return await this.computeApi.prepareImage(userId, key);
	}

	async setImageModel(im: ImageModel) {
		if (!im || !im.md5Hash) {
			return;
		}
		let f = this.list.find((f) => f.md5Hash === im.md5Hash);
		if (!f) {
			f = await this.findByMd5Hash(im.md5Hash);
		}

		if (f) {
			im.metadata = f.metadata;
			im.originalFileName = f.originalFileName;
			if (!im.userId) {
				im.userId = this.authStore.current!.id!;
			}
			return im;
		}
	}
}
