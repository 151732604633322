import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GlobalStore } from './stores/global.store';
export const globalStoreInstance = new GlobalStore();
export const StoreContext = React.createContext(globalStoreInstance);
globalStoreInstance.authStore.loadCurrUser();

export interface IProps extends RouteComponentProps<any> {
	store?: GlobalStore;
};

export const useStore = () => React.useContext(StoreContext);
export const withStore = (WrappedComponent: any): any => {
	return withRouter(class extends React.Component<IProps> {
		render() {
			return <StoreContext.Consumer>
				{ ctx => <WrappedComponent store={ctx} {...{ ...this.props, ...this.state }} />}				
			</StoreContext.Consumer>	
		}
	})
};