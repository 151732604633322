import { computed } from 'mobx';
import moment from 'moment';
import { IPaddlePlan, PaddlePlan } from '..';

export enum BillingInterval {
    monthly = 'monthly',
    yearly = 'yearly',
}

export enum Perk {
    level1,
    level15,
    level50,
    level999,
    nowatermark,
    watermark,
    hd,
    template25,
    customFonts,
    saveInfinite,
    terminateMonthly,
}

interface IPaddleWebHookRequest {
    alert_id: any;
    alert_name: string;
    status: string;
    created_at: string;
    updated_at: string;
    attempts: number;
    fields: any;
}

interface IPaddleAlert {
    userId: string;

    // alert data
    id: any;
    alert_name: string;
    status: string;
    created_at: string;
    updated_at: string;
    attempts: number;
    fields: Object;
}

export class PaddleAlertModel {
    constructor(opts: IPaddleAlert) {
        this.userId = opts.userId;
        this.id = opts.id;
        this.alert_name = opts.alert_name;
        this.status = opts.status;
        this.created_at = opts.created_at;
        this.updated_at = opts.updated_at;
        this.attempts = opts.attempts;
        this.fields = opts.fields;
    }

    // firebase userId
    userId: string;

    // alert data
    id: any;
    alert_name: string;
    status: string;
    created_at: string;
    updated_at: string;
    attempts: number;
    fields: any;
}

export enum PaddleSubscptionState {
    active = 'active',
    past_due = 'past_due',
    trialing = 'trialing',
    paused = 'paused',
    deleted = 'deleted',
}

export interface IPaddleSubscriptionPayment {
    amount: number;
    currency: string;
    date: string;
}

export interface IPaddlePaymentMethod {
    payment_method: string;
    card_type?: string;
    last_four_digits?: string;
    expiry_date?: string;
}

export interface IPaddleSubscription {
    subscription_id: number;
    plan_id: number;
    plan: IPaddlePlan;
    user_id: number;
    user_email: string;
    marketing_consent: boolean;
    update_url: string;
    cancel_url: string;
    state: PaddleSubscptionState;
    signup_date: string;
    last_payment: IPaddleSubscriptionPayment;
    payment_information: IPaddlePaymentMethod;
    quantity: number;
    next_payment: IPaddleSubscriptionPayment;
}

export class PaddleAmount {
    constructor(opts: any) {
        const keys = Object.keys(opts);
        this.currency = keys[0];
        this.amount = opts[this.currency];
    }

    currency: string;
    amount: number;
}

export class PaddleSubscription {
    constructor(opts: IPaddleSubscription, _plans: IPaddlePlan[]) {
        this.subscription_id = opts.subscription_id;
        this.plan_id = opts.plan_id;
        this.user_id = opts.user_id;
        this.user_email = opts.user_email;
        this.marketing_consent = opts.marketing_consent;
        this.update_url = opts.update_url;
        this.cancel_url = opts.cancel_url;
        this.state = opts.state;
        this.signup_date = opts.signup_date;
        this.last_payment = opts.last_payment;
        this.payment_information = opts.payment_information;
        this.quantity = opts.quantity;
        this.next_payment = opts.next_payment;
        const p = _plans.find((p) => p.id === this.plan_id);
        this.plan = new PaddlePlan(p);
    }
    subscription_id: number;
    plan_id: number;
    plan: PaddlePlan;
    user_id: number;
    user_email: string;
    marketing_consent: boolean;
    update_url: string;
    cancel_url: string;
    state: PaddleSubscptionState;
    signup_date: string;
    last_payment: IPaddleSubscriptionPayment;
    payment_information: IPaddlePaymentMethod;
    quantity: number;
    next_payment: IPaddleSubscriptionPayment;

    @computed
    get subscriptionEndDate() {
        if (this.next_payment) {
            return moment(this.next_payment.date).toDate();
        }
        if (this.last_payment) {
            if (this.plan.interval === BillingInterval.monthly) {
                return moment(this.last_payment.date).add(1, 'M').toDate();
            }

            if (this.plan.interval === BillingInterval.yearly) {
                return moment(this.last_payment.date).add(1, 'y').toDate();
            }
        }
        return undefined;
    }

    @computed
    get subscriptionIsEnding() {
        return this.state == PaddleSubscptionState.deleted;
    }

    @computed
    get subscriptionIsActive() {
        const endDate = this.subscriptionEndDate;
        if (endDate) {
            const today = moment();
            if (moment(endDate).isAfter(today)) {
                return true;
            }
        }
        if (this.state === PaddleSubscptionState.deleted) {
            return false;
        }
        if (this.state === PaddleSubscptionState.paused) {
            return false;
        }
        if (this.state === PaddleSubscptionState.past_due) {
            return false;
        }
        if (this.state === PaddleSubscptionState.active) {
            return true;
        }
        return false;
    }

    serialize() {
        return {
            subscription_id: this.subscription_id,
            plan_id: this.plan_id,
            user_id: this.user_id,
            user_email: this.user_email,
            marketing_consent: this.marketing_consent,
            update_url: this.update_url,
            cancel_url: this.cancel_url,
            state: this.state,
            signup_date: this.signup_date,
            last_payment: this.last_payment,
            payment_information: this.payment_information,
            quantity: this.quantity,
            next_payment: this.next_payment,
        };
    }
}

export class PaddlePayment {
    constructor(opts: any) {
        this.id = opts.id;
        this.subscription_id = opts.subscription_id;
        this.amount = opts.amount;
        this.currency = opts.currency;
        this.payout_date = opts.payout_date;
        this.is_paid = opts.is_paid;
        this.receipt_url = opts.receipt_url;
        this.is_one_off_charge = opts.receipt_url;
    }
    id: number;
    subscription_id: number;
    amount: number;
    currency: string;
    private payout_date: string;
    @computed
    get payoutDate() {
        return moment(this.payout_date).toDate();
    }

    is_paid: number;
    receipt_url: string;
    is_one_off_charge: number;
}
