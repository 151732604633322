import { CropModal } from 'app/components/common/CropModal';
import Icon from 'app/components/common/Icon';
import { ImageViewer } from 'app/components/common/ImageViewer';
import { SelectUploadedFileControlButton } from 'app/components/common/SelectUploadedFileControl';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { ImageModel } from 'shared-puredio';
import FileUploader, { FileUploaderProps } from './FileUploader';
import t from 'app/common/translate';
import { UploadResponse } from 'app/api/bucket.api';

type ImageUploadWidgetProps = Pick<FileUploaderProps, 'storageFolder'> & {
	value?: ImageModel;
	onChange: (image: ImageModel) => void;
};

const ImageUploadWidget: React.FC<ImageUploadWidgetProps> = observer(({ storageFolder, value, onChange }) => {
	const { uiStore, authStore, fileStore } = useStore();
	const userId = authStore.current!.id!;
	const lastHandledModel = useRef<ImageModel>();
	const [imageModel, setImageModel] = useState(() => new ImageModel({ userId }));
	const [cropRequired, setCropRequired] = useState<boolean>(false);

	useEffect(() => {
		let isCancelled = false;
		if (value && lastHandledModel.current !== value) {
			const model = ImageModel.fromStringOrObject(value);
			model.userId = userId;
			if (model.md5Hash) {
				fileStore.findByMd5Hash(model.md5Hash).then((f) => {
					if (isCancelled) {
						return;
					}
					if (f) {
						model.originalFileName = f.originalFileName;
						model.md5Hash = f.md5Hash;
						model.metadata = f.metadata;
						model.userId = f.userId;
						setImageModel(model);
					}
				});
			} else {
				setImageModel(model);
			}
			lastHandledModel.current = value;
		}
		return () => {
			isCancelled = true;
		};
	}, [value, fileStore, userId]);

	const handleSelectPreviousUpload = (md5: string) => {
		fileStore.findByMd5Hash(md5).then((f) => {
			if (f) {
				imageModel.md5Hash = f.md5Hash;
				imageModel.originalFileName = f.originalFileName;
				imageModel.metadata = f.metadata;
				imageModel.userId = f.userId;
				imageModel.cropKey = undefined;
				imageModel.cropData = undefined;
				setImageModel(imageModel);
				onChange(imageModel);
			}
		});
	};

	const handleCropStart = () => {
		uiStore.showModal(uiStore.modalIds.cropImage);
	};

	const handleCropCancel = () => {
		uiStore.hideModal(uiStore.modalIds.cropImage);
	};

	const handleCropDone = async (model: ImageModel) => {
		imageModel.cropKey = model.cropKey;
		imageModel.cropData = model.cropData;
		handleCropCancel();
		onChange(imageModel);
	};

	const handleClearImage = () => {
		imageModel.md5Hash = undefined;
		imageModel.cropKey = undefined;
		imageModel.cropData = undefined;

		onChange(imageModel);
	};

	const handleUpload = (res: UploadResponse) => {
		fileStore.findByMd5Hash(res.md5Hash!).then((res) => {
			if (res) {
				imageModel.md5Hash = res.md5Hash;
				imageModel.userId = res.userId;
				imageModel.originalFileName = res.originalFileName;

				onChange(imageModel);
			}
		});
	};

	const checkIfCropRequired = (ref: HTMLImageElement | null) => {
		setCropRequired(!!(ref?.width !== ref?.height));
	};

	return (
		<div className={`uploader is-image ${imageModel.hasActiveFile ? 'has-active-file' : ''}`}>
			{imageModel.hasActiveFile ? (
				<div className="active-file">
					<div className="active-image-container">
						<ImageViewer ref={checkIfCropRequired} bucketKey={imageModel.key} />
						<div className="image-controls">
							<button type="button" className={`button is-icon is-overlay is-small ${cropRequired ? 'has-notification' : ''}`} onClick={handleCropStart}>
								<Icon iconName="crop" />
							</button>

							<button type="button" className={`button is-icon is-overlay is-small`} onClick={handleClearImage}>
								<Icon iconName="trash-alt" />
							</button>
						</div>
					</div>

					{imageModel.key && <CropModal image={imageModel} onCropCancel={handleCropCancel} onCropOk={handleCropDone} />}
				</div>
			) : (
				<>
					<FileUploader
						maxSize={20000000}
						maxFiles={1}
						multiple={false}
						accept={'image/jpeg, image/png'}
						filetype="image"
						dropZoneActiveText={t('components:image.upload.dropZoneActiveText')}
						dropZoneText={t('components:image.upload.dropZoneText')}
						uploadingText={t('components:image.upload.uploadingText')}
						onUploadSuccess={handleUpload}
						storageFolder={storageFolder}
					/>
					<div className="pad-top-1rem align-center">
						<SelectUploadedFileControlButton fileType={'image'} onSelect={handleSelectPreviousUpload} />
					</div>
				</>
			)}
		</div>
	);
});

export default ImageUploadWidget;
