import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
interface IDateFormateProps {
	date?: Date;
}
export const DateFormat: React.FC<IDateFormateProps> = observer((props: IDateFormateProps) => {
	if (!props.date) {
		return <></>;
	}
	const m = moment(props.date);
	return <>{m.format('DD MMMM YYYY')}</>;
});
