import { observable } from 'mobx';
import { ImageModel } from './image.model';
import { ProjectModel } from './project.model';

export interface IGroupModel {
    id?: string;
    title: string;
    titleImage?: ImageModel;
    userId: string;
    created?: Date;
    modified?: Date;
}

export class GroupModel {
    constructor(parms: IGroupModel) {
        this.id = parms.id;
        this.title = parms.title;
        // this.titleImageKey = parms.titleImageKey;
        this.titleImage = new ImageModel();
        if (parms.titleImage) {
            Object.assign(this.titleImage, parms.titleImage);
        }

        this.userId = parms.userId;
        this.created = parms.created;
        this.modified = parms.modified;
        this.fixDate('created');
        this.fixDate('modified');
    }

    @observable id: string;
    @observable userId: string;
    @observable title: string;
    @observable titleImage: ImageModel;

    // previewKey(projects: ProjectModel[]) {
    //     if (this.titleImage.key) {
    //         return this.titleImage.key;
    //     }
    //     const proj = projects.length > 0 ? projects[0] : undefined;
    //     if (proj && proj.previewKey) {
    //         return proj.previewKey;
    //     }
    //     // key to default image will go here
    //     return 'https://dummyimage.com/300x300/F7F8FB/66789C&text=' + this.title;
    // }

    @observable created?: Date;
    @observable modified?: Date;
    fixDate(prop: string) {
        if (this[prop] && this[prop].toDate) {
            this[prop] = this[prop].toDate();
        }
    }

    serialize() {
        const p: any = {
            id: this.id,
            title: this.title,
            titleImage: this.titleImage.serialize(),
            userId: this.userId,
            created: this.created,
            modified: this.modified,
        };
        return p;
    }
}
