import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { DateFormat } from '../common/DateFormat';
import Icon from 'app/components/common/Icon';
import { GroupModel } from 'shared-puredio';
import { Button } from '../common/Button';
import { GroupNewModal } from './GroupNewModal';
import { GroupEditModal } from './GroupEditModal';
import { GroupDeleteModal } from './GroupDeleteModal';
import t from '../../common/translate';
import { SvgIcon, SvgIconType } from '../common/SvgIcon';
import { GroupTitleImage } from '../Renders/RenderList';
import { ProjectStore } from 'app/stores/project.store';

interface IGroupItemProps {
	group: GroupModel;
}

const GroupItem: React.FC<IGroupItemProps> = observer((props: IGroupItemProps) => {
	const group = props.group;
	const { uiStore } = useStore();
	const history = useHistory();

	const editModalId = uiStore.modalIds.editGroup(group.id);
	const onEdit = () => {
		uiStore.showModal(editModalId);
		setSettingsActive(false);
	};
	const deleteModalId = uiStore.modalIds.deleteGroup(group.id);
	const onDelete = () => {
		uiStore.showModal(deleteModalId);
		setSettingsActive(false);
	};

	const setCurrent = () => {
		history.push(routes.LISTPROJECTS.getPath(props.group.id));
	};

	const [settingsActive, setSettingsActive] = useState(false);
	const toggleSettings = () => {
		setSettingsActive(!settingsActive);
	};

	return (
		<>
			<div className={`group-item`}>
				<div className="image" onClick={setCurrent}>
					<GroupTitleImage group={group} />
				</div>

				<div className="group-title">
					<h3>{group.title}</h3>

					<div className={`settings  ${settingsActive ? 'is-active' : ''}`}>
						<div className={`toggle`} onClick={toggleSettings}>
							<SvgIcon iconName={SvgIconType.settingsToggle} />
						</div>
						{settingsActive && (
							<>
								<div className="settings-background" onClick={toggleSettings}></div>
								<div className="buttons">
									<Button className="button is-white align-left is-small" onClick={onEdit}>
										<Icon iconName="cog" faModifier="far" />
										<span>{t('common:settings')}</span>
									</Button>
									<Button className="button is-white align-left is-small" onClick={onDelete}>
										<Icon iconName="trash-alt" faModifier="far" />
										<span>{t('common:delete')}</span>
									</Button>
								</div>
							</>
						)}
					</div>
				</div>

				<Button className="button is-block" onClick={setCurrent}>
					{t('general:groups.gotoprojects')}
				</Button>

				<div className="debug">
					<small>
						{t('common:created')}: <DateFormat date={group.created} />
					</small>
				</div>
			</div>

			<GroupEditModal group={group} modalId={editModalId} onDone={() => {}} />
			<GroupDeleteModal group={group} modalId={deleteModalId} onDone={() => {}} />
		</>
	);
});

export const GroupList: React.FC = observer(() => {
	const { groupStore, uiStore } = useStore();
	const history = useHistory();
	let groups = groupStore.list;
	groups = groups.slice().sort((a, b) => a.created!.getTime() - b.created!.getTime());

	const modalId = uiStore.modalIds.groupNew;
	const showNewGroupModal = () => {
		uiStore.showModal(modalId);
	};
	const onNewGroupDone = (g: GroupModel) => {
		uiStore.hideModal(modalId);
		history.push(routes.NEWPROJECT.getPath(g.id));
	};

	return (
		<>
			{!groupStore.isBusy && (
				<>
					{groups.length === 0 ? (
						<>
							<div className="section">
								<div className="section-title is-size-4">
									<span>{t('general:groups.empty.title')}</span>{' '}
									<span role="img" aria-label="wave">
										{t('general:groups.empty.emoji')}
									</span>
								</div>
								<div className="content is-size-5">
									<p>{t('general:groups.empty.text')}</p>
								</div>

								<Button className="button is-primary is-medium" onClick={showNewGroupModal}>
									<Icon iconName="plus" faModifier="fal" />
									<span>{t('general:groups.add')}</span>
								</Button>
							</div>
						</>
					) : (
						<div className="section">
							<div className="group-list-items">
								{groups.map((group) => (
									<GroupItem group={group} key={group.id} />
								))}
							</div>
						</div>
					)}
					<GroupNewModal onDone={onNewGroupDone} modalId={modalId} />
				</>
			)}
		</>
	);
});
