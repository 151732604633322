import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import { DateFormat } from '../common/DateFormat';
import { ExportTaskModel, GroupModel, ProjectModel } from 'shared-puredio';
import { Button } from 'app/components/common/Button';
import t from 'app/common/translate';
import { RenderStatus } from '../common/RenderStatus';
import _ from 'lodash';
import { ProjectDeleteModal } from './ProjectDeleteModal';
import Icon from '../common/Icon';
import { ProjectDuplicateModal } from './ProjectDuplicateModal';
import { ProjectPreviewImage } from './ProjectPreviewImage';

interface IProjectItemProps {
	project: ProjectModel;
}

export const ProjectCurrentRenderStatus = observer((props: IProjectItemProps) => {
	const { rendersStore } = useStore();
	const [mainTask, setMainTask] = useState<ExportTaskModel>();
	useEffect(() => {
		const p = rendersStore.getLatestMainTaskForProject(props.project.id);
		setMainTask(p);
	}, [rendersStore, props.project.id]);

	return <RenderStatus mainTask={mainTask} project={props.project} />;
});

export const ProjectItem: React.FC<IProjectItemProps> = observer((props: IProjectItemProps) => {
	const { projectStore, uiStore } = useStore();
	const project = props.project;
	const history = useHistory();

	const setCurrent = () => {
		history.push(routes.EDITPROJECT.getPath(props.project.id));
	};

	const isCurrent = projectStore.current?.id === project.id;
	const deleteProjectModalId = uiStore.modalIds.projectDelete(project.id);
	const duplicateProjectModalId = uiStore.modalIds.projectDuplicate(project.id);

	const onDuplicate = async () => {
		uiStore.showModal(duplicateProjectModalId);
	};

	const onDelete = async () => {
		uiStore.showModal(deleteProjectModalId);
	};
	const handleProjectDelete = () => {};

	return (
		<>
			<tr className={`project-item ${isCurrent ? 'is-active' : ''}`}>
				<td className="image" onClick={setCurrent}>
					<ProjectPreviewImage project={props.project} />
				</td>
				<td className="details" onClick={setCurrent}>
					<h3 className="title is-5">{project.title}</h3>
					<small>
						{t('common:created')} <DateFormat date={project.created} />
					</small>
				</td>
				<td className="status">
					<ProjectCurrentRenderStatus {...props} />
				</td>
				<td className="actions">
					<Button className="button is-icon" onClick={setCurrent} data-tooltip={t('common:edit')} data-tooltip-position={'left'}>
						<Icon iconName="pencil" faModifier="far" />
					</Button>
					<Button className="button is-icon" onClick={onDuplicate} data-tooltip={t('general:project.duplicate')} data-tooltip-position={'left'}>
						<Icon iconName={'clone'} faModifier="far" />
					</Button>

					<Button className="button is-icon" onClick={onDelete} data-tooltip={t('general:project.delete')} data-tooltip-position={'left'}>
						<Icon iconName={'trash-alt'} faModifier="far" />
					</Button>
					<ProjectDeleteModal project={project} modalId={deleteProjectModalId} onDone={handleProjectDelete} />
					<ProjectDuplicateModal project={project} modalId={duplicateProjectModalId} onDone={handleProjectDelete} />
				</td>
			</tr>
		</>
	);
});

interface IProjectListProps {
	group?: GroupModel;
}

const ProjectList: React.FC<IProjectListProps> = observer((props: IProjectListProps) => {
	const history = useHistory();
	const store = useStore();
	if (!props.group) {
		console.warn('no group');
		return null;
	}
	const group = props.group;
	let data = store.projectStore.projectApi.list.flat();
	data = data.filter((p) => p.groupId === group.id);
	data = _.sortBy(data, 'createdTime').reverse();

	const showAddProjectModal = () => {
		history.push(routes.NEWPROJECT.getPath(group.id));
	};

	return (
		<>
			{data.length === 0 ? (
				<div className="section">
					<div className="section-title is-size-4">{t('general:project.empty.title')}</div>
					<div className="content is-size-5">
						<p>{t('general:project.empty.text')}</p>
					</div>

					<Button className="button is-primary is-medium" onClick={showAddProjectModal}>
						<Icon iconName="plus" faModifier="fal" />
						<span>{t('general:projects.add')}</span>
					</Button>
				</div>
			) : (
				<>
					<div className="section project-items-list">
						<div className="section-title is-size-4">{t('general:projects.title')}</div>
					</div>

					<div className="section project-list">
						<table className="table project-list-table">
							<thead>
								<tr>
									<th className="image"></th>
									<th className="details">{t('general:project.title')}</th>
									<th className="status">{t('general:project.status')}</th>
									<th className="actions"></th>
								</tr>
							</thead>
							<tbody>
								{data.map((project) => (
									<ProjectItem project={project} key={project.id} />
								))}
							</tbody>
						</table>
					</div>
				</>
			)}
		</>
	);
});
export default ProjectList;
