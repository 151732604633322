import { action, computed, runInAction } from 'mobx';
import { IEntity } from 'shared-puredio';
import { AuthStore } from '../auth.store';
import { BaseApi } from '../../api/base.api';
import CRUDStore from './crud.store';

export default class CRUDSrvStore<TEntity extends IEntity> extends CRUDStore<TEntity> {
	service: BaseApi<TEntity>;
	authStore: AuthStore;

	constructor(authStore: AuthStore, service: BaseApi<TEntity>) {
		super();
		this.authStore = authStore;
		this.service = service;
	}

	@computed
	get list(): TEntity[] {
		return this.service.list;
	}

	@action
	async create(m: TEntity): Promise<TEntity> {
		return await this.service.create(m);
	}

	@action
	async save(m: Partial<TEntity>): Promise<TEntity> {
		return await this.service.save(m.id!, m);
	}

	@action
	async getById(id: string): Promise<TEntity | undefined> {
		try {
			this.setEntityLoading(true);
			const thing = await this.service.findById(id);
			runInAction(() => {
				this.setEntityLoading(false);
			});
			return thing;
		} catch (ex: any) {
			runInAction(() => {
				this.setEntityErrors(ex.code);
				this.setEntityLoading(false);
			});
		}
	}

	@action
	async deleteById(id: string) {
		await this.service.__delete(id);
	}
}
