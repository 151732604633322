import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStore } from 'app/context';
import { IModalButton, Modal } from './Modal';
import { Button } from './Button';
import t from '../../common/translate';
import UploadedFilesTable from './UploadedFilesTable';
import Icon from './Icon';

interface ISelectUploadedFileControlButtonProps {
	fileType: 'audio' | 'image';
	onSelect: (key: string) => void;
}

export const SelectUploadedFileControlButton: React.FC<ISelectUploadedFileControlButtonProps> = observer((props: ISelectUploadedFileControlButtonProps) => {
	const store = useStore();
	const [currentMd5, setCurrentMd5] = useState<string | undefined>(undefined);
	const modalId = store.uiStore.modalIds.selectUploadedFile;

	const showModal = () => {
		store.uiStore.showModal(modalId);
	};
	const hideModal = () => {
		store.uiStore.hideModal(modalId);
	};
	const onSelect = (key: string) => {
		setCurrentMd5(key);
	};

	let buttons: IModalButton[] = [
		{
			title: t('common:close'),
			onClick: () => {
				hideModal();
			},
			className: 'button is-secondary',
		},
	];

	if (currentMd5) {
		buttons.push({
			title: t('common:apply'),
			onClick: () => {
				props.onSelect(currentMd5!);
				hideModal();
			},
			className: 'button is-primary',
		});
	}

	const headingText = props.fileType === 'audio' ? t('components:upload.select.audio') : t('components:upload.select.image');

	return (
		<>
			<div className="pad-bottom-1rem">
				<Button type="button" className="button is-text is-black not-underlined" onClick={showModal}>
					<Icon iconName="cloud-upload" faModifier="far" />
					<span>{t('components:upload.select.previous.' + props.fileType)}</span>
				</Button>
			</div>
			<Modal title={headingText} size="medium" modalId={modalId} buttons={buttons} onClose={hideModal}>
				<UploadedFilesTable {...props} currentKey={currentMd5} onSelect={onSelect} />
			</Modal>
		</>
	);
});
