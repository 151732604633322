import React from 'react';

// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA: string, versionB: string) => {
	const versionsA = versionA.split(/\./g);

	const versionsB = versionB.split(/\./g);
	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift());

		const b = Number(versionsB.shift());
		// eslint-disable-next-line no-continue
		if (a === b) continue;
		// eslint-disable-next-line no-restricted-globals
		return a > b || isNaN(b);
	}
	return false;
};

const currentVersion = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : 'dev';

interface IDefaultProps {
	children?: any;
}

interface IState {
	loading: boolean;
	isLatestVersion: boolean;
	refreshCacheAndReload: () => void;
}

export default class CacheBuster extends React.Component<IDefaultProps, IState> {
	constructor(props: IDefaultProps) {
		super(props);
		this.state = {
			loading: true,
			isLatestVersion: false,
			refreshCacheAndReload: () => {
				console.log('Clearing cache and hard reloading...');
				if (caches) {
					// Service worker cache should be cleared with caches.delete()
					caches.keys().then(function(names) {
						for (let name of names) caches.delete(name);
					});
				}
				// delete browser cache and hard reload
				window.location.reload();
			},
		};
	}

	componentDidMount() {
		if (currentVersion === 'dev') {
			this.setState({ loading: false, isLatestVersion: true });
			return;
		}

		fetch('/meta.json')
			.then(response => response.json())
			.then(meta => {
				const latestVersion = meta.version;
				const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
				if (shouldForceRefresh) {
					console.log(`We have a new version - ${latestVersion}. Should force refresh`);
					this.setState({ loading: false, isLatestVersion: false });
				} else {
					console.log(
						`You already have the latest version - ${latestVersion}. No cache refresh needed.`,
					);
					this.setState({ loading: false, isLatestVersion: true });
				}
			});
	}

	render() {
		const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
		if (loading) return null;
		if (!loading && !isLatestVersion) {
			// You can decide how and when you want to force reload
			refreshCacheAndReload();
		}

		return <code className="app-version">v{currentVersion}</code>;
	}
}
