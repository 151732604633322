import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'app/components/common/Button';
import Icon from 'app/components/common/Icon';

interface IProjectUndoProps {
	project: ProjectVM;
}

export const ProjectUndo: React.FC<IProjectUndoProps> = observer((props: IProjectUndoProps) => {
	const history = useHistory();
	const project = props.project;

	const onUndo = useCallback(() => {
		const snapshot = project.undo();
		if (snapshot) {
			history.push({
				search: `?panel=${snapshot.navState.panel}`,
			});
		}
	}, [history, project]);

	const historyLen = project.history.length;
	const undoDisabled = historyLen === 0;

	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			if (e.key !== 'z' || undoDisabled) {
				return;
			}
			if (e.metaKey === true || e.ctrlKey === true) {
				onUndo();
			}
		};

		document.addEventListener('keydown', (e) => handleKeyPress(e));
		return () => {
			document.removeEventListener('keydown', (e) => handleKeyPress(e));
		};
	}, [onUndo, undoDisabled]);

	// let lastSnap = project.lastSnap;
	return (
		<div className="project-undo">
			{!undoDisabled && (
				<Button className="button is-icon is-grey" title="Undo" onClick={onUndo} disabled={undoDisabled}>
					<Icon iconName="undo" />
				</Button>
			)}
			{/*
			<button className="btn btn--primary" onClick={onUndo} disabled={undoDisabled}>
				{t('common:undo')} {lastSnap?.key} {historyLen}
			</button>
			{project.history.map((hi) => (
                <div key={hi.time}>{hi.key}  {hi.time} {hi.navState.panel} {JSON.stringify(hi.data, null, 2)} </div>
            ))} */}
		</div>
	);
});
