import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import t from 'app/common/translate';
import { useStore } from 'app/context';
import { IModalButton, Modal } from 'app/components/common/Modal';
import { ImageModel } from 'shared-puredio';
import * as path from 'path';

interface ICropProps {
	image: ImageModel;
	onCropCancel: () => void;
	onCropOk: (image: ImageModel) => void;
}

const cropDef = {};

export const CropModal = (props: ICropProps) => {
	const store = useStore();
	const userId = store.authStore.current!.id!;
	const [src, setSrc] = useState<string | undefined>(undefined);
	const { animatorStore } = useStore();

	useEffect(() => {
		props.image.userId = userId;
		store.bucketStore.getDownloadUrl(props.image.originalKey).then((key) => {
			setSrc(key);
		});
	});

	const onOk = async () => {
		const crop = animatorStore.currentCropImageData!;
		if (crop.width === 0 && crop.height === 0) {
			return props.onCropCancel();
		}
		const imageRef = animatorStore.currentCropImageRef;
		const img = await saveCropToStorage(imageRef!);
		props.onCropOk(img);
	};

	const saveCropToStorage = async (imageRef: HTMLImageElement): Promise<ImageModel> => {
		const crop = animatorStore.currentCropImageData!;
		const canvas = document.createElement('canvas');
		const scaleX = imageRef.naturalWidth / imageRef.width;
		const scaleY = imageRef.naturalHeight / imageRef.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx!.drawImage(imageRef, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
		let base64Image = canvas.toDataURL();
		const uploadKey = path.join(store.authStore.modifiedFolder, 'random.png');
		const res = await store.bucketStore.uploadDataUrl(base64Image, uploadKey, true);
		const img = new ImageModel({ userId });
		img.md5Hash = props.image.md5Hash;
		img.cropKey = res.key;
		img.cropData = crop;
		return img;
	};

	const cropButtons: IModalButton[] = [
		{
			title: t('common:cancel'),
			onClick: props.onCropCancel,
			className: 'button',
		},
		{
			title: t('common:ok'),
			onClick: onOk,
			className: 'button is-primary',
		},
	];
	return (
		<Modal title={t('components:crop.image')} buttons={cropButtons} onClose={props.onCropCancel} modalId={store.uiStore.modalIds.cropImage}>
			{src && <Cropper src={src} {...props} />}
		</Modal>
	);
};

interface ICropper {
	src: string;
	image: ImageModel;
}

const Cropper = (props: ICropper) => {
	const { src, image } = props;
	const [crop, setCrop] = useState(image.cropData ? image.cropData : cropDef);
	const { animatorStore } = useStore();

	const onCropImageLoaded = (imageRef: HTMLImageElement) => {
		animatorStore.currentCropImageRef = imageRef;
		animatorStore.currentCropImageRef.crossOrigin = 'anonymous';
	};

	const onCropChange = (newCrop: any) => {
		animatorStore.currentCropImageData = newCrop;
		setCrop(newCrop);
	};

	return (
		<>
			<ReactCrop src={src} crop={crop} ruleOfThirds onChange={(newCrop: any) => onCropChange(newCrop)} onImageLoaded={onCropImageLoaded} />
		</>
	);
};
