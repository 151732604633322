import React from 'react';

interface IImageProps {
	src: string;
	alt?: string;
	className?: string;
}

export const Image: React.FC<IImageProps> = ({ src, alt, className }) => {
	return (
		<img
			className={`image ${className ? className : ''}`}
			src={process.env.PUBLIC_URL + '/images/' + src}
			alt={alt ? alt : ''}
		/>
	);
};
