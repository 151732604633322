import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { LayerModel } from 'shared-puredio';
import t from 'app/common/translate';
import { SvgIcon, SvgIconType } from 'app/components/common/SvgIcon';
import { Link } from 'react-router-dom';

interface IAnimLayerFormProps {
	title: string;
	projectVM: ProjectVM;
	animLayer: LayerModel;
}

export const LayerFormWatermark = observer((props: IAnimLayerFormProps) => {
	return (
		<>
			<div className="ui-control-group is-watermark">
				<div className="content">
					<p>{t('payment:watermark.upgrade.text')}</p>
				</div>
				<div className="pad-1rem">
					<div className="watermark-image">
						<SvgIcon iconName={SvgIconType.watermark} />
					</div>
				</div>
				<div className="pad-top-1rem">
					<Link to={'/account/payment'} className="button is-primary is-inverted is-block">
						{t('payment:watermark.upgrade.cta')}
					</Link>
				</div>
			</div>
		</>
	);
});
