import React, { useEffect } from 'react';
import { useStore } from 'app/context';
import { humanFileSize } from '../../utils';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Table } from '../Table/table';
import { TableModel, TableRow } from '../Table/table.model';
import { ImageModel } from 'shared-puredio';
import { runInAction } from 'mobx';
import { ImageViewer } from '../common/ImageViewer';

export const ImageFilesList = observer(() => {
	const { fileStore } = useStore();
	const history = useHistory();
	const tm = new TableModel<ImageModel, string>();

	const cols = [
		{
			label: 'Dateiname',
			path: 'originalFileName',
		},
		{
			label: 'Status',
			path: 'fileStatus',
		},
		{
			label: 'Audiolänge',
			path: 'audioData.duration',
			render: (row: ImageModel) => {
				if (!row.thumbKey) {
					return <> </>;
				}
				return <>{<ImageViewer bucketKey={row.thumbKey} />}</>;
			},
		},
		{
			label: 'Grösse',
			path: 'audioData.metadata.size',
			render: (row: ImageModel) => {
				if (!row.metadata) {
					return <> </>;
				}
				return <>{humanFileSize(row.metadata.size)}</>;
			},
		},
	];
	tm.setCols(cols);
	tm.sortBy = 'name';
	tm.idProperty = 'id';
	tm.idType = 'string';

	tm.onRowClick = (row: TableRow<ImageModel>) => {
		history.push(routes.IMAGFILES.getPath(row.data.id));
	};

	useEffect(() => {
		runInAction(() => {
			tm.selectedId = fileStore.selectId;
		});
	}, [fileStore.selectId, tm.selectedId]);

	useEffect(() => {
		tm.setData(fileStore.imageFiles);
	}, [tm, fileStore.imageFiles]);
	return (
		<>
			<div className="section">
				<Table className="render-list" tm={tm}></Table>
			</div>
		</>
	);
});
