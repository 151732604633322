import { IProps, useStore, withStore } from 'app/context';
import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import t from '../../common/translate';
import { observer } from 'mobx-react';
import FormField from '../Form/FormField';
import { AuthStore } from '../../stores/auth.store';
import { Button } from '../common/Button';
import { action, observable } from 'mobx';
import { Modal } from '../common/Modal';
import { useHistory } from 'react-router';
import routes from 'routes';
import { UpdatePasswordModal } from 'app/components/auth/UpdatePasswordModal';
import { notify } from 'app/common/notify';
import { UiStore } from 'app/stores/ui.store';
import { UpdateEmailModal } from './UpdateEmailModal';

const settingsFormSchema = Yup.object()
	.shape({
		firstName: Yup.string().required(t('validation:required.field')).default(''),
		lastName: Yup.string().required(t('validation:required.field')).default(''),
	})
	.required();

type Settings = Yup.InferType<typeof settingsFormSchema>;

@withStore
@observer
export default class AccountSettingsForm extends React.Component<Partial<IProps>> {
	constructor(props: IProps) {
		super(props);
		this.authStore = this.props.store!.authStore;
		this.uiStore = this.props.store!.uiStore;
	}
	authStore: AuthStore;
	uiStore: UiStore;

	@observable
	isSaving: boolean = false;

	@action
	async handleSubmit(values: Settings) {
		this.isSaving = true;
		const user = this.authStore.current!;
		if (user.firstName === values.firstName && user.lastName === values.lastName) {
			return;
		}

		user.firstName = values.firstName;
		user.lastName = values.lastName;
		user.email = values.email;

		await this.authStore.updateUser(user.id!, user).finally(() => {
			this.isSaving = false;
			notify(t('auth:update.success'), 'success');
		});
	}

	@action
	onDeleteAccount() {
		this.props.store!.uiStore.showModal('delete_account');
	}
	@action
	onUpdatePassword() {
		this.props.store!.uiStore.showModal('update_password');
	}

	@action
	onUpdateEmail() {
		this.props.store!.uiStore.showModal('updateEmail');
	}
	public render() {
		const user = this.authStore.current;
		if (!user) {
			// needed when deleting account
			return <></>;
		}
		return (
			<>
				<div className="section">
					<Formik validationSchema={settingsFormSchema} initialValues={{ firstName: user.firstName, lastName: user.lastName, email: user.email }} onSubmit={this.handleSubmit.bind(this)}>
						{({ errors, touched }) => (
							<Form className="form">
								<h2 className="title is-5">{t('auth:personalData')}</h2>
								<div className="field is-inline is-spaced">
									<FormField label={t('auth:firstName')} error={errors.firstName} touched={touched.firstName}>
										<Field type="text" name="firstName" />
									</FormField>
									<FormField label={t('auth:lastName')} error={errors.lastName} touched={touched.lastName}>
										<Field type="text" name="lastName" />
									</FormField>
								</div>

								<div className="align-right">
									<Button type="submit" className="button is-primary" disabled={this.isSaving}>
										{t('common:save')}
									</Button>
								</div>

								<h2 className="title is-5">{t('auth:email')}</h2>
								<div className="field is-spaced">
									{user.email}{' '}
									<Button className="button" disabled={this.isSaving} onClick={this.onUpdateEmail.bind(this)}>
										{t('auth:changeemail.submit')}
									</Button>
								</div>

								<h2 className="title is-5">{t('auth:password')}</h2>
								<div className="field is-spaced">
									<Button className="button" disabled={this.isSaving} onClick={this.onUpdatePassword.bind(this)}>
										{t('auth:resetpassword.submit')}
									</Button>
								</div>

								<h2 className="title is-5">{t('auth:delete.account')}</h2>
								<div className="field is-spaced">
									<p>{t('auth:delete.account.info')}</p>
									<Button type="button" className="button is-red is-inverted" disabled={this.isSaving} onClick={this.onDeleteAccount.bind(this)}>
										{t('auth:delete.account')}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>

				<AccountDeleteModal modalId={this.uiStore.modalIds.delete_account} />
				<UpdatePasswordModal modalId={this.uiStore.modalIds.update_password} />
				<UpdateEmailModal modalId={this.uiStore.modalIds.updateEmail} />
			</>
		);
	}
}
interface IAccountDeleteModalProps {
	modalId: string;
}

export const AccountDeleteModal: React.FC<IAccountDeleteModalProps> = observer((props: IAccountDeleteModalProps) => {
	const store = useStore();
	const history = useHistory();
	const onDone = () => {
		store.uiStore.hideModal(props.modalId);
	};
	const onDelete = async () => {
		await store.authStore.deleteAccount();
		store.authStore.resetEntity();
		history.push(routes.AUTH_LOGIN.getPath());
	};

	const user = store.authStore.current;
	if (!user) {
		// needed when deleting account
		return <></>;
	}

	return (
		<Modal title={t('auth:delete.account')} size="medium" modalId={props.modalId}>
			<p>{t('auth:delete.account.areyousure', { email: user.email })} </p>
			<br />
			<Button type="button" className="button is-primary" onClick={onDelete}>
				{t('auth:delete.account')}
			</Button>
			<Button type="button" className="button is-red" onClick={onDone}>
				{t('common:cancel')}
			</Button>
		</Modal>
	);
});
