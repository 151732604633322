import { observer } from 'mobx-react';
import React, { useState } from 'react';
import CheckboxControl from './CheckboxControl';

export interface ICheckboxListItem {
	label: string;
	value: string;
}

interface ICheckbobxItemProps {
	label: string;
	value: string;
	selected: boolean;
	onSelect: (value: string, selected: boolean) => void;
}

const CheckboxItem = observer((props: ICheckbobxItemProps) => {
	const [selected, setSelected] = useState(props.selected);
	const onChange = (name: string, value: any) => {
		setSelected(!!value);
		props.onSelect(props.value, !!value);
	};
	return <CheckboxControl label={props.label} name={props.value} value={selected} onChange={onChange} />;
});

interface ICheckboxListProps {
	values: string[];
	items: ICheckboxListItem[];
	onSelect: (value: string[]) => void;
}

export const CheckboxListControl = observer((props: ICheckboxListProps) => {
	const [values, setValues] = useState<string[]>(props.values);

	const onItemSelect = (value: string, selected: boolean) => {
		if (selected) {
			values.push(value);
			setValues(values);
			props.onSelect(values);
		} else {
			const filterd = values.filter((v) => v !== value);
			setValues(filterd);
			props.onSelect(filterd);
		}
	};

	return (
		<div>
			{props.items.map((b, i) => {
				const selected = values.includes(b.value);
				return <CheckboxItem key={i} {...b} selected={selected} onSelect={onItemSelect} />;
			})}
		</div>
	);
});
