import React, { useState } from 'react';
import { VideoFormats } from 'shared-puredio';
import * as Yup from 'yup';
import t from 'app/common/translate';
import { Formik, Form } from 'formik';
import { INewProjectParms } from 'app/containers/ProjectNewContainer';
import { useHistory, useParams } from 'react-router';
import FormField from 'app/components/Form/FormField';
import { VideoFormatSelectField } from 'app/components/fields/VideoFormatSelectField';
import { Button } from 'app/components/common/Button';
import { TemplateSelectField } from 'app/components/fields/TemplateSelectField';
import routes from 'routes';
import Icon from 'app/components/common/Icon';

const projecNewSchema = Yup.object()
	.shape({
		templateId: Yup.string().required(t('validation:required.template')),
		fmtId: Yup.string().required(t('validation:required.format')),
	})
	.required();
type NewProject = Yup.InferType<typeof projecNewSchema>;

export const ProjectNewSelectTemplate = () => {
	const history = useHistory();
	const parms = useParams<INewProjectParms>();
	const { groupId, templateId } = parms;
	const [fmtId, setFmtId] = useState<string>(parms.fmtId ? parms.fmtId : VideoFormats.square.id);

	const onFmtIdChange = (id: string) => {
		setFmtId(id);
	};

	async function handleSubmit(values: NewProject) {
		history.push(routes.NEWPROJECT.getPath(groupId, values.fmtId, values.templateId));
	}

	return (
		<Formik
			validationSchema={projecNewSchema}
			initialValues={{
				fmtId: fmtId,
				templateId: templateId ? templateId : '',
			}}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid }) => {
				return (
					<Form className="new-project">
						<div className="project-details">
							<FormField className="title" label={t('general:project.format')} error={errors.fmtId} touched={touched.fmtId}>
								<VideoFormatSelectField name="fmtId" onChange={onFmtIdChange} />
							</FormField>

							<FormField className="template" label={t('general:project.template')} error={errors.templateId} touched={true}>
								<TemplateSelectField name="templateId" onChange={() => {}} fmtId={fmtId} />
							</FormField>
						</div>

						<div className="button-container is-fixed">
							<Button type="submit" className="button is-primary" isFormInvalid={!isValid} disabled={!isValid}>
								<span>{t('general:project.selectAudio')}</span>
								<Icon iconName="arrow-right" faModifier="fal" />
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
