import { action, makeObservable, observable } from 'mobx';
class BaseStore {
	constructor() {
		makeObservable(this);
	}

	@observable
	public debug = false;

	@action
	public toggleDebug = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		this.debug = !this.debug;
	};
}
export default BaseStore;
