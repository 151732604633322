import React, { useState } from 'react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { ImageModel, LayerModel } from 'shared-puredio';
import { ColorPickerControl } from 'app/components/common/ColorPickerControl';
import t from 'app/common/translate';
import { GroupToggle } from './GroupToggle';
import { ImageUploadControl } from './ImageUploadControl';
import { useStore } from 'app/context';
import RangeControl from 'app/components/common/RangeControl';
import { runInAction } from 'mobx';
import CheckboxControl from 'app/components/common/CheckboxControl';

interface IAnimLayerFormProps {
	title: string;
	projectVM: ProjectVM;
	animLayer: LayerModel;
}

export const LayerFormImage = (props: IAnimLayerFormProps) => {
	const { animLayer, projectVM } = props;
	const { authStore } = useStore();
	const [hasBorder, setHasBorder] = useState(animLayer.opts.hasBorder);
	const [isRound, setisRound] = useState(animLayer.opts.isRound);

	const uploadFolder = authStore.uploadFolder!;

	const onColorChange = (name: string, value: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			if (name === 'borderColor') {
				animLayer.opts.borderColor = value;
			}
		});
		animLayer.commitSnapshot();
	};

	const onImageChange = (val: ImageModel) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			animLayer.opts.image = val;
		});
		animLayer.commitSnapshot();
	};

	const onRoundToggle = (name: string, value: boolean) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			animLayer.opts.isRound = value;
			setisRound(value);
		});
		animLayer.commitSnapshot();
	};

	const onBorderToggle = (name: string, value: boolean) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			animLayer.opts.hasBorder = value;
			setHasBorder(value);
		});
		animLayer.commitSnapshot();
	};

	const onBorderWidthChange = (name: string, val: any) => {
		animLayer.beginSnapshot(projectVM.navState);
		runInAction(() => {
			animLayer.opts.borderWidth = val;
		});
		animLayer.commitSnapshot();
	};

	return (
		<>
			<div className="ui-control-group">
				<ImageUploadControl label={t('components:uie.image')} value={animLayer.opts.image} onChange={onImageChange} uploadFolder={uploadFolder} />
			</div>
			<div className="ui-control-group">
				<CheckboxControl label={t('components:uie.isRound')} name={t('components:uie.isRound')} value={isRound} onChange={onRoundToggle} />
			</div>
			<div className="ui-control-group">
				<GroupToggle name={t('components:uie.border')} value={hasBorder} onChange={onBorderToggle} />
				{hasBorder && (
					<>
						<RangeControl name={'borderWidth'} label={t('components:uie.borderWidth')} value={animLayer.opts.borderWidth} min={0} max={10} step={0.05} onChange={onBorderWidthChange} />
						<ColorPickerControl name={'borderColor'} label={t('components:uie.borderColor')} value={animLayer.opts.borderColor} onChange={onColorChange} />
					</>
				)}
			</div>
		</>
	);
};
