import { AnimationOptions, IAssetResolver, LayerModel } from 'shared-puredio';
import { action, computed, makeObservable, observable, reaction, runInAction, when } from 'mobx';
import { PaperVM } from './viewmodels/paper.vm';
import * as Meyda from 'meyda';
import { ProjectVM } from './viewmodels/project.vm';
import React from 'react';
import { AudioStore } from './audio.store';
import { Point } from 'paper';
import { Font } from '../../../../shared-puredio/src/models/fonts';
import { ProjectApi } from 'app/api/project.api';
import { BucketStore } from './bucket.store';
import { AuthStore } from './auth.store';
import { PeaksVM } from './viewmodels/peaks.vm';

export class AnimatorStore implements IAssetResolver {
	constructor(authStore: AuthStore, bucketApi: BucketStore, projectApi: ProjectApi) {
		makeObservable(this);
		this.authStore = authStore;
		this.bucketStore = bucketApi;
		this.projectApi = projectApi;
		this.audioStore = new AudioStore();
		this.audioContextSupported = this.audioStore.audioContextCheck();
	}

	authStore: AuthStore;
	projectApi: ProjectApi;
	bucketStore: BucketStore;
	@observable
	audioStore: AudioStore;

	@computed
	get isBusy(): boolean {
		return this.isSettingUp || this.audioStore.isLoading || this.projectApi.isBusy;
	}

	@computed
	get selectedLayer() {
		if (this.paperVm) {
			return this.paperVm.selectedLayer;
		}
		return undefined;
	}

	@computed
	get selectedLayerKey() {
		if (this.selectedLayer) {
			return this.selectedLayer.key;
		}
		return '';
	}

	@observable
	isSettingUp: boolean = false;

	@observable
	audioContextSupported: boolean = true;

	@observable
	isRunning: boolean = false;

	@observable
	paperVm?: PaperVM;

	@observable
	project?: ProjectVM;

	audioRef: React.RefObject<HTMLAudioElement> = React.createRef<HTMLAudioElement>();

	disposers: any[] = [];

	@observable
	waveDataUri?: string;

	async getWaveData(uri: string) {
		return await this.projectApi.getWaveFormData(uri);
	}

	@observable
	anim?: AnimationOptions;

	// state for image croppper to avoid reload bug
	@observable
	currentCropImageRef?: HTMLImageElement;

	// state for image croppper to avoid reload bug
	@observable
	currentCropImageData?: any;

	@action
	async setup(audioRef: React.RefObject<HTMLAudioElement>, canvasRef: React.RefObject<HTMLCanvasElement>, anim: AnimationOptions, project: ProjectVM) {
		const waveDataUri = await this.bucketStore.getDownloadUrl(anim.audioLayer.audioFile!.wavedataKey);
		runInAction(() => {
			this.waveDataUri = waveDataUri;
			this.anim = anim;
			this.isSettingUp = true;
			this.project = project;
		});
		const audioUrl = await this.bucketStore.getDownloadUrl(anim.audioLayer.audioFile!.originalKey);
		await this.audioStore.setup(audioRef.current!, audioUrl, project);

		runInAction(() => {
			this.paperVm = new PaperVM({
				audioRef,
				canvasRef,
				anim,
				assetResolver: this,
				project,
				user: this.authStore.current ? this.authStore.current : undefined,
				onLayerSelect: (key: string) => {
					runInAction(() => {
						project.currentSidePanel = key;
					});
				},

				onAnimate: (t: number) => {
					this.animateAndPlay(t);
				},
				onStop: async () => {
					return await this.stop();
				},
			});
		});

		await when(() => this.paperVm!.isReady);
		await this.stop();
		if (this.project!.currentSidePanel) {
			this.paperVm!.selectLayer(this.project!.currentSidePanel);
		}
		runInAction(() => {
			this.isSettingUp = false;
			console.log('paper and peaks are ready');
			window.dispatchEvent(new Event('resize')); // needed to get the scale correct

			// updated audio file
			let disp = reaction(
				() => project.model.audioLayer.audioFileMd5Hash,
				async () => {
					const audioUrl = await this.bucketStore.getDownloadUrl(project.model.audioLayer.audioFile!.originalKey);
					await this.audioStore.setup(audioRef.current!, audioUrl, project);
					// this.peaksVm?.setupPeaks();
				},
				{
					fireImmediately: false,
					delay: 50,
				},
			);
			this.disposers.push(disp);

			// changed the start time
			disp = reaction(
				() => project.model.audioLayer.start,
				() => {
					this.seek(project.model.audioLayer.start);
				},
				{},
			);
			this.disposers.push(disp);

			disp = reaction(
				() => project.scaleRatio,
				() => {
					this.updateScale();
				},
				{
					fireImmediately: true,
					delay: 50,
				},
			);
			this.disposers.push(disp);

			disp = reaction(
				() => this.audioStore.currentTime,
				() => {
					if (this.audioStore.currentTime > this.project!.model.audioLayer.end) {
						this.stop();
					}
				},
				{
					fireImmediately: false,
				},
			);
			this.disposers.push(disp);
		});
	}

	getPeaksVM(project: ProjectVM, peaksZoomRef: HTMLDivElement) {
		if (!this.audioStore.audioEl || !peaksZoomRef) {
			return;
		}
		return new PeaksVM({
			audioEl: this.audioStore.audioEl!,
			peaksZoomRef: peaksZoomRef,
			anim: this.anim!,
			bucketStore: this.bucketStore,
			project: project,
			audioStore: this.audioStore,
			onEnd: () => {
				debugger;
			},
		});
	}

	@action
	updateScale() {
		const scale = this.project?.scaleRatio;
		const view = this.paperVm?.paperScope?.view;
		const bg = this.paperVm?.layers.find((l) => {
			return l.key.indexOf('background') >= 0;
		});

		if (scale && view && bg) {
			view.translate(new Point(view.bounds.center.x - bg!.pl.bounds.center.x, view.bounds.center.y - bg!.pl.bounds.center.y));
			view.scaling = new Point(scale, scale);
		} else {
			console.warn('updateScale called without scale && view && bg');
		}
	}

	@action
	async dispose() {
		await this.stop();
		runInAction(() => {
			for (const disp of this.disposers) {
				disp();
			}
			this.disposers = [];
			if (this.paperVm) {
				this.paperVm.dispose();
			}
			this.paperVm = undefined;
		});
	}

	@action
	async ensureAudioContext() {
		await this.audioStore.setupAudioContext();
	}

	@action
	async playViaUserInteraction() {
		// await this.ensureAudioContext();
		this.animateAndPlay();
	}

	@action
	async animateAndPlay(overrideTime?: number) {
		if (!this.paperVm) {
			return;
		}
		let currTime = this.project!.model.audioLayer.start;
		if (this.audioStore.audioEl!.paused) {
			currTime = this.audioStore.currentTime;
		}
		this.isRunning = true;
		this.audioStore.audioEl!.play();
		this.paperVm!.animate(currTime);
	}

	@action
	async pause() {
		this.isRunning = false;
		if (!this.paperVm) {
			return;
		}
		this.paperVm.pause();
		this.audioStore.audioEl!.pause();
	}

	@action
	async stop() {
		if (!this.paperVm) {
			return;
		}
		this.paperVm!.stop();
		this.audioStore.audioEl!.pause();
		this.audioStore.audioEl!.currentTime = this.project!.model.audioLayer.start;
		this.isRunning = false;
		return this.audioStore.audioEl!.currentTime;
	}

	@action
	seek(t: number) {
		this.audioStore.audioEl!.currentTime = t;
		this.paperVm!.seek(t);
	}

	/////////////////////////
	/// IAssetResolver implemenation below
	async getDownloadUrl(key: string): Promise<string> {
		if (!key) {
			console.warn('empty key');
			return '';
		}
		if (key.startsWith('http')) {
			return key;
		}
		let url = await this.bucketStore.getDownloadUrl(key);
		if (!url) {
			url = await this.bucketStore.getDownloadUrl('public/image-not-found.png');
		}
		return url;
	}

	async loadAudioData(audioLayer: LayerModel) {
		// not needed on client
	}

	onLoad() {
		this.updateScale();
	}

	getAudioDataForFrame(frame: number, feature: Meyda.MeydaAudioFeature): Float32Array | number[] {
		if (!this.paperVm || !this.audioStore.analyser) {
			return [];
		}
		const x = this.audioStore.analyser.get(feature);
		if (!x) {
			return [];
		}
		// @ts-ignore
		return x;
	}

	log(msg: string) {
		console.log('LOGG', msg);
	}

	downloadFont(font: Font) {
		console.log('not needed on client');
	}
}
