import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { PaymentPlan, PlanType } from 'shared-puredio';
import t from 'app/common/translate';

interface IPlanName {
	planType: PlanType;
}

export const PlanName = observer((props: IPlanName) => {
	const { paddleStore } = useStore();
	const [plan, setPlan] = useState<PaymentPlan>();

	useEffect(() => {
		const p = paddleStore.plans.find((pp) => pp.planType === props.planType);
		setPlan(p);
	}, [paddleStore.plans, props.planType]);

	const user = paddleStore.authStore.current;
	if (!user || !plan) {
		return <></>;
	}

	return <span>{t(plan.title)}</span>;
});
