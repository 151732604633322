import React from 'react';
import { GroupModel, ImageModel } from 'shared-puredio';
import * as Yup from 'yup';
import t from 'app/common/translate';
import { Formik, Field, Form } from 'formik';
import FormField from '../Form/FormField';
import { useStore } from 'app/context';
import { Button } from '../common/Button';
import { ImageUploadField } from '../fields/ImageUploadField';
import { runInAction } from 'mobx';

const groupNewSchema = Yup.object()
	.shape({
		title: Yup.string().required(t('validation:required.field')).default(''),
		md5Hash: Yup.string().default(''),
		originalFileName: Yup.string().default(''),
		cropKey: Yup.string().default(''),
		cropData: Yup.object().default({}),
	})
	.required();
type NewGroup = Yup.InferType<typeof groupNewSchema>;

interface IGroupNewProps {
	onDone: (g: GroupModel) => void;
}

export const GroupNewForm: React.FC<IGroupNewProps> = (props: IGroupNewProps) => {
	const title = t('general:group.new');
	const { authStore, groupStore } = useStore();
	const userId = authStore.current!.id!;
	const titleImage = new ImageModel({ userId });
	titleImage.userId = authStore.current!.id!;

	const handleSubmit = async (values: NewGroup) => {
		runInAction(() => {
			groupStore._isBusy = true;
		});
		try {
			titleImage.md5Hash = values.md5Hash!;
			titleImage.originalFileName = values.originalFileName!;
			titleImage.cropKey = values.cropKey;
			titleImage.cropData = values.cropData;
			let m = new GroupModel({
				title: values.title,
				titleImage,
				userId: authStore.current!.id!,
			});
			m = await groupStore.saveNew(m);
			props.onDone(m);
		} finally {
			runInAction(() => {
				groupStore._isBusy = false;
			});
		}
	};
	const uploadFolder = authStore.uploadFolder;

	return (
		<Formik validationSchema={groupNewSchema} initialValues={{ title: title, imageKey: '' }} onSubmit={handleSubmit}>
			{({ errors, touched, setFieldValue }) => {
				const onChange = (image: ImageModel) => {
					setFieldValue('cropKey', image.cropKey);
					setFieldValue('md5Hash', image.md5Hash);
					setFieldValue('cropData', image.cropData);
				};
				return (
					<Form className="group-new-form">
						<FormField className="title" label={t('general:group.title')} error={errors.title} touched={touched.title}>
							<Field type="text" name="title" autoComplete="username" />
						</FormField>
						<div>
							<FormField className="image" label={t('general:group.titleImage')}>
								<ImageUploadField type="text" name="md5Hash" value={titleImage} onChange={onChange} uploadFolder={uploadFolder} />
							</FormField>
						</div>

						<div className="actions button-container">
							<Button type="submit" className="button is-primary" disabled={groupStore.isBusy || groupStore.isEntityLoading}>
								{t('general:group.add.submit')}
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
