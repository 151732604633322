import React from 'react';
import { GroupModel, ImageModel } from 'shared-puredio';
import * as Yup from 'yup';
import t from 'app/common/translate';
import { Formik, Field, Form } from 'formik';
import FormField from '../Form/FormField';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { ImageUploadField } from '../fields/ImageUploadField';
import { Button } from 'app/components/common/Button';
import { GroupDeleteModal } from 'app/components/Group/GroupDeleteModal';

const groupNewSchema = Yup.object()
	.shape({
		title: Yup.string().required(t('validation:required.field')).default(''),
		md5Hash: Yup.string().default(''),
		originalFileName: Yup.string().default(''),
		cropKey: Yup.string().default(''),
		cropData: Yup.object().default({}),
	})
	.required();
type NewGroup = Yup.InferType<typeof groupNewSchema>;

interface IGroupEditProps {
	group: GroupModel;
	onDone: (g: GroupModel) => void;
	onDeleted: () => void;
}

export const GroupEditForm: React.FC<IGroupEditProps> = observer((props: IGroupEditProps) => {
	const store = useStore();
	const group = props.group;
	const title = group.title;
	const originalFileName = group.titleImage.originalFileName;
	const md5Hash = group.titleImage.md5Hash;
	const cropKey = group.titleImage.cropKey;
	const cropData = group.titleImage.cropData;
	async function handleSubmit(values: NewGroup) {
		group.title = values.title;
		group.titleImage.originalFileName = values.originalFileName!;
		group.titleImage.md5Hash = values.md5Hash;
		group.titleImage.cropKey = values.cropKey;
		group.titleImage.cropData = values.cropData;
		const m = await store.groupStore.save(group);
		props.onDone(m);
	}
	const uploadFolder = store.authStore.uploadFolder;

	const deleteModalId = store.uiStore.modalIds.deleteGroup(group.id);
	const showDeleteModal = () => {
		store.uiStore.showModal(deleteModalId);
	};

	const groupDeleted = () => {
		props.onDeleted();
	};

	return (
		<>
			<Formik validationSchema={groupNewSchema} initialValues={{ title, md5Hash, cropKey, cropData, originalFileName }} onSubmit={handleSubmit}>
				{({ errors, touched, setFieldValue }) => {
					const onChange = (image: ImageModel) => {
						setFieldValue('md5Hash', image.originalFileName);
						setFieldValue('md5Hash', image.md5Hash);
						setFieldValue('cropKey', image.cropKey);
						setFieldValue('cropData', image.cropData);
						console.log('onChange', image);
					};
					return (
						<Form className="group-edit-form">
							<FormField className="title" label={t('general:group.title')} error={errors.title} touched={touched.title}>
								<Field type="text" name="title" autoComplete="username" />
							</FormField>
							<FormField className="image" label={t('general:group.titleImage')} error={errors.md5Hash} touched={touched.md5Hash}>
								<ImageUploadField type="text" name="md5Hash" value={group.titleImage} onChange={onChange} uploadFolder={uploadFolder} />
							</FormField>
							<div className="actions">
								<Button type="button" onClick={showDeleteModal} className="button is-red is-inverted" disabled={store.projectStore.isEntityLoading}>
									{t('general:group.delete')}
								</Button>
								<Button type="submit" className="button is-primary" disabled={store.projectStore.isEntityLoading}>
									{t('common:save')}
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
			<GroupDeleteModal group={group} modalId={deleteModalId} onDone={groupDeleted} />
		</>
	);
});
