import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
interface IInputControl {
	value: string;
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
	type?: string;
	numbersOnly?: boolean;
	debounceTime?: number;
	classModifier?: string;
}

const InputControl = observer((props: IInputControl) => {
	const type = props.type ? props.type : 'text';
	const id = props.id ? props.id : type + '' + props.name;
	const numbersOnly = props.numbersOnly || false;
	const debounceTime = props.debounceTime || 0;
	const [value, setValue] = useState<string>(props.value);
	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	const debounceValueChange = (name: string, value: string) => {
		if (numbersOnly) {
			value = value.replace(/\D/, '');
		}
		props.onChange(name, value);
	};
	const handler = useCallback(debounce(debounceValueChange, debounceTime), []);
	const onChange = (event: any) => {
		setValue(event.target.value);
		handler(event.target.name, event.target.value);
	};
	return (
		<div className={`form-control ${props.classModifier || ''}`}>
			{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
			<input className="input" id={id} key={id} name={props.name} onChange={onChange} type={type} value={value} />
		</div>
	);
});

export default InputControl;
