import React, { useEffect, useState } from 'react';
import { ExportTaskModel, ProjectModel } from 'shared-puredio';
import Icon from './Icon';
import t from 'app/common/translate';
import { useStore } from 'app/context';
import { RenderState } from '../Project/Render/ProjectRender';

interface IRenderStatusProps {
	project?: ProjectModel;
	mainTask?: ExportTaskModel;
}
export const RenderStatus = (props: IRenderStatusProps) => {
	const { mainTask, project } = props;
	const { projectStore } = useStore();
	const [renderState, setRenderState] = useState<RenderState>(RenderState.canStart);

	useEffect(() => {
		let isCancelled = false;
		if (!project) {
			return;
		}
		projectStore.getRenderStatus(project, mainTask).then((status) => {
			if (isCancelled) {
				return;
			}
			setRenderState(status);
		});
		return () => {
			isCancelled = true;
		};
	});

	if (!mainTask || !project) {
		return <div></div>;
	}

	switch (renderState) {
		case RenderState.canStart:
		case RenderState.waitingForAudio:
			return <div></div>;
		case RenderState.error:
			return (
				<div className="render-status">
					<Icon iconName="circle text-red" />
					<span>{t('components:renderStatus.error')}</span>
				</div>
			);
		case RenderState.aborted:
			return (
				<div className="render-status">
					<Icon iconName="circle text-red" />
					<span>{t('components:renderStatus.aborted')}</span>
				</div>
			);
		case RenderState.success:
			return (
				<div className="render-status">
					<Icon iconName="circle text-green" />
					<span>{t('components:renderStatus.success')}</span>
				</div>
			);
		default:
			return (
				<div className="render-status">
					<Icon iconName="spinner fa-pulse text-grey" />
					<span>{t('components:renderStatus.processing')}</span>
				</div>
			);
	}
};
