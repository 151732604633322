import { AdminExportUsageApi } from 'app/api/export.usage.api';
import { AdminProjectApi } from 'app/api/project.api';
import { UserApi } from 'app/api/user.api';
import { makeObservable, observable, runInAction } from 'mobx';
import { ExportUsageModel, ProjectModel, UserModel } from 'shared-puredio';
import { FileStore } from './file.store';
import { ProjectStore } from './project.store';

export class AdminUsageVm {
	constructor(usage: ExportUsageModel, p?: ProjectModel, u?: UserModel) {
		makeObservable(this);
		this.usage = usage;
		this.project = p;
		this.user = u;
	}
	@observable
	usage: ExportUsageModel;

	@observable
	project?: ProjectModel;
	user?: UserModel;
}

export class AdminUsageStore {
	adminProjectApi: AdminProjectApi;
	userApi: UserApi;
	projectStore: ProjectStore;
	fileStore: FileStore;
	usageApi: AdminExportUsageApi;

	constructor(adminProjectApi: AdminProjectApi, userApi: UserApi, projectStore: ProjectStore, fileStore: FileStore, usageApi: AdminExportUsageApi) {
		makeObservable(this);
		this.adminProjectApi = adminProjectApi;
		this.userApi = userApi;
		this.projectStore = projectStore;
		this.fileStore = fileStore;
		this.usageApi = usageApi;
	}

	@observable
	items: AdminUsageVm[] = observable([]);

	@observable
	current?: AdminUsageVm = undefined;

	// @action
	// loadById(projectId: string) {
	// 	this.current = this.items.find((i) => i.project.id === projectId);
	// }

	async load() {
		const scope = {
			userId: 'userId',
		};
		const p: any = [];
		p.push(this.usageApi.listAll(scope));
		p.push(this.adminProjectApi.listAll(scope));
		p.push(this.userApi.listAll(scope));

		const res = await Promise.all(p);

		const usage = res[0] as ExportUsageModel[];
		const projects = res[1] as ProjectModel[];
		const users = res[2] as UserModel[];

		const items = usage.map((usage) => {
			const u = users.find((u) => u.fakeId === usage.userId);
			const p = projects.find((p) => p.id === usage.projectId);
			return new AdminUsageVm(usage, p, u);
		});

		runInAction(() => {
			this.items = items;
		});
	}
}
