import gsap from 'gsap';
import { computed, reaction } from 'mobx';
import { PointText, Color, Point, Shape, Group } from 'paper';
import { LayerType } from '../..';
import { BaseTextLayer } from './BaseTextLayer';

export default class TextArea extends BaseTextLayer {
    public layerType: LayerType = LayerType.textArea;
    constrainProportions = false;

    @computed
    get fontSize() {
        return this.anim.animationLayer.opts.fontSize;
    }

    public getDefaultOpts = (ftmId: string) => {
        return {
            x: {
                v: 0.5,
                d: 'w',
            },
            y: {
                v: 0.5,
                d: 'h',
            },
            width: {
                v: 0.5,
                d: 'w',
            },
            height: {
                v: 0.25,
                d: 'h',
            },
            color: '#000000',
            content: 'Text',
            fontFamily: 'Roboto',
            fontSize: 30,
            rotation: 0,
            scale: 1,
            backgroundColor: '#FFFFFF',
            textAlign: 'center',
        };
    };

    public create = async () => {
        this.group = new Group();

        let disp = reaction(
            () => [
                this.content,
                this.phrases,
                this.color,
                this.fontFamily,
                this.fontVariant,
                this.textAlign,
                // this.font,
                this.fontSize,
                this.hasBackground,
                this.backgroundColor,
                this.x,
                this.y,
                this.width,
                this.height,
                this.scale,
                this.rotation,
            ],
            () => {
                this.drawText();
                this.setupControlGroup();
            },
            { fireImmediately: true },
        );

        this.disposers.push(disp);
    };

    public drawText = (currentframe?: number) => {
        this.group.removeChildren();

        // prepare props
        // const content = this.getTextForFrame(currentframe || Math.floor((gsap.globalTimeline.time() - this.anim.audioLayer.start) * 30));
        const x = this.calc(this.x);
        const y = this.calc(this.y);
        const width = this.calc(this.width);
        const height = this.calc(this.height);
        // const textAlign = this.textAlign || 'center';
        // const color = this.color || new Color(0, 0, 0, 0.7);
        // const fontFamily = this.googleFont.family;
        // const fontWeight = this.googleFont.currentVariant.fontWeight;
        // const fontSize = this.fontSize || 100;
        const scale = this.scale;
        let fontStyle = this.googleFont.currentVariant.fontStyle;
        if (fontStyle === 'regular') {
            fontStyle = '';
        }

        if (scale !== 1 || !this.fontSize) {
            this.normaliseScale();
        }

        const reddy = new Shape.Rectangle({
            point: [x, y],
            size: [width, height],
            fillColor: 'transparent',
            opacity: 0.5,
        });

        this.group.addChild(reddy);
        this.group.position.x = x;
        this.group.position.y = y;
        this.group.rotation = this.rotation;

        this.redrawText(currentframe);
        this.controlGroup.bind(this);
    };

    public redrawText = (currentframe?: number) => {
        if (this.text) {
            this.text.remove();
        }

        const content = this.getTextForFrame(currentframe || Math.floor((gsap.globalTimeline.time() - this.anim.audioLayer.start) * 30));
        const x = this.calc(this.x);
        const y = this.calc(this.y);
        const textAlign = this.textAlign || 'center';
        const color = this.color || new Color(0, 0, 0, 0.7);
        const fontFamily = this.googleFont.family;
        const fontWeight = this.googleFont.currentVariant.fontWeight;
        const fontSize = this.fontSize || 100;
        let fontStyle = this.googleFont.currentVariant.fontStyle;
        if (fontStyle === 'regular') {
            fontStyle = '';
        }

        const ptOpts: any = {
            point: [x, y],
            content: this.calculateWordRows(content),
            fillColor: color,
            fontFamily: fontFamily,
            // fontWeight: (fontWeight + ' ' + fontStyle).trim(),
            justification: textAlign,
            fontSize: fontSize,
            selected: false,
        };

        if (fontWeight && fontStyle) {
            ptOpts.fontWeight = (fontWeight + ' ' + fontStyle).trim();
        }
        if (fontWeight && !fontStyle) {
            ptOpts.fontWeight = fontWeight;
        }
        if (!fontWeight && fontStyle) {
            ptOpts.fontWeight = fontStyle;
        }
        if (!fontWeight && !fontStyle) {
            ptOpts.fontWeight = 400;
        }

        const text = new PointText(ptOpts);

        text.bounds.top = this.group.bounds.top;
        text.bounds.left = this.group.bounds.left;
        if (textAlign === 'center') {
            text.bounds.center.x = this.group.bounds.center.x;
        }
        if (textAlign === 'right') {
            text.bounds.right = this.group.bounds.right;
        }

        this.text = text;
        this.drawBackgroundRect();
        this.group.insertAbove(text);
    };

    public onPositionUpdate = () => {
        this.redrawText();
    };
    public onScaleUpdate = () => {
        this.redrawText();
    };

    public calculateWordRows = (content: string) => {
        // split words into array seperated by spaces
        // add words to text box one at a time until the width exceeds the bounds
        // remove last word, and add this as a row with /n at the end
        // repeat

        if (!content) {
            return '';
        }

        // create text box
        const fontFamily = this.googleFont.family;
        const fontWeight = this.googleFont.currentVariant.fontWeight;
        const fontSize = this.fontSize || 8;
        let fontStyle = this.googleFont.currentVariant.fontStyle;
        if (fontStyle === 'regular') {
            fontStyle = '';
        }

        const tempText = new PointText({
            fillColor: 'red',
            fontFamily: fontFamily,
            fontWeight: fontWeight + ' ' + fontStyle,
            fontSize: fontSize,
        });

        let ret = '';
        let contentLines = content.split('\n');

        contentLines.forEach((cl, i) => {
            let contentArr = cl.split(' ');
            let lineArr = [];

            while (contentArr.length > 0) {
                // remove first word of array, add it to lineArr
                let word = contentArr.shift();
                lineArr.push(word);
                tempText.content = lineArr.join(' ');

                // if lineArr is too long
                if (tempText.bounds.width > this.group.bounds.width) {
                    //remove last word, put it back in contentArr.... but only if it's not a single word
                    if (lineArr.length > 1) {
                        lineArr.pop();
                        contentArr.unshift(word);
                    }

                    // add return text
                    ret += `${lineArr.join(' ')}\n`;
                    lineArr = [];
                }

                if (contentArr.length === 0) {
                    ret += `${lineArr.join(' ')}`;
                }
            }

            if (i < contentLines.length) {
                ret += '\n';
            }
        });

        tempText.remove();
        return ret.slice(0, -1);
    };

    public normaliseScale = () => {
        const scale = this.scale;
        const fontSize = this.fontSize || 100;
        const content = this.getTextForFrame(1);
        const fontFamily = this.googleFont.family;
        const fontWeight = this.googleFont.currentVariant.fontWeight;
        let fontStyle = this.googleFont.currentVariant.fontStyle;
        if (fontStyle === 'regular') {
            fontStyle = '';
        }

        // create a temp text to measure the width/height
        const tempText = new PointText({
            content: content,
            fillColor: 'transparent',
            fontFamily: fontFamily,
            fontWeight: fontWeight + ' ' + fontStyle,
            fontSize: 100,
            scaling: [scale, scale],
        });

        const width = Math.ceil(tempText.bounds.width);
        const height = Math.ceil(tempText.bounds.height);
        tempText.remove();

        this.optsUpdate({
            fontSize: Math.floor(fontSize * scale),
            scale: 1,
            width: { v: this.calcFromPixel('w', width), d: 'w' },
            height: { v: this.calcFromPixel('h', height), d: 'h' },
        });
    };
}
