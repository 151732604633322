import { observer } from 'mobx-react';
import React from 'react';
import { Button } from './Button';
import { Modal } from './Modal';
import SupportForm from './SupportForm';
import t from '../../common/translate';
import { useStore } from 'app/context';

interface IContactButtonWithModalProps {
	className?: string;
	art?: string;
}

const ContactButtonWithModal: React.FC<IContactButtonWithModalProps> = observer(({ children, ...props }) => {
	const { uiStore } = useStore();
	const modalId = uiStore.modalIds.contact;

	const handleOpenSupportModal = () => {
		uiStore.showModal(modalId);
	};

	return (
		<>
			<Button {...props} onClick={handleOpenSupportModal}>
				{children}
			</Button>

			<Modal title={t('auth:support')} size="large" modalId={modalId}>
				<SupportForm art={props.art} />
			</Modal>
		</>
	);
});

export default ContactButtonWithModal;
