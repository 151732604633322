import React from 'react';
import { observer } from 'mobx-react';
import { ProjectVM } from 'app/stores/viewmodels/project.vm';
import { AnimatedPhrase, LayerModel } from 'shared-puredio';
import { AnimatedTextControl } from './AnimatedTextControl';
import { runInAction } from 'mobx';

interface IAnimLayerFormProps {
	title: string;
	projectVM: ProjectVM;
	animLayer: LayerModel;
}

export const LayerFormAnimatedText = observer((props: IAnimLayerFormProps) => {
	const { animLayer, projectVM } = props;

	const onPhraseChange = (name: any, phrases: AnimatedPhrase[]) => {
		runInAction(() => {
			animLayer.beginSnapshot(projectVM.navState);
			phrases.forEach((p) => {
				const a = animLayer.opts.phrases.find((a) => a.id === p.id);
				if (a) {
					// copy all relevant props, to foree the observer to fire wherever needed
					runInAction(() => {
						a.text = p.text;
						a.start = p.start;
						a.end = p.end;
						a.st = p.st;
						// console.log('put', a.start, a.end);
					});
				}
			});
			animLayer.opts.phrases = phrases;
			animLayer.commitSnapshot();
		});
	};

	return (
		<>
			<div className="secondary-controls" key={'phrases'}>
				<AnimatedTextControl name={'phrases'} value={animLayer.opts.phrases} onChange={onPhraseChange} />
			</div>
		</>
	);
});
