import React, { useEffect } from 'react';
import { IProps, useStore } from './../context';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { SideMenuAdmin } from 'app/components/SideMenuAdmin';
import { NotFound } from 'App';
import { runInAction } from 'mobx';
import { AdminMailsList } from 'app/components/admin/mails/AdminMailsList';
import { AdminMailDetailPanel } from 'app/components/admin/mails/AdminMailDetailPanel';

export interface AdminProjectParamTypes {
	id?: string;
}

const AdminMailsContainer: React.FC<IProps> = observer(() => {
	const { adminUserStore, adminMailsStore } = useStore();

	const { id } = useParams<AdminProjectParamTypes>();

	useEffect(() => {
		adminMailsStore.load().then(() => {
			if (id) {
				console.log('loading', id);
				adminMailsStore.loadById(id);
			} else {
				runInAction(() => {
					adminMailsStore.current = undefined;
				});
			}
		});
	}, [id, adminMailsStore]);

	return (
		<>
			{adminUserStore.isAdmin && (
				<BaseContainer menu={<SideMenuAdmin />}>
					<AdminMailsList />
					{adminMailsStore.current && <AdminMailDetailPanel />}
				</BaseContainer>
			)}
			{!adminUserStore.isAdmin && <NotFound />}
		</>
	);
});

export default AdminMailsContainer;
